import { LoadingOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { Observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { ActionLocked } from '../../../../../../common/components/ActionLocked/ActionLocked';
import { CommonBadge } from '../../../../../../common/components/CommonBadge/CommonBadge';
import { ItemLogo } from '../../../../../../common/components/ItemLogo/ItemLogo';
import { CURRENCY_SYMBOL } from '../../../../../../common/constants/currency-symbol.const';
import { monthDiff } from '../../../../../../common/utils/dates.utils';
import { displayMoneyValue, floatFixed, numberWithCommas, percentageDisplay, quantityFormatWithCommas } from '../../../../../../common/utils/number-display.utils';
import { getValueByPath, isNullOrUndefined } from '../../../../../../common/utils/object.utils';
import { useStore } from '../../../../../app/data/root.store';
import { AkoyaLink } from '../../../../../connected-institutions/components/akoya-link/AkoyaLink';
import { instSyncTimeAgo } from '../../../../../connected-institutions/components/institutions-panel/institutionPanel.commons';
import { PlaidLink } from '../../../../../connected-institutions/components/plaid-link/PlaidLink';
import { SaltedgeLink } from '../../../../../connected-institutions/components/saltedge-link/SaltedgeLink';
import { ToggleClosedItemsVisibility } from '../../../ToggleClosedItemsVisibility/ToggleClosedItemsVisibility';
import { stockTypeTitleMapper } from '../../../asset/components/Tickers/tickers.utils';
import { AssetTotalValue, CashFlowConnectedItemToolTipWrapper, CashFlowValue, HoldingValue, HoldingsBadgeSquare, HoldingsBadgeTriangle, ItemContent, ItemMoreInfos, ItemSelectedCheckbox, ItemSelectedCheckboxWrapper, ItemText, ItemTitle, ItemTitleHoldingsBadgeText, ItemTitleHoldingsBadgeTriangle, ItemTitleHoldingsBadgeWrapper, ItemValue, ListItemWrapper, MoreInfosItem, TableCell } from '../../../class/components/ClassPage.styles';
import { IndicationCircle, IndicationsWrapper, InflowOutflowCalculationMessage } from '../WealthOverviewPage.styles';
import { getRelevantValueByEventTypes } from '../../../../data/wealth.utils';

const showUsdValueCategories = [39,36]

const getValueByCategory = (item, isIncognito ,isPartialHolding ) => {
  const value =  ( showUsdValueCategories.includes(item.categoryId) ? item.itemCurrencyValue : item.holdingsValue ) / ( isPartialHolding ? item.holdings : 1)
  return item.isClosed ? '--' : displayMoneyValue(value, item.currency, isIncognito )
}

export const ItemsListView = (props) => {
  const { isIncognito , handleItemClick } = props;
  const { wealthStore, inflowOutflowDataLayersStore, uiStore } = useStore()
  
  return (<Observer>{() => (
    <>    
      
      {
        wealthStore.displayedListItems.filter(item => wealthStore.isShowClosedAssets ? true : !item.isClosed ).map((item, index) => {
          return (
            <ItemRow key={item.isContainer ? ('c'+item.title) : item.id} item={item} index={index} isIncognito={isIncognito} handleItemClick={handleItemClick} />
        )})}

        {wealthStore.closedItemsInDisplayCount ? <ToggleClosedItemsVisibility closedCount={wealthStore.closedItemsInDisplayCount} /> : null}
             
        {wealthStore.viewType === 'cashflow' && <ListItemWrapper isTotal isCashFlowDataView lastRounded
            matricesColumns={ uiStore.isDesktopView  ? inflowOutflowDataLayersStore.columns.length : 1}>
            <TableCell bold>Total ({wealthStore.displayedListItems.length} items)</TableCell>
            {( uiStore.isDesktopView ? wealthStore.inflowOutflowTotalColumns : [wealthStore.inflowOutflowTotalColumns[wealthStore.inflowOutflowTotalColumns.length-1]] ).map(cl => (
                    <TableCell key={cl.label} isValue isCashFlow>
                      <CashFlowValue isInflow>{ displayMoneyValue(cl.inflow,'USD') }</CashFlowValue>
                      <CashFlowValue>{displayMoneyValue(cl.outflow,'USD')}</CashFlowValue>
                    </TableCell>
                  ))}
        </ListItemWrapper>}

        { wealthStore.viewType === 'cashflow' && wealthStore.isContainItemsFromCashOrCredit && wealthStore.isContainItemsFromNonCashOrCredit && <InflowOutflowCalculationMessage>* Cash accounts and credit cards are not included in total inflows and outflows calculations.</InflowOutflowCalculationMessage> }
    </>
  )}</Observer>)
}

const ItemRow = ({item, index, isIncognito , handleItemClick}) => {
  const { wealthStore, dataLayersStore, inflowOutflowDataLayersStore, uiStore } = useStore()
  
  const handleInstReconnect = () => {
    window.location.reload();
  }

  const handleReconnectError = (item) => {
    uiStore.setShowReconnectErrorModal(true, item.connectedInstitutionName , item.logoBase64, item.logoUrl );
  }

  const handleItemSelectedCheckboxClick = (e) => {
    e.stopPropagation();
    if (!item.isContainer){
      if (wealthStore.selectedItemsForBulkEdit.length === 0) {
        wealthStore.setIsBulkEditMode(true);
      }
      wealthStore.addOrRemoveItemForBulkEdit(item.id);
    }
  }
  
  return (<Observer>{() => (

          <ListItemWrapper key={item.isContainer ? ('c'+item.title) : item.id} 
            isCashFlowDataView={wealthStore.viewType === 'cashflow'}
            matricesColumns={wealthStore.viewType === 'cashflow' ? (uiStore.isDesktopView ? inflowOutflowDataLayersStore.columns.length : 1) : (  uiStore.isDesktopView ? dataLayersStore.dataLayersColumns.length : 0 ) } 
            onClick={() => { handleItemClick(item) }} lastRounded
            isSelected={wealthStore.selectedItemsForBulkEdit.includes(item.id)}
            {...( index === 1 ? {className : 'item_list_row_2'} : {})}
            >
            <TableCell style={{overflow:'hidden'}} isBulkEditMode={wealthStore.isBulkEditMode}>
              {uiStore.isDesktopView && <ItemSelectedCheckboxWrapper disabled={item.isContainer} onClick={handleItemSelectedCheckboxClick} isSelected={wealthStore.selectedItemsForBulkEdit.includes(item.id)} isBulkEditMode={wealthStore.isBulkEditMode}>
                  <ItemSelectedCheckbox isSelected={wealthStore.selectedItemsForBulkEdit.includes(item.id)}>
                      {wealthStore.selectedItemsForBulkEdit.includes(item.id) && <ItemSelectedIcon />}
                  </ItemSelectedCheckbox>
                </ItemSelectedCheckboxWrapper>}
              
              <ItemLogo item={item} />
              <ItemContent>
                {(item.category.id === 39 && !item.isContainer) ? <StockItemTitleDisplay  item={item} viewType={wealthStore.viewType} isDesktopView={uiStore.isDesktopView}/> :
                <ItemText>
                  <ItemTitle>
                    {/* {item.title.split(' ').map((word)=>(<span>{word}</span>))} */}
                    {item.title}
                    {wealthStore.viewType === 'cashflow' && item.isInflowOutflowCalculated === false ? '*' : ''}
                    {item.holdings !== 1 && <ItemTitleHoldingsBadgeWrapper><ItemTitleHoldingsBadgeText>{floatFixed(item.holdings * 100, 2)}%</ItemTitleHoldingsBadgeText><ItemTitleHoldingsBadgeTriangle /></ItemTitleHoldingsBadgeWrapper>}
                    {uiStore.isDesktopView && item.isClosed && <CommonBadge badgeType={'closed'} />}
                    {item.trnIndication || item.eventIndication ? <IndicationsWrapper>{item.trnIndication ? <Tooltip title="Review transaction"><IndicationCircle /></Tooltip> : item.eventIndication ? <Tooltip title="Event overdue"><IndicationCircle isEventPass /></Tooltip> : null}</IndicationsWrapper> : null}
                  </ItemTitle>

                  <ItemMoreInfos>
                    <MoreInfosItem>
                      {item.isContainer && !item.isConnected ? 'Investment account' : item.category.title}
                    </MoreInfosItem>
                    {item.isLoginRequired ?
                      (item.connectedProvider === 'plaid' ?
                        <ActionLocked action={'classPageLoginRequired'}>
                          <PlaidLink reconnectId={item.connectedInstitutionId} onSuccess={() => handleInstReconnect()} >
                            <CommonBadge noMargin={!uiStore.isDesktopView} isCTA badgeType={'loginRequired'} lastSync={item.lastSync} />
                          </PlaidLink>
                        </ActionLocked>
                        :
                        item.connectedProvider === 'saltEdge' ? 
                        <ActionLocked action={'classPageLoginRequired'}>
                          <SaltedgeLink reconnectId={item.connectedInstitutionId} onSuccess={(data) => { handleInstReconnect() }} loadingComp={<Button ><LoadingOutlined /></Button>}>
                            <CommonBadge isCTA noMargin={!uiStore.isDesktopView}  badgeType={'loginRequired'} lastSync={item.lastSync} />
                          </SaltedgeLink>
                        </ActionLocked>
                        :
                        <ActionLocked action={'classPageLoginRequired'}>
                          <AkoyaLink reconnectId={item.connectedInstitutionId} onError={(data)=>{ handleReconnectError(item) }} 
                            onSuccess={(data) => { handleInstReconnect() }} loadingComp={<Button ><LoadingOutlined /></Button>}>
                            <CommonBadge isCTA noMargin={!uiStore.isDesktopView} badgeType={'loginRequired'} lastSync={item.lastSync} />
                          </AkoyaLink>
                        </ActionLocked>
                      ) : 
                      item.isNotFound ?
                        <MoreInfosItem><CommonBadge noMargin={!uiStore.isDesktopView}  badgeType={'notFound'} /></MoreInfosItem> 
                      : item.vipInstitutionId ?
                      <MoreInfosItem><CommonBadge noMargin={!uiStore.isDesktopView}  badgeType={'vipService'} /></MoreInfosItem>
                      : item.isConnected ?
                        <MoreInfosItem><CommonBadge noMargin={!uiStore.isDesktopView}  badgeType={'connected'} lastSync={item.lastSync} /></MoreInfosItem>
                       : null }
                       {!uiStore.isDesktopView && item.isClosed && <CommonBadge noMargin badgeType={'closed'} />}
                  </ItemMoreInfos>
                </ItemText> }
              </ItemContent>

            </TableCell>

          {wealthStore.viewType === 'overview' && <>
            { dataLayersStore.dataLayersColumns.map(dl => (
              <TableCell key={dl.dataKey} isValue={dl.type==='ast_value' || !uiStore.isDesktopView }>
                {RenderDataValue(dl, item, isIncognito)}
              </TableCell>
            ))}
            {/* <TableCell>
              <IndicationsWrapper>{item.trnIndication ?   <Tooltip title="Review transaction"><IndicationCircle /></Tooltip> : item.eventIndication ? <Tooltip title="Event overdue"><IndicationCircle isEventPass /></Tooltip> : null }</IndicationsWrapper> 
              </TableCell> */}
            {( uiStore.isDesktopView || dataLayersStore.dataLayersColumns.length === 0 ) &&  <TableCell isValue>


              <ItemValue>
                <HoldingValue>{ getValueByCategory(item, isIncognito )} {(!CURRENCY_SYMBOL[item.currency] && !showUsdValueCategories.includes(item.categoryId)) && item.currency}</HoldingValue>
                {item.holdings !== 1 && !item.isClosed &&
                  <AssetTotalValue>{getValueByCategory(item, isIncognito, true)} {(!CURRENCY_SYMBOL[item.currency] && !showUsdValueCategories.includes(item.categoryId)) ? item.currency : ''}<HoldingsBadgeSquare /><HoldingsBadgeTriangle /></AssetTotalValue>}
                {item.categoryId === 39 && !item.isClosed &&
                  <HoldingValue style={{fontSize:'12px'}}>({quantityFormatWithCommas(item.holdingsValue)} {item.stockDetails.symbol})</HoldingValue>}
                {item.categoryId === 36 && !item.isClosed && item.cryptoCoinDetails &&
                  <HoldingValue style={{fontSize:'12px'}}>({quantityFormatWithCommas(item.holdingsValue)} {item.cryptoCoinDetails?.symbol})</HoldingValue>}
              </ItemValue>
            </TableCell>}
            </>}

            {wealthStore.viewType === 'cashflow' && <>
                  { (uiStore.isDesktopView ? inflowOutflowDataLayersStore.columns : [inflowOutflowDataLayersStore.columns[inflowOutflowDataLayersStore.columns.length-1]] ).map((cl,index) => (
                    <TableCell key={cl.label} isValue isCashFlow>
                      {RenderCashFlowValue(item.historicalCashFlow, cl.date , inflowOutflowDataLayersStore.timeFrame, cl.timeFrameIndex , item.hideInflowOutflowData ,index === inflowOutflowDataLayersStore.columns.length-1, inflowOutflowDataLayersStore.selectedEventTypes )}
                    </TableCell>
                  ))}
            </>}
          </ListItemWrapper>
     
  )}</Observer>)
}

export const ItemSelectedIcon = () => (
  <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.7553 1.30005L3.25305 4.8043L1.50004 3.05182" stroke="white" strokeWidth="1.5"/>
</svg>

)

export const RenderDataValue = (dataLayerColumn, item, isIncognito) => {
  let dataLayerValue = ( dataLayerColumn?.categoryPath?.[item.categoryId] ) ? 
  getValueByPath(item,dataLayerColumn.categoryPath[item.categoryId])
  : dataLayerColumn.path ? getValueByPath(item,dataLayerColumn.path) : item[dataLayerColumn.dataKey] ;

  if (isNullOrUndefined(dataLayerValue) && dataLayerColumn.fallbackPath){ 
      dataLayerValue = getValueByPath(item,dataLayerColumn.fallbackPath) 
  }
  
  if (!dataLayerValue && dataLayerValue !== 0 && dataLayerColumn.type !== 'bool'){ return '--' }
  
  switch (dataLayerColumn.type) {
    case 'date' : 
      let toReturn;
      
      if (dataLayerColumn.dataKey === 'lastUpdate') {
        if (isNaN(new Date(dataLayerValue).getTime())){
          return '--'
        }
        toReturn = instSyncTimeAgo(moment(dataLayerValue).toDate());
      } else {
        toReturn = moment(dataLayerValue).format('MMM YYYY');
      }
      return toReturn;
    case 'remainingTimeFromDate' :
      if (moment(dataLayerValue).isValid){
        const monthsDiff = monthDiff(new Date(), moment(dataLayerValue).toDate());
        return `${monthsDiff} Month${monthsDiff > 1 ? "s" : ''}`
      } else {
        return '--'
      }
    case 'money':
      return displayMoneyValue(dataLayerValue, item.currency, isIncognito, item.holdings ? item.holdings * 100 : 100); 
    case 'number' :
      return numberWithCommas(dataLayerValue); 
    case 'percentage':
      return percentageDisplay(dataLayerValue);
    case 'percentageMax1':
      return percentageDisplay(dataLayerValue * 100);
    case 'multiplier':
      return numberWithCommas(parseFloat(dataLayerValue.toFixed(2)))+'x';
    case 'text' :
      let textValue = ( dataLayerColumn.labels && dataLayerColumn.labels[dataLayerValue] ) ? dataLayerColumn.labels[dataLayerValue] : dataLayerValue;
      return <FreeTextOverflow value={textValue} />
    case 'liabilityAmount' : 
      return Array.isArray(dataLayerValue) && dataLayerValue.length ? displayMoneyValue( dataLayerValue.reduce((a,b)=>a+b.usdValue , 0),'USD') : '--';
    case 'bool' : 
      return dataLayerValue ? 'V' : '--'  
    default:
      return dataLayerValue
  }
};

export const RenderCashFlowValue = (data , date, timeFrame, timeFrameIndex, hideInflowOutflowData , isLastColumn, selectedEventTypes) => {
  let inflowValue = 0;
  let outflowValue = 0;
  if (!isNullOrUndefined(data) && !hideInflowOutflowData ){
    if (timeFrame === 'months'){
      if (data[date.format("MM-YYYY")]){
        const relevantValue = data[date.format("MM-YYYY")];
        // const relevantInflowAddition = getDeltaByEventTypes(relevantValue.i.ae, selectedEventTypes);
        // inflowValue = (relevantValue.i.a - relevantInflowAddition) || 0;
        // const relevantOutflowAddition = getDeltaByEventTypes(relevantValue.o.ae, selectedEventTypes, 'out');
        // outflowValue = (relevantValue.o.a + relevantOutflowAddition) || 0;
        inflowValue = getRelevantValueByEventTypes(relevantValue.i.ae, selectedEventTypes);
        outflowValue = getRelevantValueByEventTypes(relevantValue.o.ae, selectedEventTypes , 'out');
      }
    } else if (timeFrame === 'years'){
      const relevantYear = date.year();
      for (const key in data) {
        if (key.endsWith(`-${relevantYear}`)) {
          // const relevantInflowAddition = getDeltaByEventTypes(data[key].i.ae, selectedEventTypes);
          // inflowValue += (data[key].i.a - relevantInflowAddition) || 0;
          // const relevantOutflowAddition = getDeltaByEventTypes(data[key].o.ae, selectedEventTypes, 'out');
          // outflowValue += (data[key].o.a + relevantOutflowAddition) || 0;
          inflowValue += getRelevantValueByEventTypes(data[key].i.ae, selectedEventTypes);
          outflowValue += getRelevantValueByEventTypes(data[key].o.ae, selectedEventTypes , 'out');
        }
      }
    } else if (timeFrame === 'quarters'){
      const relevantYear = date.year();
      for (const key in data) {
        const relevantMonth = parseInt(key.substring(0,2));
        if (key.endsWith(`-${relevantYear}`) && relevantMonth >= timeFrameIndex*3-2 && relevantMonth <= timeFrameIndex*3) {
          // const relevantInflowAddition = getDeltaByEventTypes(data[key].i.ae, selectedEventTypes);
          // inflowValue += (data[key].i.a - relevantInflowAddition) || 0;
          // const relevantOutflowAddition = getDeltaByEventTypes(data[key].o.ae, selectedEventTypes, 'out');
          // outflowValue += (data[key].o.a + relevantOutflowAddition) || 0;
          inflowValue += getRelevantValueByEventTypes(data[key].i.ae, selectedEventTypes);
          outflowValue += getRelevantValueByEventTypes(data[key].o.ae, selectedEventTypes , 'out');
        }
      }
    } else if (timeFrame === 'total'){
      for (const key in data) {
        // const relevantInflowAddition = getDeltaByEventTypes(data[key].i.ae, selectedEventTypes);
        // inflowValue += (data[key].i.a - relevantInflowAddition) || 0;
        // const relevantOutflowAddition = getDeltaByEventTypes(data[key].o.ae, selectedEventTypes, 'out');
        // outflowValue += (data[key].o.a + relevantOutflowAddition) || 0;
        inflowValue += getRelevantValueByEventTypes(data[key].i.ae, selectedEventTypes);
        outflowValue += getRelevantValueByEventTypes(data[key].o.ae, selectedEventTypes , 'out');
      }
    }
  }

  return (!hideInflowOutflowData ? <>
    <CashFlowValue isInflow>{displayMoneyValue(inflowValue,'USD')}</CashFlowValue>
    <CashFlowValue>{displayMoneyValue(outflowValue,'USD')}</CashFlowValue>
  </> : isLastColumn ? <CashFlowConnectedItemToolTipWrapper>
    <Tooltip 
    // overlayStyle={{marginLeft:'24px'}}
    align={{ offset: [25, 4]  }} 
    title={'Inflow/outflow is not available in online synced accounts'} placement={"top"}>
    <CashFlowValue isInflow>-</CashFlowValue>
    <CashFlowValue>-</CashFlowValue>
  </Tooltip></CashFlowConnectedItemToolTipWrapper> : null)
}


export const FreeTextOverflow = ({value}) => {

  const textRef = useRef(null);
  const [isTooltipNeeded, setIsTooltipNeeded] = useState(false);

  useEffect(() => {
    const needToolTip = textRef.current && textRef.current.scrollWidth+17 > textRef.current.parentElement.offsetWidth;
    setIsTooltipNeeded(needToolTip);
    return () => {}
  }, []);

  return ( <Tooltip title={isTooltipNeeded ? value : ''} placement={"topLeft"}>
              <div style={{overflow:'hidden',textOverflow:'ellipsis', whiteSpace: 'nowrap'}} ref={textRef} > {value}</div>
   
          {/* {isTooltipNeeded && <ArrowDownWrapper><ArrowDownSmallTriangleIcon /></ArrowDownWrapper>} */}
    </Tooltip>
  )
  

};

export const StockItemTitleDisplay = ({item, viewType, isDesktopView}) => {
  return (
    <ItemText>
                  <ItemTitle>
                    {`${item.stockDetails.symbol} - ${item.stockDetails.name}${viewType === 'cashflow' && item.isInflowOutflowCalculated === false ? '*' : ''}`}
                    {item.holdings !== 1 && <ItemTitleHoldingsBadgeWrapper><ItemTitleHoldingsBadgeText>{floatFixed(item.holdings * 100, 2)}%</ItemTitleHoldingsBadgeText><ItemTitleHoldingsBadgeTriangle /></ItemTitleHoldingsBadgeWrapper>}
                    {isDesktopView && item.isClosed && <CommonBadge badgeType={'closed'} />}
                    {item.trnIndication || item.eventIndication ? <IndicationsWrapper>{item.trnIndication ? <Tooltip title="Review transaction"><IndicationCircle /></Tooltip> : item.eventIndication ? <Tooltip title="Event overdue"><IndicationCircle isEventPass /></Tooltip> : null}</IndicationsWrapper> : null}
                  </ItemTitle>
                  {!isDesktopView && item.isClosed && <CommonBadge noMargin badgeType={'closed'} />}
                  <ItemMoreInfos keepRowForMobile>
                    <MoreInfosItem>
                      {stockTypeTitleMapper(item.stockDetails.type)}
                    </MoreInfosItem>
                    {
                       item.container && <MoreInfosItem leftMargin={-4}>· { item.container}{(item.name && item.name !== item.container) ? `, ${item.name}`: ''}</MoreInfosItem>
                    }
                   
                  </ItemMoreInfos>
                </ItemText>
)}