
import React from 'react';

// import { assetFieldsObj } from '../../../modules/wealth/pages/asset/components/assetFields';
// import { FieldLabel, InputSwitcherWrapper } from './FormDisplayer.styles';
import { Observer} from 'mobx-react';
import { useStore } from '../../../../modules/app/data/root.store';

import { Select } from 'antd';
import { ArrowDownIcon } from '../../../../assets/icons/common/common-icons';
import { isNullOrUndefinedOrEmptyString } from '../../../utils/object.utils';
// import { CheckMarkSvgWrapper, SelectWithDescriptionInOptionItemSubTitle, SelectWithDescriptionInOptionItemTitle, SelectWithDescriptionInOptionItemTitlesWrapper } from './SelectWithDescriptionInOption/SelectWithDescriptionInOption.styles';
import { ModifiedSelectClearIcon } from '../../ModifiedSelectClearIcon/ModifiedSelectClearIcon';
import { customClassColorsObj } from '../../../../modules/user/components/CustomClassList/icons/custom-colors';
import { customClassSvgObj } from '../../../../modules/user/components/CustomClassList/new-svgs/new-custom-svgs';
import { ImageTile, SelectEmptyStateMessage, SelectEmptyStateWrapper } from '../../../../modules/user/components/CustomClassList/EditCustomClass.styles';
import { colors } from '../../../styles/theme.styles';

export function CustomSubClassSelect(props) {
    const { inputKey, handleChange, disabled, value, scrollContainerId, defaultValue, allowClear , customClassId} = props;

    const {metadataStore , uiStore} = useStore();
    const customClass = metadataStore.customClassObj[customClassId];
    const { colorImage: relevantImage, fallbackColor: relevantFallbackColor } = customClassColorsObj[customClass?.colorCode] || {};
            const  RelevantSvg  =  customClassSvgObj[customClass?.icon] || '';
    const { Option } = Select;

    return (<Observer>{()=>(
      <Select
        value={value}
        name={inputKey}
        disabled={disabled}
        allowClear={allowClear && !isNullOrUndefinedOrEmptyString(value)}
        clearIcon={allowClear ? <ModifiedSelectClearIcon /> : null}
        optionLabelProp='label'
        defaultValue={defaultValue}
        onChange={(val) => { handleChange(inputKey,val)} }
        dropdownClassName='formDisplayerDefaultSelect'
        getPopupContainer={() => scrollContainerId ? (document.getElementById(scrollContainerId) || document.body) : document.body}
        suffixIcon={<ArrowDownIcon />}
        notFoundContent={<SelectEmptyStateWrapper>
            <ImageTile
                image={relevantImage}
                fallbackColor={relevantFallbackColor}
                size={24}
                borderRadius={8} >
                 {RelevantSvg && <RelevantSvg />}
            </ImageTile>
            <SelectEmptyStateMessage>No sub classes of this class</SelectEmptyStateMessage>
            
        </SelectEmptyStateWrapper>}
        dropdownRender={menu => (<>
            {menu}
            <div style={{ display: 'flex', flex: 1, alignItems:'center', justifyContent:'center'  , borderTop : `1px solid ${colors.gray3}` }}>
                <a style={{ display: 'flex', flex: 1, alignItems:'center', gap:'8px' , justifyContent:'center' , padding:'8px' , color:'#2565E7'  }} href={'/profile/preferences' +  (uiStore.isDesktopView ? '?spt=customClass' : `/custom-class-list`)} >Edit custom class and sub-class list<ArrowLinkSvg /></a>
            </div>
        </>)}
      >
        {metadataStore.customClassObj[customClassId]?.customSubClasses.map(customSubClass => {
            
            return (
                <Option key={customSubClass.id} value={customSubClass.id} className='formDisplayerSelectWithDescriptionOption' label={
                    <div style={{ display: 'flex', flex: 1, alignItems:'center', gap:'8px' }}>
                       <ImageTile
                            image={relevantImage}
                            fallbackColor={relevantFallbackColor}
                            size={24}
                        >
                        {RelevantSvg && <RelevantSvg />}
                     </ImageTile>
                        {customSubClass.name}</div>}>
            <div style={{ display: 'flex', flex: 1, alignItems:'center', gap:'8px' }}>
                       <ImageTile
                            image={relevantImage}
                            fallbackColor={relevantFallbackColor}
                            size={32}
                        >
                        {RelevantSvg && <RelevantSvg />}
                     </ImageTile>
                        {customSubClass.name}</div>
            </Option>
            )
        }    
        )}
      </Select>
)}</Observer>)
    }
    

const ArrowLinkSvg = () => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9675 8.875C10.9675 8.875 15.233 8.875 17.1652 8.875C17.1652 10.8072 17.1652 15.0727 17.1652 15.0727" stroke="#2565E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.25 16.7969L16.8958 9.15103" stroke="#2565E7" strokeWidth="1.5" strokeLinecap="round"/>
</svg>
)