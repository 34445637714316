import React, { useEffect } from 'react';
import { Tooltip } from 'antd';
import { useLocation, useHistory } from "react-router-dom";
import { ItemIconWrapper, ItemContent, ItemTitle, LightColorText, ItemValue, IndicationCircle, IndicationsWrapper, ShowHideClassButton, ClassAddButton, ItemDetailsWrapper, InflowOutflowCalculationMessage, AllocationItemIconWrapper, AllocationItemContent, AllocationItemCountsWrapper, AllocationItemCountSeparator, AllocationInstitutionItemIconWrapper } from '../WealthOverviewPage.styles';
import { useStore } from '../../../../../app/data/root.store';
import { displayMoneyFormatter, displayMoneyValue } from '../../../../../../common/utils/number-display.utils';
import { AssetClassIcon } from '../../../../../assets/components/AssetClassIcon';
import { Observer, observer } from 'mobx-react';
import { LockIcon } from '../../../../../../assets/icons/common/common-icons';
import { CashFlowValue, ListItemWrapper, TableCell } from '../../../class/components/ClassPage.styles';
import { AllocationIcon } from '../../../../../allocations/components/AllocationIcon';
import { allocationByFilterOptions } from '../../../../../allocations/data/allocations.const';
import { RenderDataValue } from './ItemsListView';
import { InstitutionLogoWrapper } from '../../../../../allocations/components/AllocateBy.styles';
import { ImageTile } from '../../../../../user/components/CustomClassList/EditCustomClass.styles';


export const WealthOverviewClassesListView = (props) => {
    const { isIncognito } = props;
    const { wealthStore , userStore, metadataStore, uiStore, billingStore, filtersStore, dataLayersStore, inflowOutflowDataLayersStore} = useStore();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        wealthStore.setUnSelectedClass();
        if (metadataStore.classWithItemsCount < 2){
            wealthStore.setViewType('overview');
        }
        return () => { }
    }, [location, wealthStore, metadataStore]);

    const handleClassOrCategoryClick = (item) => {
            if (item.items.length > 0 ){
                if (wealthStore.allocateBy==='classes'){
                    filtersStore.handleSelectClass(item.id);
                }  else {
                    filtersStore.handleSelectCategoryFromAllocation(item.classId , item.id);
                } 
                filtersStore.setAppliedFiltersFromSelection();
                wealthStore.refreshWealthData(false, history);
            } else {
                if(userStore.isDemoOrReadOnlyMode) {
                  billingStore.setShowPricingTableModal(true);
                } else {
                  // We decide to keep with empty class view.. , but still need to show billing popup if it's empty + demo or read only
                  filtersStore.handleSelectClass(item.id);
                  filtersStore.setAppliedFiltersFromSelection();
                  wealthStore.refreshWealthData(false, history);
            }
        }
    }

    const handleAllocationClick = (item) => {
        const allocationFilterOptions = allocationByFilterOptions[wealthStore.allocateBy];
        let refreshData = true;
        if (wealthStore.allocateBy === 'customClasses'){
            filtersStore.handleSelectCustomClass(item.filterValue);
        } else if (wealthStore.allocateBy === 'customSubClasses'){
            filtersStore.handleSelectSubClassFromAllocation(item.customClassId , item.filterValue);
        }
        else if (allocationFilterOptions){
            if (allocationFilterOptions.filterType === 'list'){
                filtersStore.setListOptionSelectedFromAllocation(allocationFilterOptions.filterPropName, item.filterValue);
            }
        } else {
            refreshData = false
        }
        if (refreshData){
            filtersStore.setAppliedFiltersFromSelection();
            wealthStore.refreshWealthData(false, history);
        }
    }

    const ClassOrCategoryItemRow = observer(({viewType,onClick,assetClass}) => {
        return (
            <ListItemWrapper key={assetClass.id} 
                onClick={onClick} 
                isCashFlowDataView={viewType === 'cashflow'}
                isClassItem
                matricesColumns={wealthStore.viewType === 'cashflow' ? (uiStore.isDesktopView ? inflowOutflowDataLayersStore.columns.length : 1) : (  uiStore.isDesktopView ? dataLayersStore.dataLayersColumns.length : 0 ) } 
                {...( assetClass.id === 4 ? {id: 'realEstateClassRow'} : {})}
                noBorder >
                <ItemDetailsWrapper>
                <ItemIconWrapper color={assetClass.color}><AssetClassIcon name={assetClass.icon} /></ItemIconWrapper>
                <ItemContent>
                    <ItemTitle>{assetClass.title}{viewType === 'cashflow' && wealthStore.allocateBy==='classes'  && !assetClass.isInflowOutflowCalculated ? '*' : ''} {assetClass.items?.length > 0 &&<LightColorText>({assetClass.items?.length})</LightColorText>}</ItemTitle>
                    <IndicationsWrapper>{assetClass.trnIndication ? <Tooltip title="Review transaction"><IndicationCircle /></Tooltip> : assetClass.eventIndication ? <Tooltip title="Event overdue"><IndicationCircle isEventPass /></Tooltip> : null }</IndicationsWrapper>
                </ItemContent>
                </ItemDetailsWrapper>
                {viewType === 'overview' && <>


                { dataLayersStore.dataLayersColumns.map(dl => (
                    <TableCell key={dl.dataKey} isValue={!uiStore.isDesktopView }>
                        {RenderDataValue(dl, assetClass, isIncognito)}
                    </TableCell>
                ))}
                 {( uiStore.isDesktopView || dataLayersStore.dataLayersColumns.length === 0 ) && 
                <TableCell isValue>
                {assetClass.items?.length > 0 ? 
                <ItemValue>{displayMoneyValue(assetClass.usdValue, 'USD', isIncognito)}</ItemValue> :
                <ClassAddButton>Add{userStore.isDemoOrReadOnlyMode ? <LockIcon style={{marginRight:'8px'}} /> : <ClassAddItemArrowIcon /> }</ClassAddButton>
                }
                </TableCell>
    }
                </>}
   
                {viewType === 'cashflow' && <>
                    { (uiStore.isDesktopView ? assetClass.cashflowColumns : [assetClass.cashflowColumns[assetClass.cashflowColumns.length-1]] ).map(cl => (
                        <TableCell key={cl.label} isValue isCashFlow>
                        <CashFlowValue isInflow>{displayMoneyFormatter(cl.inflow,'USD')}</CashFlowValue>
                        <CashFlowValue>{displayMoneyFormatter(cl.outflow,'USD')}</CashFlowValue>
                        </TableCell>
                    ))}
                </>}
            </ListItemWrapper>
        )
    })

    const AllocationItemRow = observer(({viewType,onClick,allocationItem}) => {
        // const { colorImage: relevantImage, fallbackColor: relevantFallbackColor } = customClassColorsObj[allocationItem.color] || {};
        const  RelevantSvg = allocationItem.relevantSvg; 
        return (
            <ListItemWrapper key={allocationItem.id} 
                onClick={onClick} 
                isCashFlowDataView={viewType === 'cashflow'}
                matricesColumns={wealthStore.viewType === 'cashflow' ? (uiStore.isDesktopView ? inflowOutflowDataLayersStore.columns.length : 1) : (  uiStore.isDesktopView ? dataLayersStore.dataLayersColumns.length : 0 ) } 
                noBorder >
                <ItemDetailsWrapper>
                    {allocationItem.allocationType === 'institution' ?
                    <AllocationInstitutionItemIconWrapper><InstitutionIcon title={allocationItem.title} /></AllocationInstitutionItemIconWrapper>
                    :
                    ['customClasses','customSubClasses'].includes(allocationItem.allocationType) && allocationItem.title !== 'Unspecified' ? 
                    <AllocationItemIconWrapper >
                    <ImageTile
                            image={allocationItem.relevantImage}
                            fallbackColor={allocationItem.relevantFallbackColor}
                            size={32}
                            borderRadius={2}
                        >
                       
                       <RelevantSvg />
                     </ImageTile>
                     </AllocationItemIconWrapper>
                    // <AllocationItemIconWrapper color={allocationItem.color}><AllocationIcon name={allocationItem.icon} title={allocationItem.title} {...(allocationItem.title==='Unspecified' ? { color:'white'} : {})} /></AllocationItemIconWrapper>
                    :
                    <AllocationItemIconWrapper color={allocationItem.color}><AllocationIcon name={allocationItem.icon} title={allocationItem.title} {...(allocationItem.title==='Unspecified' ? { color:'white'} : {})} /></AllocationItemIconWrapper>
                }
                <AllocationItemContent>
                    <ItemTitle>{allocationItem.title}</ItemTitle>
                    <AllocationItemCountsWrapper>
                        {allocationItem.assetsLength > 0 && <span>{allocationItem.assetsLength} assets</span>}
                        {allocationItem.assetsLength > 0 && allocationItem.liabilityLength > 0 && <AllocationItemCountSeparator />}  
                        {allocationItem.liabilityLength > 0 && <span>{allocationItem.liabilityLength} liabilities</span>}
                    </AllocationItemCountsWrapper>
                </AllocationItemContent>
                </ItemDetailsWrapper>
                {viewType === 'overview' && <>

                { dataLayersStore.dataLayersColumns.map(dl => (
                    <TableCell key={dl.dataKey} isValue={!uiStore.isDesktopView }>
                        {RenderDataValue(dl, allocationItem, isIncognito)}
                    </TableCell>
                ))}

{( uiStore.isDesktopView || dataLayersStore.dataLayersColumns.length === 0 ) && 
                <TableCell isValue>
                
                    <ItemValue>{displayMoneyValue(allocationItem.usdValue, 'USD', isIncognito)}</ItemValue>
                
                
                </TableCell>
    }
                </>}
                {viewType === 'cashflow' && <>
                    { (uiStore.isDesktopView ? allocationItem.cashflowColumns : [allocationItem.cashflowColumns[allocationItem.cashflowColumns.length-1]] ).map(cl => (
                        <TableCell key={cl.label} isValue isCashFlow>
                        <CashFlowValue isInflow>{displayMoneyFormatter(cl.inflow,'USD')}</CashFlowValue>
                        <CashFlowValue>{displayMoneyFormatter(cl.outflow,'USD')}</CashFlowValue>
                        </TableCell>
                    ))}
                </>}
            </ListItemWrapper>
        )
    })

    return (
        <Observer>{() => (
        <>
            {wealthStore.wealthOverviewList.map((item, itemIndex) => (
                <React.Fragment key={'fr'+ itemIndex}>
                    {
                        ['classes','categories'].includes(wealthStore.allocateBy) ?
                            <ClassOrCategoryItemRow 
                                key={item.id} 
                                viewType={wealthStore.viewType}
                                assetClass={item}
                                onClick={() => { handleClassOrCategoryClick(item) }}
                            /> :
                            <AllocationItemRow 
                                key={item.id} 
                                viewType={wealthStore.viewType}
                                allocationItem={item}
                                onClick={() => { handleAllocationClick(item) }}
                            />
                    }
                    {/* <ListItemWrapper key={assetClass.id} 
                    onClick={() => { handleClassClick(assetClass, assetClassIndex) }} 
                    isCashFlowDataView={wealthStore.viewType === 'cashflow'}
                    isClassItem
                    matricesColumns={wealthStore.viewType === 'cashflow' ? (uiStore.isDesktopView ? inflowOutflowDataLayersStore.columns.length : 1) : 0} 
                    {...( assetClass.id === 4 ? {id: 'realEstateClassRow'} : {})}
                    noBorder >
                    <ItemDetailsWrapper>
                    <ItemIconWrapper color={assetClass.color}><AssetClassIcon name={assetClass.icon} /></ItemIconWrapper>
                    <ItemContent>
                        <ItemTitle>{assetClass.title}{wealthStore.viewType === 'cashflow' && !assetClass.isInflowOutflowCalculated ? '*' : ''} {assetClass.items?.length > 0 &&<LightColorText>({assetClass.items?.length})</LightColorText>}</ItemTitle>
                        <IndicationsWrapper>{assetClass.trnIndication ? <Tooltip title="Review transaction"><IndicationCircle /></Tooltip> : assetClass.eventIndication ? <Tooltip title="Event overdue"><IndicationCircle isEventPass /></Tooltip> : null }</IndicationsWrapper>
                    </ItemContent>
                    </ItemDetailsWrapper>
                    {wealthStore.viewType === 'overview' && <>
                    <TableCell isValue>
                    {assetClass.items?.length > 0 ? 
                    <ItemValue>{displayMoneyValue(assetClass.totalUsdValue, 'USD', isIncognito)}</ItemValue> :
                    <ClassAddButton>Add{userStore.isDemoOrReadOnlyMode ? <LockIcon style={{marginRight:'8px'}} /> : <ClassAddItemArrowIcon /> }</ClassAddButton>
                    }
                    </TableCell>
                    </>}
                    {wealthStore.viewType === 'cashflow' && <>
                        { (uiStore.isDesktopView ? assetClass.cashflowColumns : [assetClass.cashflowColumns[assetClass.cashflowColumns.length-1]] ).map(cl => (
                            <TableCell key={cl.label} isValue isCashFlow>
                            <CashFlowValue isInflow>{displayMoneyFormatter(cl.inflow,'USD')}</CashFlowValue>
                            <CashFlowValue>{displayMoneyFormatter(cl.outflow,'USD')}</CashFlowValue>
                            </TableCell>
                        ))}
                    </>}
                </ListItemWrapper> */}
                </React.Fragment >
                
            ))}
            
            {wealthStore.allocateBy==='classes' && <>
            { wealthStore.shouldShowMoreClassButton && <ShowHideClassButton isCashFlowView={wealthStore.viewType === 'cashflow'} isOpen={wealthStore.isShowAllClasses} onClick={()=>{wealthStore.setIsShowAllClasses(!wealthStore.isShowAllClasses)}} >Show {wealthStore.isShowAllClasses ? 'less' : 'more'} classes<ShowMoreClassesArrowIcon /></ShowHideClassButton> }
            </>}
            { wealthStore.viewType === 'cashflow' && <InflowOutflowCalculationMessage>* Cash accounts and credit cards are not included in total inflows and outflows calculations.</InflowOutflowCalculationMessage> }
            
        </>
        )}</Observer>)
}

const InstitutionIcon = observer(({ title }) => {
    const { metadataStore } = useStore();
    const relevantInstitution = metadataStore.connectedInstitutions.find(institution => institution.name === title);
  
    if (relevantInstitution) {
      if (relevantInstitution.logoBase64) {
        return <InstitutionLogoWrapper><img alt={relevantInstitution.connectedInstitutionName} src={`data:image/png;base64,${relevantInstitution.logoBase64}`} /></InstitutionLogoWrapper>;
      } else if (relevantInstitution.logoUrl) {
        return <InstitutionLogoWrapper><img alt={relevantInstitution.connectedInstitutionName} src={relevantInstitution.logoUrl} /></InstitutionLogoWrapper>;
      }
    }
    return <InstitutionPlaceholderSvg />;
  });

const ShowMoreClassesArrowIcon = () => {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 3.5L6 9.5M6 3.5L3 6M6 3.5L9 6" stroke="#41557D" strokeWidth="1.2"/>
        </svg>
    )
}

const ClassAddItemArrowIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8197 7.98634L14.153 12L10.8197 16.0137" stroke="black" strokeWidth="1.5"/>
        </svg>
    )
}

const InstitutionPlaceholderSvg = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.32099 11.2227H13.679L14.6667 12.7041H1.33334L2.32099 11.2227Z" fill="#9097A3"></path>
        <path d="M1.58025 5.29695L7.99981 2.33398L14.4198 5.29695H1.58025Z" fill="#9097A3"></path>
        <rect x="8.74069" y="6.28516" width="1.48148" height="3.95062" fill="#9097A3"></rect>
        <rect x="2.81489" y="6.28516" width="1.48148" height="3.95062" fill="#9097A3"></rect>
        <rect x="11.7038" y="6.28516" width="1.48148" height="3.95062" fill="#9097A3"></rect>
        <rect x="5.77802" y="6.28516" width="1.48148" height="3.95062" fill="#9097A3"></rect>
      </svg>
    )
}