import { Tooltip } from 'antd'
import { Observer } from 'mobx-react'
import React from 'react'
import { ModalCloseIcon } from '../../../assets/icons/common/common-icons'
import { BaseModal } from '../../../common/components/BaseModal/BaseModal'
import { useStore } from '../../app/data/root.store'
import { SecuredIcon } from '../assets/icons'
import { MAGIC_BOX_FORM_STEPS } from '../data/magic-box.store'
import { MagicBox } from './MagicBox'
import {
  AltTextPrompt, MagicBoxModalClose, MagicBoxModalContent, MagicBoxModalHeader, MagicBoxModalTitle, SecuredPromptWrapper, TooltipAreaWrapper
} from './MagicBox.styles'

export const UploadingFilesModal = ({ onClose }) => {
  const { magicBoxStore } = useStore();


  return (
    <Observer>{() => (
      <BaseModal width={'800px'} height={'auto'} borderRadius={8}>
        <MagicBoxModalHeader>
          <MagicBoxModalTitle>
            Magic box
          </MagicBoxModalTitle>
          {magicBoxStore.formStep !== MAGIC_BOX_FORM_STEPS.DONE_SUCCESS && <MagicBoxModalClose onClick={onClose}>
            <ModalCloseIcon />
          </MagicBoxModalClose>}
        </MagicBoxModalHeader>
        <MagicBoxModalContent>
          <MagicBox />
          <SecuredPrompt />
        </MagicBoxModalContent>
      </BaseModal>
    )}
    </Observer>)
}

const SecuredPrompt = () => {
  return (
    <SecuredPromptWrapper>
      <Tooltip overlayStyle={{ maxWidth: '500px' }} title={<><div>Don't worry, Vyzer doesn't share any of your information.</div><div>Your data is encrypted at-rest and in-transit.</div></>}>
        <TooltipAreaWrapper>
          <SecuredIcon />
          <AltTextPrompt textSize={'12'}>Secured</AltTextPrompt>
        </TooltipAreaWrapper>
      </Tooltip>
    </SecuredPromptWrapper>
  )
}