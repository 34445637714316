import { Player } from '@lottiefiles/react-lottie-player'
import { Observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { ApprovedIcon, ArrowRightIcon, LinkArrowIcon, MobileBackButton, MobileLeftCornerMask, MobileRightCornerMask } from '../../../assets/icons/common/common-icons'
import { ActivationChecklistPane } from '../../activation/components/checklist-pane/ChecklistPane'
import { useStore } from '../../app/data/root.store'
import { AppHeader } from '../../layout/components/Header/Header'
import { PageWrapper, RightSidePushMenuWrapper } from '../../layout/components/Layout.styles'
import { successNotification } from '../../layout/components/Notifications/notifications'
import { CardContentBoxWrapper } from '../../wealth/components/wealth.commons.styles'
import magicBoxAnimData from '../../wealth/pages/asset/Icons/magic-box-animation-data.json'
import { DocumentRejectedIcon, IllustrationIcon, UploadIcon } from '../assets/icons'
import { MAGIC_BOX_FORM_STEPS } from '../data/magic-box.store'
import {
  CounterRowWrapper,
  CountersWrapper,
  // DemoStartPortfolioButton, 
  // ActionButton,
  DocumentListHeader, DocumentRow, DocumentStatusBadge, DocumentStatusBadgeWrapper, DocumentStatusIconWrapper, FileDropArea, FileDropAreaWrapper, FileRowIconColumn, FileRowMoreInfo, FileRowTextColumn, FileRowTitle, HeaderTitle, IllustrationIconTextSecondRow, IllustrationIconTextTopRow, IllustrationIconWrapper, LearnMoreButtonWrapper, MagicBoxCardsWrapper, MagicBoxHeroRightPaneHeaderWrapper, MagicBoxHeroRightPaneWrapper, MagicBoxHeroSubtitle, MagicBoxHeroTextWrapper, MagicBoxHeroTitle, MagicBoxHeroWrapper, MagicBoxPromptHoverEffect, MagicBoxPromptHoverEffectWrapper, MagicBoxPromptWrapper, MobileBottomContentWrapper, MobileFileRow, MobileFilesListWrapper, MobileHeader, MobileListTitle, SectionWrapper, UploadAnimatedBar, UploadAnimatedBarWrapper
} from './MagicBox.styles'
import { UploadingFilesModal } from './UploadingFilesModal'
import { DemoStartPortfolioButton, PrimaryButtonWithIcon } from '../../../common/components/buttons/NewButtons.styles'
import { CommonBadge } from '../../../common/components/CommonBadge/CommonBadge'
import { LimitationModal } from '../../../common/components/LimitationModal/LimitationModal'
import moment from 'moment'
import { CommonTextOverflow } from '../../../common/components/CommonTextOverflow/CommonTextOverflow'
import { startIntercomTourById } from '../../../common/utils/intercom.utils'
import { MobileBackButtonWrapper } from '../../layout/components/MobileLayout.styles'
import { useAnalytics } from '../../app/data/AnalyticsProvider'
import { ROUTES } from '../../../common/constants/routes.const'

export const MagicBoxPage = (props) => {
  const { magicBoxStore, userStore, billingStore , uiStore } = useStore();
  const { trackEvent } = useAnalytics();
  const [isHovered, setIsHovered] = useState(0);

  useEffect(() => {
    //magicBoxStore.fetchData();
    async function fetchData() {
      await magicBoxStore.fetchData();
      if (magicBoxStore.shouldStartLearnTour) {
        magicBoxStore.setShouldStartLearnTour(false);
        startIntercomTourById('449391');
      }
    }
    fetchData();

    return () => { }
  }, [magicBoxStore]);

  const addDocuments = useCallback(async (acceptedFiles, fileRejections) => {
    await magicBoxStore.uploadDocuments(acceptedFiles, fileRejections)

    if (!acceptedFiles.length) return;
    for (const file of acceptedFiles) {
      const { type: file_type, name: file_name } = file;
      trackEvent('Document Uploaded', { file_id: `${file_name}-${Date.now()}`, file_type, file_name })
    }

  }, [magicBoxStore, trackEvent]);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (magicBoxStore.isUploading) return;
    if (userStore.isDemoOrReadOnlyMode) {
      billingStore.setShowPricingTableModal(true);
    } else {

      if (userStore.userLimitations.magicBox.filesPerMonth !== null && userStore.userLimitations.magicBox.filesPerMonth <= magicBoxStore.uploadThisMonthAmount) {
        magicBoxStore.setShowLimitationPopup(true);
      } else if (userStore.userLimitations.magicBox.filesPerMonth !== null && userStore.userLimitations.magicBox.filesPerMonth < magicBoxStore.uploadThisMonthAmount + acceptedFiles.length) {
        magicBoxStore.setShowFilesLeftLimitationPopup(true, (userStore.userLimitations.magicBox.filesPerMonth - magicBoxStore.uploadThisMonthAmount));
      } else {
        addDocuments(acceptedFiles, fileRejections);
      }

    }
  }, [addDocuments, billingStore, userStore, magicBoxStore])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxSize: 26214400, accept: ['.pdf', '.csv', '.xls', '.xlsx', '.doc', '.docx', '.ppt', '.pptx', '.jpg', '.jpeg', '.png', '.xlsm'] })

  const handleCopyText = () => {
    navigator.clipboard.writeText('secure-import@vyzer.co');
    successNotification('Email copied')
  }

  const handleLimitationBadgeClick = (e) => {
    e.stopPropagation();
    billingStore.setShowPricingTableModal(true);
  }

  const handleStartMyPortfolioClick = async () => {
    if (userStore.isDemoMode && (uiStore.isMobileAppView || userStore.isRelevantVariant === 6)) {
      await billingStore.setStarterPlan();
      window.location.href = `${ROUTES.wealthOverview.url}?subscribed_success&sw`;
    } else {
      billingStore.setShowPricingTableModal(true);
    }
  }

  return (
    <Observer>{() => (
      <> 
          {uiStore.isDesktopView ? <AppHeader /> : 
      <MobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}>
        <MobileBackButtonWrapper onClick={()=>{uiStore.setIsAppMobileMenuOpen(true)}}><MobileBackButton /></MobileBackButtonWrapper>
        <HeaderTitle>Magic box</HeaderTitle>
      </MobileHeader> } 
        <RightSidePushMenuWrapper >
          {userStore.isViewingOwnPortfolio && userStore.isGetStarted && <ActivationChecklistPane />}
        </RightSidePushMenuWrapper >

        <PageWrapper withHeader >
          <MagicBoxCardsWrapper>
            <MagicBoxHeroWrapper>
              <MagicBoxHeroTextWrapper>
                <MagicBoxHeroTitle>Set up your account quickly</MagicBoxHeroTitle>
                <MagicBoxHeroSubtitle>Upload Excel spreadsheets, financial statements, investment trackers and turn it into data.</MagicBoxHeroSubtitle>
                {userStore.isDemoOrReadOnlyMode &&
                  <LearnMoreButtonWrapper onClick={() => { window.open('https://help.vyzer.co/en/articles/6514782-the-magic-box', '_blank') }}>Learn more <ArrowRightIcon /></LearnMoreButtonWrapper>
                }
              </MagicBoxHeroTextWrapper>

              <MagicBoxHeroRightPaneWrapper>
                { uiStore.isDesktopView && <MagicBoxHeroRightPaneHeaderWrapper>
                  <MagicBoxPromptWrapper isVisible={isHovered === 1} onMouseEnter={() => { setIsHovered(1) }} onMouseLeave={() => { setIsHovered(0) }}>
                    Pro tips
                    <MagicBoxPromptHoverEffectWrapper isVisible={isHovered === 1} >
                      <MagicBoxPromptHoverEffect >
                        <div>
                          Include documents that contain<br />your<span style={{ color: '#72E1AC', whiteSpace: 'nowrap', fontWeight: '600' }}> investment terms, history<br />and future cash flow.</span><br /><span onClick={() => { window.open('https://help.vyzer.co/en/articles/6514782-the-magic-box', '_blank') }} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Learn more</span>
                        </div>
                      </MagicBoxPromptHoverEffect>
                    </MagicBoxPromptHoverEffectWrapper>

                  </MagicBoxPromptWrapper>

                  <MagicBoxPromptWrapper isVisible={isHovered === 2} onMouseEnter={() => { setIsHovered(2) }} onMouseLeave={() => { setIsHovered(0) }}>
                    Concierge
                    <MagicBoxPromptHoverEffectWrapper rightPos isVisible={isHovered === 2} >
                      <MagicBoxPromptHoverEffect >
                        <div>
                          Quickly and securely forward any<br />financial document you receive<br />via email to your Magic Box.
                          {!userStore.isDemoOrReadOnlyMode && <>
                            <br /><span onClick={handleCopyText} style={{ cursor: 'pointer', color: '#72E1AC', whiteSpace: 'nowrap', fontWeight: '600', display: 'flex', alignItems: 'center' }}>secure-import@vyzer.co<span style={{ marginLeft: '4px', paddingTop: '4px' }}><CopyIcon /></span></span>
                          </>}
                        </div>
                      </MagicBoxPromptHoverEffect>
                    </MagicBoxPromptHoverEffectWrapper>
                  </MagicBoxPromptWrapper>
                </MagicBoxHeroRightPaneHeaderWrapper> }
                <Player
                  loop={true}
                  src={magicBoxAnimData}
                  //play={showLetsGoAnim}
                  speed={1}
                  autoplay
                  style={ uiStore.isDesktopView ? { width: '480px', height: '240px', margin: '0', marginLeft: 'auto' } : {width:'100%'}}
                />
              </MagicBoxHeroRightPaneWrapper>
            </MagicBoxHeroWrapper>

            {magicBoxStore.formStep === MAGIC_BOX_FORM_STEPS.DROP_FILES && <>
              <FileDropAreaWrapper {...getRootProps()} {...(userStore.isDemoOrReadOnlyMode ? { onClick: handleStartMyPortfolioClick } : {})} isHoverActive={isDragActive}>
                {!uiStore.isDesktopView && <><MobileLeftCornerMask style={{position:'absolute', top:'0' , left:'0'}}/>
                <MobileRightCornerMask style={{position:'absolute', top:'0' , right:'0'}}/></>}
                <input {...getInputProps()} disabled={magicBoxStore.isUploading} />
                <FileDropArea>
                  <IllustrationIconWrapper>
                    {uiStore.isDesktopView ? <IllustrationIcon /> : <MobileDocsIcon />}
                  </IllustrationIconWrapper>
                  {magicBoxStore.isUploading ? <UploadAnimatedBarWrapper><UploadAnimatedBar /></UploadAnimatedBarWrapper> :
                    <>
                      <IllustrationIconTextTopRow>{uiStore.isDesktopView ? 'Drag and Drop Here:' : `Upload Spreadsheet / K-1 / PFS / PPM etc.`}</IllustrationIconTextTopRow>
                      <IllustrationIconTextSecondRow>
                        {uiStore.isDesktopView ? `Spreadsheet / K-1 / PFS / PPM etc.` : <>
                        Include documents that contain your investment terms, history and future cash flow.<br/>
                        Vyzer will automatically update your performance.
                        </>
                        }
                      </IllustrationIconTextSecondRow>
                      {userStore.isDemoMode ? <DemoStartPortfolioButton style={{ marginTop: '16px' }}>{userStore.isScoreTier4 ? 'Start my portfolio' : 'Try the Magic box for free'}<LinkArrowIcon /></DemoStartPortfolioButton> :
                        <PrimaryButtonWithIcon style={{ marginTop: '8px' }} noStroke>
                          {userStore.isReadOnlyMode ?
                          <><MembershipSvg /> Unlock with membership</>
                          : 
                          <><UploadIcon />Upload files</>}
                          </PrimaryButtonWithIcon>}
                      {/* <ActionButton withIcon ><UploadIcon />Upload files</ActionButton> */}
                    </>}
                </FileDropArea>
                {userStore.isFreeTier && userStore.userLimitations.magicBox.filesPerMonth !== null && <div style={{ position: 'absolute', top: '8px', left: '8px' }}><CommonBadge noMargin badgeType="limitedFeature" title={`Up to ${userStore.userLimitations.magicBox.filesPerMonth}  file${userStore.userLimitations.magicBox.filesPerMonth > 1 ? 's' : ''} per month`} rounded clickable onClick={handleLimitationBadgeClick} /></div>}
                {userStore.isPayedTier &&
                  (userStore.userLimitations.magicBox.filesPerMonth !== null && userStore.userLimitations.magicBox.filesPerMonth <= magicBoxStore.uploadThisMonthAmount)
                  && <div style={{ position: 'absolute', top: '8px', left: '8px' }}><CommonBadge color={'#2D72FF'} noMargin badgeType="limitedFeature" title={`Up to ${userStore.userLimitations.magicBox.filesPerMonth}  file${userStore.userLimitations.magicBox.filesPerMonth > 1 ? 's' : ''} per month`} rounded clickable onClick={handleLimitationBadgeClick} /></div>}
              </FileDropAreaWrapper>

            </>}

            {magicBoxStore.formStep !== MAGIC_BOX_FORM_STEPS.DONE_SUCCESS && magicBoxStore.sortedUploadedFiles.length > 0 ? 
              <>
              {uiStore.isDesktopView ? 
              <CardContentBoxWrapper marginBottom={45}>
              <SectionWrapper style={{ padding: '16px 32px' }}>
                <DocumentListHeader>File name ({magicBoxStore.isLoading ? 'Loading...' : magicBoxStore.uploadedFiles?.length})
                  <div style={{ marginLeft: 'auto' , textAlign:'right' , width: '120px' }}>Uploaded</div>
                  <div style={{  width: '120px' , textAlign:'right'}}>Status</div>
                </DocumentListHeader>
                {magicBoxStore.sortedUploadedFiles?.map((document, ind) => (
                  <DocumentRow key={ind}>
                    {/* {document.originalName} */}
                    <CommonTextOverflow text={document.originalName} />
                    <div style={{ marginLeft: 'auto' , textAlign:'right' , width: '120px' }}>{moment(document.createdAt).format('MMM D, YYYY')}</div>
                    <DocumentStatusBadgeWrapper>
                      <DocumentStatusBadge status={document.status} style={{  textAlign:'right' , width:'fit-content' }}>
                        <DocumentStatusIconWrapper><DocumentStatusIcon status={document.status} /></DocumentStatusIconWrapper>
                        {document.status === 'Processing' || document.status === 'Pending' ? 'In process' : document.status}
                      </DocumentStatusBadge>
                    </DocumentStatusBadgeWrapper>
                  </DocumentRow>
                ))}
              </SectionWrapper>
            </CardContentBoxWrapper> :
            <MobileBottomContentWrapper>
              {(magicBoxStore.filesInProcessCount > 0 || magicBoxStore.filesDoneCount > 0) &&
                <CountersWrapper>
                   {magicBoxStore.filesInProcessCount > 0 && <CounterRowWrapper><FileInProcessIcon  />{magicBoxStore.filesInProcessCount} File{magicBoxStore.filesInProcessCount > 1 ? 's' : ''} are in process</CounterRowWrapper> }
                   {magicBoxStore.filesDoneCount > 0 && <CounterRowWrapper><FileDoneIcon />{magicBoxStore.filesDoneCount} File{magicBoxStore.filesDoneCount > 1 ? 's' : ''} is done</CounterRowWrapper> }
                </CountersWrapper>
              }
              <MobileFilesListWrapper>
                {magicBoxStore.sortedUploadedFiles.length && <MobileListTitle>{magicBoxStore.sortedUploadedFiles.length} File{magicBoxStore.sortedUploadedFiles.length > 1 ? 's' : ''}</MobileListTitle>}
                {magicBoxStore.sortedUploadedFiles?.map((document, ind) => (
                    <MobileFileRow>
                        <FileRowIconColumn>
                          {document.status === 'Processing' && <FileInProcessIcon  /> }
                          {document.status === 'Done' && <FileDoneIcon  /> }
                          </FileRowIconColumn>
                          <FileRowTextColumn>
                              <FileRowTitle>{document.originalName}</FileRowTitle>
                              <FileRowMoreInfo>Uploaded {moment(document.createdAt).format('MMM D, YYYY')}</FileRowMoreInfo>
                          </FileRowTextColumn>
                    </MobileFileRow>
                ))}
              </MobileFilesListWrapper>
            </MobileBottomContentWrapper>
            }</> : null}
          </MagicBoxCardsWrapper>

        </PageWrapper>
        {magicBoxStore.showModal && <UploadingFilesModal onClose={() => { magicBoxStore.setFormStep(MAGIC_BOX_FORM_STEPS.DROP_FILES) }} />}

        {magicBoxStore.showLimitationPopup && <LimitationModal modalType="magicBox"
          paramsForText={[userStore.userLimitations.magicBox.filesPerMonth]}
          onUpgrade={() => { billingStore.setShowPricingTableModal(true) }}
          onClose={() => magicBoxStore.setShowLimitationPopup(false)} />}

        {magicBoxStore.filesLeftLimitationPopup.show && <LimitationModal modalType="magicBoxFilesLeft"
          paramsForText={[magicBoxStore.filesLeftLimitationPopup.sum, userStore.userLimitations.magicBox.filesPerMonth]}
          onUpgrade={() => { billingStore.setShowPricingTableModal(true) }}
          onClose={() => magicBoxStore.setShowFilesLeftLimitationPopup(false, 0)} />}

      </>
    )}</Observer>)
}

export const DocumentStatusIcon = (props) => {
  const { status } = props;
  switch (status) {
    case 'Done':
      return <ApprovedIcon />
    case 'Rejected':
      return <DocumentRejectedIcon />
    default:
      return null;
  }
}


const CopyIcon = () => {
  return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.5" y="5" width="7.5" height="9" rx="0.5" stroke="#72E1AC" />
    <path d="M6 3H12C12.5523 3 13 3.44772 13 4V11" stroke="#72E1AC" strokeLinecap="round" />
  </svg>
  )
}

const MembershipSvg = () => {
  return(
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.96577 11.5653L4.6875 7.38002V16.3086H12.5H20.3125V7.38002L16.0342 11.5653L12.5 4.58984L8.96577 11.5653Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M19.5312 19.8438H5.46875V17.9688H19.5312V19.8438Z" fill="white"/>
    </svg>
  )
}

const MobileDocsIcon = () => (
  <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 12.9998V10.5695C16 9.59214 16.7923 8.7998 17.7697 8.7998H37C37.6627 8.7998 38.2 9.33706 38.2 9.99981L38.2 14.7998C38.2 15.4625 38.7373 15.9998 39.4 15.9998H43.6C44.2627 15.9998 44.8 16.5371 44.8 17.1998V41.7887C44.8 42.7661 44.0077 43.5584 43.0303 43.5584H40.6" stroke="#1A1B1D" strokeWidth="1.44"/>
    <path d="M44.8 24.9998L44.8 16.7823C44.8 16.4913 44.6943 16.2102 44.5025 15.9913L38.5585 9.2089C38.3307 8.9489 38.0018 8.7998 37.656 8.7998H17.7697C16.7923 8.7998 16 9.59214 16 10.5695V12.9998" stroke="#1A1B1D" strokeWidth="1.44"/>
    <path d="M13.1199 14.1696C13.1199 13.5899 13.5899 13.1199 14.1696 13.1199H32.9248C33.2029 13.1199 33.4697 13.2303 33.6665 13.4268L40.1719 19.9219C40.3691 20.1189 40.4799 20.3861 40.4799 20.6648V45.3888C40.4799 45.9685 40.0099 46.4385 39.4302 46.4385H14.1696C13.5899 46.4385 13.1199 45.9685 13.1199 45.3888V14.1696Z" stroke="#1A1B1D" strokeWidth="1.44"/>
    <path d="M13.1199 14.1696C13.1199 13.5899 13.5899 13.1199 14.1696 13.1199H31.8873C32.4671 13.1199 32.937 13.5899 32.937 14.1696V19.0461C32.937 19.9324 33.6556 20.6509 34.5419 20.6509H39.4302C40.0099 20.6509 40.4799 21.1209 40.4799 21.7007V45.3888C40.4799 45.9685 40.0099 46.4385 39.4302 46.4385H14.1696C13.5899 46.4385 13.1199 45.9685 13.1199 45.3888V14.1696Z" stroke="#1A1B1D" strokeWidth="1.44"/>
    <path d="M22.125 36.5L18 36.5" stroke="#1A1B1D" strokeWidth="1.35" strokeLinejoin="round"/>
    <path d="M29 36.5L24.875 36.5" stroke="#1A1B1D" strokeWidth="1.35" strokeLinejoin="round"/>
    <path d="M23.5 37.875L23.5 42" stroke="#1A1B1D" strokeWidth="1.35" strokeLinejoin="round"/>
    <path d="M23.5 31L23.5 35.125" stroke="#1A1B1D" strokeWidth="1.35" strokeLinejoin="round"/>
    <path d="M48 8.5L45 8.5" stroke="#1A1B1D" strokeWidth="1.35" strokeLinejoin="round"/>
    <path d="M53 8.5L50 8.5" stroke="#1A1B1D" strokeWidth="1.35" strokeLinejoin="round"/>
    <path d="M49 9.5L49 12.5" stroke="#1A1B1D" strokeWidth="1.35" strokeLinejoin="round"/>
    <path d="M49 4.5L49 7.5" stroke="#1A1B1D" strokeWidth="1.35" strokeLinejoin="round"/>
  </svg>
)

const FileInProcessIcon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.5716 5.07688C16.6144 5.7766 14.5814 4.24096 14.7192 2.16699H11.4522C11.59 4.24096 9.55702 5.7766 7.59979 5.07688L6.86645 4.81471L5.23291 7.64427C6.90846 9.01179 6.90846 11.5722 5.23291 12.9397L6.86645 15.7693L7.59979 15.5071C9.55702 14.8074 11.59 16.343 11.4522 18.417H14.7192C14.5814 16.343 16.6144 14.8074 18.5716 15.5071L19.3049 15.7693L20.9385 12.9397C19.2629 11.5722 19.2629 9.01179 20.9385 7.64427L19.3049 4.81471L18.5716 5.07688ZM12.9336 14.1444C15.0604 14.1444 16.7846 12.4202 16.7846 10.2932C16.7846 8.16622 15.0604 6.44196 12.9336 6.44196C10.8067 6.44196 9.08259 8.16622 9.08259 10.2932C9.08259 12.4202 10.8067 14.1444 12.9336 14.1444Z" fill="#F33DAA"/>
    <path d="M3.25 8.96924L13 12.0892L22.75 8.96924V18.8492L13 21.9692L3.25 18.8492V8.96924Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.9165 13.9095L3.25 11.1362V21.0162L11.9165 23.7895V13.9095ZM14.0832 13.9096V23.7896L22.75 21.0162V11.1362L14.0832 13.9096Z" fill="#F33DAA"/>
  </svg>
)

const FileDoneIcon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="26" height="26" rx="6" fill="#72E1AC"/>
    <path d="M17.8216 10.028L11.6926 16.1605L8.62485 13.0936" stroke="white" strokeWidth="2"/>
  </svg>
)