import { makeAutoObservable } from "mobx";
import { getUrlDisplayName } from "../../../common/utils/string.utils";
import { fetchWealthData, fetchLastChangesData, fetchProjectionData, fetchIndicationsData, getUpdatesData, bulkEditUpdate, bulkDelete } from './wealth.service';
import { projectionClassLineChartData, projectionOverviewLineChartData, wealthOverviewAllocationPieChartData, wealthOverviewCategoriesPieChartData, wealthOverviewFiltersPieChartData, wealthOverviewPieChartData } from './wealth.chrats-data';
import { ASSET_CLASSES_OVERVIEW_DATA, ASSET_SINGLE_CATEGORY_OVERVIEW_DATA, MIXED_RESULT_HERO_DATA } from "../pages/class/data/ClassOverviewData.const";
import { getValueByPath, isNullOrUndefined } from "../../../common/utils/object.utils";
import moment from "moment";
import currency from 'currency.js';
import { ls } from "../../../common/utils/localstorage.util";
import { colors, wealthGroupToColor } from "../../../common/styles/theme.styles";
import { liquidityFixedGroups, riskFixedGroups } from "../../allocations/data/allocations.const";
import { lastUpdate_path, roi_total_path, total_capital_path, xirr_total_path } from "../../data-layers/data/data-layers.const";
import { getRelevantValueByEventTypes, weightedAverage } from "./wealth.utils";
import { successNotification, successNotificationWithUndo, errorNotification } from "../../layout/components/Notifications/notifications";
import { customClassColorsObj } from "../../user/components/CustomClassList/icons/custom-colors";
import { customClassSvgObj } from "../../user/components/CustomClassList/new-svgs/new-custom-svgs";

const LS_KEY = 'wealth-config';

export const WEALTH_FILTER_RESULT_TYPE = {
    MIXED: "mixed",
    MAIN: "main",
    SINGLE_CLASS: "singleClass",
    EMPTY: "empty",
}

const textsForBulkEditAttribute = {
    customClass: 'Custom Class and sub class',
    beneficiary: 'Entity',
    riskLevel: 'Risk Level',
    liquidity: 'Liquidity level',

}

const inflowOutflowHiddenClasses = [1, 11];
export class WealthStore {
    selectedClassId = 0;
    selectedClassIndex = -1;
    selectedClassUrlName = '';
    isLoadedOnce = false;
    isLoading = true;
    isLoadingProjectionData = true;
    isLoadingLastChangesData = true;
    isLoadingDelete = false;
    isCheckingUpdates = false;
    isCashFlowView = false;
    managedSelectedTab = 'events';
    assetsGroups = [];
    assetsTotalUsdValue = 0;
    liabilitiesTotalUsdValue = 0;
    totalNetWorthUsdValue = 0;
    totalDistributions = [];
    totalNetWorthHistoryPoints = [];
    groupsHistoryPoints = [];
    netWorthChartData = [];
    assetsProjectionData = [];
    assetIdsWithTransactionsIndications = [];
    assetIdsWithEventsIndications = [];
    viewType = 'overview'; //'overview'; // overview | cashflow
    viewOf = 'all'; // all | class | filter | tag | item | community
    resultType = WEALTH_FILTER_RESULT_TYPE.MAIN;
    listViewMode = ''; // classes | items | archived | holdings | institutions
    hiddenCanceledEventsItems = Array.isArray(JSON.parse(window.localStorage.getItem('hiddenCanceledEventsItems'))) ? JSON.parse(window.localStorage.getItem('hiddenCanceledEventsItems')) : [];
    heroMetricsData = {};
    filterResultSingleClassId = null;
    isShowAllClasses = false;
    isShowClosedAssets = false;
    showClosedAssetsLocalStorageKey = '';
    lastChangesStorageKey = '';
    checkUpdatesStorageKey = '';
    lastAppliedFilters = '';
    localStorageKey = '';
    currenciesRates = {};
    shouldCheckLastChangesWhenGetData = true;
    shouldCheckLastChangesWhenExitItemPage = false;
    allocateBy = 'classes';
    isBulkEditMode = false;
    selectedItemsForBulkEdit = [];
    selectedAttributeToEdit = '';
    numberOfBulkEditUpdatedItems = 0;
    valuesForBulkEditUndoAction = [];


    constructor(cashFlowStore, documentsStore, metadataStore, tickersStore, filtersStore, dataLayersStore, inflowOutflowDataLayersStore, uiStore) {
        makeAutoObservable(this);
        this.cashFlowStore = cashFlowStore;
        this.documentsStore = documentsStore;
        this.metadataStore = metadataStore;
        this.tickersStore = tickersStore;
        this.filtersStore = filtersStore;
        this.dataLayersStore = dataLayersStore;
        this.inflowOutflowDataLayersStore = inflowOutflowDataLayersStore;
        this.uiStore = uiStore;
    }

    getData = async (hideLoading = false, fetchAnyway = false) => {
        if (!hideLoading) {
            this.setLoading(true);
        }
        try {
            if (fetchAnyway || (this.lastAppliedFilters !== JSON.stringify(this.filtersStore.appliedFilters))) {
                const data = await fetchWealthData(this.filtersStore.appliedFilters);
                await this.filtersStore.fetchData();
                this.lastAppliedFilters = JSON.stringify(this.filtersStore.appliedFilters);
                this.setData(data, true);
            }
        }
        //catch (e) {}
        finally {
            this.setLoading(false);
        }

        fetchIndicationsData().then(data => {
            this.setIndicationsData(data);
        })

        if (this.shouldCheckLastChangesWhenGetData) {
            this.getLastChanges();
        }

        // this.setIsLoadingLastChangesData(true);
        // fetchLastChangesData().then(data=>{
        //     this.setLastChangesData(data);
        // }).finally(()=>{
        //     this.setIsLoadingLastChangesData(false);
        // });
    }

    getProjectionData = async () => {
        if (this.netWorthChartData.length === 0) {
            this.setIsLoadingProjectionData(true);
            fetchProjectionData().then(data => {
                this.setProjectionData(data);
            })
        }
    }

    refreshWealthData = async (hideLoading = false, refreshViewMode = true) => {
        if (!hideLoading) {
            this.setLoading(true);
        }

        try {
            const data = await fetchWealthData(this.filtersStore.appliedFilters);
            // if(data.resultType === WEALTH_FILTER_RESULT_TYPE.SINGLE_CLASS) {
            //     navigateToUrl(history, this.filtersStore.appliedFiltersToUrlParams());
            // }
            this.lastAppliedFilters = JSON.stringify(this.filtersStore.appliedFilters);
            this.setData(data, refreshViewMode);
        }
        //catch (e) {} 
        finally {
            this.setLoading(false);
        }
    }


    setShouldCheckLastChangesWhenExitItemPage = (shouldCheck) => {
        this.shouldCheckLastChangesWhenExitItemPage = shouldCheck;
    }

    setGetLastChangesDataAfterExitPage = () => {
        this.shouldCheckLastChangesWhenGetData = false
        this.shouldCheckLastChangesWhenExitItemPage = true;
        ls.remove(this.lastChangesStorageKey)
    }

    getLastChanges = async (skipTimeout = false) => {
        this.shouldCheckLastChangesWhenGetData = true;
        const shouldCallLastChanges = skipTimeout || checkLSTime(this.lastChangesStorageKey, 1);
        if (shouldCallLastChanges) {
            this.setIsLoadingLastChangesData(true);
            fetchLastChangesData().then(data => {
                this.setLastChangesData(data);
                window.localStorage.setItem(this.lastChangesStorageKey, JSON.stringify({ time: new Date().getTime(), value: data }));
            }).finally(() => {
                this.setIsLoadingLastChangesData(false);
            });
        } else {
            const lastChangesData = JSON.parse(window.localStorage.getItem(this.lastChangesStorageKey));
            if (lastChangesData && lastChangesData.value) {
                this.setLastChangesData(lastChangesData.value);
            }
            this.setIsLoadingLastChangesData(false);
        }
    }

    checkUpdates = async (itemStore, connectedInstitutionsStore) => {
        const shouldCallCheckUpdates = checkLSTime(this.checkUpdatesStorageKey, 1);
        if (shouldCallCheckUpdates) {
            this.setIsCheckingUpdates(true);
            try {
                // const data = getUpdatesData
                // check updates for crypto and tickers
                await getUpdatesData();
                // updating connected institutions data
                //  await  connectedInstitutionsStore.fetchData();
                //  const updateData = await connectedInstitutionsStore.syncAllData();
                const updateData = await connectedInstitutionsStore.syncAllInstitutionsOnce();
                // console.log(updateData);
                if (updateData) {
                    this.getData(true);
                    if (itemStore.item?.id && itemStore.item?.isConnected && !itemStore.item?.isLoginRequired) {
                        itemStore.updateItemData();
                    }
                }


                window.localStorage.setItem(this.checkUpdatesStorageKey, JSON.stringify({ time: new Date().getTime(), value: '1' }));
            }
            //catch (e) {} 
            finally {
                this.setIsCheckingUpdates(false);
            }
        }
    }

    setLSKey(userId) {
        this.localStorageKey = LS_KEY + '_' + userId;
        this.showClosedAssetsLocalStorageKey = 'isShowClosedAssets_' + userId;
        this.isShowClosedAssets = ls.get(this.showClosedAssetsLocalStorageKey) === '1';
        this.lastChangesStorageKey = 'lastChanges_' + userId;
        this.checkUpdatesStorageKey = 'checkUpdates' + userId;
    }

    setData(data, updateViewMode = true) {
        this.resultType = data.resultType;
        this.currenciesRates = data.ratesObject;
        const isItemsContainsStocks = data.result.items.some((assetClass) => (assetClass.id === 3 && (assetClass.items.some(item => item.categoryId === 39) || assetClass.archivedItems.some(item => item.categoryId === 39))));

        if (!this.uiStore.isDesktopView) {
            this.dataLayersStore.setSortByForMobile()
        }
        this.assetsGroups = data.result.items;

        if (this.selectedClassUrlName !== '') {
            this.selectedClassIndex = this.assetsGroups.findIndex(g => getUrlDisplayName(g.title) === this.selectedClassUrlName);
        }

        if ([WEALTH_FILTER_RESULT_TYPE.MAIN].includes(data.resultType)) {
            this.assetsTotalUsdValue = data.result.itemsTotalUsdValue;
            this.liabilitiesTotalUsdValue = data.result.liabilitiesTotalUsdValue;
            this.totalNetWorthUsdValue = data.result.totalNetWorthUsdValue;
            this.totalDistributions = data.result.totalDistributions;
        } else {
            const { classPerformance } = data.result.metricsResult;
            this.heroMetricsData = { ...classPerformance, currency: 'USD' };
            this.totalNetWorthUsdValue = data.result.metricsResult.classPerformance.totalItemsWorthUsdValue;
            this.filterResultSingleClassId = data.result.classId || null;
        }

        if (updateViewMode) {
            this.listViewMode = data.resultType === WEALTH_FILTER_RESULT_TYPE.MAIN || (data.resultType === WEALTH_FILTER_RESULT_TYPE.SINGLE_CLASS && [3, 9].includes(data.result.classId)) ? 'allocations' :
                (data.result.items.flatMap((assetClass) => assetClass.items).length > 0 ? 'items' : 'archived');
            this.allocateBy = data.resultType === WEALTH_FILTER_RESULT_TYPE.MAIN ? 'classes' :
                data.resultType === WEALTH_FILTER_RESULT_TYPE.SINGLE_CLASS && [3, 9].includes(data.result.classId) ? 'accounts' :
                    this.filtersStore.filterTags.length === 1 && this.filtersStore.filterTags[0].type === 'customClasses' ? 'customSubClasses' : 'categories';

            this.dataLayersStore.setSelectedListViewMode(this.listViewMode, this.allocateBy);
            this.dataLayersStore.setResultType(data.resultType, data.result.classId || 0, isItemsContainsStocks, this.listViewMode, this.allocateBy)

            this.isBulkEditMode = false;
            this.selectedItemsForBulkEdit = []
            this.selectedAttributeToEdit = '';
        }


    }

    setLastChangesData(data) {
        this.totalNetWorthHistoryPoints = data.totalNetWorthHistoryPoints;
        this.groupsHistoryPoints = [...data.assets, ...data.liabilities];
    }

    setProjectionData(data) {
        this.netWorthChartData = data.netWorthChartData || [];
        this.assetsProjectionData = data.assets;
        this.setIsLoadingProjectionData(false);
    }

    setIndicationsData(data) {
        this.assetIdsWithTransactionsIndications = data.items.assets.transactions;
        this.assetIdsWithEventsIndications = data.items.assets.eventPass;
    }

    setViewOf = (viewOf) => {
        this.viewOf = viewOf;
    }

    setIsCashFlowView = (isInView) => {
        this.isCashFlowView = isInView;
    }

    setIsCheckingUpdates(isChecking) {
        this.isCheckingUpdates = isChecking;
    }

    onBoardingDone = async () => {
        this.isOnBoarding = false;
        // this.getData();
    }

    setLoading(isLoading) {
        this.isLoading = isLoading;
        if (isLoading === false) {
            this.isLoadedOnce = true;
        }
    }

    setIsLoadingProjectionData(isLoading) {
        this.isLoadingProjectionData = isLoading;
    }

    setIsLoadingLastChangesData(isLoading) {
        this.isLoadingLastChangesData = isLoading;

    }


    setSelectedClassUrlName(classUrlName,) {
        this.selectedClassUrlName = classUrlName;
        if (!this.isLoading) {
            this.selectedClassIndex = this.assetsGroups.findIndex(g => getUrlDisplayName(g.title) === classUrlName);
        }
    }

    setMainPageView() {
        this.viewOf = 'all';
        this.selectedClassIndex = -1;
        this.selectedClassUrlName = '';
    }

    setClassPageView(classUrlName) {
        this.viewOf = 'class';
        this.setSelectedClassUrlName(classUrlName)
    }

    setUnSelectedClass() {
        this.selectedClassIndex = -1;
        this.selectedClassUrlName = '';
    }

    setListViewMode(viewMode) {
        this.listViewMode = viewMode;
    }

    // routeChanged = (pathName) => {
    //     this.viewType =  pathName.includes('projection') ? 'projection' : 'overview';
    // }
    setViewType = (viewType) => {
        this.viewType = viewType;
    }

    setIsShowAllClasses = (show) => {
        this.isShowAllClasses = show;
    }

    setIsShowClosedAssets = (show) => {
        this.isShowClosedAssets = show;
        ls.set(this.showClosedAssetsLocalStorageKey, show ? '1' : '0');
    }

    setAllocateBy(allocateBy) {
        this.allocateBy = allocateBy;
    }

    setIsBulkEditMode(isEdit, clearItems = false) {
        this.isBulkEditMode = isEdit;
        if (clearItems) {
            this.selectedItemsForBulkEdit = [];
        }
    }

    removeClosedItemsFromBulkEditSelection() {
        if (['items', 'crypto-holdings'].includes(this.listViewMode) || (this.isAllocationsViewMode && this.allocateBy === 'accounts')) {
            this.selectedItemsForBulkEdit = this.selectedItemsForBulkEdit.filter(id => !this.closedItemsIdsInDisplay.includes(id));
        } else {
            this.selectedItemsForBulkEdit = this.selectedItemsForBulkEdit.filter(id => !this.tickersStore.closedHoldingsIdsInDisplay.includes(id));
        }
    }

    addOrRemoveItemForBulkEdit(itemId) {
        if (this.selectedItemsForBulkEdit.includes(itemId)) {
            this.selectedItemsForBulkEdit = this.selectedItemsForBulkEdit.filter(id => id !== itemId);
        } else {
            this.selectedItemsForBulkEdit.push(itemId);
        }
    }

    bulkEditToggleSelectAllItems() {
        if (this.selectedItemsForBulkEdit.length > 0) {
            this.selectedItemsForBulkEdit = [];
        } else {
            if (['items', 'crypto-holdings'].includes(this.listViewMode) || (this.isAllocationsViewMode && this.allocateBy === 'accounts')) {
                this.selectedItemsForBulkEdit = this.itemsList.filter(item => !item.isContainer).filter(item => this.isShowClosedAssets ? true : !item.isClosed).map(item => item.id);
            } else { // tickers)
                this.selectedItemsForBulkEdit = this.tickersStore.tickers.filter(ticker => ticker.isSingleTicker).filter(item => this.isShowClosedAssets ? true : !item.isClosed).map(ticker => ticker.id);
            }
        }
    }

    setSelectedAttributeToEdit(attribute) {
        this.selectedAttributeToEdit = attribute;
    }

    saveBulkEditChanges = async (valueToSave) => {
        const dataToSend = {
            values: [{
                ids: this.selectedItemsForBulkEdit,
                type: this.selectedAttributeToEdit,
                value: valueToSave || null
            }]
        }
        await bulkEditUpdate(dataToSend);

        const valuesForUndoAction = this.selectedItemsForBulkEdit.map(itemId => {
            const item = this.assetsGroups.flatMap(ag => ag.items).find(item => item.id === itemId);
            const value = this.selectedAttributeToEdit === 'customClass' ? { customClassId: item.customClassId, customSubClassId: item.customSubClassId } : item[this.selectedAttributeToEdit];
            return { ids: [itemId], type: this.selectedAttributeToEdit, value }
        });
        this.valuesForBulkEditUndoAction = valuesForUndoAction;
        this.numberOfBulkEditUpdatedItems = this.selectedItemsForBulkEdit.length;
        this.refreshWealthData(false, false);
        this.filtersStore.fetchData();
        this.isBulkEditMode = false;
        this.selectedItemsForBulkEdit = [];
        successNotificationWithUndo(`You've successfully edit the ${textsForBulkEditAttribute[this.selectedAttributeToEdit]} for ${this.numberOfBulkEditUpdatedItems} items`,
            () => { this.undoBulkEditChanges() }
            , { autoClose: 20000 });
    }

    bulkDelete = async () => {
        this.isLoadingDelete = true;
        const data = {
            ids: this.selectedItemsForBulkEdit
        };

        try {
            await bulkDelete(data);
            this.numberOfBulkEditUpdatedItems = this.selectedItemsForBulkEdit.length;
            this.refreshWealthData(false, false);
            this.filtersStore.fetchData();
            const pluralSuffix = ((1 === this.numberOfBulkEditUpdatedItems) ? '' : 's');

            successNotification(`You've successfully delete ${this.numberOfBulkEditUpdatedItems} item${pluralSuffix}`, { autoClose: 20000 });
        } catch (e) {
            errorNotification('Error occured', { autoClose: 2000 });
        }

        this.isBulkEditMode = false;
        this.selectedItemsForBulkEdit = [];
        this.isLoadingDelete = false;
    }

    undoBulkEditChanges = async () => {
        await bulkEditUpdate({ values: this.valuesForBulkEditUndoAction });
        this.refreshWealthData(false, false);
        this.filtersStore.fetchData();
        successNotification('You have successfully undo the changes');
        this.isBulkEditMode = true;
        this.selectedItemsForBulkEdit = this.valuesForBulkEditUndoAction.flatMap(v => v.ids);
    }

    get shouldShowMoreClassButton() {
        return this.uiStore.isDesktopView && this.assetsGroups.some(cls => cls.items.length === 0);
    }

    get currentCashPosition() {
        return this.assetsGroups.find(ac => ac.id === 1)?.totalUsdValue || null;
    }

    get assetClass() {
        return this.assetsGroups.length && this.selectedClassIndex > -1 ? this.assetsGroups[this.selectedClassIndex] : {}
    }
    get liabilityClass() {
        return this.assetsGroups.length && this.selectedClassIndex > -1 ? this.assetsGroups[this.selectedClassIndex] : {}
    }

    get selectedClass() {
        return this.isSingleClassFilterResults ? (
            this.filterResultSingleClassId ? this.classesList.find(cls => cls.id === this.filterResultSingleClassId) : null
        ) : (
            this.classesList.length && this.selectedClassIndex > -1 ? this.classesList[this.selectedClassIndex] : {}
        );
    }

    get selectedClassHistoryPoints() {
        if (this.isSingleClassFilterResults) {
            const selectedIndex = this.assetsGroups.findIndex(ag => ag.id === this.filterResultSingleClassId);
            return this.groupsHistoryPoints.length ? this.groupsHistoryPoints[selectedIndex] : null;
        } else if (this.isMainResults) {
            return this.totalNetWorthHistoryPoints.length ? this.totalNetWorthHistoryPoints : null;
        } else {
            return null
        }
    }

    get selectedClassCategoriesLength() {
        return this.metadataStore.categories.filter(category => category.classId === this.selectedClass.id).length
    }

    get liabilitiesGroups() {
        return this.assetsGroups.filter(g => g.isLiability);
    }

    get classesList() {
        const assetsUsdValue = this.assetsGroups.filter(a => !a.isLiability).flatMap((assetClass) => assetClass.items).reduce((a, b) => a + b.usdValue, 0);
        const classesRows =
            this.assetsGroups
                .filter(ag =>
                (!this.uiStore.isDesktopView || this.isShowAllClasses ||
                    ag.items.length + ag.archivedItems.length > 0 ||
                    (this.metadataStore.classWithItemsCount < 2 && this.metadataStore.defaultClassesListIds.includes(ag.id))))
                .map((ag, index) => {
                    const groupHaveTrnIndication = ag.items.some(groupItem => this.assetIdsWithTransactionsIndications.includes(groupItem.id));
                    const groupHaveEventIndication = ag.items.some(groupItem => this.assetIdsWithEventsIndications.includes(groupItem.id));
                    const classCashFlowColumnsData = this.inflowOutflowDataLayersStore.columns.map(col => {
                        return getColumnDataForItems(col, ag.items, this.inflowOutflowDataLayersStore.timeFrame, this.inflowOutflowDataLayersStore.selectedEventTypes)
                    });

                    const classItemsUsdValue = ag.items.reduce((a, b) => a + b.usdValue, 0);
                    const assetsWithRoi = ag.items.filter(item => !isNullOrUndefined(getValueByPath(item, roi_total_path)));
                    const assetsRoiWeightedAvg = weightedAverage(assetsWithRoi.map(item => ({ value: getValueByPath(item, roi_total_path), weight: item.usdValue })));
                    const assetsWithIrr = ag.items.filter(item => !isNullOrUndefined(getValueByPath(item, xirr_total_path)));
                    const assetsIrrWeightedAvg = weightedAverage(assetsWithIrr.map(item => ({ value: getValueByPath(item, xirr_total_path), weight: item.usdValue })));

                    const contributionsAndDistributions = getContributionsAndDistributionsData(ag.items, this.currenciesRates);

                    const lastUpdateDate = new Date(Math.max(...ag.items.filter(item => !isNullOrUndefined(getValueByPath(item, lastUpdate_path))).map(item => new Date(getValueByPath(item, lastUpdate_path)).getTime())));

                    const totalCommittedCapital = ag.items.reduce((a, b) => a + (getValueByPath(b, total_capital_path) || 0) / (this.currenciesRates[b.currency]), 0);

                    const totalLoansValue = ag.items.reduce((a, b) => a + (b.connectedLiabilities?.reduce((acc, item) => acc + item.usdValue, 0) || 0), 0);

                    // Array.isArray(dataLayerValue) && dataLayerValue.length ? displayMoneyValue( dataLayerValue.reduce((a,b)=>a+b.usdValue , 0),'USD')

                    const classItemRow = {
                        ...ag,
                        usdValue: ag.totalUsdValue,
                        trnIndication: groupHaveTrnIndication,
                        eventIndication: groupHaveEventIndication,
                        isInflowOutflowCalculated: !inflowOutflowHiddenClasses.includes(ag.id),
                        cashflowColumns: classCashFlowColumnsData,
                        allocation: assetsUsdValue === 0 ? 0 : (classItemsUsdValue / assetsUsdValue) * 100,
                        assetsRoi: assetsRoiWeightedAvg,
                        assetsIrr: assetsIrrWeightedAvg,
                        currency: 'USD',
                        ...contributionsAndDistributions,
                        loansAmount: totalLoansValue,
                        lastUpdate: lastUpdateDate,
                        totalCommittedCapital
                    }
                    classItemRow.sortValue = this.dataLayersStore.sortByColumn.path ? (getValueByPath(classItemRow, this.dataLayersStore.sortByColumn.path) || null) : (classItemRow[this.dataLayersStore.sortByColumn.dataKey] || null);
                    return classItemRow;
                })
                .sort((a, b) => {
                    return a.items.length > 0 && b.items.length === 0 ? -1 :
                        b.items.length > 0 && a.items.length === 0 ? 1 : 0;
                });

        if (this.dataLayersStore.sortByColumn.dataKey === 'title') {
            return classesRows;
        } else {
            return sortDisplayedItems(classesRows, this.dataLayersStore.sortByColumn, this.currenciesRates);
        }

    }


    get categoriesList() {

        const allItems = this.assetsGroups.flatMap((assetClass) => assetClass.items);
        const assetsUsdValue = this.assetsGroups.filter(a => !a.isLiability).flatMap((assetClass) => assetClass.items).reduce((a, b) => a + b.usdValue, 0);

        const categoriesToDisplay = this.assetsGroups.flatMap(ag => {
            const classCategories = this.metadataStore.categories.filter(category => category.classId === ag.id);
            return classCategories.map((category) => {
                const categoryItems = allItems.filter(item => item.categoryId === category.id);
                const categoryTotalUsdValue = categoryItems.reduce((a, b) => a + b.usdValue, 0);
                const relatedClassObj = this.assetsGroups.find(ag => ag.id === category.classId);
                const categoryCashFlowColumnsData = this.inflowOutflowDataLayersStore.columns.map(col => {
                    return getColumnDataForItems(col, categoryItems, this.inflowOutflowDataLayersStore.timeFrame, this.inflowOutflowDataLayersStore.selectedEventTypes)
                });

                const assetsWithRoi = categoryItems.filter(item => !isNullOrUndefined(getValueByPath(item, roi_total_path)));
                const assetsRoiWeightedAvg = weightedAverage(assetsWithRoi.map(item => ({ value: getValueByPath(item, roi_total_path), weight: item.usdValue })));
                const assetsWithIrr = categoryItems.filter(item => !isNullOrUndefined(getValueByPath(item, xirr_total_path)));
                const assetsIrrWeightedAvg = weightedAverage(assetsWithIrr.map(item => ({ value: getValueByPath(item, xirr_total_path), weight: item.usdValue })));
                const contributionsAndDistributions = getContributionsAndDistributionsData(categoryItems, this.currenciesRates);
                const lastUpdateDate = new Date(Math.max(...categoryItems.filter(item => !isNullOrUndefined(getValueByPath(item, lastUpdate_path))).map(item => new Date(getValueByPath(item, lastUpdate_path)).getTime())));
                const totalCommittedCapital = categoryItems.reduce((a, b) => a + (getValueByPath(b, total_capital_path) || 0) / (this.currenciesRates[b.currency]), 0);
                const totalLoansValue = categoryItems.reduce((a, b) => a + (b.connectedLiabilities?.reduce((acc, item) => acc + item.usdValue, 0) || 0), 0);

                const categoryItemRow = {
                    ...category,
                    isLiability: relatedClassObj.isLiability,
                    color: relatedClassObj.color,
                    icon: relatedClassObj.icon,
                    items: categoryItems,
                    usdValue: categoryTotalUsdValue,
                    cashflowColumns: categoryCashFlowColumnsData,
                    allocation: assetsUsdValue === 0 ? 0 : (categoryTotalUsdValue / assetsUsdValue) * 100,
                    assetsRoi: assetsRoiWeightedAvg,
                    assetsIrr: assetsIrrWeightedAvg,
                    currency: 'USD',
                    ...contributionsAndDistributions,
                    loansAmount: totalLoansValue,
                    lastUpdate: lastUpdateDate,
                    totalCommittedCapital,
                }
                categoryItemRow.sortValue = this.dataLayersStore.sortByColumn.path ? (getValueByPath(categoryItemRow, this.dataLayersStore.sortByColumn.path) || null) : (categoryItemRow[this.dataLayersStore.sortByColumn.dataKey] || null);
                return categoryItemRow;
            }).sort((a, b) => { return b.usdValue - a.usdValue });
        }).filter(cat => cat.items.length > 0)

        if (this.dataLayersStore.sortByColumn.dataKey === 'title') {
            return categoriesToDisplay;
        } else {
            return sortDisplayedItems(categoriesToDisplay, this.dataLayersStore.sortByColumn, this.currenciesRates);
        }
    }

    get customClassesList() {
        const customClassesGroups = this.metadataStore.customClasses.map(customClass => {
            const { colorImage: relevantImage, fallbackColor: relevantFallbackColor } = customClassColorsObj[customClass.colorCode] || {};
            const relevantSvg = customClassSvgObj[customClass.icon] || {};
            return { label: customClass.name, value: customClass.id, icon: customClass.icon, color: relevantFallbackColor, relevantImage, relevantSvg }
        });
        return getGroupedItemsByProp({ items: this.itemsForDisplay, allocationType: 'customClasses', propKey: 'customClassId', icon: 'customClasses', fixedGroups: customClassesGroups, inflowOutflowColumns: this.inflowOutflowDataLayersStore.columns, inflowOutflowTimeFrame: this.inflowOutflowDataLayersStore.timeFrame, currenciesRates: this.currenciesRates, sortByColumn: this.dataLayersStore.sortByColumn, selectedEventTypes: this.inflowOutflowDataLayersStore.selectedEventTypes });
    }

    get customSubClassesList() {
        const customClassesGroups = this.metadataStore.customClasses.map(customClass => {
            const { colorImage: relevantImage, fallbackColor: relevantFallbackColor } = customClassColorsObj[customClass.colorCode] || {};
            const relevantSvg = customClassSvgObj[customClass.icon] || {};
            return customClass.customSubClasses.map(customSubClass => (
                { label: customSubClass.name, value: customSubClass.id, customClassId: customClass.id, icon: customClass.icon, color: relevantFallbackColor, relevantImage, relevantSvg }
            ))
        }).flat();
        return getGroupedItemsByProp({ items: this.itemsForDisplay, allocationType: 'customSubClasses', propKey: 'customSubClassId', icon: 'customSubClasses', fixedGroups: customClassesGroups, inflowOutflowColumns: this.inflowOutflowDataLayersStore.columns, inflowOutflowTimeFrame: this.inflowOutflowDataLayersStore.timeFrame, currenciesRates: this.currenciesRates, sortByColumn: this.dataLayersStore.sortByColumn, hideEmptyFixedGroups: !this.isMainResults, selectedEventTypes: this.inflowOutflowDataLayersStore.selectedEventTypes });
    }

    get entitiesList() {
        return getGroupedItemsByProp({ items: this.itemsForDisplay, allocationType: 'entities', propKey: 'beneficiary', icon: 'entity', inflowOutflowColumns: this.inflowOutflowDataLayersStore.columns, inflowOutflowTimeFrame: this.inflowOutflowDataLayersStore.timeFrame, currenciesRates: this.currenciesRates, sortByColumn: this.dataLayersStore.sortByColumn, selectedEventTypes: this.inflowOutflowDataLayersStore.selectedEventTypes, metadataStore: this.metadataStore });
    }

    get currenciesList() {
        return getGroupedItemsByProp({ items: this.itemsForDisplay, allocationType: 'currencies', propKey: 'currency', icon: 'currency', inflowOutflowColumns: this.inflowOutflowDataLayersStore.columns, inflowOutflowTimeFrame: this.inflowOutflowDataLayersStore.timeFrame, currenciesRates: this.currenciesRates, sortByColumn: this.dataLayersStore.sortByColumn, selectedEventTypes: this.inflowOutflowDataLayersStore.selectedEventTypes });
    }

    get riskList() {
        return getGroupedItemsByProp({ items: this.itemsForDisplay, allocationType: 'risk', propKey: 'riskLevel', fixedGroups: riskFixedGroups, inflowOutflowColumns: this.inflowOutflowDataLayersStore.columns, inflowOutflowTimeFrame: this.inflowOutflowDataLayersStore.timeFrame, currenciesRates: this.currenciesRates, sortByColumn: this.dataLayersStore.sortByColumn, selectedEventTypes: this.inflowOutflowDataLayersStore.selectedEventTypes });
    }

    get propertyTypeList() {
        return getGroupedItemsByProp({ items: this.itemsForDisplay, allocationType: 'propertyType', propKey: 'aggregatedMetricsData.attributes.propertyType', icon: 'propertyType', inflowOutflowColumns: this.inflowOutflowDataLayersStore.columns, inflowOutflowTimeFrame: this.inflowOutflowDataLayersStore.timeFrame, currenciesRates: this.currenciesRates, sortByColumn: this.dataLayersStore.sortByColumn, selectedEventTypes: this.inflowOutflowDataLayersStore.selectedEventTypes });
    }

    get companySectorList() {
        return getGroupedItemsByProp({ items: this.itemsForDisplay, allocationType: 'companySector', propKey: 'aggregatedMetricsData.attributes.companySector', icon: 'companySector', inflowOutflowColumns: this.inflowOutflowDataLayersStore.columns, inflowOutflowTimeFrame: this.inflowOutflowDataLayersStore.timeFrame, currenciesRates: this.currenciesRates, sortByColumn: this.dataLayersStore.sortByColumn, selectedEventTypes: this.inflowOutflowDataLayersStore.selectedEventTypes });
    }

    get liquidityList() {
        return getGroupedItemsByProp({ items: this.itemsForDisplay, allocationType: 'liquidity', propKey: 'liquidity', fixedGroups: liquidityFixedGroups, inflowOutflowColumns: this.inflowOutflowDataLayersStore.columns, inflowOutflowTimeFrame: this.inflowOutflowDataLayersStore.timeFrame, currenciesRates: this.currenciesRates, sortByColumn: this.dataLayersStore.sortByColumn, selectedEventTypes: this.inflowOutflowDataLayersStore.selectedEventTypes });
    }

    get institutionList() {
        return getGroupedItemsByProp({ items: this.itemsForDisplay, allocationType: 'institution', propKey: 'name', icon: 'institution', inflowOutflowColumns: this.inflowOutflowDataLayersStore.columns, inflowOutflowTimeFrame: this.inflowOutflowDataLayersStore.timeFrame, currenciesRates: this.currenciesRates, sortByColumn: this.dataLayersStore.sortByColumn, selectedEventTypes: this.inflowOutflowDataLayersStore.selectedEventTypes });
    }

    get wealthOverviewList() {
        if (this.allocateBy === 'categories') {
            return this.categoriesList;
        } if (this.allocateBy === 'entities') {
            return this.entitiesList;
        } else if (this.allocateBy === 'currencies') {
            return this.currenciesList;
        } else if (this.allocateBy === 'risk') {
            return this.riskList;
        } else if (this.allocateBy === 'liquidity') {
            return this.liquidityList;
        } else if (this.allocateBy === 'institution') {
            return this.institutionList;
        } else if (this.allocateBy === 'accounts') {
            return this.itemsList;
        } else if (this.allocateBy === 'customClasses') {
            return this.customClassesList;
        } else if (this.allocateBy === 'customSubClasses') {
            return this.customSubClassesList;
        } else if (this.allocateBy === 'propertyType') {
            return this.propertyTypeList;
        } else if (this.allocateBy === 'companySector') {
            return this.companySectorList;
        } else {
            return this.classesList;
        }
    }

    // get isResultsOnlyCashOrCredit = this.assetClass
    get isContainItemsFromNonCashOrCredit() {
        return this.assetsGroups.some(ag => !inflowOutflowHiddenClasses.includes(ag.id) && ag.items.length > 0);
    }

    get isContainItemsFromCashOrCredit() {
        return this.assetsGroups.some(ag => inflowOutflowHiddenClasses.includes(ag.id) && ag.items.length > 0);
    }

    get itemsForDisplay() {
        const { sortByColumn } = this.dataLayersStore;
        const isContainItemFromNonCashOrCredit = this.assetsGroups.some(ag => !inflowOutflowHiddenClasses.includes(ag.id) && ag.items.length > 0);

        const items = this.assetsGroups.flatMap((assetClass) => assetClass[this.listViewMode === 'archived' ? 'archivedItems' : 'items']?.map((item) => {
            const customClass = item.customClassId ? (this.metadataStore.customClassObj[item.customClassId]?.name || null) : null;
            const customSubClass = item.customSubClassId ? this.metadataStore.customSubClassesObj[item.customSubClassId]?.name || null : null;
            const sortValue =
                sortByColumn.dataKey === 'customClass' ? customClass :
                    sortByColumn.dataKey === 'customSubClass' ? customSubClass :
                        sortByColumn.categoryPath?.[item.categoryId] ? (getValueByPath(item, sortByColumn.categoryPath?.[item.categoryId]) || null) : sortByColumn.path ? (getValueByPath(item, sortByColumn.path) || null) : item[sortByColumn.dataKey];

            return {
                ...item,
                customClass,
                customSubClass,
                isAsset: !assetClass.isLiability,
                trnIndication: this.assetIdsWithTransactionsIndications.includes(item.id),
                eventIndication: this.assetIdsWithEventsIndications.includes(item.id),
                isInflowOutflowCalculated: isContainItemFromNonCashOrCredit ? !inflowOutflowHiddenClasses.includes(assetClass.id) : true,
                hideInflowOutflowData: ((item.isLoginRequired || item.isConnected) && !item.vipInstitutionId && !inflowOutflowHiddenClasses.includes(assetClass.id)),
                sortValue,
            }
        }));
        return items;
    }

    get itemsList() {
        const { sortByColumn } = this.dataLayersStore;
        const containersObj = this.itemsForDisplay.filter(item => item.container && !item.isConnected).reduce((acc, item) => {
            if (!acc.hasOwnProperty(item.container)) {
                acc[item.container] = [item];
            } else {
                acc[item.container].push(item);
            }
            return acc;
        }, {});

        const containersItems = Object.keys(containersObj).map((containerName) => {
            
            const relevantContainer = containersObj[containerName];
            const itemsUsdValue = relevantContainer.reduce((a, b) => a + b.usdValue, 0);
            return {
                title: containerName,
                category: {id:0,title:'--'}  , // relevantContainer[0].category,
                categoryClass: relevantContainer[0].categoryClass,
                holdings: 1,
                isContainer: true,
                // usdValue: itemsUsdValue,
                currency: 'USD',
                holdingsValue: itemsUsdValue,
                value: itemsUsdValue
            }
        })
        const displayItems = (this.isDisplayingAccountAsContainers ? [...this.itemsForDisplay.filter(item => !item.container || item.isConnected), ...containersItems] : this.itemsForDisplay)
        let valuesForSpecialSort = {};
        if (sortByColumn.dataKey === 'riskLevel') {
            valuesForSpecialSort = { 'null': 1, 'Low': 2, 'Medium': 3, 'High': 4 }
        }
        else if (sortByColumn.dataKey === 'class') {
            this.assetsGroups.forEach((assetClass, index) => {
                valuesForSpecialSort[assetClass.title] = index + 1;
            })
        } else if (sortByColumn.dataKey === 'category') {

            this.metadataStore.sortedCategories.forEach((cat, index) => {
                valuesForSpecialSort[cat.title] = index + 1;
            });
        } else if (sortByColumn.dataKey === 'customClass') {
            this.metadataStore.customClasses.forEach((customClass, index) => {
                valuesForSpecialSort[customClass.name] = index + 1;
            })
            valuesForSpecialSort['null'] = this.metadataStore.customClasses.length + 1;
        } else if (sortByColumn.dataKey === 'customSubClass') {
            let subClassesIndex = 1;
            this.metadataStore.customClasses.forEach((customClass, index) => {
                customClass.customSubClasses.forEach((subClass) => {
                    valuesForSpecialSort[subClass.name] = subClassesIndex++;
                })
            })
            valuesForSpecialSort['null'] = subClassesIndex;
        }
        return sortDisplayedItems(displayItems, sortByColumn, this.currenciesRates, true, valuesForSpecialSort);
    }

    get cryptoHoldingsList() {
        const { sortByColumn } = this.dataLayersStore;
        const displayItems = this.itemsForDisplay.filter(i => i.categoryId !== 12);
        return sortDisplayedItems(displayItems, sortByColumn, this.currenciesRates, true);
    }

    get displayedListItems() {
        return this.listViewMode === 'crypto-holdings' ? this.cryptoHoldingsList : this.itemsList;
    }

    get isDisplayingAccountAsContainers() {
        return this.filtersStore.isOnlyOneClassFilter && [3, 9].includes(this.filtersStore.singleClassFilter.id) && this.listViewMode !== 'archived';
    }

    get closedItemsIdsInDisplay() {
        return this.displayedListItems.filter(item => item.isClosed).map(item => item.id);
    }

    get closedItemsInDisplayCount() {
        return this.closedItemsIdsInDisplay.length;
    }

    // same as itemsList, but always items ( without archived ) , and maybe will include more data for the pie
    // also keeping the sort as we don't want to change  the pie if we change the order of the displayed items  
    get itemsForFiltersPieChart() {
        return [
            ...this.assetsGroups.flatMap((assetClass) => assetClass.items?.map((item) => (
                { ...item, color: assetClass.color, isAsset: !assetClass.isLiability }
            ))),
            // ...this.liabilitiesGroups.flatMap((liabilityClass) => liabilityClass.items?.map((item) => ({...item, isAsset: false})))
        ].sort((a, b) => (a.usdValue < b.usdValue ? 1 : a.usdValue > b.usdValue ? -1 : 0));
    }

    get liabilitiesForPieCharts() {
        // const assetItems = this.itemsForFiltersPieChart.filter(item => item.isAsset);
        const liabilitiesItems = this.itemsForFiltersPieChart.filter(item => !item.isAsset || item.usdValue < 0);
        const liabilitiesItemsIds = liabilitiesItems.map(lia => lia.id);
        const itemsWithLiabilities = this.itemsForFiltersPieChart.filter(item => item?.connectedLiabilities?.length > 0);

        const connectedLiabilities = itemsWithLiabilities?.map(iwl => iwl.connectedLiabilities).flat();
        // remove connected liabilities that are included in liabilities items    
        return [
            ...liabilitiesItems.map(lia => ({ ...lia, liabilityId: lia.id })),
            ...connectedLiabilities.filter(clia => !liabilitiesItemsIds.includes(clia.liabilityId))
        ]
    }

    get isFilterOnlyLiabilities() {
        return this.itemsForFiltersPieChart.filter(item => item.isAsset).length === 0;
    }

    get isOnlyCashOrCreditItems() {
        return !this.assetsGroups.some(ag => !inflowOutflowHiddenClasses.includes(ag.id) && ag.items.length > 0);
    }

    get itemsListLength() {
        return this.isEmptyFilterResults ? 0 : this.isDisplayingAccountAsContainers ? this.itemsList.length : this.assetsGroups.flatMap((assetClass) => assetClass.items).length;
    }

    get classesListLength() {
        return this.isEmptyFilterResults ? 0 : this.assetsGroups.length;
    }

    get archivedItemsListLength() {
        return this.assetsGroups.flatMap((assetClass) => assetClass.archivedItems).length;
    }

    get assetsAsObj() {
        return this.assetsGroups.map(ag => ag.items).flat().reduce(
            (acc, curr) => ((acc[curr.id] = curr), acc), {} /* eslint-disable-line no-sequences*/
        );
    }

    get chartAssetsDataForTooltips() {
        if (this.allocateBy === 'categories') {
            return this.categoriesList;
        } else if (['entities', 'currencies', 'risk', 'liquidity', 'institution', 'propertyType', 'companySector', 'customClasses', 'customSubClasses'].includes(this.allocateBy)) {
            return this.wealthOverviewList;
        } else {
            return this.assetsGroups;
        }
    }
    get chartAssetsData() {
        if (this.allocateBy === 'categories') {
            return wealthOverviewCategoriesPieChartData(this.totalNetWorthUsdValue, this.categoriesList);
        }
        if (['entities', 'currencies', 'risk', 'liquidity', 'institution', 'propertyType', 'companySector', 'customClasses', 'customSubClasses'].includes(this.allocateBy)) {
            return wealthOverviewAllocationPieChartData(this.totalNetWorthUsdValue, this.wealthOverviewList);
        }
        return wealthOverviewPieChartData(this.totalNetWorthUsdValue, this.assetsGroups);
    }

    get isOnlyLoansItems() {
        return this.categoriesList.filter(g => !g.isLiability && g.usdValue > 0).map(group => group.usdValue).reduce((a, b) => a + b, 0) === 0;
    }

    get pieFiltersData() {
        return wealthOverviewFiltersPieChartData(this.heroMetricsData.totalItemsWorthUsdValue, this.itemsForFiltersPieChart);
    }

    get netWorthLineChartData() {
        return projectionOverviewLineChartData(this.netWorthChartData);
    }

    get classNetWorthLineChartData() {
        return this.assetClass ? projectionClassLineChartData(this.assetsProjectionData[this.selectedClassIndex]) : {};
    }

    get totalAssetsCount() {
        return this.assetsGroups.filter(g => !g.isLiability).reduce(
            (a, b) => (a + b.items.length), 0
        );
    }

    get totalLiabilitiesCount() {
        return this.assetsGroups.filter(g => g.isLiability).reduce(
            (a, b) => (a + b.items.length), 0
        );
    }

    get isMainResults() {
        return this.resultType === WEALTH_FILTER_RESULT_TYPE.MAIN;
    }

    get isMixedFilterResults() {
        return this.resultType === WEALTH_FILTER_RESULT_TYPE.MIXED;
    }

    get isSingleClassFilterResults() {
        return this.resultType === WEALTH_FILTER_RESULT_TYPE.SINGLE_CLASS;
    }

    get singleClass() {
        return (this.isSingleClassFilterResults && this.filterResultSingleClassId) ? this.metadataStore.classesObj[this.filterResultSingleClassId] : null;
    }

    get isEmptyFilterResults() {
        return this.resultType === WEALTH_FILTER_RESULT_TYPE.EMPTY;
    }

    get isAnyFilterApplied() {
        return this.isMixedFilterResults || this.isSingleClassFilterResults;
    }

    get heroFiltersDisplayData() {
        // Flatten the nested arrays of items and map them to their categoryIds
        const flattenedCategoryIds = this.assetsGroups.flatMap(group => group.items).map(item => item.categoryId);
        // Create a Set to get unique categoryIds
        const uniqueCategoryIds = new Set(flattenedCategoryIds);
        // Check if there's only one unique categoryId
        const filterResultSingleCategoryId = uniqueCategoryIds.size === 1 ? Array.from(uniqueCategoryIds)[0] : null;
        return this.filterResultSingleClassId ? (ASSET_SINGLE_CATEGORY_OVERVIEW_DATA[filterResultSingleCategoryId] || ASSET_CLASSES_OVERVIEW_DATA[this.filterResultSingleClassId] || { stats: [] }) : MIXED_RESULT_HERO_DATA; //   ;
    }

    get isHeroHaveAnyStats() {
        return this.heroFiltersDisplayData?.stats.length > 0;
    }

    get isAllocationsViewMode() {
        return this.listViewMode === 'allocations'
    }

    get inflowOutflowTotalColumns() {
        return this.inflowOutflowDataLayersStore.columns.map((col) => getColumnDataForItems(col, this.itemsList, this.inflowOutflowDataLayersStore.timeFrame, this.inflowOutflowDataLayersStore.selectedEventTypes));
    }

    get inflowOutflowHeroTotalColumns() {
        return this.inflowOutflowDataLayersStore.wealthMainHeroYears.map((col) => getColumnDataForItems(col, this.itemsList, this.inflowOutflowDataLayersStore.timeFrame, this.inflowOutflowDataLayersStore.selectedEventTypes));
    }

    get totalInflowForHero() {
       return this.itemsList.filter(item=>![1,11].includes(item.categoryClass.id)).reduce((acc, item) => {
            let itemInflowSum = 0; 
            Object.entries(item.historicalCashFlow || {}).forEach(([date, cashFlow]) => {
                if (moment(date,'MM-YYYY').isSameOrBefore(moment(),'month')) {
                    itemInflowSum += currency(cashFlow?.i.a || 0,{precision:8}).value
                }
            })
            return currency(acc,{precision:8}).add(itemInflowSum).value;
        }
        , 0);

    }

    get overviewHeroInflow() {
        return [...this.inflowOutflowHeroTotalColumns, { label: 'total', inflow: this.totalInflowForHero }];
    }

    get isInflowNavigateLeftEnable() {

        const firstStartDate = this.itemsList.reduce((oldest, current) => {
            var currentDate = new Date()
            if (!current.startDate || (current.isConnected && [1, 11].includes(current.categoryClass.id))) {
                const inflowOutflowDates = Object.keys(current.historicalCashFlow || {});
                if (!inflowOutflowDates.length) {
                    return oldest;
                }
                const firstInflowOutflowDate = inflowOutflowDates.map(i => i.split('-').reverse().join('-')).sort((a, b) => {
                    return new Date(a) - new Date(b);
                })?.[0];
                currentDate = new Date(firstInflowOutflowDate);
            } else {
                currentDate = new Date(current.startDate);
            }
            if (!currentDate) return oldest;
            return currentDate < oldest ? currentDate : oldest;
        }, new Date());


        const timeFrame = this.inflowOutflowDataLayersStore.timeFrame;
        const endIndex = this.inflowOutflowDataLayersStore.endpointIndex;
        if (timeFrame === 'years') {
            const firstItemYear = moment(firstStartDate).year();
            const currentMinYear = moment().add((endIndex + 2) * -1, 'years').year();
            return currentMinYear > firstItemYear;
        }
        if (timeFrame === 'quarters') {
            const firstItemYear = moment(firstStartDate).year();
            const firstItemQuarter = Math.floor(moment(firstStartDate).month() / 3) + 1;
            const currentMinYear = moment().add((endIndex + 3) * -3, 'months').year();
            const currentMinQuarter = Math.floor(moment().add((endIndex + 3) * -3, 'months').month() / 3) + 1;
            return currentMinYear > firstItemYear ? true : (currentMinYear === firstItemYear && currentMinQuarter > firstItemQuarter);
        }
        if (timeFrame === 'months') {
            const firstItemYear = moment(firstStartDate).year();
            const firstItemMonth = moment(firstStartDate).month();
            const currentMinYear = moment().add((endIndex + 5) * -1, 'months').year();
            const currentMinMonth = moment().add((endIndex + 5) * -1, 'months').month();
            return currentMinYear > firstItemYear ? true : (currentMinYear === firstItemYear && currentMinMonth > firstItemMonth);
        }
        return false;
    }

    get inflowOutflowBarsRatio() {
        const maxValue = Math.max(...this.inflowOutflowTotalColumns.map(col => Math.max(Math.abs(col.inflow), Math.abs(col.targetInflow), Math.abs(col.outflow), Math.abs(col.targetOutflow))));
        return 220 / maxValue;
    }

    get numberOfDifferentSelectedAttributeForSelectedItem() {
        const values = {};
        const attributeToCheck = this.selectedAttributeToEdit === 'customClass' ? 'customClassId' : this.selectedAttributeToEdit;

        this.selectedItemsForBulkEdit.forEach((itemId) => {
            if (!values.hasOwnProperty(this.assetsAsObj[itemId][attributeToCheck])) {
                const value = this.assetsAsObj[itemId][attributeToCheck] ? this.assetsAsObj[itemId][attributeToCheck] : 'empty';
                values[value] = true;
            }
        })
        return Object.keys(values).length;
    }

    get commonSingleSelectedAttributeForSelectedItem() {
        if (this.numberOfDifferentSelectedAttributeForSelectedItem === 1) {
            let commonValue = {};
            if (this.selectedAttributeToEdit === 'customClass') {
                commonValue = {
                    customClassId: this.assetsAsObj[this.selectedItemsForBulkEdit[0]].customClassId,
                }
                const customSubClassesValues = {};
                this.selectedItemsForBulkEdit.forEach((itemId) => {
                    if (!customSubClassesValues.hasOwnProperty(this.assetsAsObj[itemId]['customSubClassId'])) {
                        customSubClassesValues[this.assetsAsObj[itemId]['customSubClassId']] = true;
                    }
                })
                if (Object.keys(customSubClassesValues).length === 1) {
                    commonValue.customSubClassId = this.assetsAsObj[this.selectedItemsForBulkEdit[0]].customSubClassId;
                }
            } else {
                commonValue = { [this.selectedAttributeToEdit]: this.assetsAsObj[this.selectedItemsForBulkEdit[0]][this.selectedAttributeToEdit] }
            }
            return commonValue;
        } else {
            return {};
        }
    }

    get isAllItemsSelectedForBulkEdit() {
        let relevantItemsListLength;

        if (['items', 'crypto-holdings'].includes(this.listViewMode)) {
            relevantItemsListLength = this.itemsList.length - (this.isShowClosedAssets ? 0 : this.closedItemsInDisplayCount);
        } else if (this.isAllocationsViewMode && this.allocateBy === 'accounts') {
            relevantItemsListLength = this.itemsList.filter(item => !item.isContainer).length - (this.isShowClosedAssets ? 0 : this.closedItemsInDisplayCount);
        } else { // tickers
            relevantItemsListLength = this.tickersStore.tickers.length - (this.isShowClosedAssets ? 0 : this.tickersStore.closedHoldingsInDisplayCount);
        }

        return this.selectedItemsForBulkEdit.length === relevantItemsListLength;
    }

    get isPartialItemsSelectedForBulkEdit() {
        return this.selectedItemsForBulkEdit.length > 0 && !this.isAllItemsSelectedForBulkEdit;
    }

    get holdingsHeaderTabCount() {
        return this.tickersStore.tickers.length - (this.isShowClosedAssets ? 0 : this.tickersStore.closedHoldingsInDisplayCount);
    }

    get cryptoHoldingsHeaderTabCount() {
        return this.cryptoHoldingsList.length - (this.isShowClosedAssets ? 0 : this.cryptoHoldingsList.filter(item => item.isClosed).length);
    }

    get accountsHeaderTabCount() {
        return this.wealthOverviewList.length - (this.isShowClosedAssets ? 0 : this.closedItemsInDisplayCount);
    }

    get itemsHeaderTabCount() {
        return this.itemsListLength - (this.isShowClosedAssets ? 0 : this.closedItemsInDisplayCount);
    }
}

const checkLSTime = (key, hours) => {
    const lastCall = JSON.parse(window.localStorage.getItem(key));
    if (!lastCall) {
        return true;
    }
    const millisecondInHours = hours * 60 * 60 * 1000;
    if (new Date().getTime() > lastCall.time + millisecondInHours) {
        return true;
    }
    return false
}

const getColumnDataForItems = (col, items, timeFrame, selectedEventTypes) => {
    // console.log(selectedEventTypes);
    let inflowValue = 0;
    let targetInflow = 0;
    let outflowValue = 0;
    let targetOutflow = 0;
    items.filter(i => i.isInflowOutflowCalculated !== false).forEach(item => {
        if (!isNullOrUndefined(item.historicalCashFlow)) {
            const data = item.historicalCashFlow;
            const date = col.date;
            if (timeFrame === 'months') {
                if (data[date.format("MM-YYYY")]) {
                    const relevantValue = data[date.format("MM-YYYY")];

                    // const relevantInflowAddition = getDeltaByEventTypes(relevantValue.i.ae, selectedEventTypes);
                    // inflowValue += (relevantValue.i.a - relevantInflowAddition) || 0;
                    inflowValue += getRelevantValueByEventTypes(relevantValue.i.ae, selectedEventTypes);

                    // const relevantTargetInflowAddition = getDeltaByEventTypes(relevantValue.i.te, selectedEventTypes);
                    // targetInflow += (relevantValue.i.t - relevantTargetInflowAddition) || 0;
                    targetInflow += getRelevantValueByEventTypes(relevantValue.i.te, selectedEventTypes);

                    // const relevantOutflowAddition = getDeltaByEventTypes(relevantValue.o.ae, selectedEventTypes, 'out');
                    // outflowValue += (relevantValue.o.a + relevantOutflowAddition) || 0;
                    outflowValue += getRelevantValueByEventTypes(relevantValue.o.ae, selectedEventTypes, 'out');

                    // const relevantTargetOutflowAddition = getDeltaByEventTypes(relevantValue.o.te, selectedEventTypes, 'out');
                    // targetOutflow += (relevantValue.o.t + relevantTargetOutflowAddition) || 0;
                    targetOutflow += getRelevantValueByEventTypes(relevantValue.o.te, selectedEventTypes, 'out');
                }
            } else if (timeFrame === 'years') {
                const relevantYear = date.year();
                for (const key in data) {
                    if (key.endsWith(`-${relevantYear}`)) {
                        // const relevantInflowAddition = getDeltaByEventTypes(data[key].i.ae, selectedEventTypes);
                        // inflowValue += (data[key].i.a - relevantInflowAddition) || 0;
                        inflowValue += getRelevantValueByEventTypes(data[key].i.ae, selectedEventTypes);

                        // const relevantTargetInflowAddition = getDeltaByEventTypes(data[key].i.te, selectedEventTypes);
                        // targetInflow += (data[key].i.t - relevantTargetInflowAddition) || 0;
                        targetInflow += getRelevantValueByEventTypes(data[key].i.te, selectedEventTypes);

                        // const relevantOutflowAddition = getDeltaByEventTypes(data[key].o.ae, selectedEventTypes, 'out');
                        // outflowValue += (data[key].o.a + relevantOutflowAddition) || 0;
                        outflowValue += getRelevantValueByEventTypes(data[key].o.ae, selectedEventTypes, 'out');

                        // const relevantTargetOutflowAddition = getDeltaByEventTypes(data[key].o.te, selectedEventTypes, 'out');
                        // targetOutflow += (data[key].o.t + relevantTargetOutflowAddition) || 0;
                        targetOutflow += getRelevantValueByEventTypes(data[key].o.te, selectedEventTypes, 'out');
                    }
                }
            } else if (timeFrame === 'quarters') {
                const relevantYear = date.year();
                const timeFrameIndex = col.timeFrameIndex;
                for (const key in data) {
                    const relevantMonth = parseInt(key.substring(0, 2));
                    if (key.endsWith(`-${relevantYear}`) && relevantMonth >= timeFrameIndex * 3 - 2 && relevantMonth <= timeFrameIndex * 3) {
                        // const relevantInflowAddition = getDeltaByEventTypes(data[key].i.ae, selectedEventTypes);
                        // inflowValue += (data[key].i.a - relevantInflowAddition) || 0;
                        inflowValue += getRelevantValueByEventTypes(data[key].i.ae, selectedEventTypes);

                        // const relevantTargetInflowAddition = getDeltaByEventTypes(data[key].i.te, selectedEventTypes);
                        // targetInflow += (data[key].i.t - relevantTargetInflowAddition) || 0;
                        targetInflow += getRelevantValueByEventTypes(data[key].i.te, selectedEventTypes);

                        // const relevantOutflowAddition = getDeltaByEventTypes(data[key].o.ae, selectedEventTypes, 'out');
                        // outflowValue += (data[key].o.a + relevantOutflowAddition) || 0;
                        outflowValue += getRelevantValueByEventTypes(data[key].o.ae, selectedEventTypes, 'out');

                        // const relevantTargetOutflowAddition = getDeltaByEventTypes(data[key].o.te, selectedEventTypes, 'out');
                        // targetOutflow += (data[key].o.t + relevantTargetOutflowAddition) || 0;
                        targetOutflow += getRelevantValueByEventTypes(data[key].o.te, selectedEventTypes, 'out');
                    }
                }
            } else if (timeFrame === 'total') {
                for (const key in data) {
                    // const relevantInflowAddition = getDeltaByEventTypes(data[key].i.ae, selectedEventTypes);                    
                    // inflowValue += (data[key].i.a - relevantInflowAddition) || 0;
                    inflowValue += getRelevantValueByEventTypes(data[key].i.ae, selectedEventTypes);

                    // const relevantTargetInflowAddition = getDeltaByEventTypes(data[key].i.te, selectedEventTypes);
                    // targetInflow += (data[key].i.t - relevantTargetInflowAddition) || 0;
                    targetInflow += getRelevantValueByEventTypes(data[key].i.te, selectedEventTypes);

                    // const relevantOutflowAddition = getDeltaByEventTypes(data[key].o.ae, selectedEventTypes, 'out');
                    // outflowValue += (data[key].o.a + relevantOutflowAddition) || 0;
                    outflowValue += getRelevantValueByEventTypes(data[key].o.ae, selectedEventTypes, 'out');

                    // const relevantTargetOutflowAddition = getDeltaByEventTypes(data[key].o.te, selectedEventTypes, 'out');
                    // targetOutflow += (data[key].o.t + relevantTargetOutflowAddition) || 0;
                    targetOutflow += getRelevantValueByEventTypes(data[key].o.te, selectedEventTypes, 'out');
                }
            }

        }
    })
    return { inflow: inflowValue, outflow: outflowValue, targetInflow, targetOutflow, label: col.label }
}

const sortDisplayedItems = (items, sortByColumn, currenciesRates, doSecondarySortByTitle = true, specialSortValues) => {
    const sortedItems = items.sort((a, b) => {
        let result = 0
        if (['date', 'remainingTimeFromDate'].includes(sortByColumn.type)) {
            const dateA = new Date(a.sortValue);
            const dateB = new Date(b.sortValue);
            if (isNaN(dateA) && isNaN(dateB)) {
                result = 0;
            } else if (isNaN(dateA)) {
                result = 1;
            } else if (isNaN(dateB)) {
                result = -1;
            } else {
                result = +dateB - +dateA;
            }
        }
        else if (sortByColumn.type === 'text') {
            result = ('' + (b.sortValue || '')).localeCompare(('' + (a.sortValue || '')));
        }
        else if (sortByColumn.type === 'liabilityAmount') {
            result = b.sortValue?.reduce((c, d) => c + d.usdValue, 0) - a.sortValue?.reduce((c, d) => c + d.usdValue, 0);
        }
        else if (['percentage', 'percentageMax1', 'multiplier', 'number'].includes(sortByColumn.type)) {
            result = b.sortValue - a.sortValue;
        }
        else if (sortByColumn.type === 'bool') {
            result = b.sortValue === a.sortValue ? 0 : b.sortValue ? 1 : -1;
        }
        else if (sortByColumn.dataKey === 'usdValue') {
            result = b.sortValue - a.sortValue;
        }
        else if (['riskLevel', 'class', 'category', 'customClass', 'customSubClass'].includes(sortByColumn.dataKey)) {
            result = specialSortValues?.['' + b.sortValue] - specialSortValues?.['' + a.sortValue];
        } else {
            result = (b.sortValue * (b.holdings || 1) / (currenciesRates?.[b.currency] || 1)) - (a.sortValue * (a.holdings || 1) / (currenciesRates?.[a.currency] || 1));
        }

        // Secondary sort by title if sortValue is equal 
        if (doSecondarySortByTitle && result === 0) {
            result = sortByColumn.direction === "asc" ? b.title.localeCompare(a.title) : a.title.localeCompare(b.title);
        }

        if (sortByColumn.dataKey === 'usdValue') {
            if (a.isClosed && !b.isClosed) {
                result = 1;
            }
            if (b.isClosed && !a.isClosed) {
                result = -1;
            }
        }

        return result;
    });
    return sortByColumn.direction === "asc" ? sortedItems.reverse() : sortedItems;
}

const getGroupedItemsByProp = ({ items, allocationType, propKey, icon, fixedGroups, inflowOutflowColumns, inflowOutflowTimeFrame, currenciesRates, sortByColumn, hideEmptyFixedGroups = false, selectedEventTypes, metadataStore }) => {

    const containerAcc = fixedGroups ? fixedGroups.reduce((acc, group) => {
        acc[group.value || 'Unspecified'] =
        {
            label: group.label, filterValue: group.value, icon: group.icon, color: group.color, items: []
            , ...(['customClasses', 'customSubClasses'].includes(allocationType) ? { relevantImage: group.relevantImage, relevantSvg: group.relevantSvg, customClassId: group.customClassId } : {})
        }; return acc;
    }, {}) : {};
    const containersObj = items.reduce((acc, item) => {
        const relevantProp = getValueByPath(item, propKey) || 'Unspecified';
        if (!acc.hasOwnProperty(relevantProp)) {
            acc[relevantProp] = {
                label: relevantProp,
                filterValue: relevantProp === 'Unspecified' ? (allocationType === 'institution' ? '' : null) : relevantProp,
                items: [item]
            };
        } else {
            acc[relevantProp].items.push(item);
        }
        return acc;
    }, { ...containerAcc });

    const itemsAssetsUsdValue = items.filter(a => a.isAsset).reduce((a, b) => a + b.usdValue, 0);

    const containersItems = Object.keys(containersObj).map((propValueName, index) => {

        const relevantContainer = containersObj[propValueName];


        const assetsWithRoi = relevantContainer.items.filter(item => !isNullOrUndefined(getValueByPath(item, roi_total_path)));
        const assetsRoiWeightedAvg = weightedAverage(assetsWithRoi.map(item => ({ value: getValueByPath(item, roi_total_path), weight: item.usdValue })));
        const assetsWithIrr = relevantContainer.items.filter(item => !isNullOrUndefined(getValueByPath(item, xirr_total_path)));
        const assetsIrrWeightedAvg = weightedAverage(assetsWithIrr.map(item => ({ value: getValueByPath(item, xirr_total_path), weight: item.usdValue })));
        const itemsUsdValue = relevantContainer.items.reduce((a, b) => a + b.usdValue, 0);
        const containerAssetsUsdValue = relevantContainer.items.filter(i => i.isAsset).reduce((a, b) => a + b.usdValue, 0);
        const liabilitiesUsdValue = relevantContainer.items.filter(i => !i.isAsset).reduce((a, b) => a + b.usdValue, 0);
        const itemsCashFlowColumnsData = inflowOutflowColumns.map(col => {
            return getColumnDataForItems(col, relevantContainer.items, inflowOutflowTimeFrame, selectedEventTypes);
        });

        const contributionsAndDistributions = getContributionsAndDistributionsData(relevantContainer.items, currenciesRates);

        const lastUpdateDate = new Date(Math.max(...relevantContainer.items.filter(item => !isNullOrUndefined(getValueByPath(item, lastUpdate_path))).map(item => new Date(getValueByPath(item, lastUpdate_path)).getTime())));

        const totalCommittedCapital = relevantContainer.items.reduce((a, b) => a + (getValueByPath(b, total_capital_path) || 0) / (currenciesRates[b.currency]), 0);

        const totalLoansValue = relevantContainer.items.reduce((a, b) => a + (b.connectedLiabilities?.reduce((acc, item) => acc + item.usdValue, 0) || 0), 0);
        //TODO: Gilad please refactor this to be more readable | it just for the allocationType === 'entities' case
        relevantContainer.color = getBgColor();

        const containerItemToReturn = {
            allocationType,
            icon: relevantContainer.icon || icon,
            title: relevantContainer.label,
            filterValue: relevantContainer.filterValue,
            itemsLength: relevantContainer.items.length,
            assetsLength: relevantContainer.items.filter(i => i.isAsset).length,
            liabilityLength: relevantContainer.items.filter(i => !i.isAsset).length,
            color: relevantContainer.color ? relevantContainer.color : propValueName === 'Unspecified' ? colors.darkGray2 : wealthGroupToColor(relevantContainer.label),
            id: index,
            holdings: 1,
            isContainer: true,
            ...contributionsAndDistributions,
            // usdValue: itemsUsdValue,
            currency: 'USD',
            holdingsValue: itemsUsdValue,
            usdValue: containerAssetsUsdValue,
            cashflowColumns: itemsCashFlowColumnsData,
            assetsRoi: assetsRoiWeightedAvg,
            assetsIrr: assetsIrrWeightedAvg,
            assetsAllocation: itemsAssetsUsdValue === 0 ? 0 : (containerAssetsUsdValue / itemsAssetsUsdValue) * 100,
            liabilitiesValue: liabilitiesUsdValue,
            loansAmount: totalLoansValue,
            lastUpdate: lastUpdateDate,
            totalCommittedCapital,
            ...(['customClasses', 'customSubClasses'].includes(allocationType) ? { relevantImage: relevantContainer.relevantImage, relevantSvg: relevantContainer.relevantSvg, customClassId: relevantContainer.customClassId || null } : {})
            // sortValue: sortByColumn.categoryPath?.[item.categoryId] ? (getValueByPath(item,sortByColumn.categoryPath?.[item.categoryId]) || null) : sortByColumn.path ? (getValueByPath(item,sortByColumn.path) || null) : item[sortByColumn.dataKey],    
        }
        // sortByColumn.path ? (getValueByPath(item,sortByColumn.path) || null) : item[sortByColumn.dataKey]
        containerItemToReturn.sortValue = sortByColumn.path ? (getValueByPath(containerItemToReturn, sortByColumn.path) || null) : containerItemToReturn[sortByColumn.dataKey];
        return containerItemToReturn;

        function getBgColor() {
            if (allocationType === 'entities') { //In this case we have to get the color from the beneficiaries list
                const name = relevantContainer.items[0].beneficiary;
                return metadataStore.beneficiaries.find(b => b.name === name)?.colorCode || relevantContainer.color;
            }

            return relevantContainer.color;
        }
    })

    let sortedItems;
    if (fixedGroups) {
        const orderedContainers = fixedGroups.map(group => containersItems.find(c => c.title === group.label));
        if (!containerAcc.hasOwnProperty('Unspecified') && containersObj.hasOwnProperty('Unspecified')) {
            orderedContainers.push(containersItems.find(c => c.title === 'Unspecified'));
        }
        if (sortByColumn.dataKey === 'title') {
            sortedItems = sortByColumn.direction === "asc" ? orderedContainers : orderedContainers.reverse();
        }
        else {
            sortedItems = sortDisplayedItems(orderedContainers, sortByColumn, currenciesRates, false);
        }
    } else {
        sortedItems = sortDisplayedItems(containersItems, sortByColumn, currenciesRates);
    }

    return sortedItems.filter(c => (c.title !== 'Unspecified' && !hideEmptyFixedGroups) || c.itemsLength > 0).sort((a, b) => {
        if (a.title === 'Unspecified' && b.title !== 'Unspecified') {
            return 1;
        } else if (a.title !== 'Unspecified' && b.title === 'Unspecified') {
            return -1;
        } else {
            return 0
        }
    });
}

const getContributionsAndDistributionsData = (items, currenciesRate) => {
    const { contributions, distributions } = items.reduce((acc, item) => {
        if (item.aggregatedMetricsData?.contributions) {
            for (const key in item.aggregatedMetricsData.contributions) {
                if (key === 'total') {
                    acc.contributions.total.actual += item.aggregatedMetricsData.contributions[key].actual / (currenciesRate[item.currency] || 1);
                } else {
                    acc.contributions[key] = acc.contributions[key] || { actual: 0 };
                    acc.contributions[key].actual += item.aggregatedMetricsData.contributions[key].actual / (currenciesRate[item.currency] || 1);
                }
            }
        }
        if (item.aggregatedMetricsData?.distributions) {
            for (const key in item.aggregatedMetricsData.distributions) {
                if (key === 'total') {
                    acc.distributions.total.actual += item.aggregatedMetricsData.distributions[key].actual / (currenciesRate[item.currency] || 1);
                } else {
                    acc.distributions[key] = acc.distributions[key] || { actual: 0 };
                    acc.distributions[key].actual += item.aggregatedMetricsData.distributions[key].actual / (currenciesRate[item.currency] || 1);
                }
            }
        }
        return acc;
    }, { contributions: { total: { actual: 0 } }, distributions: { total: { actual: 0 } } });

    return { contributions, distributions };
}

