import { LoadingOutlined } from '@ant-design/icons';
import { signInWithPopup } from 'firebase/auth';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PasswordDisplayer } from '../../../../common/components/PasswordDisplayer/PasswordDisplayer';
import { postData } from '../../../../common/utils/network.utils';
import { useStore } from '../../../app/data/root.store';
import { auth, provider } from '../../../user/data/firebase.config';
import { AUTH_STEPS } from '../../../user/data/user.store';
import GoogleLetterSign from '../../assets/google-letter-sign.png';
import { ContinueButton, FormMessage, GoogleSignInButton, HorizontalLine, HorizontalSeparatorWrapper, InputLabel, InputWrapper, LinkSpan, LoginForm, LoginInput, SignUpStepHeader, SignUpStepSubHeader, SignUpStepWrapper } from '../LoginPage.styles';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { transformEmailForMixpanel } from '../../../../common/utils/string.utils';

export const LogInStep = observer((props) => {
  const location = useLocation();
  const { userStore } = useStore()
  const { mixpanel, trackEvent } = useAnalytics()
  const [message, setMessage] = useState('');
  const [isWaitingForGoogleResponse, setIsWaitingForGoogleResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { email, password } = userStore.authFields;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordInputRef = useRef();
  const du = parseInt(new URLSearchParams(location.search).get('du'));

  useEffect(() => {
    if (passwordInputRef.current && emailRegex.test(email) && userStore.prevAuthStep === AUTH_STEPS.SIGN_UP_STEP) {
      passwordInputRef.current.focus();
    }
    // return () => {}
    // eslint-disable-next-line
  }, [])

  const handleContinueWithGoogle = () => {
    
    setIsWaitingForGoogleResponse(true);
    signInWithPopup(auth, provider).then(data => {
      const formData = {
        email: data.user.email,
        token: data.user.accessToken,
      }
      const urlParams = new URLSearchParams(location.search);
      const paramsToSend = {
        utm_source: urlParams.get('utm_source') || mixpanel.get_property('utm_source'),
        utm_medium: urlParams.get('utm_medium') || mixpanel.get_property('utm_medium'),
        utm_campaign: urlParams.get('utm_campaign') || mixpanel.get_property('utm_campaign'),
        utm_id: urlParams.get('utm_id') || mixpanel.get_property('utm_id'),
        distinct_id: urlParams.get('distinct_id') || mixpanel.get_distinct_id(),
      }
      const queryString = Object.keys(paramsToSend).filter(key => paramsToSend[key]).map(key => key + '=' + paramsToSend[key]).join('&');
      postData("user/google-auth" + (queryString ? ("?" + queryString) : ''), formData)
        .then(data => {
          window.localStorage.removeItem('mobileMessageSeen')

          if (data.success) {

            mixpanel.identify(transformEmailForMixpanel(formData.email));

            window.location.reload();
          } else {
            setIsWaitingForGoogleResponse(false);
          }
        }, error => {
          setIsWaitingForGoogleResponse(false);
          console.error(error);
        });

    }, error => {
      setIsWaitingForGoogleResponse(false);
      console.error(error);
    }).finally(() => {
      trackEvent('CTA Clicked', {
        cta_text: "Continue with Google",
        location: "Main login page",
        destination: 'Google authentication page',
        type: "Button",
        url: window.location.href
      })
    })
  }

  const login = async () => {
    trackEvent('CTA Clicked', {
      cta_text: "Continue",
      location: "Main login page",
      destination: 'Code verification page',
      type: "Button",
      url: window.location.href
    });

    const formData = {
      email: email,
      password: password,
      du
    };

    if (formData.email && formData.password) {
      setIsLoading(true);
      try {
        const data = await postData("user/login", formData);
        //The change is related to the 'Enhance your experience...' screen that appears every time the user uses his phone.
        window.localStorage.removeItem('mobileMessageSeen')
        mixpanel.identify(transformEmailForMixpanel(formData.email));

        if (!data.statusCode) {
          window.localStorage.setItem('showNotifications', '1');

          if (['demo@vyzer.co'].includes(formData.email)) {
            window.location.reload();
          } else {
            userStore.setAuthStep(AUTH_STEPS.EMAIL_VERIFICATION_STEP);
            window.localStorage.setItem('wfc', '1');
            window.localStorage.setItem('regMail', formData.email);
          }
        }
      } catch (error) {
        if (error.message) {
          setMessage(Array.isArray(error.message) ? capitalizeTheFirstLetter(error.message) : error.message);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setMessage("Please fill email & password");
    }
  };

  const capitalizeTheFirstLetter = (arrayOfStrings) => {
    return arrayOfStrings.map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' , ');
  }

  const handleEmailInputChange = (e) => {
    userStore.setAuthFields('email', e.target.value);
  }

  const handlePasswordInputChange = (value) => {
    userStore.setAuthFields('password', value);
  }

  const handleForgotPassword = () => {
    trackEvent('CTA Clicked', {
      cta_text: "Forgot password?",
      location: "Main login page",
      destination: 'Forgot password page',
      type: "Hyperlink",
      url: window.location.href
    })
    userStore.setAuthFields('password', '');
    userStore.setAuthStep(AUTH_STEPS.FORGOT_PASSWORD_STEP);
  }

  const passKeyPress = (e) => {
    if (e.keyCode === 13) {
      login();
    }
  }

  return (
    <>
      <SignUpStepWrapper>
        <SignUpStepHeader>Welcome back.</SignUpStepHeader>
        <SignUpStepSubHeader>Log in to your account</SignUpStepSubHeader>
        <GoogleSignInButton onClick={handleContinueWithGoogle} isACtive={isWaitingForGoogleResponse} >{isWaitingForGoogleResponse ? <LoadingOutlined /> : <><img src={GoogleLetterSign} alt='google sign' /> Continue with Google</>}</GoogleSignInButton>
        <HorizontalSeparatorWrapper><HorizontalLine />Or<HorizontalLine /></HorizontalSeparatorWrapper>
        <LoginForm>
          <InputWrapper>
            <InputLabel>Email</InputLabel>
            <LoginInput onChange={handleEmailInputChange} type="text" value={email} name="email" />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Password</InputLabel>
            <PasswordDisplayer ref={passwordInputRef} passKeyPress={passKeyPress} onChange={handlePasswordInputChange} placeholder='Create a secure password' />
          </InputWrapper>
          <FormMessage haveMessage={message !== ''}>
            {message}
          </FormMessage>
        </LoginForm>
        <ContinueButton onClick={login}>{isLoading ? <LoadingOutlined /> : 'Continue'}</ContinueButton>
        <LinkSpan style={{ marginTop: '40px' }} type="link" onClick={handleForgotPassword}>Forgot password?</LinkSpan>
      </SignUpStepWrapper>
    </>
  )
})
