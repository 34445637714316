export const colors = {
    lightGray1: '#F7F8F9',
    lightGray2: '#F1F3F5',
    gray1:'#979797',
    gray2: '#ACB1BB',
    gray3: '#DDE3EB',
    darkGray1 : '#1A1B1D',
    darkGray2 : '#585D66',
    darkGray3 : '#9097A3',
    redNotification : '#FF7B7B',
    holdingsBadgeColor: '#C8C98F',
    holdingsValueColor: '#AEAF76',
    assetHoldingsBadgeBgColor: '#DDE3EB',
    assetHoldingsBadgeTextColor: '#585D66',
    inflow2: '#6EBA7F',
    outflow: '#ff947c',
    scenario: '#B704E4',
    blueAzul: '#2565E7',
    darkHeroBackground: '#111213',
    inflowText: '#31AF73',
    outflowText: '#E44B54',
    upgradeOrange: '#F3A154',
    upgradeOrangeHover: '#F68F2F', 
}

export const mobileVars = {
    blackBackgroundColor: '#111213',
    headerFooterBorderColor: '#25272C',
    logoSeparatorColor: '#DDE3EB',
    headerHeight: '56px',
    footerHeight: '64px'
}

export const sizes = {
    headerHeight: '72px',
    wealthContentWidth: 960,
    contentWidth: 800,
}

export const views = {
    desktop: `@media (min-width: 769px)`,
    desktopWithMinimizedMenu:  `@media (max-width: 1023px)` ,
    tablet:  `@media (max-width: 768px)` ,
    mobile: `@media (max-width: 455px)`,
}

export const shadows = {
    cardShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
    toggleShadow: '0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16)',
    toggleShadowMobile: '0px 0px 2px 0px rgba(0, 0, 0, 0.16), 0px 2px 4px 0px rgba(0, 0, 0, 0.04)',
    toggleShadowHover: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.26)',
    toggleShadowActive: '0px 2px 4px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.36)',
    modalShadow: '0px 4px 20px rgba(0, 0, 0, 0.05), 0px 0px 30px rgba(0, 0, 0, 0.02)',
    inputShadow: '0px 0px 2px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.04)',
    menuPopup: '0px 2px 8px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.05)',
    buttonShadow: '0px 0px 1px rgba(0, 0, 0, 0.4), 0px 2px 8px rgba(0, 0, 0, 0.04)',
    selectMenuShadow: '0px 1px 8px rgba(0, 0, 0, 0.08)',
    popupShadow: '0px 0px 2px rgba(0, 0, 0, 0.3), 0px 4px 15px rgba(0, 0, 0, 0.15)',
    popupShadowMobile: '0px 4px 15px 0px rgba(0, 0, 0, 0.15), 0px 0px 2px 0px rgba(0, 0, 0, 0.30)',
}

export const rolesColorPlates = ['#BFD9DA','#EEE4AF','#ECD2C4','#DBE2C6','#DACEE0','#F1D4A9','#D6C5B6','#B4D6B4','#F3BBBB','#E5F3BB','#BDD2F3','#ECC4D2','#D3CFFE','#CFFEDA','#F2CFFE','#FBCDB3','#BBF3E6'];
// Step 2: Create a hash function
function hashEmailToNumber(email) {
    let hash = 0;
    for (let i = 0; i < email.length; i++) {
        hash += email.charCodeAt(i);
    }
    return hash;
}

// Step 3: Map the number to a color
export function emailToColor(email) {
    const hash = hashEmailToNumber(email);
    const colorIndex = hash % rolesColorPlates.length;
    return rolesColorPlates[colorIndex];
}

const currencyBgColors = {
    USD: '#F2CFFE',
    EUR: '#E5F3BB',
    GBP: '#BDD2F3',
    CAD: '#FBCDB3',
    ILS: '#BBF3E6',
    BTC: '#FFEB82',
    ETH: '#ECD2C4',
}
export function wealthGroupToColor(groupLabel) {
    // set here specific color for specific group if need
    // ex: if (groupLabel === 'USD') return 'red'
    if (currencyBgColors[groupLabel]) return currencyBgColors[groupLabel];
    const hash = hashEmailToNumber(groupLabel);
    const colorIndex = hash % rolesColorPlates.length;
    return rolesColorPlates[colorIndex];
}