import { Observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
// import { useHistory } from "react-router-dom";
import { ChartComponent } from '../../../../../common/components/charts/ChartComponent';
import { displayMoneyFormatter, 
  // displayMoneyValue, 
  percentageDisplay } from '../../../../../common/utils/number-display.utils';

import { useStore } from '../../../../app/data/root.store';
// import { ValueChangeDirectionBox, ValueChangeText, ValueChangeTextWrapper } from '../../../../wealth/pages/asset/components/AssetPage.styles';
import { CommunityIndicationText, CommunityIndicationWrapper, PieTextBigValue, PieTextContent, PieTextLabel, PieWrapper } from './CommunityPieChart.styles';
import { communityChartTooltip } from './CommunityPieChartTooltip';

export function CommunityPieChart(props) {
  const { bigTextSize } = props;
  const { communityStore } = useStore();

  // const [hoverData, setHoverData] = useState({ datasetIndex: null, index: null, isHoveredOnce: false });
  const [chartOptions, setChartOptions] = useState({ cutout: '90%' });
  const containerRef = useRef();
  const textRef = useRef();
  const [textSize, setTextSize] = useState(32);

  const getTooltipItem = useCallback((datasetIndex,dataIndex) => {
    const relevantIndex = dataIndex + ( datasetIndex === 1 ? communityStore.assetClasses.length : 0 );
    if (relevantIndex < communityStore.classesList.length){
      const relevantItem = communityStore.classesList[relevantIndex];
      return {
        classIcon: relevantItem.icon,
        classColor: relevantItem.color,
        percentage : relevantItem.percentageOfAssets,
        title: relevantItem.title,
        description: 'Of community`s total value',
        show: true
      }
    } else {
      return {show: false}
    }
  }, [ communityStore])

  const getChartOptions = useCallback(() => {
    return {
      maintainAspectRatio: true,
      cutout: '80%',
      onHover: (event, chartElement) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        if (chartElement.length) {
          const { datasetIndex, index: dataIndex } = chartElement[0];
          const relevantIndex = dataIndex + ( datasetIndex === 1 ? communityStore.assetClasses.length : 0 );
          if (relevantIndex >= communityStore.classesList.length){
            event.native.target.style.cursor = 'default';
          }
          // const relevantGroup = communityStore.classesList[relevantIndex];
          // setHoverData(relevantGroup ? {
          //   show: true,
          //   title: relevantGroup.title,
          //   value: relevantGroup.totalValue,
          //   isHoveredOnce: true
          // } : { show: false, isHoveredOnce: true })
        } 
        // else {
        //   setHoverData({
        //     show: false,
        //     isHoveredOnce: true
        //   })
        // }
      },

      onClick: (event, chartElement) => {
        if (chartElement.length){
          const { datasetIndex, index: dataIndex } = chartElement[0];
          const relevantIndex = dataIndex + ( datasetIndex === 1 ? communityStore.assetClasses.length : 0 );
          if (relevantIndex <= communityStore.classesList.length ){
            communityStore.setSelectedClassIndex(relevantIndex);
            document.getElementById('community-chart-tooltip').style.opacity = 0;
          }
        }
      },
      plugins: {
        tooltip: communityChartTooltip(getTooltipItem),
      }
    }
  }, [ communityStore, getTooltipItem])

  useEffect(() => {
    setChartOptions(getChartOptions());
    // return () => { }
  }, [getChartOptions])

  useEffect(() => {
    const containerElement = containerRef.current;
    const textElement = textRef.current;

    if (containerElement && textElement) {
      const availableWidth = containerElement.clientWidth - 80;
      const textWidth = textElement.scrollWidth;
      
      setTextSize(textWidth > availableWidth ? (availableWidth / textWidth) * 32 : 32);
    }
  }, []);

  return (<Observer>{() => (
    <PieWrapper>
      <ChartComponent type='doughnut' data={communityStore.chartClassesData} options={chartOptions} height={props.height} />
      <PieTextContent contentTop={props.contentTop} ref={containerRef}>
        {/* <PieTextLabel>{communityStore.pillarHoverData.show ? communityStore.pillarHoverData.title : hoverData.show ? hoverData.title : 'Total Community Net Worth'}</PieTextLabel> */}
        <PieTextLabel>{ communityStore.pillarHoverData.show ? ''  : 'Total Community Net Worth' }</PieTextLabel>
        {/* <PieTextLabel>Total Community Net Worth</PieTextLabel> */}
        <PieTextBigValue fontSize={bigTextSize} style={{fontSize: `${textSize}px`}} ref={textRef}>
          {
            communityStore.pillarHoverData.show ? percentageDisplay(communityStore.pillarHoverData.percentage) :
              displayMoneyFormatter(communityStore.totalNetWorthValue,'USD')
              // <CountAnimation currency={'USD'} value={hoverData.show ? hoverData.value : communityStore.totalNetWorthValue} noAnim={hoverData.isHoveredOnce} />
          }
        </PieTextBigValue>
        {communityStore.pillarHoverData.show ?
          <CommunityIndicationWrapper>
            <CommunityIndicationText>Of Vyzer’s community have:</CommunityIndicationText>
            <span style={{ color: '#fff' }}>{communityStore.pillarHoverData.title} Net worth</span>
          </CommunityIndicationWrapper>
          : null }
      </PieTextContent>
    </PieWrapper>
  )}</Observer>)
}