import { Drawer, Select } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { ArrowDownIcon } from '../../../../assets/icons/common/common-icons';
import { countryListPhone } from '../../../../common/constants/countries.const';
import { useStore } from '../../../app/data/root.store';
import { DrawerCloseButtonWrapper, DrawerHeader, PhoneInputBoxWrapper, PhoneInputWrapper, SearchIconWrapper, SearchInput, StyledInput, StyledSelect } from './PhoneInput.styles';

export const PhoneInput = observer(({
  userPhoneNumber,
  handlePhoneChange,
  withError = false,
  customWrapperStyle = {},
  customInnerWrapperStyle = {},
}) => {
  const { uiStore } = useStore();
  const { Option } = Select;
  const [searchValue, setSearchValue] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const searchInputRef = useRef(null);

  const handleSearch = (val) => {
    setSearchValue(!uiStore.isDesktopView ? val.target.value.toUpperCase() : val.toUpperCase());
  };

  const handleSelect = (val, opt) => {
    handlePhoneChange({ value: userPhoneNumber.value, alpha3: val });
    if (!uiStore.isDesktopView) {
      setIsDrawerOpen(false);
    }
  };

  const handleSelectOnClick = (e) => {
    if (!uiStore.isDesktopView) {
      e.stopPropagation();
      setIsDrawerOpen(true);
      setIsDropdownOpen(false);
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
    const maxLength = 15; // The longest possible phone number without a country code
    if (numericValue.length <= maxLength) {
      handlePhoneChange({ value: numericValue, alpha3: userPhoneNumber.alpha3 });
    }
  };

  const handleDrawerResultSelect = (alpha3) => {
    handlePhoneChange({ value: userPhoneNumber.value, alpha3: alpha3 });
    setIsDrawerOpen(false);
  }

  useEffect(() => {
    if (searchInputRef.current) {
      const timer = setTimeout(() => {
        searchInputRef.current?.focus();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [searchInputRef, isDrawerOpen]);

  return (
    <>
      <PhoneInputWrapper
        style={customWrapperStyle}>
        <StyledSelect
          name='country'
          autoComplete="chrome-off"
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px', width: '126px', zIndex: 1, alignItems: 'center', display: 'flex', height: '100%' }}
          notFoundContent={null}
          value={userPhoneNumber.alpha3}
          optionFilterProp='label'
          optionLabelProp='label'
          onSearch={uiStore.isDesktopView ? handleSearch : null}
          onSelect={handleSelect}
          onClick={handleSelectOnClick}
          filterOption={(input, option) => {
            const relevantAlpha3 = option.value;
            const relevantCode = option.props.children.props.children[4].replace('+', '');
            return relevantCode.startsWith(searchValue.replace('+', '')) || relevantAlpha3.startsWith(searchValue);
          }}
          // open
          open={uiStore.isDesktopView ? isDropdownOpen : false}
          onDropdownVisibleChange={(open) => setIsDropdownOpen(open)}
          showSearch={uiStore.isDesktopView}
          suffixIcon={<ArrowDownIcon />}
        >
          {Object.entries(countryListPhone).map(([alpha3, value], index) => (
            <Option key={index} value={alpha3} label={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', fontSize: '32px' }}>{value.flag}</div>}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                {value.flag} {alpha3} {value.code}
              </div>
            </Option>
          ))}
        </StyledSelect>
        <StyledInput
          style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, }}
          autoComplete="chrome-off"
          type='text'
          name='phone'
          prefix={`(${countryListPhone[userPhoneNumber.alpha3 || 'USA'].code})`}
          value={userPhoneNumber.value}
          onChange={handleInputChange}
          autoFocus
        />
        <span style={{ color: '#ff0000', position: 'absolute', bottom: '-24px', }}>{withError ? 'Invalid phone number. Please check and try again.' : ''}</span>
      </PhoneInputWrapper>
      <Drawer
        closable={false}
        visible={isDrawerOpen}
        width={'100%'}
        height={'40%'}
        className={'phoneInputDrawerContainer_mobile'}
        placement={'bottom'}
        destroyOnClose={true}
        push={false}
        onClose={() => { setIsDrawerOpen(false) }} >
        <DrawerHeader style={{ padding: '14px 20px', boxShadow: 'none' }}>
          Select country / dialing code
          <DrawerCloseButtonWrapper onClick={() => { setIsDrawerOpen(false) }}><DrawerCloseButtonSvg /></DrawerCloseButtonWrapper>
        </DrawerHeader>

        <div style={{ display: 'flex', height: '100%', flexDirection: 'column', padding: '0 20px 20px 20px', gap: '10px' }}>
          <PhoneInputBoxWrapper>
            <SearchInput
              ref={searchInputRef}
              type="text"
              style={{ borderRadius: '27px', flexShrink: 0 }}
              value={searchValue}
              onChange={handleSearch}
              placeholder='Search...'
            />
            <SearchIconWrapper><SearchResultsIcon /></SearchIconWrapper>
          </PhoneInputBoxWrapper>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {Object.entries(countryListPhone).filter(([key, value]) => {
              const relevantCode = value.code.replace('+', '');
              const relevantAlpha3 = key;
              return relevantCode.startsWith(searchValue.replace('+', '')) || relevantAlpha3.startsWith(searchValue);
            }).map(([label, value], index) => (
              <div onClick={() => {handleDrawerResultSelect(label)}} key={index} style={{ display: 'flex', alignItems: 'center', gap: '12px', height: '32px' }}>
                <div style={{ fontSize: '32px' }}>{value.flag}</div>
                <div>{label}</div>
                <div>({value.code})</div>
              </div>
            ))}
          </div>
        </div>
      </Drawer>
    </>
  )

})

const DrawerCloseButtonSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2L14 14M14 2L2 14" stroke="#75818D" strokeWidth="1.5" />
    </svg>
  )
}

const SearchResultsIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.85205" cy="7.85202" r="6" stroke="#9097A3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <line x1="11.8642" y1="11.803" x2="14.7869" y2="14.7257" stroke="#9097A3" strokeWidth="1.5" />
    </svg>
  )
}