import React , { useState, useEffect} from 'react';
import moment from 'moment';
import { DeleteIcon, PlusIcon, TooltipIcon } from '../../../../../assets/icons/common/common-icons';
import { Wrapper, TableHeaderRow, HeaderCell, RowWrapper, RowCell, RowActionsCell, TableScrollContent, ActionButtonWrapper, TableBottomActions, CreateButton,  HoldingsBadgeText, HoldingsBadgeTriangle, HoldingsBadgeWrapper, InputWithBudgeWrapper, TooltipWrapper, AmountPercentageWrapper } from './ScheduleTable.styles'
import { Input,DatePicker, Tooltip  } from "antd";
// import { disabledMoreThanToday } from '../../utils/dates.utils';
import NumberFormat from 'react-number-format';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString, isNullOrUndefinedOrZeroOrEmptyString } from '../../../../utils/object.utils';
import { colors } from '../../../../styles/theme.styles';
import { disabledLessThanDate } from '../../../../utils/dates.utils';
import { enabledBetween } from '../../../../utils/dates.utils';
import { addRow, changeEndDate, changeStartDate, deleteRow } from './scheduleTable.helpers';
// import { errorNotification } from '../../../modules/layout/components/Notifications/notifications';

// const getDisplayDate = (date) => {
//     //const d = new Date(date);
//     //return (("0"+(d.getMonth()+1)).slice(-2) + "/" +d.getFullYear());
//     return moment(date).format('MMM YYYY');
// }

const tableColumns = ['From date','To date','Periodic payment','Annual return %','Note'];
const annualReturnToolTipText = 'Your expected annual cash distribution rate for this investment. This rate must be an annual rate regardless of the timeframe.';
export function AttributeScheduleTable(props){
    
    const {onChange, disabled, value , rowValuePreFix, startDate, holdings , showPercentage,  isPercentageDisplay, relativeAmount, monthsPerPeriod, period, expectedAnnualPercentage, customAnnualReturnLabel, customAnnualReturnToolTipText, customColumnsSize, defaultEditedRow = false} = props;
    const [editedRow,setEditedRow] = useState(defaultEditedRow && value.length > 0 ? 't'+value[value.length-1].tempId : 0); // useState(value.length ? value[value.length -1].id || ('t'+value[value.length -1].tempId) : 0);

    const handleAddRowClick = () => {
        // let updatedSchedule = [...value]; 
        if (disabled){return}
        const newRowValue = relativeAmount && expectedAnnualPercentage ? (
                isPercentageDisplay ? 
                expectedAnnualPercentage : 
                    (relativeAmount / 100 * expectedAnnualPercentage) 
            ) : ''
        const { updatedSchedule , newRowTempId } = addRow(value, startDate, newRowValue);
        setEditedRow('t' + (newRowTempId));    
        onChange && onChange(updatedSchedule);

    }

    const handleAttributeRowUpdated = (updatedIndex,data,key) => {
        // let updatedRows = value.map((item,index)=>(index === updatedIndex ? data : item));
        // onChange && onChange(updatedRows);
        let updatedSortedSchedule = value.map((item,index)=>(index === updatedIndex ? data : item));
        
        // if (key === 'date'){
        //     updatedSortedSchedule = updatedSortedSchedule.sort((a,b)=>new Date(a.date) - new Date(b.date));
        //     // updatedSortedSchedule = updatedSortedSchedule.map((item,index)=>{
        //     //     if(index === 0) return item;
        //     //     return {...item, endDate: updatedSortedSchedule[index - 1].date };
        //     // });
        // }
        onChange && onChange(updatedSortedSchedule);
    }

    const handleStartDateChanged = (updatedIndex,newDate) => {
        const updatedSchedule = changeStartDate(value,updatedIndex,newDate); 
        onChange && onChange(updatedSchedule);
    }

    const handleEndDateChanged = (updatedIndex,newDate) => {
        const updatedSchedule = changeEndDate(value,updatedIndex,newDate); 
        onChange && onChange(updatedSchedule);
    }
    
    const handleAttributeRowDeleted = (deletedIndex) => {
      const updatedSchedule = deleteRow(value,deletedIndex); 
      onChange && onChange(updatedSchedule);
    }

    // console.log(period);
    
    return (
        <Wrapper>
            <TableHeaderRow onClick={(e)=>{e.stopPropagation()}} 
                isWithHoldings={holdings !== 100}
                isNumberAndPercentages={showPercentage && !isPercentageDisplay}
                customColumnsSize={customColumnsSize}
            >  
              {tableColumns.filter(columnLabel => columnLabel !== (isPercentageDisplay ? 'Periodic payment' : '')).map((columnLabel,ind)=>(
                <HeaderCell key={'hc1'+ind} >
                    <span>{columnLabel === 'Periodic payment' ? ((period || 'Periodic') + ' payment') : (columnLabel === 'Annual return %' && customAnnualReturnLabel ? customAnnualReturnLabel : columnLabel )}</span>
                    {columnLabel === 'Annual return %' ? <Tooltip title={customAnnualReturnToolTipText || annualReturnToolTipText} placement="top">
                            <AmountPercentageWrapper><TooltipWrapper><TooltipIcon /></TooltipWrapper></AmountPercentageWrapper>
                        </Tooltip> : null}
                </HeaderCell>
              ))}
            </TableHeaderRow> 
            <TableScrollContent>
              {/* {value?.filter(row=>row.value!==0).map((row,index)=>( */}
              {value?.map((row,index)=>{
                const isNextRowZero = index+1 !== value.length ? value[index+1].value === 0 : false;
                const isPrevRowZero = index > 0 ? value[index-1].value === 0 : false;
                const nextSecondRowDate = index < value.length - (isNextRowZero ? 3 :2) ? value[index+(isNextRowZero ? 3 :2)].date : null;
                const endDateDisabledTo = nextSecondRowDate ? moment(nextSecondRowDate).add(-2,'M') : null;
                const prevNotZeroDate = index > (isPrevRowZero ? 1 : 0) ? value[index-(isPrevRowZero ? 2 :1)].date : null; 
                const startDateDisabledFrom = index === 0 ? startDate : moment(prevNotZeroDate).add(1,'M');
                  // isFirstRow ? disabledFrom : moment(disabledFrom).add(isPrevRowZero ? 0 : 1,'M');
                // const startDateDisabledFrom =  index !== 0 ? (
                //     index  
                //     moment(disabledFrom).add(isPrevRowZero ? 0 : 1,'M')
                // ) : startDate;

                // const startDateDisabledFrom = isFirstRow ? disabledFrom : moment(disabledFrom).add(isPrevRowZero ? 0 : 1,'M');
                return(
                <HistoryRow key={row.id || ('t' + row.tempId)} 
                // isHidden={row.value === 0}
                disabled={disabled}
                rowValuePreFix={rowValuePreFix} index={index} 
                isFirstRow={index === 0}
                isLastRow={index === value.length - 1} 
                disabledFrom={index !== 0 ? value[index-1].date : startDate}
                isPrevRowZero={index > 0 ? value[index-1].value === 0 : false}
                allowClearEndDate={index === value.length - 1 || ( index === value.length - 2 && value[index+1].value === 0 )}
                nextRowDate={index === value.length - 1 ? null : value[index+1].date} 
                startDateDisabledFrom={startDateDisabledFrom}
                endDateDisabledTo={endDateDisabledTo } 
                item={row}  atrKey={row.atrKey} 
                isEditMode={ row.id ? (editedRow === row.id) : (editedRow === 't' + row.tempId)  }
                setEditMode={()=> setEditedRow(row.id ? row.id : 't' + row.tempId)}
                holdings={holdings}
                rowUpdated={(data,key)=>{handleAttributeRowUpdated(index,data,key)}}
                isNumberAndPercentages={showPercentage && !isPercentageDisplay}
                isPercentageDisplay={isPercentageDisplay}
                relativeAmount={relativeAmount}
                customColumnsSize={customColumnsSize}
                monthsPerPeriod={monthsPerPeriod}
                // handleDateRangeChanged={(data)=>{handleDateRangeChanged(index,data)}}
                handleStartDateChanged={(data)=>{handleStartDateChanged(index,data)}}
                handleEndDateChanged={(data)=>{handleEndDateChanged(index,data)}}
                rowDeleted={()=>handleAttributeRowDeleted(index)} />
              )})}
              
            </TableScrollContent>
            <TableBottomActions>
            <CreateButton onClick={handleAddRowClick} disabled={disabled}>
                {/* <AddIcon /> */}
                <PlusIcon />
                <span className='button-text'>Add date range</span>
            </CreateButton>
            {/* <button style={{width:'80%', alignSelf:'center'}} onClick={handleAddRowClick}>Add row</button> */}
            </TableBottomActions>
            {/* <div style={{color:'#fff'}}>{JSON.stringify(scheduleHistory)}</div> */}
            {/* {eventTypes.map((event,index)=>(
                <ActualsMenuItem onClick={()=>{props.setItem(index)}} key={index} isHidden={ index === 0 && props.isValueDisabled} > 
                    <ActualsMenuItemTitle>{event.title}</ActualsMenuItemTitle>
                    <ActualsMenuItemDescription>{event.description}</ActualsMenuItemDescription>
                </ActualsMenuItem> 
            ))} */}
            {/* { isEditMode ? <>
                <button style={{width:'80%', alignSelf:'center'}} onClick={handleAddRowClick}>Add row</button>
                <button style={{width:'80%', alignSelf:'center'}} onClick={handleSaveChangesClick}>Save</button>
                <button style={{width:'80%', alignSelf:'center'}} onClick={handleCancelChangesClick}>Cancel changes</button>
            </> : <>
                
            </>} */}
            {/* <button style={{width:'80%', alignSelf:'center'}} onClick={()=>{setIsEditMode(true)}}>Edit</button> */}
           
        </Wrapper>
    )
}

const HistoryRow = (props) => {
    const { TextArea } = Input;
    const {item , disabled, rowDeleted , isEditMode , setEditMode, startDateDisabledFrom, endDateDisabledTo, nextRowDate, rowValuePreFix, holdings,
        handleStartDateChanged, handleEndDateChanged , allowClearEndDate, isNumberAndPercentages , relativeAmount, monthsPerPeriod, isPercentageDisplay, customColumnsSize} = props;
    const [focusedInput,setFocusedInput] = useState(0);
    const [valueNumber,setValueNumber] = useState('');
    const [isHoverOn,setIsHoverOn] = useState(false);
    const [amountPercentage, setAmountPercentage] = useState(relativeAmount && item.value ? parseFloat((item.value / relativeAmount * 100).toFixed(2)) : null);
    const [lastChangedPercentage, setLastChangedPercentage] = useState(amountPercentage);
    
    // console.log(prevRowDate);

    const handleFieldChange = (field,value) => {
        if (field === 'value' && !isPercentageDisplay && !isNullOrUndefinedOrZeroOrEmptyString(value) ){
            value = value * (12 / monthsPerPeriod) ;
        }
        props.rowUpdated({
            ...item,
            [field]: isNullOrUndefinedOrEmptyString(value) ? '' : value
        },field)
    }

    useEffect(() => {
        if (monthsPerPeriod && ( item.value || item.value === 0)){
            setValueNumber(item.value / (isPercentageDisplay ? 1 : (12 / monthsPerPeriod)));
        } else {
            setValueNumber(item.value);
        }

        if (relativeAmount && item.value) {
            const percValue = parseFloat((item.value / relativeAmount * 100).toFixed(2));
            setAmountPercentage(percValue);
            setLastChangedPercentage(percValue);
        } else {
            setAmountPercentage('');
            setLastChangedPercentage(0);
        }
        return () => { }
    }, [relativeAmount, monthsPerPeriod, isPercentageDisplay, item.value])
    
    const handlePercentageBlur = (updatedPercValue) => {
        if (relativeAmount && !isNullOrUndefinedOrZeroOrEmptyString(updatedPercValue)) {
            if (updatedPercValue !== lastChangedPercentage) {
                const newValue = relativeAmount / 100 * updatedPercValue / (12 / monthsPerPeriod);
                handleFieldChange('value', newValue);
                setLastChangedPercentage(updatedPercValue);
            }
        } else {
            setAmountPercentage('');
            handleFieldChange('value', '');
        }
    }
    // const cancelChanges = () => {
    //     setRowData(defaultValues);
    //     setIsEdit(false);
    // }

    // const saveChanges = () => {
    //     if (rowData.value || rowData.value === 0){
    //         setIsEdit(false);
    //         // props.rowUpdated && props.rowUpdated({...item,isEdit:false});
    //     } else {
    //         errorNotification(`Can't save empty value.\nPlease set value or delete the update`,{
    //             style:{whiteSpace:'pre-line'}
    //         });
    //     }
    // }

    // const approveDelete = () => {
    //     // setIsDelete(false);
    //     props.rowDeleted && props.rowDeleted();
    // }
    
    const startDateDisabledTo =  nextRowDate ? moment(nextRowDate).add(-1,'M') : null;
    
    // const endDateDisabledTo = disabledTo ? moment(disabledFrom).add(1,'M') : null;

    return (
        <RowWrapper onClick={(e)=>{e.stopPropagation(); if (item.value !== 0){ setEditMode() }}} isWithHoldings={holdings !== 100} isHidden={item.value===0} isNumberAndPercentages={isNumberAndPercentages} customColumnsSize={customColumnsSize}
            onMouseEnter={()=>{setIsHoverOn(true)}} onMouseLeave={()=>{setIsHoverOn(false)}} isEdited={isEditMode} >
            
            <RowCell >
               
               {/* <RangePicker picker="month" disabled={item.eventId} */}
               
               <DatePicker picker="month" disabled={item.eventId || disabled}  
               // value={ [moment(item.date), nextRowDate ? moment(nextRowDate) : null] } 
               // onChange={(val)=>{ handleDateRangeChanged(( val ? val : null))}}
               // disabledDate={enabledBetween} 
               disabledDate={(currentDate)=> startDateDisabledTo ? enabledBetween(currentDate,startDateDisabledFrom,startDateDisabledTo) : disabledLessThanDate(currentDate,startDateDisabledFrom) }
               onFocus={()=>{setEditMode()}}
               allowClear={false}
               inputReadOnly
               format={'MM-YYYY'}
               placeholder={['Start date','End date']}
               style={ ( isEditMode || isHoverOn || disabled ) ? {} : {borderColor:'transparent', backgroundColor:'transparent'}}
               {...(( isEditMode || isHoverOn || disabled ) ? {} : {suffixIcon:null})}
               value={ moment(item?.date) } 
               // onChange={(val)=>{  handleFieldChange('date', (val ? val.format() : item?.date))}}
               onChange={(val)=>{  handleStartDateChanged((val ? val : item?.date))}}
               />  
               {/* :getDisplayDate(item.date) } */}

               {/* <DateRangePicker 
                   value={item.date} 
                   endDate={nextRowDate} 
                   prevDate={prevRowDate}
                   isDisabled={item.eventId}
                   onChange={(val)=>{ handleDateRangeChanged(( val ? val : null))}}
                   setEditMode={setEditMode}
                   isEditMode={isEditMode}
               /> */}
       </RowCell>
       <RowCell>
           <DatePicker picker="month" disabled={item.eventId || disabled}  
               // value={ [moment(item.date), nextRowDate ? moment(nextRowDate) : null] } 
               // onChange={(val)=>{ handleDateRangeChanged(( val ? val : null))}}
               // disabledDate={enabledBetween} 
               disabledDate={(currentDate)=> endDateDisabledTo ? enabledBetween(currentDate,item?.date,endDateDisabledTo) : disabledLessThanDate(currentDate,item?.date) }
               onFocus={()=>{setEditMode()}}
               allowClear={allowClearEndDate}
               inputReadOnly
               format={'MM-YYYY'}
               placeholder={'End date'}
               style={( isEditMode || isHoverOn || disabled ) ? {} : {borderColor:'transparent', backgroundColor:'transparent'}}
               {...(( isEditMode || isHoverOn || disabled ) ? {} : {suffixIcon:null})}
               value={ nextRowDate ? moment(nextRowDate).add(-1,"M") : null } 
               onChange={(val)=>{handleEndDateChanged((val ? val : null))}}
               defaultPickerValue={ moment(item?.date) }
               /> 
           {/* { isLastRow ? 'End date' : 
           // isEdit ? 
           //     <DatePicker picker="month" disabled={rowData.eventId} 
           //     // disabledDate={disabledMoreThanToday} 
           //     value={ moment(rowData?.endDate) } onChange={(val)=>{handleFieldChange('date', (val ?val.format(): rowData?.endDate))}}/> :
               getDisplayDate(nextRowDate)
           } */}
           {/* </EndDateText> */}
       </RowCell>

            {!isPercentageDisplay ?
            <RowCell>

            { !isNullOrUndefined(holdings) && holdings !== 100 && <NumberFormat 
                    disabled={disabled}
                    thousandSeparator={true} 
                    customInput={Input}
                    prefix={rowValuePreFix}
                    placeholder={( isEditMode || isHoverOn ) ? rowValuePreFix : ''}
                    style={{width:'50%' , borderRadius:'4px 0 0 4px', ...(( isEditMode || isHoverOn || disabled ) ? {} : {borderColor:'transparent', backgroundColor:'transparent'})}}
                    value={( isNullOrUndefinedOrEmptyString( valueNumber) ) ? '' : parseFloat( (valueNumber * (holdings/100)).toFixed(2)) }
                    autoComplete="off"
                    onFocus={()=>{setFocusedInput(1)}}
                    onValueChange={(values) => {
                        if ( ''+valueNumber !== values.value && focusedInput === 1 && 
                             (!isNullOrUndefined(valueNumber) || !isNullOrUndefined(values.floatValue))){
                                // handleFieldChange('value', ( values.floatValue || values.floatValue === 0 ) ? values.floatValue * (100/holdings) : null)
                                setValueNumber(( values.floatValue || values.floatValue === 0 ) ? values.floatValue * (100/holdings) : null)
                        }
                    }}
                    onBlur={(e) => { handleFieldChange('value',valueNumber === 0 ? '' : valueNumber)}}
            />}
                <InputWithBudgeWrapper>
                     <NumberFormat 
                     thousandSeparator={true} 
                     customInput={Input}
                     prefix={rowValuePreFix}
                     placeholder={( isEditMode || isHoverOn || disabled ) ? rowValuePreFix : ''}
                     style={{width:'100%',...(( isEditMode || isHoverOn || disabled ) ? {} : {borderColor:'transparent', backgroundColor:'transparent'}),  ...( holdings !== 100 ? {paddingLeft:'30px', color:colors.holdingsValueColor, borderRadius:'0px 4px 4px 0px'} : {})}}
                    //  style={isIncognito && field.shouldBeHiddenIncognito ? {WebkitTextSecurity:'disc'}:{}} 
                     value={( isNullOrUndefinedOrEmptyString(valueNumber) ) ? '' : parseFloat( (+valueNumber).toFixed(2)) }
                     autoComplete="off"
                     disabled={disabled}
                     onFocus={()=>{setFocusedInput(2)}}
                     onValueChange={(values) => { setValueNumber(values.floatValue)}}
                     onBlur={(e) => {
                        //  if (valueNumber === 0){
                        //     if (item.value === ''){
                        //         setValueNumber('');
                        //     } else {
                        //         handleFieldChange('value','');
                        //     }
                        //  } else {
                        //     handleFieldChange('value',valueNumber);
                        //  }
                        
                        if (valueNumber === 0 && item.value === ''){ 
                            setValueNumber('') 
                        } else {
                            handleFieldChange('value',valueNumber === 0 ? '' : valueNumber);
                        }
                        // if (item.value)
                    }}
                    //  onValueChange={(values) => {
                    //     handleFieldChange('value', values.floatValue === 0 ? null : values.floatValue)
                    // }}
                    />
                    { holdings !== 100 && <HoldingsBadgeWrapper><HoldingsBadgeText></HoldingsBadgeText><HoldingsBadgeTriangle /></HoldingsBadgeWrapper> }
                </InputWithBudgeWrapper>
            </RowCell> : <RowCell>
                <NumberFormat
                        customInput={Input}
                        suffix={'%'} decimalScale={2}
                        placeholder={( isEditMode || isHoverOn || disabled ) ? '%' : ''}
                        style={{width:'100%',...(( isEditMode || isHoverOn || disabled ) ? {} : {borderColor:'transparent', backgroundColor:'transparent'}),  ...( holdings !== 100 ? {paddingLeft:'30px', color:colors.holdingsValueColor, borderRadius:'0px 4px 4px 0px'} : {})}}
                        // style={{ flex: 0.5, borderRadius: '0 4px 4px 0', minWidth: '84px', ...(isEditMode ? {} : { borderColor: 'transparent', backgroundColor: 'transparent' }) }}
                        value={valueNumber}
                        disabled={disabled}
                        autoComplete="off"
                        onValueChange={(values) => { setValueNumber(values.floatValue)}}
                        onBlur={(e) => { handleFieldChange('value',valueNumber === 0 ? '' : valueNumber)}}
                /> 

            </RowCell> }

            {(isNumberAndPercentages) ?
                    <RowCell>
                        <NumberFormat
                        customInput={Input}
                        suffix={'%'} decimalScale={2}
                        placeholder={( isEditMode || isHoverOn || disabled ) ? '%' : ''}
                        style={{width:'100%',...(( isEditMode || isHoverOn|| disabled ) ? {} : {borderColor:'transparent', backgroundColor:'transparent'})}}
                        // style={{ flex: 0.5, borderRadius: '0 4px 4px 0', minWidth: '84px', ...(isEditMode ? {} : { borderColor: 'transparent', backgroundColor: 'transparent' }) }}
                        value={amountPercentage}
                        disabled={disabled}
                        autoComplete="off"
                        onValueChange={(values) => { setAmountPercentage(values.floatValue) }}
                        onBlur={() => { handlePercentageBlur(amountPercentage) }}
                        /> 
                    </RowCell> : null
                }

            <RowCell>
                    <TextArea rows={1} 
                    onFocus={()=>{setEditMode()}}
                    disabled={disabled}
                    style={{resize:'none', ...(( isEditMode || isHoverOn || disabled ) ? {} : {borderColor:'transparent', backgroundColor:'transparent'})}}
                    value={item?.remarks} onChange={(e)=>{handleFieldChange('remarks',e.target.value)}}/>     
                
            </RowCell>
            <RowActionsCell isRowHidden={item.value===0}>
            {/* { isEditMode &&      */}
            { !disabled && <ActionButtonWrapper 
                        //onClick={()=>{showDeleteUpdateConfirm()}}>
                        // onClick={()=>{setIsDelete(true)}} >
                            onClick={()=>{rowDeleted && rowDeleted()}} >
                             
                        <DeleteIcon />
                    </ActionButtonWrapper> }
                    {/* } */}
            </RowActionsCell>
            {/* {item.value===0 && <RowWRapperBlocker />} */}
            {/* <RowActionsCell show={isEdit || isDelete}>
                {isEdit ? <>
                    <SaveButton onClick={saveChanges}>Save</SaveButton>
                    <ActionButtonWrapper onClick={cancelChanges}>
                        <CloseXIcon />
                    </ActionButtonWrapper>
                </> : (
                isDelete ? <>
                    <SaveButton onClick={approveDelete}>Delete</SaveButton>
                    <ActionButtonWrapper onClick={()=>{setIsDelete(false)}}>
                        <CloseXIcon />
                    </ActionButtonWrapper>
                </>:
                <>
                    <ActionButtonWrapper style={{marginRight:'16px'}} onClick={()=>{setIsEdit(!isEdit)}} >
                        <EditIcon />
                    </ActionButtonWrapper>
                    <ActionButtonWrapper 
                        //onClick={()=>{showDeleteUpdateConfirm()}}>
                        onClick={()=>{setIsDelete(true)}} >
                        <DeleteIcon />
                    </ActionButtonWrapper>
                </>)}
            </RowActionsCell> */}
        </RowWrapper>
    )
}
  