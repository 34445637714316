import { Tooltip } from 'antd';
import { Observer } from 'mobx-react';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PrimaryButton } from '../../../../common/components/buttons/NewButtons.styles';
import { removeUrlParam } from '../../../../common/utils/navigation.utils';
import { displayMoneyValue } from '../../../../common/utils/number-display.utils';
import { useStore } from '../../../app/data/root.store';
import { PlanSwitchWrapper } from '../PricingModal/PricingModal.styles';
import { AnnuallyTooltipWrapper, BigDiscountLabeWrapper, CardHeader, CardPerkRow, CardPerksRowsWrapper, CardPlanDescription, CardPlanPriceValue, CardPlanPriceWrapper, CardPortfolioAndFeaturesTitle, CardPortfolioAndFeaturesWrapper, CardSubtitleWrapper, CardTitleWrapper, CardTitlesWrapper, MobileComparePlansContentWrapper, MobileComparePlansHeader, MobileComparePlansModalCard, MobileComparePlansModalCardsWrapper, MobileComparePlansTitle, PlanSwitchOption, TitleAndPlanSwitchWrapper, TooltipBoldText } from './ComparePlansModal.styles';

export const MobileComparePlansContent = ({ pricingPlans, isWithDiscount }) => {
  const { userStore, billingStore, filtersStore } = useStore();
  const history = useHistory();
  const location = useLocation();

  const handleExploreDemoLinkClick = () => {
    billingStore.setShowComparePlansModal(false);
    userStore.closeTier4FirstTimeExperienceModal();
    userStore.setShowTier4FirstTimeExperienceModal(false);
    removeUrlParam(history, location, 'fo');
  }

  const setSelectedPlan = (plan) => {
    billingStore.setSelectedPlan(plan);
  }

  const handleSelectClick = async (planIndex) => {
    filtersStore.handleClearFilterSelections();
    if (planIndex === 0) {
      billingStore.setShowStarterModal(true);
    } else {
      const { url } = (userStore.isDemoOrReadOnlyMode || userStore.isFreeTier || userStore.isFreeUser || userStore.isManuallyFreeTier) ? await billingStore.fetchSubscriptionUrl(planIndex - 1, window.location.href) : await billingStore.createDeepLink(planIndex - 1);
      window.location.href = url;
    }
  }

  return (<Observer>{() => (
    <MobileComparePlansContentWrapper>

      <MobileComparePlansHeader>
        <div onClick={handleExploreDemoLinkClick} style={{ display: 'grid', placeItems: 'center', position: 'fixed', left: '20px' }}>
          <BackArrowMobileSvg />
        </div>
        <VyzerTextLogo />
      </MobileComparePlansHeader>

      <TitleAndPlanSwitchWrapper>
        <MobileComparePlansTitle>Choose the perfect plan for your investment journey</MobileComparePlansTitle>
        <PlanSwitchWrapper style={{ width: '100%', maxWidth: '435px' }} isCentered>
          <Tooltip overlayInnerStyle={{ borderRadius: '6px', backgroundColor: '#000' }} title={<AnnuallyTooltipComp />} ><PlanSwitchOption style={{ padding: '4px 16px', fontSize: '16px', fontWeight: '450', fontFamily: 'Circular' }} isBlack isSelected={billingStore.selectedPlan === 'year'} onClick={() => { setSelectedPlan('year') }}>Annually / Save 20%</PlanSwitchOption></Tooltip>
          <PlanSwitchOption style={{ padding: '4px 16px', fontSize: '16px', fontWeight: '450', fontFamily: 'Circular', flex: 1, textAlign: 'center' }} isBlack isSelected={billingStore.selectedPlan === 'month'} onClick={() => { setSelectedPlan('month') }}>Monthly</PlanSwitchOption>
        </PlanSwitchWrapper>
      </TitleAndPlanSwitchWrapper>


      <MobileComparePlansModalCardsWrapper>

        {userStore.isDemoTier ? <MobileComparePlansModalCard>
          <CardHeader>
            <CardTitlesWrapper>
              <CardTitleWrapper>Starter</CardTitleWrapper>
              <CardSubtitleWrapper>Explore the Power of Vyzer. Risk-Free</CardSubtitleWrapper>
            </CardTitlesWrapper>
            <CardPlanPriceWrapper>
              <CardPlanPriceValue>Free</CardPlanPriceValue>
              <div>/</div>
              <CardPlanDescription>No credit card required</CardPlanDescription>
            </CardPlanPriceWrapper>
          </CardHeader>
          <PrimaryButton onClick={() => { handleSelectClick(0) }} style={{ borderRadius: '45px', height: '40px', width: '100%' }}>Start for free</PrimaryButton>
          <CardPortfolioAndFeaturesWrapper>
            <CardPortfolioAndFeaturesTitle>Portfolio size</CardPortfolioAndFeaturesTitle>
            <CardPerksRowsWrapper>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Manage 3 private investments</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Sync up to 3 financial institutions</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Explore 2 cash flow scenarios</CardPerkRow>
            </CardPerksRowsWrapper>
            <CardPortfolioAndFeaturesTitle>Features and services</CardPortfolioAndFeaturesTitle>
            <CardPerksRowsWrapper>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Automated transaction identification</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Investment performance tracking and notifications</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Unlimited document uploads for setup, then 1 file per month</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> VIP Syncing Service of up to 1 portal</CardPerkRow>
            </CardPerksRowsWrapper>
          </CardPortfolioAndFeaturesWrapper>
        </MobileComparePlansModalCard> : null}

        <MobileComparePlansModalCard>
          <CardHeader>
            <CardTitlesWrapper>
              <CardTitleWrapper>Plus</CardTitleWrapper>
              <CardSubtitleWrapper>Tailored for passive income investors with growing portfolios.</CardSubtitleWrapper>
            </CardTitlesWrapper>
            <CardPlanPriceWrapper>
              <CardPlanPriceValue>
                {displayMoneyValue(Math.floor((isWithDiscount ? pricingPlans[0].originalPriceAfterDiscount : pricingPlans[0].originalPrice) / 100 / (billingStore.selectedPlan === 'year' ? 12 : 1)), 'USD')}
                {/* {isWithDiscount && <PlanPriceDiscountValue>{displayMoneyValue(pricingPlans[0].originalPrice / 100 / (billingStore.selectedPlan === 'year' ? 12 : 1), 'USD')}</PlanPriceDiscountValue>} */}
              </CardPlanPriceValue>
              <div>/</div>

              <CardPlanDescription>
                {isWithDiscount && <BigDiscountLabeWrapper>{pricingPlans[0].discountPercentage}% OFF</BigDiscountLabeWrapper>}
                Per month. Billed annually
              </CardPlanDescription>
            </CardPlanPriceWrapper>
          </CardHeader>
          <PrimaryButton onClick={() => { handleSelectClick(1) }} style={{ borderRadius: '45px', height: '40px', width: '100%' }}>Get started</PrimaryButton>
          <CardPortfolioAndFeaturesWrapper>
            <CardPortfolioAndFeaturesTitle>Portfolio size</CardPortfolioAndFeaturesTitle>
            <CardPerksRowsWrapper>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Manage unlimited private investments</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Sync unlimited financial institutions</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Explore 10 cash flow scenarios</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Add 5 different holding entities</CardPerkRow>
            </CardPerksRowsWrapper>

            <CardPortfolioAndFeaturesTitle>Features and services</CardPortfolioAndFeaturesTitle>
            <CardPerksRowsWrapper>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> All Starter features</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Unlimited document uploads for setup, then 5 files per month</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> VIP Syncing Service of up to 3 portals</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Reports and statements</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Access Vyzer’s mobile app</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Community benchmarking </CardPerkRow>
            </CardPerksRowsWrapper>

          </CardPortfolioAndFeaturesWrapper>
        </MobileComparePlansModalCard>

        <MobileComparePlansModalCard>
          <CardHeader>
            <CardTitlesWrapper>
              <CardTitleWrapper>Premium</CardTitleWrapper>
              <CardSubtitleWrapper>Designed for experienced investors with diverse portfolios.</CardSubtitleWrapper>
            </CardTitlesWrapper>
            <CardPlanPriceWrapper>
              <CardPlanPriceValue>
                {displayMoneyValue(Math.floor((isWithDiscount ? pricingPlans[1].originalPriceAfterDiscount : pricingPlans[1].originalPrice) / 100 / (billingStore.selectedPlan === 'year' ? 12 : 1)), 'USD')}
              </CardPlanPriceValue>
              <div>/</div>
              <CardPlanDescription>
                {isWithDiscount && <BigDiscountLabeWrapper>{pricingPlans[0].discountPercentage}% OFF</BigDiscountLabeWrapper>}
                Per month. Billed annually
              </CardPlanDescription>
            </CardPlanPriceWrapper>
          </CardHeader>
          <PrimaryButton onClick={() => { handleSelectClick(2) }} style={{ borderRadius: '45px', height: '40px', width: '100%' }}>Get started</PrimaryButton>
          <CardPortfolioAndFeaturesWrapper>
            <CardPortfolioAndFeaturesTitle>Portfolio size</CardPortfolioAndFeaturesTitle>
            <CardPerksRowsWrapper>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Manage unlimited private investments</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Sync unlimited financial institutions</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Explore 20 cash flow scenarios</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Add 10 different holding entities</CardPerkRow>
            </CardPerksRowsWrapper>

            <CardPortfolioAndFeaturesTitle>Features and services</CardPortfolioAndFeaturesTitle>
            <CardPerksRowsWrapper>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> All Plus features</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Unlimited document uploads for setup, then 20 files per month</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> VIP Syncing Service of up to 10 portals</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Role management </CardPerkRow>
            </CardPerksRowsWrapper>

          </CardPortfolioAndFeaturesWrapper>
        </MobileComparePlansModalCard>

        <MobileComparePlansModalCard>
          <CardHeader>
            <CardTitlesWrapper>
              <CardTitleWrapper style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>Elite <FamilyOfficeSvg size={'24'} /></CardTitleWrapper>
              <CardSubtitleWrapper>Ultimate wealth management for sophisticated investors.</CardSubtitleWrapper>
            </CardTitlesWrapper>
            <CardPlanPriceWrapper>
              <CardPlanPriceValue>
                {displayMoneyValue(Math.floor((isWithDiscount ? pricingPlans[2].originalPriceAfterDiscount : pricingPlans[2].originalPrice) / 100 / (billingStore.selectedPlan === 'year' ? 12 : 1)), 'USD')}
              </CardPlanPriceValue>
              <div>/</div>
              <CardPlanDescription>
                {isWithDiscount && <BigDiscountLabeWrapper>{pricingPlans[2].discountPercentage}% OFF</BigDiscountLabeWrapper>}
                Per month. Billed annually
              </CardPlanDescription>
            </CardPlanPriceWrapper>
          </CardHeader>
          <PrimaryButton onClick={() => { handleSelectClick(3) }} style={{ borderRadius: '45px', height: '40px', width: '100%' }}>Get started</PrimaryButton>
          <CardPortfolioAndFeaturesWrapper>
            <CardPortfolioAndFeaturesTitle>Unlimited portfolio size</CardPortfolioAndFeaturesTitle>
            <CardPerksRowsWrapper>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Manage unlimited private investments</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Sync unlimited financial institutions</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Unlimited cash flow scenarios</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Add unlimited holding entities</CardPerkRow>
            </CardPerksRowsWrapper>

            <CardPortfolioAndFeaturesTitle>Features and services</CardPortfolioAndFeaturesTitle>
            <CardPerksRowsWrapper>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> All Premium features</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Automated updates of unlimited files with the Magic Box</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Unlimited VIP Syncing Service to your portals</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Personal Vyzer account manager</CardPerkRow>
              <CardPerkRow><CardPerkRowCheckMarkSvg /> Role management </CardPerkRow>
            </CardPerksRowsWrapper>

          </CardPortfolioAndFeaturesWrapper>
        </MobileComparePlansModalCard>

      </MobileComparePlansModalCardsWrapper>

    </MobileComparePlansContentWrapper>
  )}</Observer>)
}

function AnnuallyTooltipComp() {
  return (
    <AnnuallyTooltipWrapper onClick={(e) => { e.stopPropagation() }}>
      <TooltipBoldText>Save 20%</TooltipBoldText> with<br /> annual plan
    </AnnuallyTooltipWrapper>
  )
}

const CardPerkRowCheckMarkSvg = () => {
  return (
    <svg style={{ flexShrink: 0 }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="14" height="14" rx="7" fill="#7D8FA4" />
      <path d="M10.2145 5.02128L6.12854 9.10957L4.08336 7.06501" stroke="white" strokeWidth="2" />
    </svg>
  )
}

const BackArrowMobileSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 4L8 12L16 20" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}

const VyzerTextLogo = () => {
  return (
    <svg width="64" height="24" viewBox="0 0 64 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.969062 5.69141H3.70948L7.59629 14.6397L11.4831 5.69141H14.2235L8.51885 18.8343H6.6732L0.968506 5.69141H0.969062Z" fill="#1A1B1D" />
      <path d="M36.0274 8.06859H29.2169V5.69141H40.7468L33.4503 16.4571H40.2625V18.8337H28.7537L36.028 8.06804L36.0274 8.06859Z" fill="#1A1B1D" />
      <path d="M63.0272 5.69141H57.8323H55.5679H55.1758V18.8343H57.8323V8.06859H61.993L63.0272 5.69141Z" fill="#1A1B1D" />
      <path d="M17.7921 23.9919H20.6098L28.5744 5.69141H25.7779L22.0033 14.3604L17.8921 5.69141H15.04L20.6609 17.464L17.7921 23.9919Z" fill="#1A1B1D" />
      <path d="M50.924 14.4602L50.8884 14.5369C50.2186 15.9726 48.9944 16.7313 47.3487 16.7313C45.0026 16.7313 43.5413 14.8984 43.2486 13.0128H53.7877L53.7888 12.7984C53.8005 10.6273 53.0612 8.68166 51.7065 7.31977C50.4941 6.10063 48.9033 5.42969 47.2265 5.42969C43.6213 5.42969 40.6887 8.49393 40.6887 12.2602C40.6887 16.0265 43.6141 19.0424 47.3487 19.0424C49.9814 19.0424 52.0276 17.8183 53.099 15.6466L50.924 14.4608V14.4602ZM47.2271 7.74022C48.9261 7.74022 50.4413 8.95492 50.9945 10.7267H43.4119C43.9035 9.20153 45.3176 7.74022 47.2271 7.74022Z" fill="#1A1B1D" />
    </svg>
  )
}

const FamilyOfficeSvg = ({ color, size }) => {
  return (
    <svg width={size || "20"} height={size || "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.1101 8.90597L10 2.76758V2.76767L6.88988 8.90606L3.125 5.22303V13.0802H10V13.0801H16.875V5.22294L13.1101 8.90597Z" fill={color || "#1A1B1D"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.1875 16.1905H3.8125V14.5405H16.1875V16.1905Z" fill={color || "#1A1B1D"} />
    </svg>
  )
}