import { Observer } from 'mobx-react';
import React, { useState } from 'react';
import { CheckmarkIcon } from '../../../../assets/icons/common/common-icons';
import { useTrackEvent, useTrackGTMEvent } from '../../../../hooks/useTrackEvent/useTrackEvent';
import { useStore } from '../../../app/data/root.store';
import { MagicBoxActivationFlow } from '../../../magic-box/components/MagicBoxActivationFlow';
import { SecuredMagicBoxIcon } from '../../assets/userActivationIcons';
// import { AnimatedText } from '../ChatRow/AnimatedText';
import { ChatRow } from '../ChatRow/ChatRow';
import { useAnimationsStepDelayedHook } from '../useAnimationsStepDelayedHook';
// import { useAnimationsStepDelayedHook } from '../useAnimationsStepDelayedHook';
import { ActionButton, ActionButtonsWrapper, AnimatedActivationFlowWrapper, CheckmarkIconWrapper, ActivationFlowStepWrapper, ProTipBadge, SecuredPromptHoverEffect, SecuredPromptWrapper, StepContent, StepFooter } from '../UserActivationFlowPage.styles';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';

export const MagicBoxStep = (props) => {
  const { magicBoxStore, userStore, uiStore } = useStore();
  // const animationStep = useAnimationsStepDelayedHook({ animationsStepDelayed: [500, 4800, 1000, 1200, 1500, 2200] });
  const animationStep = useAnimationsStepDelayedHook({ animationsStepDelayed: [700] });
  const [isDone, setIsDone] = useState(false);
  const { trackEvent } = useAnalytics()
  const mixpanelStepsData = {step_name: 'Upload documents', step_number: 2};

  const handleContinueOnClick = () => {
    magicBoxStore.approveUploadingFiles(userStore, true);
    handleFlowStepChange(1);
  }

  const handleFlowStepChange = (delta) => {
    if (delta) {
      uiStore.setActivationStepAsDone(1);
      trackEvent('Onboarding Step Completed', mixpanelStepsData);
    }
    uiStore.setActivationStepIndex(uiStore.activationStepIndex + delta);
  }

  const handleImDoneOnClick = () => {
    if (!magicBoxStore.hasAnyFileUpload) return;
    if (!isDone) {
      setIsDone(true);
      setTimeout(()=>{
        window.scrollTo(0, document.body.scrollHeight);
      },200);
      
      // objDiv.scrollTop = objDiv.scrollHeight;
    }
  }

  useTrackEvent('Onboarding Step Viewed', mixpanelStepsData);
  useTrackGTMEvent('Onboarding Step Viewed', mixpanelStepsData);

  return (<Observer>{() => (
    <ActivationFlowStepWrapper>

      <StepContent withFixedActions>
         <ChatRow duration={0}>
          {/* <AnimatedText animationSpeed={3000} delay={800} text={`Vyzer does the heavy lifting for you.\nSimply upload your financial documents into the Magic Box, and Vyzer will convert them and set up your\nportfolio for you. Please note that this process may take up to 48 hours.`} /> */}
          <span>Vyzer does the heavy lifting for you.<br />Simply upload your financial documents into the Magic Box, and Vyzer will convert them and set up your<br />portfolio for you. Please note that this process may take up to 48 hours.</span>
        </ChatRow>
       <ChatRow duration={0} textOnly={!uiStore.isDesktopView}>
          <ProTipBadge>PRO TIP</ProTipBadge>
          {/* {animationStep > 3 && <AnimatedText animationSpeed={1200} style={{ color: '#2565E7' }} text={`Include documents such as financial statements, Excel spreadsheets, or periodic records that contain your `} />} */}
          <span style={{color: '#2565E7'}}>Include documents such as financial statements, Excel spreadsheets, or periodic records that contain your</span>
          {/* {animationStep > 4 && <AnimatedText style={{ fontWeight: 600, color: '#2565E7' }} text={`investment history, future cash flow, and target performance.`} />} */}
          <span style={{fontWeight: 600, color: '#2565E7'}}> investment history, future cash flow, and target performance.</span>
        </ChatRow>
      

        <AnimatedActivationFlowWrapper padding={uiStore.isDesktopView ? '16px 0 16px 62px': ''}><MagicBoxActivationFlow /></AnimatedActivationFlowWrapper>

        {isDone && <ChatRow textOnly={!uiStore.isDesktopView}>
          {/* <AnimatedText animationSpeed={3000} delay={800} text={`Great job! 👏 Your files was uploaded successfully.  
You’ll receive an email once your assets have been set up. Remember, it can take up to 48 hours.`} /> */}
            <span>Great job! 👏 Your files was uploaded successfully.  
You’ll receive an email once your assets have been set up. Remember, it can take up to 48 hours.</span>
        </ChatRow>}
      </StepContent>

      
      <StepFooter isFixedToBottom>
        <ActionButtonsWrapper>
        {uiStore.isDesktopView && <ActionButton isBack onClick={() => { handleFlowStepChange(-1) }}>Back</ActionButton>}
          {!magicBoxStore.hasAnyFileUpload ? <div onClick={() => { handleFlowStepChange(1) }} style={{ display: 'grid', placeItems: 'center', cursor: 'pointer' , padding: uiStore.isDesktopView ? '0' : '0 16px'}}>Skip</div> : null}
          {!isDone ? <ActionButton disabled={!magicBoxStore.hasAnyFileUpload} onClick={handleImDoneOnClick} withIcon black>
            <CheckmarkIconWrapper><CheckmarkIcon /></CheckmarkIconWrapper> I'm done
          </ActionButton> : <ActionButton onClick={handleContinueOnClick} withIcon black>Continue</ActionButton>}
        </ActionButtonsWrapper>
      </StepFooter>

        {(uiStore.isDesktopView && animationStep > 0) && 
        <SecuredPromptWrapper>
          <SecuredMagicBoxIcon />
          Secured
          <SecuredPromptHoverEffect>Don’t worry, Vyzer doesn’t share any of your information. Your data is encrypted at-rest and in-transit.</SecuredPromptHoverEffect>
        </SecuredPromptWrapper> }
      

    </ActivationFlowStepWrapper>
  )}</Observer>)
}