import axios from 'axios';
import { makeAutoObservable } from "mobx";
import { ROUTES } from "../../../common/constants/routes.const";
import { setWelcomeDone } from "../../onboarding/data/onBoarding.service";
import { datadogRum } from '@datadog/browser-rum';

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export class UiStore{
    previousRoute = '';
    currentRoute = '';
    currentPage = '';
    headerStyle = 'black';
    isInWealthView = false;
    isInCashflowProjectionView = false;
    isIncognitoMode = window.localStorage.getItem('isIncognitoMode') === '1';
    isMobileView = ((/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent)) && !(/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)));
    isItemView = window.location.pathname.includes(ROUTES.wealthAssets.url);
    isMobileViewMessageSeen = true;
    windowWidth = window.innerWidth;
    windowHeight = window.innerHeight;
    wealthPageContentRef = null;
    isBreadcrumbsWrapperSticky = false;
    isCommunityPageHeaderSticky = false;
    isAssetEventsHeaderSticky = false;
    showLogoutModal = false;
    logoutTimout = null;
    isTableScrollOverflowing = -1;
    isRightMenuOpen =  new URLSearchParams(window.location.search).get('mo') === '1';
    isDemoOverviewOpen = false; // true;
    isUserMenuActive = false;
    isShowDemoLimitationModal = false;
    activationFlowSteps = [
        // { title: 'Meet your personal account manager', isDone: false },
        { title: 'Learn about Vyzer', isDone: false },
        { title: 'Upload documents', isDone: false },
        { title: 'Sync your accounts', isDone: false },
    ]
    activationStepIndex = 0;
    alwaysVisibleJumpToPortfolioBtn = false;
    tier4FirstTimeExperienceIndex = 0;
    isActivationStepAnimated = 0;
    isAnimatedActivationStepBack = false;
    isShowMinimizedButton = window.localStorage.getItem('__feature_menu_minimized') === '1';
    isAppMobileMenuOpen = false;
    isPhoneDesktopView = false;
    showConnectCollateralModal = false
    showConnectedCollateralsModal = false;
    mobileGetStartedStepIndex = 0;
    showMobileGetStarted = false;
    isMobileHeaderFixed = false;
    isMobileScrollOnBottom = false;
    isMobileScrollOnTop = false;
    isMobileUserNotificationsPanelOpen = false;
    showRequestPfsModal = false;
    reconnectErrorModal = {show:false};
    isMobileAppView = false;

    constructor(filtersStore){
        makeAutoObservable(this);
        this.filtersStore = filtersStore;
        const mobileViewMessageLS = JSON.parse(window.localStorage.getItem('mobileMessageSeen'));
        if (mobileViewMessageLS){
            const millisecondInDay = 24 * 60 * 60 * 1000;
            if (new Date().getTime() > mobileViewMessageLS.time + millisecondInDay){
                this.isMobileViewMessageSeen = false;
            }
        } else {
            this.isMobileViewMessageSeen = false;
        }
    }

    setAxiosInterceptors(){

        axios.interceptors.response.use(
            (successRes) => {
                this.setLogoutTimer();
                return successRes
            }, 
            (error) => {
                if (error.response && error.response.status === 401 && error.response.config.url !== (baseApiUrl + 'user') && window.location.pathname !== '/' && !window.location.href.includes(ROUTES.login.url)){
                    window.location.reload();
                }
                return Promise.reject(error.response?.data ? error.response.data : error);
            }
        );

        if (process.env.REACT_APP_ENV_KEY !== 'dev') {
            axios.interceptors.request.use(
                (config) => {
                    const sessionId = datadogRum.getInternalContext().session_id;
                    config.headers['x-datadog-session-id'] = sessionId;
                    return config;
                },
            );
        }

    }

    setWealthPageContentRef(ref){
        this.wealthPageContentRef = ref;
    }

    setIsBreadcrumbsWrapperSticky(isSticky){
        this.isBreadcrumbsWrapperSticky = isSticky;
    }

    setIsCommunityPageHeaderSticky(isSticky){
        this.isCommunityPageHeaderSticky = isSticky;
    }

    setIsTableScrollOverflowing(overflowing) {
        this.isTableScrollOverflowing = overflowing;
    }

    setIsAssetEventsHeaderSticky(isSticky){
        this.isAssetEventsHeaderSticky = isSticky;
    }

    setInWealthView(isIn){
        this.isInWealthView = isIn;
    }

    setIsAppMobileMenuOpen(isOpen){
        this.isAppMobileMenuOpen = isOpen;
    }
    setIsInCashflowProjectionView(isIn){
        this.isInCashflowProjectionView = isIn;
    }

    setIsIncognitoMode(isInMode){
        this.isIncognitoMode = isInMode;
        window.localStorage.setItem('isIncognitoMode', isInMode ? '1' : '0');
    }

    setMobileMessageSeen(){
        window.localStorage.setItem('mobileMessageSeen', JSON.stringify({time: new Date().getTime(),value: '1'}));
    }

    routeChanged(path){
        this.previousRoute = this.currentRoute;
        this.currentRoute = path;
        this.isAppMobileMenuOpen = false;
    }

    setWindowSize(){
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
    }

    setLogoutTimer(){
        if (this.logoutTimout){
            clearTimeout(this.logoutTimout)
        }
        this.logoutTimout = setTimeout(()=>{
            window.localStorage.removeItem('pfsRequestSent');
            if (this.filtersStore.countActiveFilters) {
                this.filtersStore.handleClearFilterSelections();
            }
            this.setShowLogoutModal(true);
        }, this.isMobileAppView ? 604800000 : 10800000 );
        // clear and set new timeout for logout popup
    }

    setShowLogoutModal(show){
        this.showLogoutModal = show;
    }

    setShowDemoLimitationModal(show) {
        this.isShowDemoLimitationModal = show;
    }

    setIsRightMenuOpen(isOpen){
        this.isRightMenuOpen = isOpen;
    }

    setDemoRightMenuOpen(isOpen){
        this.isRightMenuOpen = isOpen;
        this.isDemoOverviewOpen = isOpen;
    }

    setIsUserMenuActive(isActive){
        this.isUserMenuActive = isActive;
    }
    

    setActivationFlowSteps(updatedSteps) {
        this.activationFlowSteps = updatedSteps;
    }

    setActivationStepIndex(stepIndex) {
        // this.nextActivationStep = stepIndex;
        //if (stepIndex > this.activationStepIndex){
        this.isAnimatedActivationStepBack = stepIndex < this.activationStepIndex;
        this.setActivationStepAnimated(1);
            setTimeout(()=>{
                // this.activationStepIndex = stepIndex;
                this.setAlwaysVisibleJumpToPortfolioBtn(stepIndex)
                this.setActivationStepIndexProp(stepIndex);
                this.setActivationStepAnimated(2);
                setTimeout(()=>{
                    this.setActivationStepAnimated(3);
                    setTimeout(()=>{
                        this.setActivationStepAnimated(0);
                    },600);
                },10);
            },600);
        //}
    }

    setActivationStepIndexProp(stepIndex) {
        this.activationStepIndex = stepIndex;
    }

    setMobileGetStartedStepIndex = (stepIndex) => {
        this.mobileGetStartedStepIndex = stepIndex;
    }

    setAlwaysVisibleJumpToPortfolioBtn(stepIndex) {
        this.alwaysVisibleJumpToPortfolioBtn = this.alwaysVisibleJumpToPortfolioBtn || stepIndex === 3;
    }

    setActivationStepAnimated(step){
        this.isActivationStepAnimated = step;
    }

    setActivationStepAsDone(stepIndex) {
        if (stepIndex < 0 || stepIndex >= this.activationFlowSteps.length) return;
        const updatedSteps = this.activationFlowSteps.map((step,ind) => ind === stepIndex ? {...step, isDone: true} : step);
        this.setActivationFlowSteps(updatedSteps);
    }

    setTier4FirstTimeExperienceIndex = (stepIndex) => {
        this.tier4FirstTimeExperienceIndex = stepIndex;
    }

    setIsPhoneDesktopView = (isDesktop) => {
        this.isPhoneDesktopView = isDesktop;
    }

    setShowConnectCollateralModal = (show) => {
        this.showConnectCollateralModal = show;
    }

    setShowConnectedCollateralsModal = (show) => {
        this.showConnectedCollateralsModal = show;
    }

    setShowMobileGetStarted = (show) => {
        this.showMobileGetStarted = show;
    }

    setMobileHeaderFixed = (isFixed) => {
        this.isMobileHeaderFixed = isFixed;
    }

    setMobileScrollOnTop = (isOnTop) => {
        this.isMobileScrollOnTop = isOnTop;
    }
    setMobileScrollOnBottom = (isOnBottom) => {
        this.isMobileScrollOnBottom = isOnBottom;
    }

    setIsMobileUserNotificationsPanelOpen = (isOpen) => {
        this.isMobileUserNotificationsPanelOpen = isOpen;
    }
    
    setShowRequestPfsModal = (show) => {
        this.showRequestPfsModal = show;
    }

    setShowReconnectErrorModal = (show,instName,instLogoBase64,instLogoUrl) => {
        this.reconnectErrorModal = {
            show,
            instName,
            instLogoBase64,
            instLogoUrl
        }
    }

    setIsMobileAppView = (isMobileAppView) => {
        this.isMobileAppView = isMobileAppView;
    }


    async setWelcomeDone() {
        return setWelcomeDone();
        // this.shouldShowActivationChecklist = true;
        // this.isRightMenuOpen = true;
    }

    get isRouteWithEmptySideBarLayout(){   
        return [ROUTES.portfolios.url].includes(this.currentRoute);
    }

    get isRouteWithEmptySideBarLayoutForUserWithoutPortfolio(){   
        return [ROUTES.profile.url].includes(this.currentRoute);
    }


    get selectedActivationFlowStep() {
        return this.activationFlowSteps[this.activationStepIndex];
    }

    get screenSize(){
        return this.windowWidth >= 1520 ? 'big' : 'small'
    }

    get isDesktopView(){
        return this.windowWidth > 768;
    }

    get isSideMenuMinimized(){
        return this.windowWidth > 768 && this.windowWidth < 1024;
    }

    get isMobileScreenSize() {
        return this.windowWidth < 456;
    }

    get cashflowScreenView() {
        return this.windowWidth >= 1460 ? 'big' : 'small'
    }

    get onboardingScreenView() {
        return this.windowWidth > 784 ? 'normal' : this.windowWidth > 455 ? 'mid' : 'mobile';
    }
    
    get isRouteWithoutFooter(){
        return false;
    }
}
