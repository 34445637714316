import React from 'react';
import { InputSwitcher } from './InputSwitcher';
import { CustomComponentRowWrapper, FieldWrapper, RowSeparator, RowTitle, RowWrapper, SectionBgWrapper, SectionRowsWrapper, SectionTitle, SectionWrapper, Wrapper } from './FormDisplayer.styles';
import { ActionLockedIconWithTooltip } from '../ActionLocked/ActionLockedIconWithTooltip';


export function FormDisplayer(props) {
    const {sections, fields, values, isDisabled, isIncognito, handleFiledChange, handleFieldBlur, handleShowComponentAfterPreviewComponent, handleChangeShowUnlockComponent, handleUnlockComponent, isItemConnected, isStepsDisplay, currentStep, errors, scrollContainerId, fullWidth, isActionLocked, bottomErrors, isDesktopView } = props;
    const handleAttributeRowUpdated = (data) => {
        props.attributeHistoryUpdated && props.attributeHistoryUpdated(data);
    }

    const handleAttributeRowDeleted = (itemId,atrKey) => {
        props.attributeHistoryDeleted && props.attributeHistoryDeleted(itemId,atrKey);
    }

    return (
        <Wrapper >
            {sections && sections.map((section,sIndex)=>{
                if ((isStepsDisplay && currentStep !== sIndex) || section.isHidden){
                    return null;
                }
                return(
                    <SectionBgWrapper key={sIndex} index={sIndex} isStepsDisplay={isStepsDisplay}>
                        <SectionWrapper className={"fd-sectionWrapper"} fullWidth={fullWidth || section.fullWidth} margin={section.margin} padding={section.padding} isDesktopView={isDesktopView}>
                            {section.title && <SectionTitle>
                                {typeof section.title === 'function' ? section.title() : section.title} {isActionLocked && <ActionLockedIconWithTooltip marginTop={'-2px'} />}
                            </SectionTitle>}
                            <SectionRowsWrapper>
                                {section.rows.map((row,rIndex)=>{
                                    return row.type === 'separator' ? <RowSeparator key={rIndex}/> :
                                           row.type === 'title' ? <RowTitle key={rIndex}>{row.title}</RowTitle> :
                                           row.type === 'customComponent' ? <CustomComponentRowWrapper key={rIndex} >{row.component()} </CustomComponentRowWrapper>  : 
                                        (
                                        <RowWrapper key={rIndex}>
                                            {row.fields.map((field,fIndex)=>{
                                                // console.log(fields[field].isHidden );
                                                const {size,key,isHidden, fieldCustomStyle, ...fieldProp } = (fields ? fields[field] : field);
                                                const error = errors ? errors[key] : null;
                                                const fieldHistory = ( values.attributesHistory && values.attributesHistory.hasOwnProperty(key) ) ? values.attributesHistory[key] : [];
                                                return (
                                                    !isHidden && <FieldWrapper key={fIndex} size={size} customStyle={fieldCustomStyle}> 
                                                        {field.type !== 'space' && field.type !== 'separator' && <InputSwitcher 
                                                            field={fieldProp}
                                                            inputKey={key} 
                                                            value={values[key]}
                                                            history={fieldHistory}
                                                            error={error}
                                                            isFormDisabled={isDisabled}
                                                            bottomErrors={bottomErrors}
                                                            isDesktopView={isDesktopView}
                                                            // showPrevComp={showPrevComp}   
                                                            scrollContainerId={scrollContainerId}  isIncognito={isIncognito} isItemConnected={isItemConnected}
                                                            handleChange={handleFiledChange}
                                                            handleBlur={handleFieldBlur}
                                                            showComponentAfterPrevComp={handleShowComponentAfterPreviewComponent}
                                                            handleUnlockComponent={handleUnlockComponent}
                                                            handleChangeShowUnlockComponent={handleChangeShowUnlockComponent}
                                                            attributeHistoryUpdated={handleAttributeRowUpdated}
                                                            attributeHistoryDeleted={handleAttributeRowDeleted}
                                                        />}
                                                    </FieldWrapper>
                                                )
                                            })}
                                        </RowWrapper>
                                    )
                                })}
                            </SectionRowsWrapper>
                        </SectionWrapper>
                    </SectionBgWrapper>
                )
            })}
        </Wrapper>
    )

}