import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { LogoLoader } from '../../../../common/components/LogoLoader/LogoLoader';
import { displayMoneyValue } from '../../../../common/utils/number-display.utils';
import { useTrackEvent, useTrackGTMEvent } from '../../../../hooks/useTrackEvent/useTrackEvent';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { useStore } from '../../../app/data/root.store';
import { AnnuallyTooltipWrapper, BigDiscountLabeWrapper, ComparePlansButton, ConciergeLeftPaneTitle, ContinueButton, DiscountLabelText, DiscountLabelWrapper, LogoWrapper, ModalCloseButtonWrapper, ModalLeftPaneTextWrapper, ModalLeftPaneWrapper, ModalRightPaneContent, ModalRightPaneFooter, ModalRightPaneWrapper, PlanDetailsWrapper, PlanDiscountValue, PlanPriceValue, PlanPriceWrapper, PlanRowInvisibleBorder, PlanRowWrapper, PlanSelectionCircle, PlanSelectionCircleFill, PlanSwitchOption, PlanSwitchWrapper, PlanTitleWrapper, PlansWrapper, PriceDescriptionRow, PriceDiagonalSeparatorSvgWrapper, PricingModalWrapper, RecommendedPlanBanner, StartExploringFreePlanContainer, TitleWithDiscountWrapper, TooltipBoldText, UserInTier2BigText, UserInTier2ContentWrapper, UserInTier2Perk, UserInTier2PerkBigText, UserInTier2PerkSmallText, UserInTier2PerksWrapper, UserInTier2TitleWithPriceWrapper } from './PricingModal.styles';
import FullPagePricingModalLeftPaneBg from './assets/full-page-pricing-modal-left-pane-bg.png';
import PlanForYouBg from './assets/plan-for-you-bg.png';
import { ReactComponent as TitleLIlu } from './assets/title-l.svg';
import { ReactComponent as TitleRIlu } from './assets/title-r.svg';

export const PricingModal = ({ onClose, isFullPage }) => {
  const { userStore, billingStore, filtersStore } = useStore();
  const { trackEvent } = useAnalytics()
  const pricingTypes = {
      bgImage: userStore.isRelevantVariant === 3 && isFullPage ? FullPagePricingModalLeftPaneBg : PlanForYouBg,
  }
  const actualTier = userStore.tierIndex;
  let recommendedPlanIndex = userStore.data?.profile?.scoreTier - 1;
  // We need to increase the index by one to the next recommendation if the user's recommended plan is the same as what he ordered
  if (recommendedPlanIndex === actualTier) {
    recommendedPlanIndex += 1
  }

  const [selectedPlanIndex, setSelectedPlanIndex] = useState(userStore.isReadOnlyMode ? actualTier : recommendedPlanIndex || actualTier);

  const { bgImage } = pricingTypes;

  const plans = [{
    title: 'Starter',
    mixPanelTitle: 'Starter',
    description: 'Explore the Vyzer Experience. No credit card required',
  }, {
    title: 'Plus',
    mixPanelTitle: 'Plus',
    description: 'Passive Income Investors',
  }, {
    title: 'Premium',
    mixPanelTitle: 'Premium',
    description: 'Diverse Portfolios Pro',
  }, {
    titleFn: ({ isSelected }) => (<div style={{ display: 'flex' }}>Elite <FamilyOfficeSvg color={isSelected ? '#2565E7' : '#1A1B1D'} /></div>),
    mixPanelTitle: 'Elite',
    description: "Sophisticated Investors' Ultimate Management",
  }]

  useEffect(() => {
    billingStore.fetchPricingTable();
    // return () => {}
  }, [billingStore])

  useEffect(() => {
    if (userStore.isTier2 && !userStore.isReadOnlyMode) {
      setSelectedPlanIndex(2);
    } else if ((userStore.isTier3 || userStore.isScoreTier4) && !userStore.isReadOnlyMode) {
      setSelectedPlanIndex(3);
    }
    // return () => {}
  }, [userStore])

  const setSelectedPlan = (plan) => {
    billingStore.setSelectedPlan(plan);
  }

  const handleComparePlansClick = (e) => {
    trackEvent('Pricing Table Clicked', {
      click_type: 'CTA click',
      cta_text: e.target.textContent,
      type: 'button',
      tier: userStore.data?.profile?.tier,
      url: window.location.href,
      recommended_tier: userStore.data?.profile?.scoreTier,
      onboarding_step: userStore.data?.profile?.onBoardingStep,
      payment_status: userStore.data?.profile?.paymentStatus?.join(),
      selected_plan: plans[selectedPlanIndex].mixPanelTitle,
      plan_interval: billingStore.selectedPlan,
    })
    billingStore.setShowComparePlansModal(true);
  }

  const handleContinueClick = async (e, planIndex) => {
    trackEvent('Pricing Table Clicked', {
      click_type: 'CTA click',
      cta_text: e.target.textContent,
      type: 'button',
      tier: userStore.data?.profile?.tier,
      url: window.location.href,
      recommended_tier: userStore.data?.profile?.scoreTier,
      onboarding_step: userStore.data?.profile?.onBoardingStep,
      payment_status: userStore.data?.profile?.paymentStatus?.join(),
      selected_plan: plans[planIndex].mixPanelTitle,
      plan_interval: billingStore.selectedPlan,
      price: mixpanelPriceBySelectedPlan(planIndex),
    })
    filtersStore.handleClearFilterSelections();
    if (planIndex === 0) {
      billingStore.setShowStarterModal(true);
    } else {
      const { url } = (userStore.isDemoOrReadOnlyMode || userStore.isFreeTier || userStore.isFreeUser || userStore.isNoneTier || userStore.isManuallyFreeTier) ? await billingStore.fetchSubscriptionUrl(planIndex - 1, window.location.href) : await billingStore.createDeepLink(planIndex - 1);
      window.location.href = url;
    }
  }

  const handleFamilyOfficeLearnMoreClick = () => {
    onClose?.();
    billingStore.setShowComparePlansModal(true);
  }

  const mixpanelPriceBySelectedPlan = (planIndex) => {
    if (planIndex === 0 && !userStore.isFreeTier) return 0;

    const selectedPlan = billingStore.selectedPlanObj[planIndex - 1];
    const isWithDiscount = selectedPlan.hasOwnProperty('originalPriceAfterDiscount') && selectedPlan.originalPriceAfterDiscount !== selectedPlan.originalPrice;
    const price = (isWithDiscount ? selectedPlan.originalPriceAfterDiscount : selectedPlan.originalPrice) / 100;
    const monthsFactor = billingStore.selectedPlan === 'year' ? 12 : 1;

    return price / monthsFactor;
  };

  useTrackEvent('Pricing Table Viewed', {
    tier: userStore.data?.profile?.tier,
    url: window.location.href,
    recommended_tier: userStore.data?.profile?.scoreTier,
    onboarding_step: userStore.data?.profile?.onBoardingStep,
    payment_status: userStore.data?.profile?.paymentStatus?.join(),
  });
  useTrackGTMEvent('Pricing Table Viewed', {
    tier: userStore.data?.profile?.tier,
    url: window.location.href,
    recommended_tier: userStore.data?.profile?.scoreTier,
    onboarding_step: userStore.data?.profile?.onBoardingStep,
    payment_status: userStore.data?.profile?.paymentStatus?.join(),
  });

  return (<Observer>{() => (
    <BaseModal width={!isFullPage ? '968px' : '100%'} height={!isFullPage ? '608px' : '100%'} borderRadius={8} customModalWrapperStyles={{ maxWidth: !isFullPage ? '968px' : '100%', ...(isFullPage ? {maxHeight:'100%', marginLeft:0} : {}) }} noSidebarMargin={userStore.isDemoTier}>
      {!billingStore.isPricingTableLoaded ?
        <div style={{ display: 'grid', placeItems: 'center', flex: 1 }}>
          <LogoLoader />
        </div>
        : <>
          <PricingModalWrapper>
            <ModalLeftPane bgImage={bgImage} userStore={userStore} isFullPage={isFullPage} />
            <ModalRightPaneWrapper isFullPage={isFullPage}>
              {isFullPage && <LogoWrapper href="https://vyzer.co/" target="_blank"> <VyzerDarkSymbol /> </LogoWrapper>}
              <ModalRightPaneContent isFullPage={isFullPage}>
                <PlanSwitchWrapper isCentered={userStore.isTier3} isSmaller={userStore.isPayedTier}>
                  <Tooltip overlayInnerStyle={{ borderRadius: '6px', backgroundColor: '#000' }} title={<AnnuallyTooltipComp />} ><PlanSwitchOption isSelected={billingStore.selectedPlan === 'year'} onClick={() => { setSelectedPlan('year') }}>{userStore.isPayedTier ? 'Annually ' : 'Annual billing '}/ Save 20% 🎉</PlanSwitchOption></Tooltip>
                  <PlanSwitchOption isSelected={billingStore.selectedPlan === 'month'} onClick={() => { setSelectedPlan('month') }}>Monthly{userStore.isPayedTier ? null : ' billing'}</PlanSwitchOption>
                </PlanSwitchWrapper>
                {
                  userStore.isTier3 && !userStore.isReadOnlyMode ?
                    <UserInTier3Content billingStore={billingStore} />
                    :
                    <PlansWrapper>
                      {plans.map((plan, ind) => {
                        const isHiddenFreeTier = (userStore.isRelevantVariant === 1 || (!userStore.isDemoTier && !userStore.isNoneTier)) && ind === 0;
                        const isRecommended = ind === recommendedPlanIndex;
                        const isSelected = ind === selectedPlanIndex;

                        let isWithDiscount = false;
                        let relevantPricingObj = {};
                        if (ind > 0) {
                          relevantPricingObj = billingStore.selectedPlanObj[ind - 1];
                          isWithDiscount = relevantPricingObj.hasOwnProperty('originalPriceAfterDiscount') && relevantPricingObj.originalPriceAfterDiscount !== relevantPricingObj.originalPrice;
                        }

                        //For users who purchased Tier 2 but are now being recommended to upgrade to another tier
                        const isTierTwoRecommendedPlan = actualTier === 1 && recommendedPlanIndex === 2 && ind === 1 && !userStore.isReadOnlyMode;
                        const isTierTwoOrThreeNotRedeemed = ((actualTier === 1 && ind === 1) || (actualTier === 2 && ind === 2)) && !userStore.isRedeemed;
                        
                        const disableRow = isTierTwoRecommendedPlan || isTierTwoOrThreeNotRedeemed;

                        return (
                          !isHiddenFreeTier ? <PlanRowInvisibleBorder key={ind} onClick={() => { !disableRow && setSelectedPlanIndex(ind) }} isSelected={isSelected} isRecommended={isRecommended} disableRow={disableRow}>
                            {isRecommended ? <RecommendedPlanBanner isSelected={isSelected}>Recommended for you{userStore.isRelevantVariant !== 3 ? `, based on your journey` : ``}</RecommendedPlanBanner> : null}
                            <PlanRowWrapper isRecommended={isRecommended} isSelected={isSelected} displayRowInGray={disableRow}>
                              <PlanDetailsWrapper>
                                <PlanSelectionCircle isSelected={isSelected}>
                                  {isSelected ? <PlanSelectionCircleFill /> : null}
                                </PlanSelectionCircle>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <PlanTitleWrapper isSelected={isSelected}>{plan.titleFn ? plan.titleFn({ isSelected }) : plan.title}</PlanTitleWrapper>
                                  <div>{plan.description}</div>
                                </div>
                              </PlanDetailsWrapper>
                              <PlanPriceWrapper isSelected={isSelected}>
                                <PlanPriceValue isSelected={isSelected}>
                                  {ind === 0 ? '$0' : displayMoneyValue(Math.ceil((isWithDiscount ? relevantPricingObj.originalPriceAfterDiscount : relevantPricingObj.originalPrice) / 100 / (billingStore.selectedPlan === 'year' ? 12 : 1)), 'USD')}
                                </PlanPriceValue>
                                {isWithDiscount && <PlanDiscountValue isSelected={isSelected}>
                                  {ind === 0 ? 'Free' : displayMoneyValue(Math.ceil(relevantPricingObj.originalPrice / 100 / (billingStore.selectedPlan === 'year' ? 12 : 1)), 'USD')}
                                </PlanDiscountValue>}
                                {ind === 0 ? '/ FREE' : '/ mo'}
                              </PlanPriceWrapper>
                              {isWithDiscount ? <div style={{ position: 'absolute', top: '4px', right: '-4px', zIndex: 2 }}><DiscountLabel text={Math.floor(relevantPricingObj.discountPercentage) + '% OFF'} /></div> : null}
                            </PlanRowWrapper>
                          </PlanRowInvisibleBorder> : null
                        )
                      })}
                    </PlansWrapper>}
                <ModalRightPaneFooter>
                  {userStore.isTier3 && !userStore.isReadOnlyMode ? <>
                    <ComparePlansButton onClick={handleFamilyOfficeLearnMoreClick}>Compare plans</ComparePlansButton>
                    <ContinueButton onClick={(e) => handleContinueClick(e,selectedPlanIndex)}>{billingStore.isLoading ? <LoadingOutlined /> : userStore.isDemoOrReadOnlyMode || userStore.isNoneTier ? 'Continue' : 'Upgrade'}</ContinueButton>
                  </> : <>
                    <ComparePlansButton onClick={handleComparePlansClick}>Compare plans <ArrowUpRightSvg /></ComparePlansButton>
                    <ContinueButton onClick={(e) => handleContinueClick(e,selectedPlanIndex)}>{billingStore.isLoading ? <LoadingOutlined /> : userStore.isDemoOrReadOnlyMode || userStore.isNoneTier ? 'Continue' : 'Upgrade'}</ContinueButton>
                  </>
                  }
                </ModalRightPaneFooter>
                {userStore.isRelevantVariant === 1 && <StartExploringFreePlanContainer onClick={(e) => handleContinueClick(e,0)}>Start exploring the free plan</StartExploringFreePlanContainer>}
              </ModalRightPaneContent>
              {!isFullPage && <ModalCloseButtonWrapper onClick={onClose}><ModalCloseButton /></ModalCloseButtonWrapper>}
            </ModalRightPaneWrapper>
          </PricingModalWrapper>
        </>}
    </BaseModal>
  )}</Observer>)
}

function ModalLeftPane({ bgImage, userStore, isFullPage }) {
  return (
    <ModalLeftPaneWrapper bgImage={bgImage} isFullPage={isFullPage}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', padding: '27px 24px 16px' }}>
        <ModalLeftPaneTextWrapper>
          <TitleLIlu />
          <ConciergeLeftPaneTitle>{userStore.isDemoMode ? 'Welcome to your Private Investments Concierge' : 'Take your Private Investments Concierge to the next level'}</ConciergeLeftPaneTitle>
          <TitleRIlu />
        </ModalLeftPaneTextWrapper>
      </div>
    </ModalLeftPaneWrapper>
  )
}

function AnnuallyTooltipComp() {
  return (
    <AnnuallyTooltipWrapper onClick={(e) => { e.stopPropagation() }}>
      <TooltipBoldText>Save 20%</TooltipBoldText> with<br /> annual plan
    </AnnuallyTooltipWrapper>
  )
}

function UserInTier3Content({ billingStore }) {
  const relevantPricingObj = billingStore.selectedPlanObj[2];
  const isWithDiscount = relevantPricingObj.hasOwnProperty('originalPriceAfterDiscount') && relevantPricingObj.originalPriceAfterDiscount !== relevantPricingObj.originalPrice;

  return (
    <UserInTier2ContentWrapper isTier4Content>
      <TitleWithDiscountWrapper>
        <UserInTier2BigText>Elite</UserInTier2BigText>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FamilyOfficeSvg size={28} />
          {isWithDiscount && <BigDiscountLabeWrapper>{Math.floor(relevantPricingObj.discountPercentage)}% OFF</BigDiscountLabeWrapper>}
        </div>
      </TitleWithDiscountWrapper>
      Experience wealth management like never before

      <UserInTier2TitleWithPriceWrapper>
        <UserInTier2BigText>{displayMoneyValue(Math.ceil((isWithDiscount ? relevantPricingObj.originalPriceAfterDiscount : relevantPricingObj.originalPrice) / 100 / (billingStore.selectedPlan === 'year' ? 12 : 1)), 'USD')}</UserInTier2BigText>
        {isWithDiscount && <UserInTier2BigText withDiscount>{displayMoneyValue(Math.ceil(relevantPricingObj.originalPrice / 100 / (billingStore.selectedPlan === 'year' ? 12 : 1)), 'USD')}</UserInTier2BigText>}
        <PriceDiagonalSeparatorSvgWrapper><PriceDiagonalSeparatorSvg /></PriceDiagonalSeparatorSvgWrapper>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <PriceDescriptionRow>Per month</PriceDescriptionRow>
          <PriceDescriptionRow>Billed {billingStore.selectedPlan === 'year' ? 'annually' : 'monthly'}</PriceDescriptionRow>
        </div>
      </UserInTier2TitleWithPriceWrapper>

      <UserInTier2PerksWrapper withGap>
        <UserInTier2Perk><CheckMarkSvg /><UserInTier2PerkBigText>Boundless Portfolios:</UserInTier2PerkBigText><UserInTier2PerkSmallText>No limits on size or scope</UserInTier2PerkSmallText></UserInTier2Perk>
        <UserInTier2Perk><CheckMarkSvg /><UserInTier2PerkBigText>Broad Sync:</UserInTier2PerkBigText> <UserInTier2PerkSmallText>Connect with any and every institution</UserInTier2PerkSmallText></UserInTier2Perk>
        <UserInTier2Perk><CheckMarkSvg /><UserInTier2PerkBigText>Exclusive Automation:</UserInTier2PerkBigText> <UserInTier2PerkSmallText>Upload unlimited Magic Box files</UserInTier2PerkSmallText></UserInTier2Perk>
        <UserInTier2Perk><CheckMarkSvg /><UserInTier2PerkBigText>Strategic Planning:</UserInTier2PerkBigText> <UserInTier2PerkSmallText>Create infinite cash flow scenarios</UserInTier2PerkSmallText></UserInTier2Perk>
        <UserInTier2Perk><CheckMarkSvg /><UserInTier2PerkBigText>Tailored Insights:</UserInTier2PerkBigText> <UserInTier2PerkSmallText>Craft custom reports and statements</UserInTier2PerkSmallText></UserInTier2Perk>
        <UserInTier2Perk><CheckMarkSvg /><UserInTier2PerkBigText>VIP Treatment:</UserInTier2PerkBigText> <UserInTier2PerkSmallText>Always-on, unlimited syncing service</UserInTier2PerkSmallText></UserInTier2Perk>
        <UserInTier2Perk><CheckMarkSvg /><UserInTier2PerkBigText>Role Management:</UserInTier2PerkBigText> <UserInTier2PerkSmallText>Share access to your portfolio</UserInTier2PerkSmallText></UserInTier2Perk>
      </UserInTier2PerksWrapper>
    </UserInTier2ContentWrapper>
  )
}

const DiscountLabel = ({ text }) => {
  return (
    <DiscountLabelWrapper>
      <svg width="65" height="17" viewBox="0 0 65 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4579_113453)">
          <path d="M62.9296 0C63.5982 0 64.2227 0.334167 64.5936 0.890516L65.9999 2.99978C65.9999 2.99978 61.88 4.36719 61.88 3C61.88 1.63281 60.9299 0 60.9299 0H62.9296Z" fill="#B37C3B" />
        </g>
      </svg>
      <DiscountLabelText>{text}</DiscountLabelText>
    </DiscountLabelWrapper>
  )
}

const FamilyOfficeSvg = ({ color, size }) => {
  return (
    <svg width={size || "20"} height={size || "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.1101 8.90597L10 2.76758V2.76767L6.88988 8.90606L3.125 5.22303V13.0802H10V13.0801H16.875V5.22294L13.1101 8.90597Z" fill={color || "#1A1B1D"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.1875 16.1905H3.8125V14.5405H16.1875V16.1905Z" fill={color || "#1A1B1D"} />
    </svg>
  )
}

const ModalCloseButton = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="6.28033" y1="5.83393" x2="18.3011" y2="17.8547" stroke="black" strokeWidth="1.5" />
      <line x1="5.92676" y1="17.8544" x2="17.9476" y2="5.83362" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}

const PriceDiagonalSeparatorSvg = () => {
  return (
    <svg width="8" height="22" viewBox="0 0 8 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="7.48018" y1="0.139371" x2="1.23325" y2="21.6623" stroke="black" />
    </svg>
  )
}

const CheckMarkSvg = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2145 5.01884L6.12854 9.10713L4.08336 7.06257" stroke="#1A1B1D" strokeWidth="2" />
    </svg>
  )
}

const ArrowUpRightSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
      <path d="M8.87402 7.5C8.87402 7.5 12.2864 7.5 13.8322 7.5C13.8322 9.04575 13.8322 12.4581 13.8322 12.4581" stroke="#2565E7" strokeWidth="1.42493" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 13.8359L13.6167 7.71926" stroke="#2565E7" strokeWidth="1.42493" strokeLinecap="round" />
    </svg>
  )
}

const VyzerDarkSymbol = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1942 0H9.86411C4.41631 0 0 4.41631 0 9.86411V22.1942C0 27.642 4.41631 32.0584 9.86411 32.0584H22.1942C27.642 32.0584 32.0584 27.642 32.0584 22.1942V9.86411C32.0584 4.41631 27.642 0 22.1942 0Z" fill="#121D2A" />
      <path d="M10.1234 9.16623L17.7814 22.7358L15.9971 25.8974L6.55469 9.16623H10.1234ZM13.6921 9.16623L15.4588 12.297H20.1038L17.7814 16.4121L19.5658 19.5737V19.5743L25.4398 9.16623H13.6921Z" fill="white" />
    </svg>
  )
}