import React from 'react';
import { UserStore } from '../../user/data/user.store';
import { UiStore } from '../../layout/data/ui.store';
import { CashFlowStore } from '../../cash-flow/data/cash-flow.store';
import { WealthStore } from '../../wealth/data/wealth.store';
import { MetadataStore } from '../../metadata/data/metadata.store';
import { OnBoardingStore } from '../../onboarding/data/onBoarding.store';
import { ConnectedInstitutionsStore } from '../../connected-institutions/data/connected-institutions.store';
import { FormsStore } from '../../form-displayer-connector/data/forms.store';
import { EventsStore } from '../../events/data/events.store';
import { DocumentsStore } from '../../wealth/pages/asset/components/Documents/data.js/documents.store';
import { NotificationsStore } from '../../notifications/data/notifications.store';
import { CreateItemStore } from '../../add-item/data/create-item.store';
import { CashAccountTransactionsStore } from '../../wealth/pages/asset/components/Transactions/data/cashAccountTransactions.store';
import { WealthClassStore } from '../../wealth/pages/class/data/wealth-class.store';
import { MagicBoxStore } from '../../magic-box/data/magic-box.store';
import { TickersStore } from '../../wealth/pages/asset/components/Tickers/data/tickers.store';
import { BillingStore } from '../../user/data/billing.store';
import { WealthItemStore } from '../../wealth/data/wealth-item.store';
import { FiltersStore } from '../../filters/data/filters.store';
import { DataLayersStore } from '../../data-layers/data/data-layers.store';


import { CommunityStore } from '../../community/data/community.store';
import { InflowOutflowDataLayersStore } from '../../data-layers/data/inflow-outflow-data-layers.store';
import { ReportsStore } from '../../reports/data/reports.store';
import { CustomClassStore } from '../../custom-class/custom-class.store';
import { HoldingEntityStore } from '../../holding-entity/holding-entity.store';

const RootStoreContext = React.createContext(null);

export const RootStoreProvider = ({children}) => {
    const metadataStore = new MetadataStore();
    const filtersStore= new FiltersStore(metadataStore);
    const documentsStore = new DocumentsStore();
    const uiStore = new UiStore(filtersStore);
    const cashFlowStore = new CashFlowStore(uiStore);
    const dataLayersStore= new DataLayersStore(metadataStore,filtersStore,uiStore);
    const tickersStore = new TickersStore(dataLayersStore);
    const inflowOutflowDataLayersStore = new InflowOutflowDataLayersStore(metadataStore)
    const wealthStore = new WealthStore(cashFlowStore,documentsStore,metadataStore,tickersStore,filtersStore,dataLayersStore,inflowOutflowDataLayersStore,uiStore);
    const itemStore = new WealthItemStore(wealthStore,cashFlowStore,documentsStore,metadataStore,tickersStore);
    const userStore = new UserStore(metadataStore,uiStore);
    
    const stores = {
        userStore,
        uiStore,
        onBoardingStore: new OnBoardingStore(),
        metadataStore,
        cashFlowStore,
        documentsStore, 
        reportsStore: new ReportsStore(),
        wealthStore,
        wealthClassStore: new WealthClassStore(wealthStore),
        itemStore,
        tickersStore,
        communityStore: new CommunityStore(),
        filtersStore,
        dataLayersStore,
        inflowOutflowDataLayersStore,
        billingStore: new BillingStore(),
        cashAccountTransactions: new CashAccountTransactionsStore(),
        eventsStore: new EventsStore(),
        createItemStore: new CreateItemStore(metadataStore),
        customClassStore: new CustomClassStore(metadataStore),
        holdingEntityStore: new HoldingEntityStore(metadataStore, itemStore, filtersStore),
        magicBoxStore: new MagicBoxStore(),
        connectedInstitutionsStore: new ConnectedInstitutionsStore(metadataStore),
        formsStore: new FormsStore(),
        notificationsStore: new NotificationsStore(userStore),
    }
    return <RootStoreContext.Provider value={stores}>
        {children}
    </RootStoreContext.Provider>
}

export const useStore = () => React.useContext(RootStoreContext);

/// TESTING
// class RootStore {
//     constructor(){
//         const metadataStore = new MetadataStore();
//         this.userStore = new UserStore();
//         this.uiStore = new UiStore();
//         this.onBoardingStore = new OnBoardingStore(metadataStore);
//         this.metadataStore = metadataStore
//         // meStore: new MeStore(),
//         this.cashFlowStore = new CashFlowStore();
//         this.wealthStore = new WealthStore();
//         this.transactionsStore= new TransactionsStore();
//         this.addItemStore = new AddItemStore(metadataStore);
//         this.connectedAccountsStore = new ConnectedAccountsStore();
//         this.formStore = new FormStore();
//     }
// }
// const RootStoresContext = React.createContext(new RootStore());
// export const useStores = () => React.useContext(RootStoresContext);