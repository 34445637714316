import { Observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { ActionLocked } from '../../../../../../common/components/ActionLocked/ActionLocked';
import { ActionLockedIconWithTooltip } from '../../../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
import { formatItemEvent } from '../../../../../../common/utils/mixpanel.utils';
import { removeUrlParam } from '../../../../../../common/utils/navigation.utils';
import { displayMoneyFormatter } from '../../../../../../common/utils/number-display.utils';
import { isNullOrUndefined } from '../../../../../../common/utils/object.utils';
import { fetchTickerMarketCap } from '../../../../../add-item/data/add-item.service';
import { ASSET_CATEGORIES_FORMS } from '../../../../../add-item/data/info-forms/assets-info-forms.constant';
import { useStore } from '../../../../../app/data/root.store';
import { FormWithStore } from '../../../../../form-displayer-connector/components/FormWithStore';
import { useFormHook } from '../../../../../form-displayer-connector/data/useFormHook';
import { categoriesValidations } from '../categoriesValidation';
import { ActionButton, ActionsStickyWrapper, ActionsWrapper, AssetClosedTabMessage, FormWrapper, TabWrapper } from './AssetInfo.styles';
import { useAnalytics } from '../../../../../app/data/AnalyticsProvider';
import { ClosedItemSvg } from '../../../../../../common/components/CommonBadge/CommonBadge';

export function AssetEditForm(props) {

  const { id, isIncognito } = props;
  const formName = 'assetEditForm'
  const [asset, setAsset] = useState({});
  const {itemStore, userStore, uiStore} = useStore();
  const assetEditForm = useFormHook(formName);
  const { mixpanelTrack } = useAnalytics()
  const history = useHistory();
  const location = useLocation();
  
  useEffect(() => {
    const goToField = new URLSearchParams(window.location.search).get('field');
    if (props.asset && assetEditForm && goToField) {
      const element = document.getElementById(goToField);
  
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  
        // Apply the flashing effect
        element.classList.add('flashing');
        // Your existing useEffect for removing URL param    
        removeUrlParam(history, location, 'field');
        // Optional: Remove the class after some time
        setTimeout(() => {
          element.classList.remove('flashing');
        }, 2500); // Adjust the time as needed
      }
    }
  }, [history, location, props.asset, assetEditForm]);

  useEffect(() => {
    setAsset({...props.asset,...itemStore.itemFormEditedChanges});
  }, [props.asset,itemStore]);

  useEffect(() => {
    const fetchMarketCap = async (asset) => {
      const marketCapResult = await fetchTickerMarketCap(asset.stock.symbol, asset.stock.exchange);
      assetEditForm.updateValue('marketCap', displayMoneyFormatter(marketCapResult, asset.currency || 'USD') )
    }
    if (assetEditForm && props.asset && props.asset.categoryId === 39) {
      fetchMarketCap(props.asset);
    }
    // return () => {}
  }, [props.asset, assetEditForm])
  
  useEffect(() => {
    return () => {
      itemStore.setItemFormEditChanges({});
      itemStore.setItemFormHaveChanges(false);
    }
  }, [itemStore])


  const handleFiledChange = (fieldName, value ) => {
    let updatedChanges = {};
    if (asset[fieldName] !== value && (!isNullOrUndefined(asset[fieldName]) || !isNullOrUndefined(value))){
      updatedChanges = {
        ...itemStore.itemFormEditedChanges,
        //...editedChanges,
        [fieldName]: value
      }
    } else {
      //updatedChanges = {...editedChanges};
      updatedChanges = {...itemStore.itemFormEditedChanges};
      delete updatedChanges[fieldName];
    }

    const updatedAsset = {
      ...asset,
      ...updatedChanges
    }
    
    // setEditedChanges(updatedChanges);
    itemStore.setItemFormEditChanges(updatedChanges);
    // setHaveChanges(JSON.stringify(updatedAsset) !== JSON.stringify(asset));
    itemStore.setItemFormHaveChanges(JSON.stringify(updatedAsset) !== JSON.stringify(asset))
  }
  
  const saveAsset = async () => {
    if (itemStore.itemFormHaveChanges){
      
      // form validation 
      const formValidation = assetEditForm.allSectionErrors;
      if (!formValidation.isValid) {
        assetEditForm.setErrors(formValidation.errors);
        assetEditForm.setBottomErrors(formValidation.bottomErrors);
        return false;  
      }

      const {valueUpdates,attributes,aggregatedMetricsData,historicalCashFlow,attributesHistory,transactions,updates,documents,category,categoryClass,updatedAt,
        institutionsList,of_capital,on_capital,invested_capital,tickers,
        futureEvents,events,historyPoints,xirr,roi,coc,apr,irr,moic,interest_payment,ltv,paidAmounts,principal_payment,distributions,contributions,...dataToSend} = {...asset,...assetEditForm.valuesForSave};
      
      // special categories validation
      const validation = categoriesValidations.hasOwnProperty(category.id) ? categoriesValidations[category.id](dataToSend) : {isValid:true}
      if (!validation.isValid){
        alert(validation.message);
        return false;
      }
      // setEditedChanges({});
      itemStore.setItemFormEditChanges({});
      itemStore.setItemFormHaveChanges(false);
      // setHaveChanges(false);
      dataToSend['categoryId'] = category.id;
      const updated = await itemStore.updateAsset(id,dataToSend);
      mixpanelTrack('Item Updated', formatItemEvent(updated.asset))
    }
  }

  const resetChanges = () => {
    // setEditedChanges({});
    itemStore.setItemFormEditChanges({});
    itemStore.setItemFormHaveChanges(false);
    // setHaveChanges(false);
    assetEditForm.resetValues();
  }

  const handleAttributeRowUpdated = (data) => {
    itemStore.updateItemAttribute(data);
  }

  const handleAttributeRowDeleted = (itemId,atrKey) => {
    itemStore.deleteItemAttribute(itemId,atrKey);
  }

  return (
    <Observer>{()=>(
      <TabWrapper>
        <FormWrapper haveChanges={itemStore.itemFormHaveChanges}>
        {itemStore.item.closedAt !== null && <AssetClosedTabMessage style={{padding: '24px 24px 0'}}>
                      <ClosedItemSvg style={{minWidth:'16px'}}/>
                      <span>Edit events and Info is not available when item is closed</span>
                    </AssetClosedTabMessage>}
       { asset.category && <>
       <FormWithStore valueUpdated={handleFiledChange} 
        fullWidth
        scrollContainerId={"wealthPageContent"}
        sections={ASSET_CATEGORIES_FORMS[''+asset.category.id]} 
        formName={formName} defaultValues={asset} 
        isIncognito={isIncognito}
        attributeHistoryUpdated={handleAttributeRowUpdated} 
        attributeHistoryDeleted={handleAttributeRowDeleted}
        isDisabled={asset.closedAt !== null || userStore.isActionBlockedByRole('editItemInfo') || userStore.isReadOnlyMode}
        isActionLocked={userStore?.isReadOnlyMode} />
       </> }
       </FormWrapper>
       {itemStore.itemFormHaveChanges && 
       <ActionsWrapper isMobileFooterShowing={uiStore.isMobileScrollOnTop || uiStore.isMobileScrollOnBottom}>
          <ActionsStickyWrapper>
          <ActionButton white onClick={resetChanges} >
                Cancel changes
            </ActionButton>
            <ActionLocked action={'itemInfoEdit'}>
              <ActionButton onClick={saveAsset} style={{padding:'8px 48px'}} >
                <div style={{display:'flex'}}>
                  Save {userStore?.isReadOnlyMode && <ActionLockedIconWithTooltip iconColor={'#fff'} />}
                </div>
              </ActionButton>
            </ActionLocked>
            
          </ActionsStickyWrapper>
       </ActionsWrapper>
       }
        <Prompt
          when={itemStore.itemFormHaveChanges}
          message={(params) =>  params.hash !== '' ? true : "Oops... It seems there are some unsaved changes, are you sure you want to leave?"}
        />
      </TabWrapper>
   )}</Observer>)
}
