import ReactDOM from 'react-dom';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory , useLocation } from 'react-router-dom';
import { LinkArrowIcon, TooltipIcon } from '../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { CalendlyPopupWidget } from '../../../../common/components/CalendlyPopupWidget/CalendlyPopupWidget';
import { LogoLoader } from '../../../../common/components/LogoLoader/LogoLoader';
import { ModifiedTooltip } from '../../../../common/components/ModifiedTooltip/ModifiedTooltip';
import { useTrackEvent, useTrackGTMEvent } from '../../../../hooks/useTrackEvent/useTrackEvent';
import { useStore } from '../../../app/data/root.store';
import { DiscountLabelText, DiscountLabelWrapper } from '../PricingModal/PricingModal.styles';
import { AnnuallyTooltipWrapper, ArrowLeftWrapper, Cell, ColumnHeaderCell, ComparePlansModalWrapper, ConciergeLeftPaneTitle, ConciergeWrapper, EmptyTableHeaderCell, ExploreDemoLink, FaqWrapper, GridWrapper,
  HoverDataCompSubTitle,
  HoverDataCompTitle,
  LinkArrowIconWrapper,
  // LearnAboutFamilyOfficeWrapper,LearnMoreWrapper,
   ModalCloseButtonWrapper, ModalLeftPaneTextWrapper, ModalLeftPaneWrapper, ModalRightPaneContent, ModalRightPaneFooter, ModalRightPaneHeaderWrapper, ModalRightPaneWrapper, PlanSubText, PlanSwitchOption, PlanSwitchWrapper, PlanText, PlanTextWrapper, RecommendedCell, RecommendedHeader, RowHeaderCell, SectionDividerCell, SelectPlanButton, StickyGridHeadersBackground, TooltipBoldText, TooltipContainer, VyzerSvgWrapper } from './ComparePlansModal.styles';
import { MobileComparePlansContent } from './MobileComparePlansContent';
import ComparePlanBg from './assets/compare-plan-bg.jpg';
import ExclusiveOfferBg from './assets/exclusive-offer-bg.png';
import { ReactComponent as TitleLIlu } from './assets/title-l.svg';
import { ReactComponent as TitleRIlu } from './assets/title-r.svg';
import { planPerks } from './plan-perks.const';
import { removeUrlParam } from '../../../../common/utils/navigation.utils';
import { startIntercomNewMessage } from '../../../../common/utils/intercom.utils';
import { colors } from '../../../../common/styles/theme.styles';
import { ReactComponent as PortfolioSizeSvg } from './assets/hover-images/portfolio-size.svg'
import { ReactComponent as HoldingEntitiesSvg } from './assets/hover-images/holding-entities.svg'
import { ReactComponent as CashFlowSvg } from './assets/hover-images/cash-flow.svg'
import { ReactComponent as MagicBoxSvg } from './assets/hover-images/magic-box.svg'
import { ReactComponent as TransactionTrackingSvg } from './assets/hover-images/transaction-tracking.svg'
import { ReactComponent as PerformanceInsightSvg } from './assets/hover-images/performance-insight.svg'
import { ReactComponent as ReportsSvg } from './assets/hover-images/reports.svg'
import { ReactComponent as CommunitySvg } from './assets/hover-images/community.svg'

import RoleManagementImage from './assets/hover-images/role-management.jpg'
import MobileAppImage from './assets/hover-images/mobile-app.jpg'
import SyncBankImage from './assets/hover-images/sync-bank.jpg'
import VipSyncImage from './assets/hover-images/vip-sync.jpg'
import { useAnalytics } from '../../../app/data/AnalyticsProvider';

const tiersArray = [
  'TIER_1',
  'TIER_2',
  'TIER_3',
  'TIER_4',
]

export const ComparePlansModal = observer(({ onClose }) => {
  const { userStore, billingStore, filtersStore, uiStore } = useStore();
  const { mixpanelTrack } = useAnalytics();
  const actualTier = tiersArray.indexOf(userStore.data?.profile?.tier);
  const recommendedPlanIndex = userStore.isReadOnlyMode ? actualTier + 1 : userStore.data?.profile?.scoreTier || actualTier + 2; // we need to add 2 because: 1 since its zero based, 1 since we hide a column. in readonly add 1 to recommend old actual
  const [isSticky, setIsSticky] = useState(false);
  const modalRightPaneContentRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);
  const [calculatedHoverContent, setCalculatedHoverContent] = useState({ top: 0, left: 0 });
  const comparePlansModalWrapperRef = useRef(null);

  const plans = [{
    title: 'Starter',
    description: 'Explore Vyzer experience',
  }, {
    title: 'Plus',
    description: 'Passive Income Investors',
  }, {
    title: 'Premium',
    description: 'Complex portfolios ',
  }, {
    title: 'Elite',
    description: 'White gloves service for UHNWI',
    withLabel: true
  }]

  const columnHeaders = plans.map(plan => plan.title);
  const rowHeaders = [
    { label: '', tooltip: `` },
    { sectionDivider: true, label: 'Portfolio size', tooltip: ``, },
    { label: 'Portfolio size', hoverData: <HoverDataComp image={<PortfolioSizeSvg />} title={'Portfolio Size'} description={'Add diverse private investments manually, from Funds and Syndications to Private Equity, Private Loans and more.  Gain tailored insights, and plan your cash flow with a smart distribution schedule and capital calls planner.'} />, tooltip: `Manually add portfolio assets to your Vyzer account.` },
    { label: 'Sync online banks and brokerage platforms', hoverData: <HoverDataComp image={<img src={SyncBankImage} alt={'Sync Banks'} />} title={'Sync Banks and Brokerage Platforms'} description={'Seamlessly link your online accounts to monitor bank transactions and get a real-time breakdown of your holdings.'} />, tooltip: `Connect your bank accounts, investor portals, and other holdings for real-time visibility and analysis.` },
    { label: 'Holding entity', hoverData: <HoverDataComp image={<HoldingEntitiesSvg />} title={'Manage Holding Entities'} description={'Group your investments by holding entities and designate specific beneficiaries for each investment, ensuring organized and precise allocation.'} />, tooltip: `Organize your investments under holding entities and different beneficiaries for each investment.` },
    { label: 'Cash flow scenarios', hoverData: <HoverDataComp image={<CashFlowSvg />} title={'Cash Flow Scenarios'} description={'Intelligent cash flow forecasting to visualize how potential income and expenses influence your cash position in the future, empowering you to make informed financial decisions.'} />, tooltip: `Smart cash flow planning to predict outcomes based on multiple scenarios.` },
    { sectionDivider: true, label: 'Features and services', tooltip: ``, },
    { label: 'Magic Box', hoverData: <HoverDataComp image={<MagicBoxSvg />} title={'The Magic Box'} description={'Simply upload any financial document, and Vyzer will automatically create or update your assets/liabilities and performance. Streamlining your financial updates.'} />, tooltip: `Upload any financial document, and Vyzer will automatically create or update an asset or liability.` },
    { label: 'Transaction tracking & Notifications', hoverData: <HoverDataComp image={<TransactionTrackingSvg />} title={'Transaction tracking & Notifications'} description={'Stay on top of your latest distributions and upcoming capital calls with Vyzer’s automated tracking and notification system.'} />, tooltip: `Stay on top of your latest distributions and upcoming capital calls with Vyzer’s automated tracking and notification system.` },
    { label: 'Performance insights', hoverData: <HoverDataComp image={<PerformanceInsightSvg />} title={'Performance Insights'} description={'Consolidate and tailor your data for effortless monitoring and management of assets, ensuring efficient tracking at your fingertips.'} />, tooltip: `Aggregate and customize your data so you can monitor, track, and manage assets efficiently & easily.` },
    { label: 'Reports and statements', hoverData: <HoverDataComp image={<ReportsSvg />} title={'Reports and Statements'} description={'Generate and access balance sheets and investment summaries instantly, right from your Vyzer account.'} />, tooltip: `Create and download customizable balance sheets and investment summaries directly from your account.` },
    { label: 'Community benchmarking', hoverData: <HoverDataComp image={<CommunitySvg />} title={'Community Benchmarking'} description={'Peek into the investment choices of investors like you and which financial products they trust.  Benchmark your portfolio against real investment experiences. Discover a new level of transparency in the private investment market.'} />, tooltip: `Learn from like-minded investors' experiences, successes, and failures. Discover new investment opportunities.` },
    { label: 'Mobile app', hoverData: <HoverDataComp image={<img src={MobileAppImage}  alt='Mobile App' />} title={'Mobile App'} description={'Manage your wealth on-the-go effortlessly with the Vyzer mobile app.'} />, tooltip: `Control your wealth anywhere with the Vyzer mobile app.` },
    { label: 'VIP syncing service', hoverData: <HoverDataComp image={<img src={VipSyncImage} alt={'VIP Syncing Service'}  />} title={'VIP Syncing Service'} description={'Experience seamless integration across all platforms. With VIP sync, Vyzer ensures your financial data is consistently updated, effortlessly pulling from any account to keep your portfolio current.'} />, tooltip: `A fully managed service where Vyzer connects and updates your bank accounts, investor portals, and other holdings.` },
    //{ label: 'Role management', hoverData: <HoverDataComp image={<RoleManagementSvg />} title={'Role Management'} description={'Easily control access and permissions for your portfolio to invite your financial advisor, CPA, family members, or others.'} />, tooltip: `Your dedicated account manager, supported by our fantastic team, will help you every step of the way.` },
    { label: 'Role management', hoverData: <HoverDataComp image={ <img src={RoleManagementImage} alt='Role Management' />} title={'Role Management'} description={'Easily control access and permissions for your portfolio to invite your financial advisor, CPA, family members, or others.'} />, tooltip: `Your dedicated account manager, supported by our fantastic team, will help you every step of the way.` },

   
  ];

  useEffect(() => {
    billingStore.fetchPricingTable();
    // return () => {}
  }, [billingStore])

  const setSelectedPlan = (plan) => {
    billingStore.setSelectedPlan(plan);
  }

  const handleSelectClick = async (e, columnIndex, isActualTier) => {
    if (isActualTier) return;
    mixpanelTrack('Popup Clicked', {
      click_type: 'CTA click',
      cta_text: e.target.textContent,
      tier: userStore.data?.profile?.tier,
      url: window.location.href,
      selected_plan: plans[columnIndex].title,
      plan_interval: billingStore.selectedPlan,
      type: 'button',
      price: mixpanelPriceBySelectedPlan(columnIndex),
    });
    filtersStore.handleClearFilterSelections();
    if (columnIndex === 0) {
      billingStore.setShowStarterModal(true);
    } else {
      const { url } = (userStore.isDemoOrReadOnlyMode || userStore.isFreeTier || userStore.isFreeUser || userStore.isManuallyFreeTier) ? await billingStore.fetchSubscriptionUrl(columnIndex - 1,window.location.href) : await billingStore.createDeepLink(columnIndex - 1);
      window.location.href = url;
    }
  }

  const handleFaqClick = () => {
    window.open('https://vyzer.co/pricing#pricingFAQSection', '_blank');
  }

  // const handleLearnMoreClick = () => {
  //   onClose?.();
  //   billingStore.setShowPricingTableModal(false);
  //   userStore.setShowTier4FirstTimeExperienceModal(true);
  // }

  const handleBackClick = () => {
    onClose?.();
    if (billingStore.isComparePlansBlack) {
      uiStore.setTier4FirstTimeExperienceIndex(1);
      userStore.setShowTier4FirstTimeExperienceModal(true);
    }
  }

  const handleExploreDemoLinkClick = () => {
    onClose?.();
    userStore.closeTier4FirstTimeExperienceModal();
    userStore.setShowTier4FirstTimeExperienceModal(false);
    removeUrlParam(history,location, 'fo');
  }

  const onScroll = () => {
    if (modalRightPaneContentRef.current) {
      const { scrollTop } = modalRightPaneContentRef.current;
      setIsSticky(scrollTop > 142);
    }
  };

  const handleMouseEnter = (e, rowIndex) => {
    const rect = e.target.getBoundingClientRect();
    setHoveredRowIndex(rowIndex);
    setCalculatedHoverContent({
      top: rect.top + window.scrollY - 170,
      left: rect.left + rect.width * 1.15,
    });
  };

  const mixpanelPriceBySelectedPlan = (selectedPlanIndex) => {
    if (selectedPlanIndex === 0 && !userStore.isFreeTier) return 0;
  
    const selectedPlan = billingStore.selectedPlanObj[selectedPlanIndex - 1];
    const isWithDiscount = selectedPlan.hasOwnProperty('originalPriceAfterDiscount') && selectedPlan.originalPriceAfterDiscount !== selectedPlan.originalPrice;
    const price = (isWithDiscount ? selectedPlan.originalPriceAfterDiscount : selectedPlan.originalPrice) / 100;
    const monthsFactor = billingStore.selectedPlan === 'year' ? 12 : 1;
  
    return price / monthsFactor;
  };

  useTrackEvent('Compare Table Viewed', {
    tier: userStore.data?.profile?.tier,
    url: window.location.href,
    recommended_tier: userStore.data?.profile?.scoreTier,
    onboarding_step: userStore.data?.profile?.onBoardingStep,
    payment_status: userStore.data?.profile?.paymentStatus?.join(),
  })
  useTrackGTMEvent('Compare Table Viewed', {
    tier: userStore.data?.profile?.tier,
    url: window.location.href,
    recommended_tier: userStore.data?.profile?.scoreTier,
    onboarding_step: userStore.data?.profile?.onBoardingStep,
    payment_status: userStore.data?.profile?.paymentStatus?.join(),
  })

  return (
    <BaseModal width={'100vw'} height={'100vh'} customModalWrapperStyles={{ margin: 0, maxWidth: '100vw', maxHeight: uiStore.isPhoneDesktopView ? '100%' : '100vh' }} customBackDropStyle={{ zIndex: '99999999' }} >
      {!billingStore.isPricingTableLoaded ?
        <div style={{ display: 'grid', placeItems: 'center', flex: 1 }}>
          <LogoLoader />
        </div>
        :
        uiStore.isMobileScreenSize ? <MobileComparePlansContent pricingPlans={billingStore.selectedPlanObj} isWithDiscount={billingStore.selectedPlanObj[1].hasOwnProperty('originalPriceAfterDiscount') && billingStore.selectedPlanObj[1].originalPriceAfterDiscount !== billingStore.selectedPlanObj[1].originalPrice} />
          :
          <>
            <ComparePlansModalWrapper ref={comparePlansModalWrapperRef}>
              <ModalLeftPane userStore={userStore} />

              <ModalRightPaneWrapper>
                <ModalRightPaneHeaderWrapper>
                  <ArrowLeftWrapper onClick={handleBackClick}>
                    <ArrowLeftSvg />
                  </ArrowLeftWrapper>
                  Back
                </ModalRightPaneHeaderWrapper>
                {isSticky ? <StickyGridHeadersBackground /> : null}
                <ModalRightPaneContent onScroll={onScroll} ref={modalRightPaneContentRef}>
                  <PlanTextWrapper>
                    <PlanText>Which plan best serves your needs, {userStore.firstNameCapitalize}?</PlanText>
                    <PlanSubText>Navigate your financial future with clarity and confidence.</PlanSubText>
                  </PlanTextWrapper>
                  <PlanSwitchWrapper>
                    <Tooltip overlayInnerStyle={{ borderRadius: '6px', backgroundColor: '#000' }} title={<AnnuallyTooltipComp />} ><PlanSwitchOption isSelected={billingStore.selectedPlan === 'year'} onClick={() => { setSelectedPlan('year') }}>Annual billing / Save 20% 🎉</PlanSwitchOption></Tooltip>
                    <PlanSwitchOption isSelected={billingStore.selectedPlan === 'month'} onClick={() => { setSelectedPlan('month') }}>Monthly billing</PlanSwitchOption>
                  </PlanSwitchWrapper>


                  <GridWrapper isTier1Hidden={userStore.isPayedTier}>
                    <EmptyTableHeaderCell isSticky={isSticky} />

                    {columnHeaders.map((columnHeader, columnIndex) => {
                      const isTier1Hidden = userStore.isPayedTier && columnIndex === 0;
                      const isRecommended = columnIndex === recommendedPlanIndex - 1;
                      return (
                        isRecommended ?
                          <ColumnHeaderCell isSticky={isSticky} isPayedTier={userStore.isPayedTier} key={columnIndex} style={{ display: 'flex', flexDirection: 'column', backgroundColor: billingStore.isComparePlansBlack ? '#000' : '' }}>
                            <RecommendedHeader>Recommended for you{userStore.isRelevantVariant !== 3 ? `,\nbased on your journey` : ``}</RecommendedHeader>
                            <RecommendedCell />
                          </ColumnHeaderCell>
                          :
                          isTier1Hidden ? null :
                            <ColumnHeaderCell isSticky={isSticky} isPayedTier={userStore.isPayedTier} key={columnIndex} isRecommended={isRecommended}>
                            </ColumnHeaderCell>
                      )
                    })}
                    {rowHeaders.map((rowHeader, rowIndex) => (
                      <React.Fragment key={rowIndex}>
                        {rowHeader.sectionDivider ?
                        <SectionDividerCell>{rowHeader.label}</SectionDividerCell>
                        : <RowHeaderCell isHovered={hoveredRowIndex === rowIndex} onMouseEnter={(e) => handleMouseEnter(e, rowIndex)} onMouseLeave={()=>{setHoveredRowIndex(-1)}} isFirst={rowIndex === 0}><div style={{ ...(rowIndex !== 0 ? { maxWidth: '180px' } : {}) }}>{rowHeader.label}</div>{rowIndex !== 0 ?
                        <TooltipContainer>
                          <ModifiedTooltip title={rowHeader.tooltip} overlayStyle={{ zIndex:'999999991' }}>
                            <TooltipIcon />
                          </ModifiedTooltip>
                          </TooltipContainer>
                        : null}
                        {rowIndex !== 0 && hoveredRowIndex === rowIndex && comparePlansModalWrapperRef.current && 
                          ReactDOM.createPortal(
                            <HoverDataContainer rowHeader={rowHeader} calculatedHoverContent={calculatedHoverContent} />,
                            comparePlansModalWrapperRef.current
                          )
                        }
                        </RowHeaderCell>}
                        {columnHeaders.map((columnHeader, columnIndex) => {
                          const isSelected = columnIndex === recommendedPlanIndex - 1;
                          const isActualTier = columnIndex === actualTier && !userStore.isDemoOrReadOnlyMode;
                          const isFirstColumn = columnIndex === (userStore.isPayedTier ? 1 : 0);
                          const isFirstRow = rowIndex === 0;
                          const isLastColumn = columnIndex === columnHeaders.length - 1;
                          const isLastRow = rowIndex === rowHeaders.length - 1;
                          const isRecommended = columnIndex === recommendedPlanIndex - 1;
                          const isTier1Hidden = userStore.isPayedTier && columnIndex === 0;
                          const isUpgradeCTA = !userStore.isDemoOrReadOnlyMode && actualTier !== -1 && actualTier < columnIndex;
                          const isDowngradeCTA = !userStore.isDemoOrReadOnlyMode && actualTier !== -1 && actualTier > columnIndex;
                          const selectPlanButtonText = isActualTier ? 'Current plan' : isUpgradeCTA ? 'Upgrade' : isDowngradeCTA ? 'Downgrade' : 'Select';
                          let isWithDiscount = false;
                          let relevantPricingObj = {};
                          if (columnIndex > 0) {
                            relevantPricingObj = billingStore.selectedPlanObj[columnIndex - 1];
                            isWithDiscount = relevantPricingObj.hasOwnProperty('originalPriceAfterDiscount') && relevantPricingObj.originalPriceAfterDiscount !== relevantPricingObj.originalPrice;
                          }
                          return (

                            isTier1Hidden ? null :
                              <Cell isHovered={!isFirstRow && !rowHeader.sectionDivider && hoveredRowIndex === rowIndex} onMouseEnter={(e) => handleMouseEnter(e, rowIndex)} onMouseLeave={()=>{setHoveredRowIndex(-1)}} key={columnIndex} isLastColumn={isLastColumn} isLastRow={isLastRow} isFirstColumn={isFirstColumn} isSectionDivider={rowHeader.sectionDivider} isFirstRow={isFirstRow} isRecommended={isRecommended}>
                                {!rowHeader.sectionDivider ? planPerks(rowHeader.label, columnIndex, billingStore.selectedPlan, relevantPricingObj, isWithDiscount) : null}
                                {rowIndex === 0 ? <SelectPlanButton isActualTier={isActualTier} isInverted onClick={(e) => { handleSelectClick(e, columnIndex, isActualTier) }} isSelected={isSelected}>
                                  {selectPlanButtonText}
                                </SelectPlanButton> : null}

                                {rowIndex === 0 && isWithDiscount ? <div style={{ position: 'absolute', top: '4px', right: '-4px', zIndex: 2 }}><DiscountLabel text={Math.floor(relevantPricingObj.discountPercentage) + '% OFF'} /></div> : null}
                                {/* {rowIndex === 0 && isLastColumn ? <div style={{ position: 'absolute', top: '4px', right: '-4px', zIndex: 2 }}>
                                  <DiscountLabel text={<><FamilyOfficeSvg size={13} color={'#fff'} /><span>&nbsp;UHNWI&nbsp;{isWithDiscount ? ' ' + relevantPricingObj.discountPercentage + '% OFF' : ''}</span></>} />
                                </div> : null} */}

                                {isRecommended ? <RecommendedCell isLastRow={isLastRow} isLastColumn={isLastColumn} /> : null}
                                {/* {isLastRow && isLastColumn && !billingStore.isComparePlansBlack ? <LearnMoreWrapper onClick={handleLearnMoreClick}>Learn more</LearnMoreWrapper> : null} */}
                              </Cell>
                          )
                        })}
                      </React.Fragment>
                    ))}
                  </GridWrapper>

                  <ModalRightPaneFooter>
                    We are here for you.
                    <CalendlyPopupWidget triggerComponent={<div style={{ textDecoration: 'underline', color: '#1890ff', cursor: 'pointer' }}>Book a call</div>} />
                    or just
                    <div onClick={startIntercomNewMessage} style={{ textDecoration: 'underline', color: '#1890ff', cursor: 'pointer' }}>Chat with us</div>
                  </ModalRightPaneFooter>
                </ModalRightPaneContent>
                <ModalCloseButtonWrapper>
                  {!billingStore.isComparePlansBlack || !userStore.isDemoMode ?
                  <span />
                    // <LearnAboutFamilyOfficeWrapper onClick={handleLearnMoreClick}><FamilyOfficeSvg />Learn more about the Family Office service </LearnAboutFamilyOfficeWrapper>
                    : <ExploreDemoLink onClick={handleExploreDemoLinkClick}>Explore demo portfolio <LinkArrowIconWrapper><LinkArrowIcon />
                    </LinkArrowIconWrapper></ExploreDemoLink>}
                  <FaqWrapper onClick={handleFaqClick}>FAQ’s <LinkArrowIcon /></FaqWrapper>
                </ModalCloseButtonWrapper>
              </ModalRightPaneWrapper>
            </ComparePlansModalWrapper>
          </>}
    </BaseModal>
  )
})

function ModalLeftPane({userStore}) {
  return (
    <ModalLeftPaneWrapper bgImage={userStore.isScoreTier4 ? ExclusiveOfferBg : ComparePlanBg }>
      <VyzerSvgWrapper>
        <VyzerSvg />
      </VyzerSvgWrapper>
      <ConciergeWrapper>
        <ModalLeftPaneTextWrapper>
          <TitleLIlu />
          <ConciergeLeftPaneTitle>Welcome to your Private Investments Concierge</ConciergeLeftPaneTitle>
          <TitleRIlu />
        </ModalLeftPaneTextWrapper>
      </ConciergeWrapper>
    </ModalLeftPaneWrapper>
  )
}

function AnnuallyTooltipComp() {
  return (
    <AnnuallyTooltipWrapper onClick={(e) => { e.stopPropagation() }}>
      <TooltipBoldText>Save 20%</TooltipBoldText> with<br /> annual plan
    </AnnuallyTooltipWrapper>
  )
}

const DiscountLabel = ({ text }) => {
  return (
    <DiscountLabelWrapper>
      <svg width="65" height="17" viewBox="0 0 65 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4579_113453)">
          <path d="M62.9296 0C63.5982 0 64.2227 0.334167 64.5936 0.890516L65.9999 2.99978C65.9999 2.99978 61.88 4.36719 61.88 3C61.88 1.63281 60.9299 0 60.9299 0H62.9296Z" fill="#B37C3B" />
          {/* <path d="M0 8C0 4.68629 2.68629 2 6 2H62.523C63.2304 2 63.7142 1.28547 63.4514 0.628609L63 -0.5L65 0.5V14C65 15.6569 63.6569 17 62 17H0V8Z" fill="#F0BA7A" /> */}
        </g>
      </svg>
      <DiscountLabelText>{text}</DiscountLabelText>
    </DiscountLabelWrapper>
  )
}

const HoverDataComp = ({ image, title, description }) => {
  return (
    <div style={{display:'flex', height:'100%', padding: '20px', flexDirection:'column', alignItems:'center', gap: '14px', borderRadius:'8px', background: colors.lightGray1 }}>
      <div style={{width:'100%'}}>{image}</div>
      <HoverDataCompTitle>{title}</HoverDataCompTitle>
      <HoverDataCompSubTitle>{description}</HoverDataCompSubTitle>

    </div>
  )
}

const HoverDataContainer = ({calculatedHoverContent, rowHeader}) => {
  const hoverContentRef = useRef(null);

  useEffect(() => {
    if (hoverContentRef.current) {
      const rect = hoverContentRef.current.getBoundingClientRect();
      if (rect.top + rect.height > window.innerHeight) {
        hoverContentRef.current.style.top = `${window.innerHeight - rect.height - 24}px`;
      }
    }
  }, [])
  
  return (
    <div
      ref={hoverContentRef}
      style={{
        width: '300px',
        background: 'green',
        zIndex: 999,
        position: 'absolute',
        top: calculatedHoverContent.top,
        right: `calc(76% - 50px)`,
        borderRadius:'10px'
      }}
      >
      {rowHeader.hoverData}
    </div>
  )
}

// const FamilyOfficeSvg = ({ color, size }) => {
//   return (
//     <svg width={size || "20"} height={size || "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <path fillRule="evenodd" clipRule="evenodd" d="M13.1101 8.90597L10 2.76758V2.76767L6.88988 8.90606L3.125 5.22303V13.0802H10V13.0801H16.875V5.22294L13.1101 8.90597Z" fill={color || "#1A1B1D"} />
//       <path fillRule="evenodd" clipRule="evenodd" d="M16.1875 16.1905H3.8125V14.5405H16.1875V16.1905Z" fill={color || "#1A1B1D"} />
//     </svg>
//   )
// }

const ArrowLeftSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 12L18 12M6 12L11 18M6 12L11 6" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}

const VyzerSvg = () => {
  return (
    <svg width="50" height="20" viewBox="0 0 50 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.281693 4.5332H2.46463L5.56074 11.6612L8.65685 4.5332H10.8398L6.29562 15.0024H4.82543L0.28125 4.5332H0.281693Z" fill="white" />
      <path d="M28.2081 6.4268H22.783V4.5332H31.9674L26.1552 13.1088H31.5816V15.0019H22.4141L28.2085 6.42635L28.2081 6.4268Z" fill="white" />
      <path d="M49.7161 4.5332H45.578H43.7743H43.4619V15.0024H45.578V6.4268H48.8923L49.7161 4.5332Z" fill="white" />
      <path d="M13.6825 19.1108H15.9269L22.2713 4.5332H20.0437L17.037 11.4386L13.7621 4.5332H11.4902L15.9676 13.9109L13.6825 19.1108Z" fill="white" />
      <path d="M40.0749 11.5177L40.0466 11.5787C39.5131 12.7224 38.5379 13.3267 37.227 13.3267C35.3582 13.3267 34.1942 11.8667 33.961 10.3647H42.3561L42.357 10.1939C42.3663 8.46446 41.7774 6.91464 40.6983 5.8298C39.7325 4.85867 38.4654 4.32422 37.1297 4.32422C34.2579 4.32422 31.9219 6.76509 31.9219 9.7652C31.9219 12.7653 34.2521 15.1677 37.227 15.1677C39.3241 15.1677 40.954 14.1926 41.8075 12.4627L40.0749 11.5181V11.5177ZM37.1301 6.16472C38.4835 6.16472 39.6905 7.13231 40.1311 8.54366H34.0911C34.4827 7.32875 35.6091 6.16472 37.1301 6.16472Z" fill="white" />
    </svg>
  )
}