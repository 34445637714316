
import { Observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router';
import { ArrowDownIcon, ExternalIcon, MobileBackButton, ModalCloseIcon } from '../../../assets/icons/common/common-icons';
import { useStore } from '../../app/data/root.store';
import { PageWrapper, RightSidePushMenuWrapper } from '../../layout/components/Layout.styles';
import { CardContentBoxWrapper } from '../../wealth/components/wealth.commons.styles';
import { ChangePasswordModal } from './ChangePasswordModal/ChangePasswordModal';
import { ExportAllDataModal } from './ExportAllDataModal/ExportAllDataModal';
import { RadioButtonsWrapper, StyledDatePicker, StyledRadioOption ,ActionsButton, ActionsStickyWrapper, ActionsWrapper, ButtonContentWrapper, DataBoxFormContentWrapper, DataBoxFormWrapper, DataBoxHeader, DataBoxHeaderWrapper, DataBoxInputElement, DataBoxInputLabel, DataBoxInputWrapper, DataBoxSubTitle, DataBoxTitle, HeaderTitle, ProfileDesktopBackLink, ProfileMobileHeader, StyledInput, DataBoxFormTitle, StyledSelect, DataBoxInfoWrapper, InfoBoxWrapper, InfoBoxTitle, InfoBoxDescription, InputWithLabelOnTopWrapper, FloatingLabeInInput, DataBoxPhoneInputWrapper } from './Profile.styles';
import { AppHeader } from '../../layout/components/Header/Header';
import { MobileBackButtonWrapper } from '../../layout/components/MobileLayout.styles';
import { useAnalytics } from '../../app/data/AnalyticsProvider';
import { HeaderWrapper } from '../../layout/components/Header/Header.styles';
import moment from 'moment';
import { countryListAlpha3, countryListPhone } from '../../../common/constants/countries.const';
import { Select } from 'antd';
import { isNullOrUndefined } from '../../../common/utils/object.utils';
import { getProfileFinancialSettings } from '../data/profile.mixpanel-events';
import { ROUTES } from '../../../common/constants/routes.const';
import { PhoneInput } from './PhoneInput/PhoneInput';

const familyStatuses = ['Single', 'Married', 'Divorced', 'Widowed', 'Complicated'];
const kidsOption = [{ val: 1, text: '0-1' }, { val: 2, text: '2-3' }, { val: 3, text: '4+' }];

export function ProfilePage({isPopup , onClosePopup}) {
  const { Option } = Select;
  const [haveChanges, setHaveChanges] = useState(false);
  const [haveInvestorProfileChanges, setHaveInvestorProfileChanges] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const { mixpanelTrack } = useAnalytics()
  const [showExportDataModal, setShowExportDataModal] = useState(false);
  const { userStore, uiStore } = useStore();
  const { isDemoMode: is_demo } = userStore;
  let history = useHistory();
  const [userFirstName, setUserFirstName] = useState(userStore.granteeFirstNameCapitalize);
  const [userLastName, setUserLastName] = useState(userStore.granteeLastName);
  const [userPhoneNumber, setUserPhoneNumber] = useState({value: userStore.phoneNumber, alpha3: userStore.phoneAlpha3});
  const [isPhoneNumberWithError, setIsPhoneNumberWithError] = useState(false);
	const [editedChanges, setEditedChanges] = useState({});
  const isRoleBlocked = userStore.isActionBlockedByRole('updateFinancialSettings');

  useEffect(() => {
    userStore.fetchProfile();
    uiStore.setIsRightMenuOpen(false);
    uiStore.setIsUserMenuActive(true);
    return () => { 
      uiStore.setIsUserMenuActive(false);
    }
  }, [userStore, uiStore]);

  const handleFirstNameChange = ( value) => {
    setUserFirstName(value);
    setHaveChanges(userStore.granteeFirstNameCapitalize !== value);
  }

  const handleLastNameChange = ( value) => {
    setUserLastName(value);
    setHaveChanges(userStore.granteeLastName !== value);
  }

  const handlePhoneChange = (phoneNumber) => {
    setIsPhoneNumberWithError(false);
    setUserPhoneNumber(phoneNumber);
    setHaveChanges(userStore.phoneNumber.value !== phoneNumber.value || userStore.phoneNumber.countryCode !== phoneNumber.countryCode);
  }

  const handleSavePersonalInfo = async () => {
    if (isPopup && !haveChanges){
      return;
    }
    userStore.changeGranteeFullName(userFirstName + ' ' + userLastName);
    const { value: phoneNumber, alpha3 } = userPhoneNumber;
    const phoneNumberWithCountryCode = `${alpha3 ? countryListPhone[alpha3].code + '-' : ''}${phoneNumber}`;
    const isValid = await userStore.updatePhoneNumber(phoneNumberWithCountryCode, alpha3);
    setIsPhoneNumberWithError(!isValid);
    mixpanelTrack('Account Updated', {
      is_demo
    })
    setHaveChanges(false);
  }

  const handleCancelPersonalInfo = () => {
    if (isPopup && !haveChanges){
      return;
    }
    setIsChangingPassword(false);
    setUserFirstName(userStore.granteeFirstNameCapitalize);
    setUserLastName(userStore.granteeLastName);
    setHaveChanges(false);
  }
  
  const handleBackClick = () =>{
    if (userStore.isNoPortfolioSelected){
      window.location.href = ROUTES.portfolios.url;
    } else { 
      uiStore.setIsAppMobileMenuOpen(true) 
    }
  }

  const handleDesktopBackClick = () => {
    if (userStore.isRelevantVariant === 3) {
      history.push(ROUTES.welcome.url);
    } else if (userStore.isNoPortfolioSelected){
      history.push(ROUTES.portfolios.url);
    } else { 
      if ( uiStore.previousRoute === ''){
        history.push(ROUTES.wealth.url);
      } else {
        history.goBack();
      }
    }
  }

  const handleFieldChange = (fieldName, value) => {
		if (isRoleBlocked) {
			return;
		};
		let updatedChanges = {};
		if (userStore.profileData[fieldName] !== value && (!isNullOrUndefined(userStore.profileData[fieldName]) || !isNullOrUndefined(value))) {
			updatedChanges = {
				...editedChanges,
				[fieldName]: value
			}
		} else {
			updatedChanges = { ...editedChanges };
			delete updatedChanges[fieldName];
		}

		const updatedProfileData = {
			...userStore.profileData,
			...updatedChanges
		}
		setEditedChanges(updatedChanges);
		setHaveInvestorProfileChanges(JSON.stringify(updatedProfileData) !== JSON.stringify(userStore.profileData));
		userStore.updateProfile({
			[fieldName]: value
		});
	}

  const handleCancelChanges = () => {

		userStore.cancelProfileUpdates([
      'country',
      'dateOfBirth',
      'familyStatus',
      'kids',
      'zipCode',
    ]);
		setEditedChanges({});
		setHaveInvestorProfileChanges(false);
	}

	const handleSaveChanges = async () => {
		const response = await userStore.saveProfileUpdates([
      'country',
      'dateOfBirth',
      'familyStatus',
      'kids',
      'zipCode',
    ]);
		mixpanelTrack('Account Updated', getProfileFinancialSettings(response, is_demo, kidsOption));
		setEditedChanges({});
		setHaveInvestorProfileChanges(false);
	}

  return <Observer>{() => (<>
    { !isPopup && <>
      {uiStore.isDesktopView ? (
        (userStore.isViewingOwnPortfolio && !userStore.isNoPortfolioSelected) ? <AppHeader /> : 
        <HeaderWrapper displayStyle={uiStore.headerStyle}><ProfileDesktopBackLink onClick={handleDesktopBackClick}><ProfileHeaderBackButton />Back to {userStore.isRelevantVariant === 3 ? 'select plan' : userStore.isNoPortfolioSelected ? 'Shared portfolios' : userStore.fullName}</ProfileDesktopBackLink></HeaderWrapper>  
        ) : 
        <ProfileMobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}>
        <MobileBackButtonWrapper onClick={handleBackClick}><MobileBackButton /></MobileBackButtonWrapper>
        <HeaderTitle>My profile</HeaderTitle></ProfileMobileHeader>}
    </>}
    <RightSidePushMenuWrapper />
    <PageWrapper withHeader={!isPopup} isPopup={isPopup} style={isPopup ? {padding:0} : {}}>
        <div style={{display:'flex', flexDirection:'column', gap:'16px' , ...(isPopup ? {marginBottom:0} : {})}}>
      <CardContentBoxWrapper borderLess={isPopup}  >
      {isPopup && <div style={{position: 'absolute' , width : '20px' , height: '20px' , right: 20 , top: 20, cursor:'pointer'}} onClick={()=>{onClosePopup && onClosePopup()}}><ModalCloseIcon /></div>}
        <DataBoxHeaderWrapper  style={isPopup ? {padding:0} : {}}>
          <DataBoxHeader borderBottom style={isPopup ? {padding:'24px'} : {}}>
          {uiStore.isDesktopView && <DataBoxTitle>Profile</DataBoxTitle>}
            <DataBoxSubTitle>Manage your account preferences</DataBoxSubTitle>
          </DataBoxHeader>
        </DataBoxHeaderWrapper>
        <DataBoxFormContentWrapper>
          <DataBoxFormWrapper>

            <DataBoxInputWrapper>
              <InputWithLabelOnTopWrapper>
                <FloatingLabeInInput>First name</FloatingLabeInInput>
                <StyledInput autoComplete="chrome-off" type='text' name='firstName' value={userFirstName} onChange={(e) => { handleFirstNameChange( e.target.value) }} />
              </InputWithLabelOnTopWrapper>
            </DataBoxInputWrapper>
            <DataBoxInputWrapper>
            <InputWithLabelOnTopWrapper>
                <FloatingLabeInInput>Last name</FloatingLabeInInput>
                <StyledInput autoComplete="chrome-off" type='text' name='lastName' value={userLastName} onChange={(e) => { handleLastNameChange( e.target.value) }} />
            </InputWithLabelOnTopWrapper>
            </DataBoxInputWrapper>

            <DataBoxInputWrapper>
              <DataBoxInputLabel>
                Mobile number
              </DataBoxInputLabel>
              <DataBoxPhoneInputWrapper>
                <PhoneInput
                  userPhoneNumber={userPhoneNumber}
                  handlePhoneChange={handlePhoneChange}
                  customWrapperStyle={{
                    height: '60px', width:'100%', borderRadius: '8px',
                    ...(isPhoneNumberWithError ? {
                      border: '1px solid #ff0000'
                    } : {})
                  }}
                />
              </DataBoxPhoneInputWrapper>
            </DataBoxInputWrapper>

            <DataBoxInputWrapper>
            <InputWithLabelOnTopWrapper>
              <FloatingLabeInInput>
                Email
              </FloatingLabeInInput>
              <StyledInput type='text' name='email' value={userStore.granteeEmail} disabled email={'true'} />
            </InputWithLabelOnTopWrapper>
            </DataBoxInputWrapper>
            <DataBoxInputWrapper>
              <DataBoxInputElement>
                <ActionsButton onClick={() => setIsChangingPassword(true)} >{userStore.data.profile?.isPasswordEmpty ? 'Set' : 'Change'} password</ActionsButton>
                {isChangingPassword && <ChangePasswordModal onClose={() => { setIsChangingPassword(false); userStore.updateData() }} />}
              </DataBoxInputElement>
            </DataBoxInputWrapper>
            {userStore.isGranteeHavePortfolio && !userStore.isQuestionnaire &&
            <DataBoxInputWrapper>
              <DataBoxInputElement>
                <ActionsButton onClick={() => { setShowExportDataModal(true) }}><ButtonContentWrapper><ExternalIcon /> Export all data</ButtonContentWrapper></ActionsButton>
                {showExportDataModal && <ExportAllDataModal onClose={() => { setShowExportDataModal(false) }} />}
              </DataBoxInputElement>
            </DataBoxInputWrapper> }
          </DataBoxFormWrapper>
        </DataBoxFormContentWrapper>
        {(haveChanges || isPopup) &&
          <ActionsWrapper>
            <ActionsStickyWrapper style={isPopup ? {justifyContent:'flex-end' , gap:'16px'} : {}}>
              <ActionsButton borderless onClick={handleCancelPersonalInfo} disabled={isPopup && !haveChanges}>Cancel changes</ActionsButton>
              <ActionsButton black onClick={handleSavePersonalInfo} disabled={isPopup && !haveChanges}>Save</ActionsButton>
            </ActionsStickyWrapper>
          </ActionsWrapper>
        }
      </CardContentBoxWrapper>
      
      {!userStore.isQuestionnaire && <CardContentBoxWrapper>
        <DataBoxFormContentWrapper>
            <DataBoxFormWrapper>
              <DataBoxFormTitle>
                Investor profile
              </DataBoxFormTitle>

              <DataBoxInputWrapper>
                <DataBoxInputLabel>
                  Country
                </DataBoxInputLabel>
                <DataBoxInputElement>
                  <StyledSelect name='country' autoComplete="chrome-off"
                    disabled={isRoleBlocked}
                    value={userStore.profileData.country} showSearch allowClear optionFilterProp='label'
                    onChange={(val) => { handleFieldChange('country', val || '') }}
                    suffixIcon={<ArrowDownIcon />} >
                    {Object.entries(countryListAlpha3).map(([value, label]) => (
                      <Option key={value} value={value} label={label}>{label}</Option>
                    ))}
                  </StyledSelect>
                </DataBoxInputElement>
              </DataBoxInputWrapper>

              <DataBoxInputWrapper>
                <DataBoxInputLabel>
                  Date of birth
                </DataBoxInputLabel>
                <DataBoxInputElement>
                  <StyledDatePicker
                    disabled={isRoleBlocked}
                    value={userStore.profileData?.dateOfBirth ? moment(userStore.profileData?.dateOfBirth) : ''}
                    name='dateOfBirth'
                    onChange={(val) => { handleFieldChange('dateOfBirth', (val ? val.format() : null)) }}
                  />
                </DataBoxInputElement>
              </DataBoxInputWrapper>

              <DataBoxInputWrapper>
                <DataBoxInputLabel>
                  Family status
                </DataBoxInputLabel>
                <DataBoxInputElement>
                  <RadioButtonsWrapper>
                    {familyStatuses.map((fs) => (
                      <StyledRadioOption key={fs} isSelected={userStore.profileData.familyStatus === fs}
                        disabled={isRoleBlocked}
                        onClick={() => { handleFieldChange('familyStatus', userStore.profileData.familyStatus === fs ? null : fs) }}>
                        {fs}
                      </StyledRadioOption>
                    ))}
                  </RadioButtonsWrapper>
                </DataBoxInputElement>
              </DataBoxInputWrapper>

              <DataBoxInputWrapper>
                <DataBoxInputLabel>Kids</DataBoxInputLabel>
                <DataBoxInputElement>
                  <RadioButtonsWrapper>
                    {kidsOption.map((fs) => (
                      <StyledRadioOption key={fs.val} isSelected={userStore.profileData.kids === fs.val}
                        disabled={isRoleBlocked}
                        onClick={() => { handleFieldChange('kids', userStore.profileData.kids === fs.val ? null : fs.val) }}>
                        {fs.text}
                      </StyledRadioOption>
                    ))}
                  </RadioButtonsWrapper>
                </DataBoxInputElement>
              </DataBoxInputWrapper>

              <DataBoxInputWrapper>
                <DataBoxInputLabel>
                  Zip code
                </DataBoxInputLabel>
                <DataBoxInputElement>
                  <StyledInput
                    autoComplete="chrome-off"
                    type='text'
                    name='zip-code'
                    value={userStore.profileData.zipCode}
                    disabled={isRoleBlocked}
                    onChange={(e) => { handleFieldChange('zipCode', e.target.value) }} />
                </DataBoxInputElement>
              </DataBoxInputWrapper>

            </DataBoxFormWrapper>
            <DataBoxInfoWrapper>
              <InfoBoxWrapper>
                <InfoBoxTitle>
                  Why do we ask this?
                </InfoBoxTitle>
                <InfoBoxDescription>
                  This helps us customize Vyzer to your specific needs.
                </InfoBoxDescription>
              </InfoBoxWrapper>
            </DataBoxInfoWrapper>

        </DataBoxFormContentWrapper>
        {haveInvestorProfileChanges &&
          <ActionsWrapper>
            <ActionsStickyWrapper>
              <ActionsButton borderless onClick={handleCancelChanges}>Cancel changes</ActionsButton>
              <ActionsButton black onClick={handleSaveChanges} >Save</ActionsButton>
            </ActionsStickyWrapper>
          </ActionsWrapper>
        }
      </CardContentBoxWrapper>}
      </div>
      <Prompt
        when={haveChanges}
        message={(params) => params.hash !== '' ? true : "Oops... It seems there are some unsaved changes, are you sure you want to leave?"}
      />
    </PageWrapper>
  </>)}
  </Observer>
}

const ProfileHeaderBackButton = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6802 16.0132L10.3468 11.9995L13.6802 7.98584" stroke="black" strokeWidth="1.5"/>
  </svg>
)