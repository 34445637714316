import React, { useState, useCallback, useEffect } from 'react';
import { message } from "antd";
import { usePlaidLink } from 'react-plaid-link';
import { getData, postData } from '../../../../common/utils/network.utils';

export function PlaidLink(props) {
  const { reconnectId, isUpdateAccounts, onSuccess, onDone, onExit, isDisabled, onConnectionStart, checkInstitutionExistence } = props;
  const [publicToken, setPublicToken] = useState('')

  const handleLinkClick = (event) => {
    if (isDisabled) return;
    event.stopPropagation();
    if (!checkInstitutionExistence || !checkInstitutionExistence?.()) {
      openPlaidLink();
    }
  }

  const openPlaidLink = async () => {
    onConnectionStart?.();
    const queryArr = [...(reconnectId ? ['rec=' + reconnectId] : []), ...(isUpdateAccounts ? ['upd=true'] : [])]
    // if (reconnectId) {}
    const res = await getData('connected-institutions/getLinkToken?' + queryArr.join('&'))
    setPublicToken(res.data.token);
  }

  return <>
    {React.cloneElement(props.children, {
      onClick: handleLinkClick
    })}
    {publicToken !== '' && <ConnectToPlaid token={publicToken} reconnectId={reconnectId} onSuccess={onSuccess} onDone={onDone} onExit={onExit} />}
  </>
}


function ConnectToPlaid(props) {
  const { onSuccess, onDone, onExit, reconnectId, connectedInstitutions } = props;
  const onPlaidSuccess = useCallback(async (token, metadata) => {
    if (reconnectId) {
      const data = await postData('connected-institutions/reconnect-success', {
        id: reconnectId
      })
      onSuccess?.(data);
    } else {
      const isExist = (connectedInstitutions || []).find(ci => ci.institutionId === metadata.institution.institution_id);
      if (isExist) {
        message.error("Institution is already connected");
      } else {
        const data = await postData('connected-institutions/connect', {
          publicToken: token,
          institution: metadata.institution
        })
        onSuccess?.(data);
      }
    }

  }, [connectedInstitutions, onSuccess, reconnectId]);

  const onPlaidExit = useCallback(() => {
    // console.log('Done');
    onExit?.();
  }, [onExit]);

  const onPlaidEvent = useCallback((event) => {
    // console.log(event);
    if (event === 'HANDOFF') {
      onDone?.();
    }
  }, [onDone]);

  const plaidConfig = {
    token: props.token,
    onSuccess: onPlaidSuccess,
    onExit: onPlaidExit,
    onEvent: onPlaidEvent
  }

  const { open, ready } = usePlaidLink(plaidConfig);

  useEffect(() => {
    if (ready && open.name !== 'noop') {
      open();
    }
    return () => {
      //cleanup
    }
  }, [ready, open]);

  return (<></>);
}