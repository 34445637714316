import { Select } from 'antd'
import { Observer } from 'mobx-react'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { navigateToUrl } from '../../../../common/utils/navigation.utils'
import { getUrlDisplayName } from '../../../../common/utils/string.utils'
import { useStore } from '../../../app/data/root.store'
import { HeaderSearchBoxWrapper, SearchIconWrapper, SearchPlaceholderWrapper } from './HeaderSearchBox.styles'
import { HeaderSearchBoxEmptyState } from './HeaderSearchBoxEmptyState'
import { HeaderSearchBoxResultRow } from './HeaderSearchBoxResultRow'
import { ReactComponent as SearchResultsIcon } from './icons/search-icon.svg';


export const HeaderSearchBox = (props) => {
    const { metadataStore , wealthStore, filtersStore } = useStore();
    const [inputValue, setInputValue] = useState('');
    const { Option } = Select;
    let history = useHistory();
    const handleItemClick = (item) => {
        if (props.onSelect){
            props.onSelect(item);
        } else {
            filtersStore.handleClearFilterSelections();
            filtersStore.handleSelectClass(item.classId);
            filtersStore.setAppliedFiltersFromSelection();
            wealthStore.refreshWealthData(false,history);
            navigateToUrl(history, `/wealth/assets/${getUrlDisplayName(item.classTitle)}/${item.id}`);
            setInputValue(null);
        }
    }

    const handleSearchResultsFilter = (a, b) => {
        const { title: aTitle } = a.children.props.item;
        const { title: bTitle } = b.children.props.item;
        if (inputValue) {
            const aTitleIndex = aTitle.toLowerCase().indexOf(inputValue.toLowerCase());
            const bTitleIndex = bTitle.toLowerCase().indexOf(inputValue.toLowerCase());
            return aTitleIndex > bTitleIndex ? 1 : -1;
        }
        return aTitle.toLowerCase().localeCompare(bTitle.toLowerCase())
    }

    return (
        <Observer>{() => (
            <HeaderSearchBoxWrapper>
                <Select
                    defaultOpen={props.mobile}
                    // open={true}
                    // allowClear
                    autoFocus={props.mobile}
                    showSearch
                    placeholder={<SearchPlaceholderWrapper>Search your assets...</SearchPlaceholderWrapper>}
                    optionFilterProp='label'
                    suffixIcon={null}
                    style={{ width: '100%' }}
                    dropdownMatchSelectWidth={props.mobile? false : 380}
                    onSelect={(selected, option) => { handleItemClick(option.children.props.item) }}
                    value={null}
                    onSearch={(value) => setInputValue(value)}
                    notFoundContent={<HeaderSearchBoxEmptyState />}
                    dropdownClassName={`${props.mobile ? 'headerSearchBoxDropdown_mobile ': ''}headerSearchBoxDropdown`}
                    filterSort={handleSearchResultsFilter}
                    listHeight={325}
                >
                    {metadataStore.headerSearchItems.map((item, index) => (
                        <Option key={index} value={item.id} label={item.title}>
                            <HeaderSearchBoxResultRow item={item} handleItemClick={handleItemClick} inputValue={inputValue} />
                        </Option>
                    ))}
                </Select>
                <SearchIconWrapper hasSearchInput={inputValue !== ''} ><SearchResultsIcon /></SearchIconWrapper>
            </HeaderSearchBoxWrapper>
        )}</Observer>)
}
