import { LoadingOutlined } from '@ant-design/icons';
import { signInWithPopup } from 'firebase/auth';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { postData } from '../../../../common/utils/network.utils';
import { useStore } from '../../../app/data/root.store';
import { auth, provider } from '../../../user/data/firebase.config';
import { AUTH_STEPS } from '../../../user/data/user.store';
import GoogleLetterSign from '../../assets/google-letter-sign.png';
import { ContinueButton, ExistingEmailSpan, ExistingEmailWrapper, FormMessage, GoogleSignInButton, HorizontalLine, HorizontalSeparatorWrapper, InputLabel, InputWrapper, LoginForm, LoginInput, SignUpStepHeader, SignUpStepSubHeader, SignUpStepWrapper, TermOfUseMessage } from '../LoginPage.styles';
import { useRecaptchaHook } from '../useRecaptchaHook';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { transformEmailForMixpanel } from '../../../../common/utils/string.utils';

export const SignUpStep = observer(() => {
  const { userStore } = useStore();
  const { email } = userStore.authFields;
  const [createRecapcthaToken, onRecaptchaReset, MemoizedRecaptchaComponent] = useRecaptchaHook();
  // eslint-disable-next-line
  const [message, setMessage] = useState('');
  const [isExistingEmail, setIsExistingEmail] = useState(false);
  const [isWaitingForGoogleResponse, setIsWaitingForGoogleResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { mixpanel, trackEvent, mixpanelReset } = useAnalytics();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


  const handleEmailInputChange = (e) => {
    userStore.setAuthFields('email', e.target.value);
  }

  const capitalizeTheFirstLetter = (arrayOfStrings) => {
    return arrayOfStrings.map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' , ');
  }

  const handleContinueWithGoogle = () => {
    
    setIsWaitingForGoogleResponse(true);
    signInWithPopup(auth, provider).then(data => {
      const formData = {
        email: data.user.email,
        token: data.user.accessToken,
      }

      mixpanel.identify(transformEmailForMixpanel(formData.email));

      const urlParams = new URLSearchParams(location.search);
      const paramsToSend = {
        utm_source: urlParams.get('utm_source') || mixpanel.get_property('utm_source'),
        utm_medium: urlParams.get('utm_medium') || mixpanel.get_property('utm_medium'),
        utm_campaign: urlParams.get('utm_campaign') || mixpanel.get_property('utm_campaign'),
        utm_id: urlParams.get('utm_id') || mixpanel.get_property('utm_id'),
        distinct_id: urlParams.get('distinct_id') || mixpanel.get_distinct_id(),
      }
      const queryString = Object.keys(paramsToSend).filter(key => paramsToSend[key]).map(key => key + '=' + paramsToSend[key]).join('&');
      postData("user/google-auth" + (queryString ? ("?" + queryString) : ''), formData)
        .then(data => {
          if (data.success) {
            window.location.reload();
          }
          // console.log(data);
        }, error => {
          mixpanelReset()
          setIsWaitingForGoogleResponse(false);
          console.error(error);
        });
    }, error => {
      mixpanelReset()
      console.log(error);
      setIsWaitingForGoogleResponse(false);
    }).finally(() => {
      trackEvent('CTA Clicked', {
        cta_text: "Continue with Google",
        location: "Main sign up page",
        destination: 'Google authentication page',
        type: "Button",
        url: window.location.href
      })
    })
  }

  const handleContinueClick = async () => {
    trackEvent('CTA Clicked', {
      cta_text: "Continue",
      location: "Main sign up page",
      destination: 'Code verification page',
      type: "Button",
      url: window.location.href
    })
    if (!emailRegex.test(email)) return setMessage("Invalid email");
    setMessage('');
    const token = await createRecapcthaToken();
    const formData = {
      email,
      recaptchaToken: token
    }

    
    mixpanel.identify(transformEmailForMixpanel(email));

    // console.log("MMM?");
    setIsLoading(true);
    postData("user/pre-register", formData)
      .then(data => {
        if (data.success) {
          userStore.setAuthStep(AUTH_STEPS.EMAIL_VERIFICATION_STEP);
        } else {
          setIsExistingEmail(true);
        }
      }, error => {
        mixpanelReset()
        if (error.message) {
          setMessage(Array.isArray(error.message) ? capitalizeTheFirstLetter(error.message) : error.message);
        }
      }).finally(() => {
        onRecaptchaReset && onRecaptchaReset();
        setIsLoading(false);
      });
  }

  return (
    <>
      <SignUpStepWrapper>
        <SignUpStepHeader>Create your account</SignUpStepHeader>
        <SignUpStepSubHeader>Effortlessly track and analyze your diverse investment portfolio.</SignUpStepSubHeader>
        <GoogleSignInButton onClick={handleContinueWithGoogle}>{isWaitingForGoogleResponse ? <LoadingOutlined /> : <><img src={GoogleLetterSign} alt='google sign' /> Continue with Google</>}</GoogleSignInButton>
        <HorizontalSeparatorWrapper><HorizontalLine />Or<HorizontalLine /></HorizontalSeparatorWrapper>
        <LoginForm>
          <InputWrapper>
            <InputLabel>Email</InputLabel>
            <div style={{ position: 'relative' }}>
              <LoginInput onChange={handleEmailInputChange} value={email} type="text" name="email" />
              {isExistingEmail ? <EmailExistsAlertSvg /> : null}
            </div>
            {isExistingEmail ? <ExistingEmailWrapper>This email is already exist. <ExistingEmailSpan onClick={() => { userStore.setAuthStep(AUTH_STEPS.LOG_IN_STEP) }}>Log in your account</ExistingEmailSpan></ExistingEmailWrapper> : null}
          </InputWrapper>
          <FormMessage haveMessage={message !== ''}>
            {message}
          </FormMessage>
        </LoginForm>
        <ContinueButton disabled={!emailRegex.test(email)} onClick={handleContinueClick}>{isLoading ? <LoadingOutlined /> : 'Continue'}</ContinueButton>
        <MemoizedRecaptchaComponent />
      </SignUpStepWrapper>
      <TermOfUseMessage>By signing up you accept our <a href="https://www.notion.so/vyzer/Vyzer-s-Terms-and-Conditions-9ae7be5857e44dbe8d459faf93e1151d" target="_blank" rel="noreferrer" >Terms of Service</a> and <a href="https://www.notion.so/vyzer/Vyzer-s-Privacy-Policy-75421f1467b3410dbde2d27a13d94961" target="_blank" rel="noreferrer">Privacy Policy</a>.</TermOfUseMessage>
    </>
  )
})

const EmailExistsAlertSvg = () => {
  return (
    <svg style={{ position: 'absolute', right: 16, top: '50%', transform: `translateY(-50%)` }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.2698 3.28546C10.1522 3.07086 9.8478 3.07086 9.73022 3.28546L2.53928 16.4096C2.425 16.6182 2.57375 16.8745 2.80906 16.8745H17.1909C17.4263 16.8745 17.575 16.6182 17.4607 16.4096L10.2698 3.28546ZM9.37501 12.2496V8.12463H10.625V12.2496H9.37501ZM10 14.6247C9.58579 14.6247 9.25 14.2889 9.25 13.8747C9.25 13.4605 9.58579 13.1247 10 13.1247C10.4142 13.1247 10.75 13.4605 10.75 13.8747C10.75 14.2889 10.4142 14.6247 10 14.6247Z" fill="#2565E7" />
    </svg>
  )
}