import * as moment from "moment";
import { CURRENCY_SYMBOL } from "../../../../../../../common/constants/currency-symbol.const";
import { floatFixed, displayMoneyValue } from "../../../../../../../common/utils/number-display.utils";
import { calcMonthLeft } from "../../../../../../../common/utils/liabilities.utils";
import { isNullOrUndefinedOrEmptyString } from "../../../../../../../common/utils/object.utils";
import { isNotNegative, isRequired, isRequiredAndNotZero } from "../../../../../../add-item/data/input-validations";
import { eventTypeGenerator } from "./createEventUtils";
import { createLabelFnWithPredictedValue } from "../../Events/PredictedAmountDisplay/PredictedAmountDisplay";

const valueUpdateOnly = eventTypeGenerator(false, false, true);
// const contributionOnly = eventTypeGenerator(true);
// const distributionOnly = eventTypeGenerator(false, true);
// const createNewLoanOnly = eventTypeGenerator(false, false, true);
// const contributionAndValueUpdate = eventTypeGenerator(true, false, true);
// const distributionAndValueUpdate = eventTypeGenerator(false, true, true);
// const newLoanAndValueUpdate = eventTypeGenerator(false, false, true, [newLoanField]);
const amortizationItemCreatedValueUpdate = ({ updateValue }) => ([
  {
    title: '',
    rows: [
      {
        fields: [
          {
            key: 'transactionValue', type: 'numberWithHoldings', label: 'Borrowed amount',
            validations: [isRequired],
            generatePropsFn: (values) => {
              return {
                holdings: values.holdings,
                prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                decimalScale: 2,
              }
            },
            onBlur: ({value, formValues}) => {
              if (!isNullOrUndefinedOrEmptyString(value)) {
                updateValue('updatedValue', value);
              }
            },
          }
        ]
      },
      {
        fields: [
          {
            key: 'updatedValue', type: 'numberWithHoldings', label: 'Loan balance',
            // showPrevComp: true,
            // ClickToShowComponent:({show})=>(<AddValueUpdatePrevButton onClick={show}><PlusOutlined /> Add Change in value</AddValueUpdatePrevButton>),
            disabled: true,
            generatePropsFn: (values) => {
              return {
                prefix: CURRENCY_SYMBOL[values.currency] || '',
                placeholder: CURRENCY_SYMBOL[values.currency] || '',
                holdings: values.holdings,
                decimalScale: 2,
              }
            },
            onBlur: ({value, formValues}) => {
              if (!isNullOrUndefinedOrEmptyString(value)) {
                updateValue('transactionValue', value);
              }
            },
          }
        ]
      }
    ]
  }
])
//eventTypeGenerator(true, false, true);

export const loanAmortizationEventTypeGenerator = (customRows = []) => {
  return ({ updateValue }) => ([
    {
      title: '',
      rows: [
        ...customRows.map(cr => typeof cr === 'function' ? cr({ updateValue }) : cr),
        { fields: [], type: 'separator' },
        { fields: [{ key: 'eventRemarks', type: 'textarea', label: 'Notes', rows: 2, placeholder: 'Add notes to remember' }] }
      ]
    }
  ])
}

const annualInterestRateRow = {
  fields: [
    {
      key: 'interest', type: 'percentage', label: 'Interest rate',
      specificDecimalScale: 3,
      validations: [
        isNotNegative,
        {
          fn: (value, formValues) => {
            let maxValidInterest;
            if (formValues.valueAtDate) {
              maxValidInterest = ((12 * formValues.fixedMonthlyPayment / formValues.valueAtDate) * 100).toFixed(2)
            } else {
              maxValidInterest = calcMonthLeft(formValues.value, formValues.interest / 100 , value) > 0;
            }
            return value < maxValidInterest
          },
          message: 'Required',
          bottomErrorMessage: (value, formValues) => {
            const maxValidInterest = (12 * formValues.fixedMonthlyPayment / formValues.valueAtDate) * 100;
            return `The annual interest rate cannot be higher than ${maxValidInterest.toFixed(3)}%.\nIf you wish to enter a higher interest rate, change the loan terms under the 'Loan info' tab.`
          },
        },
      ],
    },
  ]
};
const annualInterestRateAttr = {
  fieldKey: 'interest',
  dataKey: 'interest',
  getEditValueFn: ({ value }) => floatFixed(value * 100, 3),
  setFnForSave: (isDistribution, value) => (value / 100)
};

const interestPaymentRowFn = ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'interestPayment', type: 'number', description: 'This value is based on the annual interest rate at date',
        disabled: true,
        size: 0.5,
        decimalScale: 2,
        generatePropsFn: (values) => {
          return {
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
            label: `Interest`
          }
        },
        onBlur: ({ value, formValues }) => {
          if (!isNullOrUndefinedOrEmptyString(value) && !isNullOrUndefinedOrEmptyString(formValues.principalPayment)) {
            const trnValue = value + formValues.principalPayment;
            updateValue('transactionValue', trnValue);
          }
        }
      },
      {
        key: 'principalPayment', type: 'number', 
        size: 0.5,
        decimalScale: 2,
        generatePropsFn: (values) => {
          const labelFn = createLabelFnWithPredictedValue('Principal',values.predictedAttributes?.principalPayment,`The expected principal payment`)
          return {
            label: labelFn,
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
            disabled: values.isConnected && moment(values.eventDate).isAfter(moment(), 'M'),
          }
        },
        onBlur: ({ value, formValues }) => {
          if (!isNullOrUndefinedOrEmptyString(value) && !isNullOrUndefinedOrEmptyString(formValues.interestPayment)) {
            const trnValue = value + formValues.interestPayment;
            const newVal = (+formValues.valueAtDate || +formValues.value) - value;
            updateValue('transactionValue', trnValue);
            updateValue('updatedValue', newVal > 0 ? newVal : 0);
            // if(formValues.hasOwnProperty('updatedValue')&& isNullOrUndefinedOrEmptyString(formValues.updatedValue)){
            //     const newVal = ( +formValues.valueAtDate || +formValues.value ) + ( value * (isContribution ? 1 : -1));
            //     updateValue('updatedValue', newVal > 0 ? newVal : 0 );
            // }
            // if (isNullOrUndefinedOrEmptyString(formValues.newNumberOfShares)) {
            //   updateValue('newNumberOfShares', floatFixed(value / formValues.pricePerShare , 2));
            // }
          }
        }
      }
    ]
  }
};

const interestPaymentAttr = { fieldKey: 'interestPayment', dataKey: 'interestPayment' };

// const principalPaymentRowFn = ({updateValue}) => { 
//   return { fields: [

//     {
//       key: 'principalPayment', type: 'number', label: 'Principal',
//       generatePropsFn: (values) => {
//         return {
//           prefix: CURRENCY_SYMBOL[values.currency] || '',
//           placeholder: CURRENCY_SYMBOL[values.currency] || '',
//         }
//       },
//       onBlur:({value,formValues})=>{
//         if (!isNullOrUndefinedOrEmptyString(value) && !isNullOrUndefinedOrEmptyString(formValues.interestPayment)){
//           const trnValue = value + formValues.interestPayment;
//           const newVal = ( +formValues.valueAtDate || +formValues.value ) - value;
//           updateValue('transactionValue', trnValue );
//           updateValue('updatedValue', newVal > 0 ? newVal : 0 );
//           // if(formValues.hasOwnProperty('updatedValue')&& isNullOrUndefinedOrEmptyString(formValues.updatedValue)){
//           //     const newVal = ( +formValues.valueAtDate || +formValues.value ) + ( value * (isContribution ? 1 : -1));
//           //     updateValue('updatedValue', newVal > 0 ? newVal : 0 );
//           // }
//           // if (isNullOrUndefinedOrEmptyString(formValues.newNumberOfShares)) {
//           //   updateValue('newNumberOfShares', floatFixed(value / formValues.pricePerShare , 2));
//           // }
//         }
//         }
//     }
//   ]
//   }
// };

const principalPaymentAttr = { fieldKey: 'principalPayment', dataKey: 'principalPayment' };


const transactionValueFieldFn = (isContribution) => ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'transactionValue',
        type: 'numberWithHoldings',
        description: 'This is the total amount paid in this transaction.',
        defaultValue: null,
        // disabled:true,
        validations: [
          isRequiredAndNotZero,
          {
            fn: (value, formValues) => {
              return formValues.principalPayment <= value && value >= formValues.interestPayment
            },
             message:' ',
            bottomErrorMessage: (value, formValues) => {
              if (value < formValues.interestPayment) {
                const minValidAmount = formValues.interestPayment;
                return `The Amount cannot be lower than ${ displayMoneyValue(minValidAmount, formValues.currency)}.\nIf you wish to enter a lower amount, change the loan terms under the 'Loan info' tab.`
              }
              return `The principal portion of the payment cannot exceed the current loan balance`
            }
          }
        ],
        generatePropsFn: (values) => {
          const labelFn = createLabelFnWithPredictedValue('Amount',values.eventPredictedAmount,`The expected contribution amount`)
          return {
            holdings: values.holdings,
            prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
            decimalScale: 2,
            label: labelFn , //  `Amount (${CURRENCY_SYMBOL[values.currency] + floatFixed(values.transactionValue, 2)})`,
            disabled: values.isConnected && moment(values.eventDate).isSameOrAfter(moment(), 'M'),
          }
        },
        onBlur: ({ value, formValues }) => {
          if (!isNullOrUndefinedOrEmptyString(value) && formValues.interestPayment) {
            const newVal = value > formValues.interestPayment ? value - formValues.interestPayment : 0;
            const newLoanBalance = formValues.valueAtDate - newVal;
         
            updateValue('principalPayment', floatFixed(newVal, 3));
            updateValue('updatedValue', floatFixed(newLoanBalance, 2))
          }
        }
      }
    ]
  }
}


const valueUpdateFieldWithDefaultFn = ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'updatedValue', type: 'numberWithHoldings', label: 'Balance',
        showPrevComp: false,
        defaultValueFn: (values) => values.value,
        onBlur: ({ value, formValues }) => {
          if ( !isNullOrUndefinedOrEmptyString(value) && formValues.valueAtDate && formValues.interestPayment && formValues.valueAtDate >= value) {
            const newPrincipalValue = formValues.valueAtDate - value;
            updateValue('principalPayment', newPrincipalValue);
            updateValue('transactionValue', newPrincipalValue + formValues.interestPayment);
          }
          if(value === 0){
            updateValue('eventTitle','End date');
            if (isNullOrUndefinedOrEmptyString(formValues.eventRemarks)) {
              updateValue('eventRemarks','Last payment 🎉');
            }
          }
        },
        validations: [
          {
            fn: (value, formValues) => {
              return formValues.valueAtDate >= value
            },
             message:' ',
            bottomErrorMessage: (value, formValues) => {
              return `Value can’t be higher than your remaining balance: ${displayMoneyValue(formValues.valueAtDate, formValues.currency)}`
            }
          }
        ],
        generatePropsFn: (values) => {
          return {
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
            holdings: values.holdings,
            decimalScale: 2,
            disabled: values.isConnected && moment(values.eventDate).isSameOrAfter(moment(), 'M'),
          }
        },
      }
   ]
  }
}

const paymentEventDeleteEnableFn = (isItemConnected,isLastPredictedApprovedEvent,isPastEvent) => {
  return !isItemConnected || (isPastEvent && !isLastPredictedApprovedEvent);
}

const paymentEventSaveEnableFn = (hasHappened,isItemConnected,isPastEvent,isLastPredictedApprovedEvent) => {
  return !(isItemConnected && isLastPredictedApprovedEvent) && ( isPastEvent || (!(isItemConnected && !hasHappened)));
}

const paymentBottomMessageFn = (isItemConnected,isLastPredictedApprovedEvent) => {
  if (isItemConnected && isLastPredictedApprovedEvent) {
    return 'This payment was automatically calculated based on your current loan balance and the amortization schedule.\nYou can manually edit events that occurred before this payment.';
  }
  return false;
};


export const loanAmortizationEvents = [
  { title: 'Interest rate', eventType: 'Annual interest rate',
    deleteEnable: ({isFirstOfEventType}) => !isFirstOfEventType,
    sections: loanAmortizationEventTypeGenerator([annualInterestRateRow]), attributesToSend: [annualInterestRateAttr],
    valuesForEditFromItem: [{dataKey:'fixedMonthlyPayment', itemDataKey:'fixedMonthlyPayment'}], hideLinkedTrn: true, isAlwaysVisible: true },
  {
    title: 'Payment', eventType: 'Interest and principal repayment', sections: loanAmortizationEventTypeGenerator([
      transactionValueFieldFn(true),
      interestPaymentRowFn,
      // principalPaymentRowFn,
      valueUpdateFieldWithDefaultFn,
    ]), attributesToSend: [interestPaymentAttr, principalPaymentAttr], valuesForEditFromItem: [{dataKey:'isConnected', itemDataKey:'isConnected'}], isHidden: true, 
    // deleteEnable: false
    sendValueUpdateAnyway: true,
    deleteEnable: ({isItemConnected,isLastPredictedApprovedEvent,isPastEvent}) => paymentEventDeleteEnableFn(isItemConnected,isLastPredictedApprovedEvent,isPastEvent),
    saveEnable: ({hasHappened,isItemConnected,isPastEvent, isLastPredictedApprovedEvent}) => paymentEventSaveEnableFn(hasHappened,isItemConnected,isPastEvent,isLastPredictedApprovedEvent),
    bottomMessage: ({isItemConnected,isLastPredictedApprovedEvent}) => paymentBottomMessageFn(isItemConnected,isLastPredictedApprovedEvent)
  },
  {
    title: 'End date', eventType: 'Interest and principal repayment', sections: loanAmortizationEventTypeGenerator([
      transactionValueFieldFn(true),
      interestPaymentRowFn,
      // principalPaymentRowFn,
      valueUpdateFieldWithDefaultFn,
    ]), attributesToSend: [interestPaymentAttr, principalPaymentAttr], valuesForEditFromItem: [{dataKey:'isConnected', itemDataKey:'isConnected'}], isHidden: true, 
    // deleteEnable: false
    deleteEnable: ({isItemConnected,isLastPredictedApprovedEvent,isPastEvent}) => paymentEventDeleteEnableFn(isItemConnected,isLastPredictedApprovedEvent,isPastEvent),
    saveEnable: ({hasHappened,isItemConnected,isPastEvent, isLastPredictedApprovedEvent}) => paymentEventSaveEnableFn(hasHappened,isItemConnected,isPastEvent,isLastPredictedApprovedEvent)
    
  },
  // { title: 'Sale of shares', description: 'Cash lent', sections: eventTypeGenerator(false, true, true, [pricePerShareField, sharesPurchasedField]), isDistribution: true, attributesToSend: [pricePerShareAttr, sharesPurchasedAttr] },
  // { title: 'Update asset value', description: 'Cash Received', sections: eventTypeGenerator(false, false, true, [pricePerShareField]), attributesToSend: [pricePerShareAttr] },
  // { title: 'Dividend', description: 'Cash Received', sections: distributionOnly, isDistribution: true },
  // { title: 'Annual dividend', isPredicted: true, sections: distributionAndValueUpdate, isDistribution: true },
  { title: 'Value update', isHidden: true, sections: valueUpdateOnly },
  { title: 'Item created', isHidden: true, sections: amortizationItemCreatedValueUpdate, deleteEnable: false },
]

