import { Observer } from 'mobx-react'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { ApprovedIcon, DeleteIcon, LightBulbIcon, PlusIcon } from '../../../assets/icons/common/common-icons'
import { useStore } from '../../app/data/root.store'
import { DocumentRejectedIcon, IllustrationIcon, MailOutlinedIcon, UploadFailIcon, UploadSuccessIcon } from '../assets/icons'
import { MAGIC_BOX_FORM_STEPS } from '../data/magic-box.store'
import { ActionButton, ActionLinkButton, ActionsWrapper, AltTextPrompt, DeleteIconWrapper, DocumentListHeader, DocumentRow, FileDropArea, FileDropAreaFailedWrapper, FileDropAreaWrapper, ForwardEmailsTipText, ForwardEmailsTipWrapper, LighterMainTextPrompt, MainTextPrompt, PlusIconWrapper, PromptWrapper, SectionWrapper, StepWrapper, SuccessMessageWrapper, UploadAnimatedBar, UploadAnimatedBarWrapper } from './MagicBox.styles'
import { useAnalytics } from '../../app/data/AnalyticsProvider'

export const MagicBox = (props) => {

  const { magicBoxStore, userStore } = useStore();
  const { trackEvent } = useAnalytics();

  const addDocuments = useCallback(async (acceptedFiles, fileRejections) => {
    await magicBoxStore.uploadDocuments(acceptedFiles, fileRejections)

    if (!acceptedFiles.length) return;
    for (const file of acceptedFiles) {
      const { type: file_type, name: file_name } = file;
      trackEvent('Document Uploaded', { file_id: `${file_name}-${Date.now()}`, file_type, file_name })
    }

  }, [magicBoxStore, trackEvent]);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (magicBoxStore.isUploading) return;
    addDocuments(acceptedFiles, fileRejections);
  }, [addDocuments, magicBoxStore.isUploading])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxSize: 26214400, accept: ['.pdf', '.csv', '.xls', '.xlsx', '.doc', '.docx', '.ppt', '.pptx', '.jpg', '.jpeg', '.png', '.xlsm'] })

  return (
    <Observer>{() => (
      <StepWrapper>
        <SectionWrapper>
          {magicBoxStore.formStep === MAGIC_BOX_FORM_STEPS.DROP_FILES && <>
            <PromptWrapper>
              <AltTextPrompt>Vyzer does the heavy lifting for you.</AltTextPrompt>
              <MainTextPrompt>Securely convert any financial documents to assets</MainTextPrompt>
            </PromptWrapper>
            <FileDropAreaWrapper {...getRootProps()} isHoverActive={isDragActive}>
              <input {...getInputProps()} disabled={magicBoxStore.isUploading} />
              <FileDropArea>
                <IllustrationIcon />
                {magicBoxStore.isUploading ? <UploadAnimatedBarWrapper><UploadAnimatedBar /></UploadAnimatedBarWrapper> : <AltTextPrompt textSize={'12'}>Click or Drop: Excel file / K-1 / FTC etc.</AltTextPrompt>}
              </FileDropArea>
            </FileDropAreaWrapper>
            <ForwardEmailsTipWrapper>
              <LightBulbIcon />
              <ForwardEmailsTipText>Tip: You can easily forward any financial document to your magic box via email to secure-import@vyzer.co</ForwardEmailsTipText>
            </ForwardEmailsTipWrapper>
          </>}
          {(magicBoxStore.formStep === MAGIC_BOX_FORM_STEPS.DONE_FAILED_FILE_FORMAT || magicBoxStore.formStep === MAGIC_BOX_FORM_STEPS.DONE_FAILED_FILE_SIZE) && <>
            <FileDropAreaFailedWrapper>
              <FileDropArea>
                <UploadFailIcon />
              </FileDropArea>
              <PromptWrapper>
                <MainTextPrompt>Oh no! Upload failed</MainTextPrompt>
                {magicBoxStore.formStep === MAGIC_BOX_FORM_STEPS.DONE_FAILED_FILE_FORMAT && <>
                  <AltTextPrompt>Supported formats:</AltTextPrompt>
                  <LighterMainTextPrompt>PDF, CSV, XLS, XLSX, DOC, DOCX, PPT, PPTX, JPG, PNG</LighterMainTextPrompt>
                </>}
                {magicBoxStore.formStep === MAGIC_BOX_FORM_STEPS.DONE_FAILED_FILE_SIZE &&
                  <LighterMainTextPrompt>File max size: 25MB</LighterMainTextPrompt>
                }
              </PromptWrapper>
              <ActionsWrapper>
                <ActionButton withIcon onClick={() => { magicBoxStore.setFormStep(MAGIC_BOX_FORM_STEPS.DROP_FILES) }}><PlusIconWrapper><PlusIcon /></PlusIconWrapper>New upload</ActionButton>
                <ActionLinkButton withIcon href="mailto:support@vyzer.co"><MailOutlinedIcon />Vyzer support team</ActionLinkButton>
              </ActionsWrapper>
            </FileDropAreaFailedWrapper>
          </>}
          {magicBoxStore.formStep === MAGIC_BOX_FORM_STEPS.DONE_SUCCESS && <>
            <SuccessMessageWrapper noPadding>
              <FileDropArea>
                <UploadSuccessIcon />
              </FileDropArea>

              <PromptWrapper>
                <MainTextPrompt>Great. Vyzer is now creating your assets</MainTextPrompt>
                <LighterMainTextPrompt>It might take a while, once it's completed you'll receive an email.</LighterMainTextPrompt>
              </PromptWrapper>
            </SuccessMessageWrapper>
          </>}
        </SectionWrapper>
        {/* {magicBoxStore.formStep === MAGIC_BOX_FORM_STEPS.DONE_SUCCESS ? */}
          <>
          {magicBoxStore.uploadingFiles.length > 0 ?  <DocumentListHeader>Files ({magicBoxStore.isLoading ? 'Loading...' : magicBoxStore.uploadingFiles?.length})</DocumentListHeader> : null}
            {magicBoxStore.uploadingFiles?.map((document) => (
              <DocumentRow key={document.id} spaceBetween>
                {document.originalName}
                <DeleteIconWrapper>
                  <DeleteIcon onClick={() => { magicBoxStore.deleteFile(document.id) }} />
                </DeleteIconWrapper>
              </DocumentRow>
            ))}
            <SectionWrapper withTopBorder style={{ alignItems: 'center' , position : 'sticky' , bottom: '0' , backgroundColor : '#fff' , padding: '8px 0' }}>
              <ActionButton withIcon onClick={() => { magicBoxStore.approveUploadingFiles(userStore); }}><ApprovedIcon />Done</ActionButton>
            </SectionWrapper>
          </>
          {/* } */}
      </StepWrapper>
    )}
    </Observer>)
}

export const DocumentStatusIcon = (props) => {
  const { status } = props;
  switch (status) {
    case 'Done':
      return <ApprovedIcon />
    case 'Rejected':
      return <DocumentRejectedIcon />
    default:
      return null;
  }
}
