
import { Checkbox, DatePicker, Dropdown, Input, Select, Switch, Tooltip } from "antd";
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { ArrowDownIcon, EditWithOutlineIcon, FieldHistoryIcon, TooltipIcon } from '../../../assets/icons/common/common-icons';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../utils/object.utils';
import { BottomInputErrorMessage, CheckboxWrapper, EditWithOutlineIconWrapper, FieldLabel, FieldLabelAndErrorWrapper, FieldLabelText, FieldLabelToolTipTriggerWrapper, HistoryIconWrapper, InputAndHistoryWrapper, InputErrorMessage, InputRequired, InputSwitcherWrapper, InputWrapper, ToggleLabel, ToggleWrapper } from './FormDisplayer.styles';
import { HistoryDropDown } from './HistoryDropDown';
import { AddressAutocomplete } from './input-types/Address/AddressAutocomplete';
import { AmortizationPeriod } from './input-types/AmortizationPeriod/AmortizationPeriod';
import { BeneficiaryAutocomplete } from './input-types/BeneficiaryAutocomplete';
import { CashAccountSelect } from './input-types/CashAccountsSelect';
import { ConnectedLiabilityInput } from './input-types/ConnectedLiability/ConnectedLiability';
import { CryptoAutoComplete } from './input-types/CryptoAutoComplete/CryptoAutoComplete';
import { CryptoTradingTable } from './input-types/CryptoTradingTable/CryptoTradingTable';
import { InstitutionAutoComplete } from './input-types/InstitutionAutoComplete/InstitutionAutoComplete';
import { NumberInput } from "./input-types/Number/NumberInput";
import { NumberWithHoldings } from './input-types/NumberWithHoldings/NumberWithHoldings';
import { PercentageInput } from './input-types/Percentage/PercentageInput';
import { Radio } from './input-types/Radio/Radio';
import { AttributeScheduleTable } from './input-types/ScheduleTable/ScheduleTable';
import { SelectWithCustomOption } from './input-types/SelectWithCustomOption/SelectWithCustomOption';
import { SelectWithDescriptionInOption } from './input-types/SelectWithDescriptionInOption/SelectWithDescriptionInOption';
import { SharesTradingTable } from './input-types/SharesTradingTable/SharesTradingTable';
import { SingleEventScheduler } from './input-types/SingleEventsScheduler/SingleEventScheduler';
import { DatePickerWithCustomOption } from "./input-types/DatePickerWithCustomOption/DatePickerWithCustomOption";
import { ContainerAutocomplete } from "./input-types/ContainerAutocomplete/ContainerAutocomplete";
import { TickerTradingTable } from "./input-types/TickerTradingTable/TickerTradingTable";
import { YearsPaymentsTable } from "./input-types/YearsPaymentsTable/YearsPaymentsTable";
import { LifeInsuranceBeneficiary } from "./input-types/LifeInsuranceBeneficiary/LifeInsuranceBeneficiary";
import { LifeInsuranceRidersTable } from "./input-types/LifeInsuranceRidersTable/LifeInsuranceRidersTable";
import { AmortizationCollateralAutoComplete } from "./input-types/AmortizationCollateralAutoComplete/AmortizationCollateralAutoComplete";
import { SingleLoanPaymentEventScheduler } from "./input-types/SingleLoanPaymentEventScheduler/SingleLoanPaymentEventScheduler";
import { ExitEventRow } from "./input-types/ExitEventRow/ExitEventRow";
import { SimpleExitEventRow } from "./input-types/SimpleExitEventRow/SimpleExitEventRow";
import { CryptoHoldingsTable } from "./input-types/CryptoHoldingsTable/CryptoHoldingsTable";
import { ModifiedSelectClearIcon } from "../ModifiedSelectClearIcon/ModifiedSelectClearIcon";
import { CustomClassSelect } from "./input-types/CustomClassSelect";
import { CustomSubClassSelect } from "./input-types/CustomSubClassSelect";
import { BeneficiarySelect } from "./input-types/BeneficiarySelect";

export function InputSwitcher(props) {
    const { Option } = Select;
    const { TextArea } = Input;
    const { isFormDisabled, inputKey, isIncognito, isItemConnected, value, handleChange, handleBlur, error, history, scrollContainerId, bottomErrors, isDesktopView } = props;
    let { field } = props;
    const errorFieldRef = useRef(null);

    const isElementPartiallyInViewport = (element) => {
        const rect = element.getBoundingClientRect();
        return (
            rect.top < window.innerHeight &&
            rect.bottom > 0 &&
            rect.left < window.innerWidth &&
            rect.right > 0
        );
    };

    useEffect(() => { // on error => scroll field with error into view
        const errorField = errorFieldRef.current;
        if(error && errorField && !isElementPartiallyInViewport(errorField)) {
            errorField.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }
    }, [error, errorFieldRef])
    

    const handleShowAfterPrevComp = () => {
        props.showComponentAfterPrevComp && props.showComponentAfterPrevComp(inputKey);
    }

    const handleUnlockComponent = () => {
        props.handleUnlockComponent && props.handleUnlockComponent(inputKey);
        // setHasClickedToUnlock(false);
    }

    const handleCancelUnlockComponent = () => {
        props.handleChangeShowUnlockComponent && props.handleChangeShowUnlockComponent(inputKey, false);
    }

    const handleAttributeRowUpdated = (data) => {
        props.attributeHistoryUpdated && props.attributeHistoryUpdated(data);
    }

    const handleAttributeRowDeleted = (itemId, atrKey) => {
        props.attributeHistoryDeleted && props.attributeHistoryDeleted(itemId, atrKey);
    }

    const handleInputWrapperClick = (isLocked) => {
        if (isLocked && !isFormDisabled) {
            props.handleChangeShowUnlockComponent && props.handleChangeShowUnlockComponent(inputKey, true);
        }
    }

    return field ? (field.showPrevComp ? field.ClickToShowComponent({ show: () => { handleShowAfterPrevComp() }, disabled: isFormDisabled || field.disabled }) : (
        <InputSwitcherWrapper ref={errorFieldRef}>
            {field.label &&
                <FieldLabelAndErrorWrapper>
                    <FieldLabel className={"fd-inputLabel"} 
                    {...(field.labelId ? { id: field.labelId } : {})}
                    >
                        <FieldLabelText className={"fd-inputLabel-text"}>{typeof field.label === 'function' ? field.label() : field.label}</FieldLabelText>
                        {field.description && <Tooltip title={field.description} placement="top" {...( field.tooltipOverlayCustomInnerStyle ? {overlayInnerStyle:{...field.tooltipOverlayCustomInnerStyle}} : {})}  ><FieldLabelToolTipTriggerWrapper ><TooltipIcon /></FieldLabelToolTipTriggerWrapper></Tooltip>}
                    </FieldLabel>
                    {error && <InputErrorMessage>{error && error.join(', ')}</InputErrorMessage>}
                    {field.validations && field.validations.length > 0  && field?.validations[0]?.message === "Required" && !error && <InputRequired>*Required</InputRequired>}
                </FieldLabelAndErrorWrapper>
            }

            {field.showUnlockComponent && field.isLocked && field.UnlockComponent({ unlock: () => { handleUnlockComponent() }, cancel: () => { handleCancelUnlockComponent() } })}
            {/* {field.description && <FieldDescription>{field.description}</FieldDescription>} */}
            <InputAndHistoryWrapper>



                <InputWrapper withError={error && error.length} onClick={() => { handleInputWrapperClick(field.isLocked) }}>
                    {field.isLocked && !isFormDisabled && !field.hideEditLockIcon && <EditWithOutlineIconWrapper><EditWithOutlineIcon /></EditWithOutlineIconWrapper>}
                    {
                        (field.type === 'institutionAutoComplete') &&
                        <InstitutionAutoComplete
                            value={value}
                            classId={field.classId}
                            disabled={isFormDisabled || field.disabled}
                            showOnlyManual={field.showOnlyManual}
                            onChange={(value) => { handleChange(inputKey, value) }}
                            scrollContainerId={scrollContainerId}
                            withAutoSyncSuggestion={field.withAutoSyncSuggestion}
                            withAutoSyncSuggestionOnlyForVip={field.withAutoSyncSuggestionOnlyForVip}
                            isDesktopView={isDesktopView}
                        />
                        // <InstitutionAutoComplete scrollContainerId={scrollContainerId} />
                    }
                    {
                        (field.type === 'cryptoAutoComplete') &&
                        <CryptoAutoComplete
                            value={value}
                            disabled={isFormDisabled || field.disabled}
                            classId={field.classId}
                            showOnlyManual={field.showOnlyManual}
                            onChange={(value) => { handleChange(inputKey, value) }}
                            scrollContainerId={scrollContainerId}
                            onBlur={(e) => { handleBlur?.(inputKey) }}
                        />
                    }
                    {
                        (field.type === 'amortizationCollateralAutoComplete') &&
                        <AmortizationCollateralAutoComplete
                            value={value}
                            disabled={isFormDisabled || field.disabled}
                            onChange={(value) => { handleChange(inputKey, value) }}
                            scrollContainerId={scrollContainerId}
                            onBlur={(e) => { handleBlur?.(inputKey) }}
                        />
                    }
                    {
                        (field.type === 'scheduleTable') &&
                        <AttributeScheduleTable onChange={(value) => { handleChange(inputKey, value) }}
                            rowValuePreFix={field.prefix}
                            startDate={field.startDate}
                            value={Array.isArray(value) ? value : Array.isArray(history) ? history.slice().reverse() : []}
                            holdings={field.holdings || 100} 
                            showPercentage={field.showPercentage}
                            isPercentageDisplay={field.isPercentageDisplay}
                            relativeAmount={field.relativeAmount}
                            monthsPerPeriod={field.monthsPerPeriod || 12}
                            period={field.period}
                            disabled={isFormDisabled || field.disabled}
                            customAnnualReturnLabel={field.customAnnualReturnLabel}
                            customAnnualReturnToolTipText={field.customAnnualReturnToolTipText}
                            customColumnsSize={field.customColumnsSize}
                            expectedAnnualPercentage={field.expectedAnnualPercentage}
                            defaultEditedRow={field.defaultEditedRow}
                            />
                    }
                      {
                        (field.type === 'yearsPaymentTable') &&
                        <YearsPaymentsTable onChange={(value) => { handleChange(inputKey, value) }}
                            rowValuePreFix={field.prefix}
                            disabled={isFormDisabled || field.disabled}
                            value={Array.isArray(value) ? value : Array.isArray(history) ? history.slice().reverse() : []}
                            holdings={field.holdings || 100} 
                        />
                    }
                    {
                        (field.type === 'sharesTradingTable') &&
                        <SharesTradingTable onChange={(value) => { handleChange(inputKey, value) }}
                            onBlur={(e) => { handleBlur && handleBlur(inputKey) }}
                            rowValuePreFix={field.prefix}
                            startDate={field.startDate}
                            disabled={isFormDisabled || field.disabled}
                            withError={error && error.length}
                            value={Array.isArray(value) ? value : []}
                            holdings={field.holdings || 100} />
                    }
                    {
                        (field.type === 'tickerTradingTable') &&
                        <TickerTradingTable onChange={(value) => { handleChange(inputKey, value) }}
                            onBlur={(e) => { handleBlur?.(inputKey) }}
                            disabled={isFormDisabled || field.disabled}
                            withError={error && error.length}
                            isDesktopView={isDesktopView}
                            currency={field.currency}
                            value={Array.isArray(value) ? value : []} />
                    }
                    {
                        (field.type === 'cryptoHoldingsTable') &&
                        <CryptoHoldingsTable onChange={(value) => { handleChange(inputKey, value) }}
                            onBlur={(e) => { handleBlur?.(inputKey) }}
                            disabled={isFormDisabled || field.disabled}
                            withError={error && error.length}
                            isDesktopView={isDesktopView}
                            currency={field.currency}
                            value={Array.isArray(value) ? value : []} />
                    }
                
                    {
                        (field.type === 'cryptoTradingTable') &&
                        <CryptoTradingTable onChange={(value) => { handleChange(inputKey, value) }}
                            onBlur={(e) => { handleBlur && handleBlur(inputKey) }}
                            rowValuePreFix={field.prefix}
                            startDate={field.startDate}
                            disabled={isFormDisabled || field.disabled}
                            withError={error && error.length}
                            value={Array.isArray(value) ? value : []}
                            holdings={field.holdings || 100}
                            currency={field.currency} />
                    }
                    {
                        (field.type === 'singleEventScheduler') &&
                        <SingleEventScheduler
                            onChange={(value) => { handleChange(inputKey, value) }}
                            rowValuePreFix={field.prefix}
                            relativeAmount={field.relativeAmount}
                            startDate={field.startDate}
                            disabled={isFormDisabled || field.disabled}
                            showPercentage={field.showPercentage}
                            percentageTooltip={field.percentageTooltip}
                            value={Array.isArray(value) ? value : Array.isArray(history) ? history.slice().reverse() : []}
                            holdings={field.holdings || 100}
                        />
                    }
                    {
                        (field.type === 'singleLoanPaymentEventScheduler') &&
                        <SingleLoanPaymentEventScheduler
                            onChange={(value) => { handleChange(inputKey, value) }}
                            rowValuePreFix={field.prefix}
                            disabled={isFormDisabled || field.disabled}
                            startDate={field.startDate}
                            headerTooltip={field.headerTooltip}
                            value={Array.isArray(value) ? value : Array.isArray(history) ? history.slice().reverse() : []}
                            holdings={field.holdings || 100}
                        />
                    }
                    {
                        (field.type === 'exitEventRow') &&
                        <ExitEventRow
                            onChange={(value) => { handleChange(inputKey, value) }}
                            rowValuePreFix={field.prefix}
                            startDate={field.startDate}
                            headerTooltip={field.headerTooltip}
                            autoCalculateTooltip={field.autoCalculateTooltip}
                            value={value}
                            disabled={isFormDisabled || field.disabled}
                            holdings={field.holdings || 100}
                        />
                    }
                    {
                        (field.type === 'simpleExitEventRow') &&
                        <SimpleExitEventRow
                            onChange={(value) => { handleChange(inputKey, value) }}
                            rowValuePreFix={field.prefix}
                            startDate={field.startDate}
                            headerTooltip={field.headerTooltip}
                            autoCalculateTooltip={field.autoCalculateTooltip}
                            value={value}
                            disabled={isFormDisabled || field.disabled}
                            holdings={field.holdings || 100}
                        />
                    }
                    {
                        (field.type === 'amortizationPeriod') &&
                        <AmortizationPeriod
                            onChange={(value) => { handleChange(inputKey, value) }}
                            value={value}
                            startDate={field.startDate}
                            withError={error}
                            holdings={field.holdings || 100}
                            disabled={isFormDisabled || field.disabled || field.isLocked}
                        />
                    }
                    {
                        (field.type === 'lifeInsuranceBeneficiary') &&
                        <LifeInsuranceBeneficiary
                            onChange={(value) => { handleChange?.(inputKey, value) }}
                            onBlur={(e) => { handleBlur?.(inputKey) }}
                            withError={error && error.length}
                            value={value}
                            disabled={isFormDisabled || field.disabled}
                        />
                    }
                    {
                        (field.type === 'lifeInsuranceRidersTable') &&
                        <LifeInsuranceRidersTable
                            onChange={(value) => { handleChange?.(inputKey, value) }}
                            withError={error && error.length}
                            value={Array.isArray(value) ? value : []}
                            rowValuePreFix={field.prefix}
                            disabled={isFormDisabled || field.disabled}
                        />
                    }
                    {
                        (field.type === 'text') &&
                        <Input type={field.type} name={inputKey}
                            value={value}
                            style={isIncognito && field.shouldBeHiddenIncognito ? { WebkitTextSecurity: 'disc' } : {}}
                            disabled={isFormDisabled || field.disabled || (isItemConnected && field.disableIfConnected)}
                            autoComplete="off"
                            onChange={(e) => { handleChange(inputKey, e.target.value) }}
                            onBlur={(e) => { handleBlur && handleBlur(inputKey) }}
                            placeholder={field.placeholder}
                        // ref={inputRef} 
                        // onFocus={()=>{handleNumberTextFocus(field.type)}} 
                        // onBlur={()=>{handleNumberBlur(field.type)}} 
                        // suffix={<InputCompleteIcon/>}
                        />
                    }
                    {
                        (field.type === 'number') &&
                        <NumberInput
                            value={value}
                            field={field}
                            inputKey={inputKey}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            isIncognito={isIncognito}
                            isItemConnected={isItemConnected}
                            disabled={isFormDisabled || field.disabled}
                        />
                    }
                    {
                        (field.type === 'numberWithHoldings') &&
                        <NumberWithHoldings
                            thousandSeparator={true}
                            customInput={Input}
                            suffix={field.suffix}
                            abssuffix={field.absSuffix}
                            prefix={field.prefix}
                            style={isIncognito && field.shouldBeHiddenIncognito ? { WebkitTextSecurity: 'disc' } : {}}
                            // value={( isNullOrUndefined(value) || value === 0) ? '' : value}
                            value={(isNullOrUndefined(value)) ? '' : value}
                            //  value={value == null ? '' : value} /// == and not === because it will work with undifined as well , think about change to function to check null or undifined.
                            disabled={isFormDisabled || field.disabled || (isItemConnected && field.disableIfConnected)}
                            autoComplete="off"
                            // onValueChange={(values) => {handleChange(inputKey,values.floatValue ? values.floatValue : 0)}}
                            onChange={(value) => { handleChange(inputKey, value) }}
                            onBlur={(e) => { handleBlur && handleBlur(inputKey) }}
                            placeholder={field.placeholder}
                            decimalScale={field.decimalScale}
                            allowNegative={field.allowNegative || false}
                            useHoldingsValue={field.useHoldingsValue}
                            holdings={field.holdings || 100}
                        />
                    }
                    {
                        (field.type === 'percentage') && 
                        <PercentageInput
                            field={field}
                            value={value}
                            inputKey={inputKey}
                            handleChange={handleChange}
                            isItemConnected={isItemConnected}
                            handleBlur={handleBlur}
                            disabled={isFormDisabled || field.disabled}
                        />
                        // <NumberFormat
                        //     thousandSeparator={true}
                        //     suffix={'%'} decimalScale={ field.specificDecimalScale || 2}
                        //     max={100}
                        //     amount={field.amount}
                        //     placeholder={field.placeholder || '%'}
                        //     customInput={Input}
                        //     style={{ cursor: field.isLocked ? 'pointer' : 'default' }}
                        //     value={(isNullOrUndefined(value)) ? '' : value}
                        //     disabled={field.isLocked || field.disabled || (isItemConnected && field.disableIfConnected)}
                        //     autoComplete="off"
                        //     {...(field.validValues ? { isAllowed: field.validValues } : {})}
                        //     // onValueChange={(values) => {handleChange(inputKey,values.floatValue ? values.floatValue : 0)}}
                        //     onValueChange={(values) => {
                        //         // console.log(value,values);
                        //         if ('' + value !== values.value &&
                        //             (!isNullOrUndefined(value) || !isNullOrUndefined(values.floatValue))) {
                        //             handleChange(inputKey, (values.floatValue || values.floatValue === 0) ? values.floatValue : null)
                        //         }
                        //     }}
                        //     onBlur={(e) => { handleBlur && handleBlur(inputKey) }}
                        // />
                    }
                    {
                        field.type === 'textarea' &&
                        <TextArea rows={field.rows || 4} style={{ resize: 'none' }} value={value}
                            disabled={isFormDisabled || field.disabled}
                            onChange={(e) => { handleChange(inputKey, e.target.value) }} placeholder={field.placeholder}
                        />
                    }
                    {
                        field.type === 'beneficiary-autocomplete' &&
                        <BeneficiaryAutocomplete value={value} disabled={isFormDisabled || (isItemConnected && field.disableIfConnected)} handleChange={handleChange} inputKey={inputKey} scrollContainerId={scrollContainerId} />
                    }
                    {
                        field.type === 'container-autocomplete' &&
                        <ContainerAutocomplete value={value} disabled={isFormDisabled || (isItemConnected && field.disableIfConnected)} handleChange={handleChange} inputKey={inputKey} scrollContainerId={scrollContainerId} withHoverAction={field.withHoverAction} />
                    }
                    {
                        field.type === 'default-cashAccount' &&
                        <CashAccountSelect value={value} disabled={isFormDisabled || (isItemConnected && field.disableIfConnected)} handleChange={handleChange} inputKey={inputKey} scrollContainerId={scrollContainerId} />
                    }
                    {
                        field.type === 'connectedLiability' &&
                        <ConnectedLiabilityInput value={value} disabled={isFormDisabled || (isItemConnected && field.disableIfConnected)} handleChange={handleChange} inputKey={inputKey} scrollContainerId={scrollContainerId} />
                    }
                    {
                        field.type === 'address-autocomplete' &&
                        <AddressAutocomplete value={value} disabled={isFormDisabled || field.disabled} handleChange={handleChange} inputKey={inputKey} />
                    }
                    {
                        field.type === 'beneficiary-select' &&
                        <BeneficiarySelect value={value} disabled={isFormDisabled || field.disabled}  defaultValue={field.defaultValue} allowClear={field.allowClear} handleChange={handleChange} inputKey={inputKey} scrollContainerId={scrollContainerId} />
                    }
                    {
                        field.type === 'custom-class' &&
                        <CustomClassSelect value={value} disabled={isFormDisabled || field.disabled}  defaultValue={field.defaultValue} allowClear={field.allowClear} handleChange={handleChange} inputKey={inputKey} scrollContainerId={scrollContainerId} />
                    }
                    {
                        field.type === 'custom-sub-class' &&
                        <CustomSubClassSelect value={value} disabled={isFormDisabled || field.disabled} customClassId={field.customClassId}  defaultValue={field.defaultValue} allowClear={field.allowClear} handleChange={handleChange} inputKey={inputKey} scrollContainerId={scrollContainerId} />
                    }


                    {
                        field.type === 'select' &&
                            (field.withCustomOption ?
                            <SelectWithCustomOption value={value} field={field} showSearch={field.showSearch} allowClear={field.allowClear}
                                disabled={isFormDisabled || (isItemConnected && field.disableIfConnected) || field.disabled}
                                onChange={(val) => { handleChange(inputKey, val || '') }}
                                getPopupContainer={() => scrollContainerId ? (document.getElementById(scrollContainerId) || document.body) : document.body}
                                suffixIcon={<ArrowDownIcon />} />
                            :
                            field.withDescriptionInOption ?
                            <SelectWithDescriptionInOption
                                inputKey={inputKey}
                                disabled={isFormDisabled || field.disabled || field.isLocked}
                                value={value}
                                field={field}
                                onChange={(val) => { handleChange(inputKey, val || '') }}
                                scrollContainerId={scrollContainerId}
                            />
                            :
                            <Select name={inputKey} value={value} showSearch={field.showSearch}
                                allowClear={field.allowClear && !isNullOrUndefinedOrEmptyString(value)}
                                clearIcon={field.allowClear ? <ModifiedSelectClearIcon /> : null}
                                disabled={isFormDisabled || (isItemConnected && field.disableIfConnected) || field.disabled}
                                onChange={(val) => { handleChange(inputKey, val || '') }}
                                className="input_switcher_default_select"
                                dropdownClassName='formDisplayerDefaultSelect'
                                getPopupContainer={() => scrollContainerId ? (document.getElementById(scrollContainerId) || document.body) : document.body}
                                suffixIcon={<ArrowDownIcon className="select_arrow" />}
                                //visible={true}
                            >

                                {field.options.map(fieldOption => (
                                    <Option key={fieldOption.value} value={fieldOption.value} >{fieldOption.label}</Option>
                                ))}
                            </Select>
                        )
                    }
                    {
                        field.type === 'date' &&
                        (field.withCustomOption ? 
                            <DatePickerWithCustomOption 
                                customOptions={field.customOptions}
                                picker="month"  name={inputKey}
                                value={value}
                                inputReadOnly
                                disabled={isFormDisabled || field.disabled || field.isLocked}
                                // value={value ? moment(value) : ''}
                                // onChange={(val) => { handleChange(inputKey, (val ? val.format() : null)) }}
                                onChange={(val) => { handleChange(inputKey, val)}}
                                getPopupContainer={() => scrollContainerId ? (document.getElementById(scrollContainerId) || document.body) : document.body}
                                disabledDate={field.disabledDate} allowClear={field.clearable} format={field.format || 'MM-YYYY'}
                            /> :
                        <DatePicker picker="month" value={value ? moment(value) : ''} name={inputKey}
                            onChange={(val) => { handleChange(inputKey, (val ? val.set('D',15).format() : null)) }}
                            inputReadOnly
                            disabled={isFormDisabled || field.disabled || field.isLocked}
                            getPopupContainer={() => scrollContainerId ? (document.getElementById(scrollContainerId) || document.body) : document.body}
                            disabledDate={field.disabledDate} allowClear={field.clearable} format={field.format || 'MM-YYYY'}
                        />)
                    }
                    {
                        field.type === 'slider' && <div style={{ display: 'flex', margin: '0 -8px' }}>
                            <div style={{ flex: '1', margin: '0 8px' }}>
                                {/* <Slider value={ value } name={inputKey} 
                min={1} max={100} 
                onChange={(val)=>{handleChange(inputKey,val)}}
            /> */}
                            </div>
                            <div style={{ flex: '0.3', margin: '0 8px' }}>
                                <Input type="number" name={inputKey} value={value}
                                    style={isIncognito && field.shouldBeHiddenIncognito ? { WebkitTextSecurity: 'disc' } : {}}
                                    disabled={isFormDisabled || (isItemConnected && field.disableIfConnected)}
                                    onChange={(e) => { handleChange(inputKey, e.target.value) }} />
                            </div>
                        </div>
                    }
                    {
                        field.type === 'checkbox' &&
                        <CheckboxWrapper>
                            <Checkbox type="checkbox" name={inputKey} checked={value} disabled={isFormDisabled || field.disabled || field.isLocked}
                                onChange={(e) => { handleChange(inputKey, e.target.checked) }}>{typeof field.checkboxLabel === 'function' ? field.checkboxLabel() : field.checkboxLabel}</Checkbox>
                            {field.description && <Tooltip title={field.description} placement="top" ><TooltipIcon  /></Tooltip>}
                        </CheckboxWrapper>
                    }
                    {
                        field.type === 'toggle' &&
                        <ToggleWrapper>
                            <Switch name={inputKey} checked={value}
                                disabled={isFormDisabled || field.disabled || field.isLocked}
                                onChange={(checked) => { handleChange(inputKey, checked) }} />
                            <ToggleLabel>{field.checkboxLabel}</ToggleLabel>
                            {field.description && <Tooltip title={field.description} placement="top" ><TooltipIcon  /></Tooltip>}
                        </ToggleWrapper>
                    }
                    {
                        field.type === 'radio' &&
                        <Radio
                            value={value}
                            options={field.options}
                            disabled={isFormDisabled || field.disabled || field.isLocked}
                            onChange={(val) => { handleChange(inputKey, val) }}
                        />
                    }
                    {field.isLocked && !isFormDisabled && <div style={{position: 'absolute' , inset:0 , backgroundColor:'transparent' , cursor:'pointer'}} />}
                </InputWrapper>

                {(!isFormDisabled && field.showHistory && history && history.length > 0) && <HistoryIconWrapper>
                    <Dropdown
                        // arrow={true}
                        overlay={
                            HistoryDropDown({
                                label: field.label,
                                history,
                                atrKey: inputKey,
                                attributeHistoryUpdated: handleAttributeRowUpdated,
                                attributeHistoryDeleted: handleAttributeRowDeleted

                                //setItem: (index)=>{setEventType(index)},
                                //isValueDisabled: item.plaidConnectedAccountId && !item.isLoginRequired
                            })
                        }
                        trigger={['click']} placement="bottomLeft"
                        getPopupContainer={() => scrollContainerId ? (document.getElementById(scrollContainerId) || document.body) : document.body}
                    // getPopupContainer={trigger => trigger.parentNode}
                    >
                        <FieldHistoryIcon />
                    </Dropdown>

                </HistoryIconWrapper>}
            </InputAndHistoryWrapper>
            {/* {error && <InputErrorMessage>{error.join(', ')}</InputErrorMessage>} */}
            {/* <BottomErrorWrapper> */}
            {bottomErrors[inputKey] && <BottomInputErrorMessage>{bottomErrors[inputKey]}</BottomInputErrorMessage>}
            {/* </BottomErrorWrapper> */}
        </InputSwitcherWrapper>
    )) : null;

}