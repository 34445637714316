import { Dropdown } from 'antd';
import { Observer } from 'mobx-react';
import React, { useState } from 'react';
import moment from 'moment';
import { ArrowDownIcon, CheckmarkIcon, TooltipIcon } from '../../../../../../../assets/icons/common/common-icons';
import { MenuItemWrapperWealthPie, MenuWrapperWealthPie } from '../../../../../../../common/components/DropDownMenu/DropDownMenu.styles';
import { ModifiedTooltip } from '../../../../../../../common/components/ModifiedTooltip/ModifiedTooltip';
import { CountAnimation } from '../../../../../../../common/components/count-animation/CountAnimation';
import { colors } from '../../../../../../../common/styles/theme.styles';
import { displayMoneyValue } from '../../../../../../../common/utils/number-display.utils';
import { capitalize } from '../../../../../../../common/utils/string.utils';
import { useStore } from '../../../../../../app/data/root.store';
import { ClassViewStatInfoBoxWrapper, MobileCarouselBoxWrapper, MobileStatBoxWrapper, MobileStatsWrapper, StatsColumn, StatsRow } from '../../../../asset/components/AssetOverviewHeroContent.styles';
import { StatBox, StatContentWrapper, StatDescriptionBig, StatTimeFrameSelectorWrapper, StatValue, ValueChangesButton } from '../../../../asset/components/Overview/OverviewTab.styles';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export function WealthOverviewHeroDataBox(props) {
    const {uiStore} = useStore();
    return (<Observer>{() => (uiStore.isDesktopView ? <WealthOverviewHeroDataBoxDesktop/> : <WealthOverviewHeroDataBoxMobile />)}</Observer>);
}   


function WealthOverviewHeroDataBoxDesktop(props) {
    const {wealthStore, uiStore} = useStore();
    const currentYear = ''+ moment().year()
    const currentYearIndex = wealthStore.overviewHeroInflow.findIndex(item=>''+item.label === currentYear)

    const [selectedTimeFrameKey, setSelectedTimeFrameKey] = useState(currentYearIndex !== -1 ? currentYear: 'total');
    // const wealthPageContentElement = document.querySelector('#wealthPageContent');

    return ( <Observer>{()=>(

        <>
        <StatsRow>
          
            <StatsColumn>
              <ClassViewStatInfoBoxWrapper>
              <StatBox isWealthView={true}>
                <div>
                    <StatDescriptionBig isWealthView>Assets ({wealthStore.totalAssetsCount})</StatDescriptionBig>
                    <StatTimeFrameSelectorWrapper></StatTimeFrameSelectorWrapper>
                </div>
                <StatContentWrapper>
                        <StatValue>
                        {uiStore.isIncognitoMode ? 
                            displayMoneyValue(wealthStore.assetsTotalUsdValue ,'USD',true) : 
                            <CountAnimation currency={'USD'} value={wealthStore.assetsTotalUsdValue } /> }    
                        </StatValue>        
                </StatContentWrapper>
                
                 </StatBox>
              </ClassViewStatInfoBoxWrapper>
            </StatsColumn>
          
            <StatsColumn>
              <ClassViewStatInfoBoxWrapper>
              <StatBox isWealthView={true}>
                <div>
                    <StatDescriptionBig isWealthView>Liabilities ({wealthStore.totalLiabilitiesCount})</StatDescriptionBig>
                    <StatTimeFrameSelectorWrapper></StatTimeFrameSelectorWrapper>
                </div>
                <StatContentWrapper>
                        <StatValue>
                        {uiStore.isIncognitoMode ?   displayMoneyValue(wealthStore.liabilitiesTotalUsdValue ,'USD',true) : 
                         <CountAnimation currency={'USD'} value={wealthStore.liabilitiesTotalUsdValue } /> }
                        </StatValue>        
                </StatContentWrapper>
                
                 </StatBox>
              </ClassViewStatInfoBoxWrapper>
            </StatsColumn>
        </StatsRow>
        <StatsRow>
          
        <StatsColumn>
              <ClassViewStatInfoBoxWrapper isWealthView>
              <StatBox isWealthView>
                <div>
                    <StatDescriptionBig isWealthView>Current cash position</StatDescriptionBig>
                    <StatTimeFrameSelectorWrapper></StatTimeFrameSelectorWrapper>
                </div>
                <StatContentWrapper>
                    <StatValue>
                            {uiStore.isIncognitoMode ? 
                            displayMoneyValue(wealthStore.currentCashPosition ,'USD',true) : 
                        <CountAnimation currency={'USD'} value={wealthStore.currentCashPosition } /> }    
                    </StatValue>        
                </StatContentWrapper>
                
                 </StatBox>
              </ClassViewStatInfoBoxWrapper>
            </StatsColumn>

        <StatsColumn>
        <ClassViewStatInfoBoxWrapper>
              <StatBox isWealthView={true}>
                <div>
                    <StatDescriptionBig isWealthView>Inflow <ModifiedTooltip overlayInnerStyle={{whiteSpace: 'pre-line', width:'276px', lineHeight:'16px'}} title={`The sum of all inflow events recorded in Vyzer.
    Transactions from 'Cash accounts' and 'Credit cards' that aren't linked to any event are excluded from this calculation.`}><TooltipIcon /></ModifiedTooltip></StatDescriptionBig>
                    <StatTimeFrameSelectorWrapper>
                    <Dropdown
                        overlay={
                            TimeFrameDropDown({
                                itemsList: wealthStore.overviewHeroInflow.map(item=>''+item.label).reverse(),
                                selectedKey : selectedTimeFrameKey,
                                selectTimeFrame: (key) => { setSelectedTimeFrameKey(key) }
                            })
                        }
                        getPopupContainer={() => document.getElementById('wealthPageContent')}
                        trigger={['click']} placement="bottomCenter" >
                        <ValueChangesButton borderLess color={colors.gray2} >{capitalize(selectedTimeFrameKey)}<ArrowDownIcon /></ValueChangesButton>
                        {/* <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    Hover me <DownOutlined />
                                </a> */}
                    </Dropdown>             

                    </StatTimeFrameSelectorWrapper>
                </div>
                <StatContentWrapper>
                        { wealthStore.totalDistributions.length ? <StatValue>
                        {uiStore.isIncognitoMode ? displayMoneyValue(wealthStore.overviewHeroInflow.find(i=> ''+i.label === selectedTimeFrameKey).inflow ,'USD',true) : 
                         <CountAnimation currency={'USD'} value={wealthStore.overviewHeroInflow.find(i=> ''+i.label === selectedTimeFrameKey).inflow  } /> }
                        </StatValue> : null }
                </StatContentWrapper>
                
                 </StatBox>
              </ClassViewStatInfoBoxWrapper>
            </StatsColumn>
          
            
        </StatsRow>
        </>
    )}</Observer>)
}   

function WealthOverviewHeroDataBoxMobile(props) {
    const {wealthStore, uiStore} = useStore();

    const currentYear = ''+ moment().year()
    const currentYearIndex = wealthStore.totalDistributions.findIndex(item=>item.label === currentYear)

    const [selectedTimeFrameKey, setSelectedTimeFrameKey] = useState(currentYearIndex !== -1 ? currentYear: 'total');
    // eslint-disable-next-line 
    const [selectedTimeFrameIndex, setSelectedTimeFrameIndex] = useState(currentYearIndex);
    // const wealthPageContentElement = document.querySelector('#wealthPageContent');

    return ( <Observer>{()=>(
        <MobileStatsWrapper>
            {/* <Carousel 
                responsive={{mobile:{breakpoint: {
                    max: 1024,
                    min: 0
                    },
                    items: 2,
                    partialVisibilityGutter: 10}}
                }
                arrows={false}
                // partialVisible
                 centerMode
                infinite
                > */}
            <Slider dots={false} infinite={true} centerMode={true} arrows={false}>
                <MobileCarouselBoxWrapper>
                <MobileStatBoxWrapper padding={16}>
                <div>
                    <StatDescriptionBig isWealthView>Assets ({wealthStore.totalAssetsCount})</StatDescriptionBig>
                    <StatTimeFrameSelectorWrapper></StatTimeFrameSelectorWrapper>
                </div>
                <StatContentWrapper>
                        <StatValue>
                        {uiStore.isIncognitoMode ? 
                            displayMoneyValue(wealthStore.assetsTotalUsdValue ,'USD',true) : 
                            <CountAnimation currency={'USD'} value={wealthStore.assetsTotalUsdValue } /> }    
                        </StatValue>        
                </StatContentWrapper>
                
                 </MobileStatBoxWrapper>
                    {/* <MobileStatBoxWrapper>Div 1</MobileStatBoxWrapper> */}
                </MobileCarouselBoxWrapper>
                <MobileCarouselBoxWrapper>
                         <MobileStatBoxWrapper padding={16}>
                            <div>
                                <StatDescriptionBig isWealthView>Liabilities ({wealthStore.totalLiabilitiesCount})</StatDescriptionBig>
                                <StatTimeFrameSelectorWrapper></StatTimeFrameSelectorWrapper>
                            </div>
                            <StatContentWrapper>
                                    <StatValue>
                                    {uiStore.isIncognitoMode ?   displayMoneyValue(wealthStore.liabilitiesTotalUsdValue ,'USD',true) : 
                                    <CountAnimation currency={'USD'} value={wealthStore.liabilitiesTotalUsdValue } /> }
                                    </StatValue>        
                            </StatContentWrapper>
                
                        </MobileStatBoxWrapper>
                    
                </MobileCarouselBoxWrapper>
                    

                <MobileCarouselBoxWrapper>
                <MobileStatBoxWrapper padding={16}>
                <div>
                    <StatDescriptionBig isWealthView>Current cash position</StatDescriptionBig>
                    <StatTimeFrameSelectorWrapper></StatTimeFrameSelectorWrapper>
                </div>
                <StatContentWrapper>
                    <StatValue>
                            {uiStore.isIncognitoMode ? 
                            displayMoneyValue(wealthStore.currentCashPosition ,'USD',true) : 
                        <CountAnimation currency={'USD'} value={wealthStore.currentCashPosition } /> }    
                    </StatValue>        
                </StatContentWrapper>
                
                 </MobileStatBoxWrapper>
                </MobileCarouselBoxWrapper>

                <MobileCarouselBoxWrapper>
                <MobileStatBoxWrapper padding={16}>
                <div>
                    <StatDescriptionBig isWealthView>Inflow <ModifiedTooltip overlayInnerStyle={{whiteSpace: 'pre-line', width:'276px', lineHeight:'16px'}} title={`The sum of all inflow events recorded in Vyzer.
    Note: Transactions from 'Cash accounts' and 'Credit cards' that aren't linked to any event are excluded from this calculation.`}><TooltipIcon /></ModifiedTooltip></StatDescriptionBig>
                    <StatTimeFrameSelectorWrapper>
                    <Dropdown
                        overlay={
                            TimeFrameDropDown({
                                itemsList: wealthStore.totalDistributions.map(item=>item.label).reverse(),
                                selectedKey : selectedTimeFrameKey,
                                selectTimeFrame: (key,index) => { setSelectedTimeFrameKey(key);setSelectedTimeFrameIndex(index) }
                            })
                        }
                        getPopupContainer={() => document.getElementById('wealthPageContent')}
                        trigger={['click']} placement="bottomCenter" >
                        <ValueChangesButton borderLess color={colors.gray2} >{capitalize(selectedTimeFrameKey)}<ArrowDownIcon /></ValueChangesButton>
                        {/* <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    Hover me <DownOutlined />
                                </a> */}
                    </Dropdown>             

                    </StatTimeFrameSelectorWrapper>
                </div>
                <StatContentWrapper>
                        { wealthStore.totalDistributions.length ? <StatValue>
                        {uiStore.isIncognitoMode ?   displayMoneyValue(wealthStore.totalDistributions.find(i=>i.label === selectedTimeFrameKey).actual ,'USD',true) : 
                         <CountAnimation currency={'USD'} value={wealthStore.totalDistributions.find(i=>i.label === selectedTimeFrameKey).actual  } /> }
                        </StatValue> : null }
                </StatContentWrapper>
                
                 </MobileStatBoxWrapper>
                </MobileCarouselBoxWrapper>

                </Slider>
            {/* </Carousel> */}
        </MobileStatsWrapper>
    )}</Observer>)
}   


function TimeFrameDropDown(props) {
    const { itemsList, selectTimeFrame , selectedKey } = props;
    return (
        <MenuWrapperWealthPie>
            {itemsList && itemsList.map((item, index) => (
                <MenuItemWrapperWealthPie key={index} onClick={() => { selectTimeFrame && selectTimeFrame(item,index) }}>{capitalize(item)} {selectedKey === item && <CheckmarkIcon/>}</MenuItemWrapperWealthPie>
            ))}
        </MenuWrapperWealthPie>
    )
}