
import React , {useState} from 'react';
import {  Select , Input } from "antd";
import NumberFormat from 'react-number-format';
import { CloseOutlined } from '@ant-design/icons';
// import { assetFieldsObj } from '../../../modules/wealth/pages/asset/components/assetFields';
// import { FieldLabel, InputSwitcherWrapper } from './FormDisplayer.styles';
import { Observer} from 'mobx-react';
import { useStore } from '../../../../../modules/app/data/root.store';
import { CloseButtonWrapper, LiabilitiesSelectOptionWrapper, LiabilityOptionText, LiabilityOptionValue, SelectLiabilityWrapper, Wrapper, YesNoButton, YesNoButtonsWrapper } from './ConnectedLiability.styles';
import { displayMoneyValue } from '../../../../utils/number-display.utils';
import { ArrowDownIcon, PlusIcon } from '../../../../../assets/icons/common/common-icons';

export function ConnectedLiabilityInput(props) {
    const { inputKey, handleChange, onStepChanged, disabled, value , scrollContainerId, defaultStep , onCloseBackToStep } = props;

    const [step,setStep] = useState(value?.id ? 1 : value?.name ? 2 : defaultStep || 0);
    const [isNoSelected, setIsNoSelected] = useState(false);
    
    const {metadataStore} = useStore();
    const { Option } = Select;
    
    const handleYesClick = () => {
        if (metadataStore.loans.filter(loan=>!loan.isConnectedToAsset && !loan.isClosed).length > 0) {
            setStep(1);
            onStepChanged && onStepChanged(1);
        } else {
            handleChange(inputKey,{isNew:true});
            setStep(2);
            onStepChanged && onStepChanged(2);
        }
    }

    const handleCreateNew = () => {
        handleChange(inputKey,{isNew:true});
        setStep(2);
        onStepChanged && onStepChanged(2);
    }

    const handleCloseClick = () => {
        if (onCloseBackToStep){
            setStep(1);
            onStepChanged && onStepChanged(1);
        } else {
            setStep(0);
            onStepChanged && onStepChanged(0);
            setIsNoSelected(true);
        }
        handleChange(inputKey,null)
    }
    
    return (<Observer>{()=>(
        <Wrapper>
            {step === 0 && 
            <YesNoButtonsWrapper>
                <YesNoButton style={{marginRight:'16px'}} onClick={()=>{handleYesClick()}}>Yes</YesNoButton>
                <YesNoButton isSelected={isNoSelected} onClick={()=>{setIsNoSelected(true)}}>No</YesNoButton>
            </YesNoButtonsWrapper>
            }
            {
                step === 1 && 
                <SelectLiabilityWrapper>
                    <div style={{flex:1}}>
                    <Select value={value?.id}
                        dropdownStyle={{minWidth:'400px'}}
                        styles={{width:'200px'}}
                        disabled={disabled} onChange={(val)=>{handleChange(inputKey,{id:val})}}
                        getPopupContainer={() => scrollContainerId ? ( document.getElementById(scrollContainerId) || document.body) : document.body}
                        optionLabelProp="label" placeholder="Select a loan" 
                        notFoundContent={<div>No Liabilities</div>}
                        suffixIcon={<ArrowDownIcon/>}
                    >
                        {/* <Option value={null} label={'None'}>
                            <LiabilitiesSelectOptionWrapper>
                                <LiabilityOptionTextValue>None</LiabilityOptionTextValue>
                            </LiabilitiesSelectOptionWrapper>
                            
                        </Option> */}
                        {metadataStore.loans.filter(loan=>!loan.isConnectedToAsset && !loan.isClosed).map(loan=>(
                            <Option key={loan.id} value={loan.id} label={loan.title}>
                                <LiabilitiesSelectOptionWrapper>
                                    <LiabilityOptionValue>{displayMoneyValue(loan.value,loan.currency,false)}</LiabilityOptionValue>
                                    <LiabilityOptionText>{loan.title}</LiabilityOptionText>
                                </LiabilitiesSelectOptionWrapper>
                            </Option>
                        ))}
                    </Select>  
                    </div>
                    <YesNoButton style={{marginLeft:'16px', width:'200px'}} onClick={handleCreateNew}><PlusIcon style={{marginRight:'8px'}}/>Create new</YesNoButton>
                    {!onCloseBackToStep && 
                        <CloseButtonWrapper onClick={handleCloseClick}>
                            <CloseOutlined />
                        </CloseButtonWrapper>
                    }
                </SelectLiabilityWrapper>
            }
              {
                step === 2 && 
                <SelectLiabilityWrapper>
                       <Input value={value?.name} 
                            autoComplete='off'
                            onChange={(e)=>{handleChange(inputKey,{...value,name:e.target.value})}} 
                            placeholder='Name'
                            style={{marginRight:'16px'}}
                        />
                         <NumberFormat 
                    thousandSeparator={true} 
                    customInput={Input}
                    value={value?.amount}
                    autoComplete='off'
                    placeholder='Value'
                    onValueChange={(values) => {handleChange(inputKey,{...value,amount:values.floatValue})}}
            
                    />
                    {/* <div style={{flex:1}}>

                    </div> */}
                    
                    <CloseButtonWrapper onClick={handleCloseClick}>
                        <CloseOutlined />
                    </CloseButtonWrapper>
                </SelectLiabilityWrapper>
            }
        </Wrapper>
        // <Select value={value} disabled={disabled} onChange={(val)=>{handleChange(inputKey,val)}}>
        // <Option value={null} >None</Option>
        //     {metadataStore.cashAccounts.map(ca=>(
        //         <Option key={ca.id} value={ca.id} >{ca.title}</Option>
        //     ))}
        // </Select>   
    )}</Observer>)
}