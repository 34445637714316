import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../app/data/root.store';
import { MagicBoxStep } from './steps/MagicBoxStep';
// import { MeetAdamStep } from './steps/MeetAdamStep';
import { QuickIntroStep } from './steps/QuickIntroStep';
import { SyncCashAccountStep } from './steps/SyncCashAccountStep';
import { StepAnimationWrapper, UserActivationFlowPageWrapper } from './UserActivationFlowPage.styles';
import { LimitationModal } from '../../../common/components/LimitationModal/LimitationModal';
import { UserActivationMobileHeader } from './MobileHeader/UserActivationMobileHeader';

const activationFlowSteps = [
  // MeetAdamStep,
  QuickIntroStep, MagicBoxStep, SyncCashAccountStep];

export const UserActivationFlowPage = observer(() => {
  const { uiStore, billingStore , createItemStore } = useStore();
  const CurrentActivationFlowStep = activationFlowSteps[uiStore.activationStepIndex];
  // const NextActivationFlowStep = activationFlowSteps[uiStore.nextActivationStep];

  useEffect(() => { // enforce desktop view in smaller devices(only after onboarding flow)

    if (uiStore.isMobileView && uiStore.windowWidth < 785 && uiStore.isMobileViewMessageSeen) {
      // const initialScale = (uiStore.windowWidth / 1172).toFixed(5);
      // const viewPostMeta = document.querySelector('meta[name="viewport"]');
      //viewPostMeta.content = 'width=1172, initial-scale=' + initialScale + ', maximum-scale=1';
      uiStore.setIsPhoneDesktopView(true);
    }
    // return () => {}
  }, [uiStore])

  return (
    <><UserActivationFlowPageWrapper>
      {!uiStore.isDesktopView && <UserActivationMobileHeader /> }
      {/* <PageWrapper> */}
        <StepAnimationWrapper isLongStep={uiStore.activationStepIndex>1} isAnimatedOut={uiStore.isActivationStepAnimated === 1} isEnterPosition={uiStore.isActivationStepAnimated === 2}  isAnimatedIn={uiStore.isActivationStepAnimated === 3} isAnimatedBack={uiStore.isAnimatedActivationStepBack}>
          <CurrentActivationFlowStep />
        </StepAnimationWrapper>
      {/* </PageWrapper> */}
    </UserActivationFlowPageWrapper>
    { createItemStore.isShowAddVipSyncLimitModal && <LimitationModal modalType="vipSync" 
        onUpgrade={()=>{billingStore.setShowPricingTableModal(true)}}
        onClose={()=>createItemStore.setShowAddVipSyncLimitModal(false) } />}
    </>
  );
});