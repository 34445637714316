import styled from '@emotion/styled/macro';
import { colors, shadows } from '../../../../common/styles/theme.styles';
import { flexCenter } from '../../../../common/styles/combos.styles';

export const PricingModalWrapper = styled.div`
  display: flex;
  flex:1;
`;

export const LoaderWrapper = styled.div`
  ${flexCenter};
  height:600px;
  padding-top:256px;
`;

export const ModalCloseButtonWrapper = styled.div`
    position:absolute;
    right:12px;
    top:12px;
    width:24px;
    height:24px;
    cursor: pointer;
    svg {
      line {
        stroke: ${colors.darkGray3};
      }
    }
`;

export const ModalLeftPaneWrapper = styled.div`
  background: url('${props => props.bgImage}') no-repeat;
  background-size: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: #fff;
  font-weight: 450;
  font-size: 16px;
  line-height: 138%;
  ${props=>props.isFullPage ? `
  width:24%;
  background-position: right bottom;
  background-color: #101214;
  background-size: contain;
  ` : `
  flex: 2;
  `}
`;

export const PlanTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  white-space: pre-line;
  text-align: center;
  padding-inline :16px;
`;

export const ModalLeftPaneTextWrapper = styled.div`
  display: flex;
  svg {
    flex-shrink: 0;
    path {
      fill: #fff;
    }
  }
`;

export const ConciergeLeftPaneTitle = styled.div`
  font-family: 'Domine';
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  color: ${colors.lightGray2};
  // padding: 0 16px;
  max-width: 400px;
  display: flex;
  align-items: center;
`;

export const ModalRightPaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  position: relative;
  ${props=>props.isFullPage ? `
    justify-content: center;
    align-items: center;
  ` : ``}
`;

export const ModalRightPaneContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding:24px 48px;
  gap:16px;
  ${props=>props.isFullPage ? `
    max-width: 568px;
    max-height: 640px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15), 0px 2px 32px 0px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    margin-top: auto;
    margin-bottom: auto;
  ` : ``}
`;

export const ModalRightPaneFooter = styled.div`
  display: flex;
  flex-direction: ${props => props.isTier3 ? 'column' : 'row'};
  // justify-content: center;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: auto;
  padding-bottom: ${props => props.isTier3 ? '20' : '8'}px;
`;

export const ComparePlansButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  height: 48px;
  cursor:pointer;
  border-radius: 30px;
  box-shadow: 0px 1px 10px rgba(0, 71, 255, 0.15);
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid var(--color-system-simulations, #2565E7);
  background: var(--White, #FFF);
  color: var(--color-system-simulations, #2565E7);
`;

export const ContinueButton = styled(ComparePlansButton)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  height: 48px;
  background-color: #2565E7;
  color: #fff;
`;

export const PlanSwitchWrapper = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: center;
  border: 1px solid #ACB1BB;
  border-radius:100px;
  padding:3px;
  font-weight: 450;
  font-size: 15px;
  line-height: 19px;
  width: ${props => props.isSmaller ? '290' : '364'}px;
  ${props=>props.isCentered ? `align-self:center` : ``};
`;

export const PlanSwitchOption = styled.div`
  font-size:14px;
  font-weight:500;
  cursor: pointer;
  color:${props => props.isSelected ? '#fff' : colors.darkGray2};
  background-color:${props => props.isSelected ? colors.blueAzul : 'none'};
  border-radius:100px;
  padding:6px 18px;
`;

export const AnnuallyTooltipWrapper = styled.div`
  color: ${colors.gray3};
  font-size:14px;
  padding:4px;
  text-align:center;
  // font-weight:400;
`;

export const TooltipBoldText = styled.div`
  font-weight:600;
  display:inline-flex;
`;

export const PlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PlanRowInvisibleBorder = styled.div`
  cursor:  ${props => props.disableRow ? '' : 'pointer'};
  border-radius: 8px;
  border: 1px solid ${props => props.isSelected ? 'transparent' : colors.gray3};
  position: relative;
  ${props => props.isSelected ? `
  ::after {
    content: '';
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    border: 2px solid #2565E7;
    border-radius: 8px;
  }
  ` : ``};
`;

export const PlanRowWrapper = styled.div`
  position: relative;
  display: flex;
  width:  100%;
  border-radius: 8px;
  padding: 18px 16px;
  color: ${props => props.isSelected ? colors.blueAzul : colors.darkGray2}; 
  ${props => props.isSelected ? `background-color: rgba(232, 238, 251, 0.5);` : ``};
  ${props => props.displayRowInGray ? `border: 1px solid var(--Gray-3, #DDE3EB);opacity: 0.4;` : ``};
`;

export const PlanDetailsWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
  align-items: center;
`;

export const RecommendedPlanBanner = styled.div`
  padding: 6px 16px;
  font-weight: 450;
  font-size: 12px;
  line-height: 110.5%;
  
  background-color: ${props => props.isSelected ? colors.blueAzul : '#F1F6FD'};
  color: ${props => props.isSelected ? '#fff' : colors.darkGray1};
  
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const PlanSelectionCircle = styled.div`
  display: grid;
  place-items: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid ${props => props.isSelected ? colors.blueAzul : '#DDE3EB'};
  box-shadow: ${shadows.toggleShadow};
`;

export const PlanSelectionCircleFill = styled.div`
  width: 8px;
  height: 8px;
  background-color: #2565E7;
  border-radius: 50%;
`;

export const PlanTitleWrapper = styled.div`
  font-family: 'Domine';
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${props=>props.isSelected ? colors.blueAzul : colors.darkGray1};
`;

export const PlanPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  color: ${props => props.isSelected ? colors.blueAzul : colors.darkGray2};
`;

export const PlanPriceValue = styled.div`
  font-family: 'Domine';
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: ${props => props.isSelected ? colors.blueAzul : colors.darkGray1};
`;

export const PlanDiscountValue = styled.div`
  font-family: 'Domine';
  font-weight: 450;
  font-size: 12px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-decoration: line-through;
  color: ${props => props.isSelected ? colors.blueAzul : colors.darkGray1};
`;

export const UserInTier2ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.darkGray1};
  padding: 20px 16px;
  ${props=>props.isTier4Content ? `
    padding-top: 48px;
  ` : ``};
`;


export const TitleWithDiscountWrapper = styled.div`
  ${flexCenter};
  justify-content: flex-start;
  gap:6px;
`;
export const UserInTier2BigText = styled.div`
  font-family: 'Domine';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  align-items: center;
  ${props=>props.withDiscount ? `
    font-size: 20px;
    // font-weight: 500;
    text-decoration: line-through;
    line-height: 20px;
    margin-left: 8px;
    padding-top: 6px;
    
  ` : ``}
`;

export const UserInTier2PerkBigText = styled.div`
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const UserInTier2PerkSmallText = styled.div`
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

export const UserInTier2TitleWithPriceWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 32px;
`;

export const PriceDiagonalSeparatorSvgWrapper = styled.div`
  padding-left: 4px;
  padding-right: 8px;
  display: flex;
  align-items: center;
`;

export const PriceDescriptionRow = styled.div`
  font-weight: 450;
  font-size: 13px;
  line-height: 16px;
  color: #2C2E32;
`;

export const UserInTier2PerksWrapper = styled.div`
  padding-top: 16px;
  border-top: 1px solid ${colors.gray3};
  max-width: 360px;
  margin-top: 16px;
  font-size: 13px;
  line-height: 16px;
  color: #2C2E32;
  display: flex;
  flex-direction: column;
  ${props=>props.withGap ? `
    gap:4px;
  ` : ``}
`;

export const UserInTier2Perk = styled.div`
  display: flex;
  gap:8px;
  align-items: center;
  padding-bottom: 8px;
`;

export const UserInTier3ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6px;
  gap:8px;
  font-weight: 450;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: ${colors.darkGray1};
`;

export const UserInTier3ContentPlanTitle = styled.div`
  font-family: 'Domine';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 41px;
  display: flex;
  align-items: center;
  gap:4px;
`;

export const DiscountLabelWrapper = styled.div`
  position:relative;
`;

export const DiscountLabelText = styled.div`
  position: absolute;
  height: 18px;
  background-color: #f0ba7a;
  top: 2px;
  right: 0px;
  padding: 0px 7px 0 9px;
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  border-radius: 6px 0;
  white-space: nowrap;
`;

export const BigDiscountLabeWrapper = styled.div`
  font-family: 'Circular';
  height: ${props => props.height || 20}px;
  background-color: #f0ba7a;
  padding: 0px 8px 0;
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: 15px;
  line-height: ${props => props.lineHeight || 20}px;
  font-weight: 500;
  border-radius: 8px 0;
  white-space: nowrap;
`;

export const StartExploringFreePlanContainer = styled.div`
  color: ${colors.blueAzul};
  text-align: center;
  font-family: Circular;
  font-size: 15px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  cursor: pointer;
  width: fit-content;
  align-self: center;
`;

export const LogoWrapper = styled.a`
  padding:24px;
  width: auto;
  box-shadow: none;
  padding: 20px 4px;
  display: flex;
  pointer-events: auto;
  z-index: 1002;
`;