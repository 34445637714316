import React from 'react'
import moment from 'moment';

// import { CloseOutlined } from '@ant-design/icons';
// import { WizardButton } from '../../add-item/components/AddItem.styles';
import {
  // ArrowRightIconWrapper,
  CancelSection, ItemIconWrapper,
  // NotificationRowActionSection,
  NotificationRowCloseWrapper, NotificationRowSection, NotificationRowWrapper, NotificationEventStatusWrapper,
  NotificationStatusIconWrapper, NotificationTransactionWrapper, NotificationAmountWrapper,
  NotificationTransactionIconWrapper, NotificationDateAndBankWrapper, NotificationDateWrapper, NotificationAcountwrapper,
  NotificationAssetAndEventWrapper, NotificationAssetWrapper, NotificationEventWrapper,
  InvitationAcceptedRoleRow,
  RolesNameIconWrapper,
  InvitationAcceptedRowNameAndMail,
  InvitationAcceptedRowNameText,
  InvitationAcceptedRowEmail
} from './Notifications.styles';

import { useStore } from '../../app/data/root.store';
import { AssetClassIcon } from '../../assets/components/AssetClassIcon';
import { displayMoneyFormatter } from '../../../common/utils/number-display.utils'
import {
  // ArrowDownIcon as ArrowRightIcon,
  CloseXIcon, CalendarIcon1, TransactionIcon, TransactionSmallIcon
} from '../../../assets/icons/common/common-icons';
import { Tooltip } from 'antd';
import { useSwipeable } from 'react-swipeable';
import { capitalize } from '../../../common/utils/string.utils';
import { emailToColor } from '../../../common/styles/theme.styles';

export const NotificationRow = (props) => {
  const { notification, onRead, onSeen, onActionClick, onClose, isMobile } = props;
  const { metadataStore, userStore } = useStore();
  const notificationRelatedClass = notification.itemCategoryId ? metadataStore.classesObj[metadataStore.categoriesObj[notification.itemCategoryId].classId] : null;
  const isEventPassedType = notification.notificationType === 'event passed';
  const isInvitationAcceptedType = notification.notificationType === 'invitation accepted';
  const isTrnToSpecificEventType = notification.notificationType === 'transaction to specific event';

  const handleActionOnClick = () => {
    onActionClick?.();
  }

  const handleCloseOnClick = (event) => {
    event.stopPropagation();
    onRead?.();
    onClose?.();
  }

  const swipeHandlers = useSwipeable(isMobile ? {
    onSwipedLeft: (eventData) => {
      handleCloseOnClick(eventData.event)
    }
  } : {});

  return (
    <NotificationRowWrapper onClick={() => { handleActionOnClick(); onSeen(); }} {...swipeHandlers} >
      <NotificationRowSection>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <NotificationEventStatusWrapper isEventPassedType={isEventPassedType}>
            <NotificationStatusIconWrapper>
              {isInvitationAcceptedType ? <InvitationAcceptedSvg /> : isEventPassedType ? <CalendarIcon1 /> : <TransactionIcon />}
            </NotificationStatusIconWrapper>
            {isInvitationAcceptedType ? 'Invitation accepted' : isEventPassedType ? 'Event is overdue' : 'Pending your approval'}
          </NotificationEventStatusWrapper>
          {isInvitationAcceptedType && <NotificationDateWrapper>{moment(notification.dynamicData.date).format("MMM Do, h:mmA")}</NotificationDateWrapper>}
        </div>

        {isTrnToSpecificEventType && <NotificationTransactionWrapper>
          <NotificationAmountWrapper>
            {notification.dynamicData.transactionValue ? (notification.dynamicData.transactionCurrency ?
              displayMoneyFormatter(Math.abs(notification.dynamicData.transactionValue), notification.dynamicData.transactionCurrency, 2)
              :
              (Math.abs(notification.dynamicData.transactionValue)).toFixed(2)) : null
            }
          </NotificationAmountWrapper>

          <NotificationDateAndBankWrapper >
            <NotificationTransactionIconWrapper><TransactionSmallIcon /></NotificationTransactionIconWrapper>
            <NotificationDateWrapper>{moment(notification.dynamicData.transactionFullDate).format("DD MMM,YY")}</NotificationDateWrapper> |
            <NotificationAcountwrapper>{metadataStore.itemsMeta[notification.dynamicData.transactionAssociatedId]?.title}</NotificationAcountwrapper>
          </NotificationDateAndBankWrapper>
        </NotificationTransactionWrapper>}

        <div style={{ display: 'flex', alignItems: 'center' }}>

          {notificationRelatedClass &&
            <ItemIconWrapper backgroundColor={notificationRelatedClass.color}>
              <AssetClassIcon name={notificationRelatedClass.icon} />
            </ItemIconWrapper>}

          {isInvitationAcceptedType &&
            <InvitationAcceptedRoleRow>

              <RolesNameIconWrapper color={emailToColor(notification.dynamicData.granteeEmail)}>
                {notification.dynamicData.fullName.charAt(0).toUpperCase()}
              </RolesNameIconWrapper>

              <InvitationAcceptedRowNameAndMail>
                <InvitationAcceptedRowNameText>
                  {capitalize(notification.dynamicData.fullName)}
                </InvitationAcceptedRowNameText>
                <InvitationAcceptedRowEmail>
                  {(notification.dynamicData.role.charAt(0).toUpperCase() + notification.dynamicData.role.slice(1).toLowerCase())} · {notification.dynamicData.roleDescription}
                </InvitationAcceptedRowEmail>
              </InvitationAcceptedRowNameAndMail>

            </InvitationAcceptedRoleRow>}

          <NotificationAssetAndEventWrapper>
            {isTrnToSpecificEventType &&
              <NotificationAssetWrapper>{notification.itemTitle}</NotificationAssetWrapper>
            }
            {isEventPassedType &&
              <NotificationAssetWrapper style={{ fontSize: '15px', marginTop: '4px' }}>{notification.itemTitle}</NotificationAssetWrapper>
            }
            {!isInvitationAcceptedType && <NotificationEventWrapper>{notification.dynamicData.description || notification.dynamicData.eventDescription} ({notification.dynamicData.date})</NotificationEventWrapper>}
          </NotificationAssetAndEventWrapper>

        </div>
      </NotificationRowSection >

      {userStore?.isActionBlockedByRole('hideNotification') ? null : <CancelSection>
        <Tooltip title='Hide notification' placement="topRight" arrowPointAtCenter={true}>
          <NotificationRowCloseWrapper onClick={(e) => { handleCloseOnClick(e) }}>

            <CloseXIcon />
          </NotificationRowCloseWrapper>
        </Tooltip>
      </CancelSection>}
    </NotificationRowWrapper>
  )
}

const InvitationAcceptedSvg = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9995 10.9995L0.999513 10.9995L0.999512 4.19951L10.9995 4.19951L10.9995 10.9995Z" stroke="#585D66" strokeWidth="1.40013" strokeLinecap="round" />
      <path d="M5.99929 7.50977L0.619382 4.01001L5.99958 0.999955L11.3694 4.00952L5.99929 7.50977Z" fill="#585D66" />
      <path d="M0.619382 4.01001L0.326431 3.48639L0.292204 4.51296L0.619382 4.01001ZM5.99929 7.50977L5.67211 8.01271L5.99961 8.22576L6.32692 8.01242L5.99929 7.50977ZM11.3694 4.00952L11.697 4.51218L11.6627 3.48612L11.3694 4.00952ZM5.99958 0.999955L6.29293 0.476554L5.70663 0.476333L5.99958 0.999955ZM0.292204 4.51296L5.67211 8.01271L6.32647 7.00682L0.94656 3.50707L0.292204 4.51296ZM6.32692 8.01242L11.697 4.51218L11.0418 3.50687L5.67166 7.00712L6.32692 8.01242ZM11.6627 3.48612L6.29293 0.476554L5.70624 1.52336L11.076 4.53292L11.6627 3.48612ZM5.70663 0.476333L0.326431 3.48639L0.912332 4.53363L6.29253 1.52358L5.70663 0.476333Z" fill="#585D66" />
    </svg>
  )
}