import { Observer } from 'mobx-react'
import React, { useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { ApprovedIcon, DeleteIcon, PlusIcon } from '../../../assets/icons/common/common-icons'
import { CommonBadge } from '../../../common/components/CommonBadge/CommonBadge'
import { LimitationModal } from '../../../common/components/LimitationModal/LimitationModal'
import { PrimaryButtonWithIcon } from '../../../common/components/buttons/NewButtons.styles'
import { useStore } from '../../app/data/root.store'
import { DocumentRejectedIcon, IllustrationIcon, MailOutlinedIcon, UploadFailIcon, UploadIcon } from '../assets/icons'
import { MAGIC_BOX_FORM_STEPS } from '../data/magic-box.store'
import { ActionButton, ActionLinkButton, ActionsWrapper, ActivationFlowFilesWrapper, AltTextPrompt, DeleteIconWrapper, DocumentRow, DocumentStatusBadge, DocumentStatusIconWrapper, FileDropArea, FileDropAreaFailedWrapper, FileDropAreaWrapper, IllustrationIconTextSecondRow, IllustrationIconTextTopRow, IllustrationIconWrapper, LighterMainTextPrompt, MainTextPrompt, PlusIconWrapper, PromptWrapper, SectionWrapper, UploadAnimatedBar, UploadAnimatedBarWrapper } from './MagicBox.styles'
import { CommonTextOverflow } from '../../../common/components/CommonTextOverflow/CommonTextOverflow'
import { useAnalytics } from '../../app/data/AnalyticsProvider'

export const MagicBoxActivationFlow = () => {

  const { magicBoxStore , userStore, billingStore, uiStore } = useStore();
  const { trackEvent } = useAnalytics()

  useEffect(() => {
    magicBoxStore.fetchData();
    return () => {
      magicBoxStore.approveUploadingFiles(userStore, true);
    }
  }, [magicBoxStore,userStore]);

  const addDocuments = useCallback(async (acceptedFiles, fileRejections) => {
    await magicBoxStore.uploadDocuments(acceptedFiles, fileRejections)

    if (!acceptedFiles.length) return;
    for (const file of acceptedFiles) {
      const { type: file_type, name: file_name } = file;
      trackEvent('Document Uploaded', { file_id: `${file_name}-${Date.now()}`, file_type, file_name })
    }

  }, [magicBoxStore, trackEvent]);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (magicBoxStore.isUploading) return;
    if (userStore.userLimitations.magicBox.filesPerMonth !== null && userStore.userLimitations.magicBox.filesPerMonth <= magicBoxStore.uploadThisMonthAmount + magicBoxStore.uploadingFiles.length){
      magicBoxStore.setShowLimitationPopup(true);
    }
    else if (userStore.userLimitations.magicBox.filesPerMonth !== null && userStore.userLimitations.magicBox.filesPerMonth < magicBoxStore.uploadThisMonthAmount + magicBoxStore.uploadingFiles.length + acceptedFiles.length){
      magicBoxStore.setShowFilesLeftLimitationPopup(true, (userStore.userLimitations.magicBox.filesPerMonth - (magicBoxStore.uploadThisMonthAmount + magicBoxStore.uploadingFiles.length)));
    } else {
      addDocuments(acceptedFiles, fileRejections);
    }
  }, [addDocuments,magicBoxStore,userStore])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxSize: 26214400, accept: ['.pdf', '.csv', '.xls', '.xlsx', '.doc', '.docx', '.ppt', '.pptx', '.jpg', '.jpeg', '.png', '.xlsm'] })

  return (
    <Observer>{() => (
      <>
      <div style={{ backgroundColor: '#fff', padding: '12px', borderRadius: '8px', boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 0px 8px rgba(0, 0, 0, 0.08)', display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'scroll' }}>
        <SectionWrapper style={{ padding: '0' }}>
          {(magicBoxStore.formStep === MAGIC_BOX_FORM_STEPS.DONE_FAILED_FILE_FORMAT || magicBoxStore.formStep === MAGIC_BOX_FORM_STEPS.DONE_FAILED_FILE_SIZE) ? <>
            <FileDropAreaFailedWrapper>
              <FileDropArea>
                <UploadFailIcon />
              </FileDropArea>
              <PromptWrapper>
                <MainTextPrompt>Oh no! Upload failed</MainTextPrompt>
                {magicBoxStore.formStep === MAGIC_BOX_FORM_STEPS.DONE_FAILED_FILE_FORMAT && <>
                  <AltTextPrompt>Supported formats:</AltTextPrompt>
                  <LighterMainTextPrompt>PDF, CSV, XLS, XLSX, DOC, DOCX, PPT, PPTX, JPG, PNG</LighterMainTextPrompt>
                </>}
                {magicBoxStore.formStep === MAGIC_BOX_FORM_STEPS.DONE_FAILED_FILE_SIZE &&
                  <LighterMainTextPrompt>File max size: 25MB</LighterMainTextPrompt>
                }
              </PromptWrapper>
              <ActionsWrapper>
                <ActionButton withIcon onClick={() => { magicBoxStore.setFormStep(MAGIC_BOX_FORM_STEPS.DROP_FILES) }}><PlusIconWrapper><PlusIcon /></PlusIconWrapper>New upload</ActionButton>
                <ActionLinkButton withIcon href="mailto:support@vyzer.co"><MailOutlinedIcon />Vyzer support team</ActionLinkButton>
              </ActionsWrapper>
            </FileDropAreaFailedWrapper>
          </> : <FileDropAreaWrapper {...getRootProps()} isHoverActive={isDragActive} isActivationFlow>
            <input {...getInputProps()} disabled={magicBoxStore.isUploading} />
            <FileDropArea>
              {uiStore.isDesktopView && <IllustrationIconWrapper><IllustrationIcon /></IllustrationIconWrapper> }
              {magicBoxStore.isUploading ? <UploadAnimatedBarWrapper><UploadAnimatedBar /></UploadAnimatedBarWrapper> : 
              <>
               {uiStore.isDesktopView && <IllustrationIconTextTopRow>Drag and Drop Here:</IllustrationIconTextTopRow>}
              <IllustrationIconTextSecondRow>Spreadsheet / K-1 / PFS / PPM etc.</IllustrationIconTextSecondRow>
              <PrimaryButtonWithIcon style={{marginTop:'8px'}}><UploadIcon />Upload files</PrimaryButtonWithIcon>
              {/* <ActionButton withIcon ><UploadIcon />Upload files</ActionButton> */}
              </>
              }
            </FileDropArea>
            {userStore.isFreeTier && userStore.userLimitations.magicBox.filesPerMonth !== null && <div style={{position:'absolute' , top: '8px' , left: '8px'}}><CommonBadge noMargin badgeType="limitedFeature" title={`Up to ${userStore.userLimitations.magicBox.filesPerMonth}  file${userStore.userLimitations.magicBox.filesPerMonth > 1 ? 's' : ''} per month`} rounded clickable onClick={(e)=>{ e.stopPropagation(); billingStore.setShowPricingTableModal(true)}} /></div>}
          </FileDropAreaWrapper>}
        </SectionWrapper>
        <ActivationFlowFilesWrapper withPadding={magicBoxStore.uploadingFiles.length > 0 || magicBoxStore.uploadedFiles.length > 0}>
        {magicBoxStore.uploadingFiles?.map((document) => (
          <DocumentRow key={document.id} isActivation >
            {/* {document.originalName} */}
            <CommonTextOverflow text={document.originalName} />
            <DeleteIconWrapper>
              <DeleteIcon onClick={() => { magicBoxStore.deleteFile(document.id) }} />
            </DeleteIconWrapper>
          </DocumentRow>
        ))}
        {magicBoxStore.uploadedFiles?.map((document, ind) => (
          <DocumentRow key={ind} isActivation >
            {/* {document.originalName} */}
            <CommonTextOverflow text={document.originalName} />
            <DocumentStatusBadge status={document.status}>
              <DocumentStatusIconWrapper><DocumentStatusIcon status={document.status} /></DocumentStatusIconWrapper>
              {document.status === 'Processing' || document.status === 'Pending' ? 'In process' : document.status}
            </DocumentStatusBadge>
          </DocumentRow>
        ))}
        </ActivationFlowFilesWrapper>
      </div>
      {magicBoxStore.showLimitationPopup && <LimitationModal modalType="magicBox" 
        paramsForText={[userStore.userLimitations.magicBox.filesPerMonth]}
        onUpgrade={()=>{billingStore.setShowPricingTableModal(true)}}
        onClose={()=>magicBoxStore.setShowLimitationPopup(false) } />}

      {magicBoxStore.filesLeftLimitationPopup.show && <LimitationModal modalType="magicBoxFilesLeft" 
        paramsForText={[magicBoxStore.filesLeftLimitationPopup.sum,userStore.userLimitations.magicBox.filesPerMonth]}
        onUpgrade={()=>{billingStore.setShowPricingTableModal(true)}}
        onClose={()=>magicBoxStore.setShowFilesLeftLimitationPopup(false,0) } />}
      
      </>
      
    )}
    </Observer>)
}

export const DocumentStatusIcon = (props) => {
  const { status } = props;
  switch (status) {
    case 'Done':
      return <ApprovedIcon />
    case 'Rejected':
      return <DocumentRejectedIcon />
    default:
      return null;
  }
}
