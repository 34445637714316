import {
    getData, postData, postFormData, deleteData
} from '../../../common/utils/network.utils';


export const getMagicBoxData = async () => {
    return (await getData("magic-box")).data;
}

export const uploadDocuments = async (formData) => {
    return (await postFormData(`magic-box`,formData))
}

export const deleteDocument = async (documentId) => {
    return (await deleteData(`magic-box/${documentId}`));
}

export const confirmUploadedFiles = async (ids) => {
    return (await postData("magic-box/confirm",{ids})).data;
}
