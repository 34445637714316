import React from 'react'
import { ConciergeRightPaneTitle, ConciergeRightPaneWrapper } from './ConciergeRightPane.styles'
import conciergeAnimData from '../assets/concierge-lottie.json';
import { Player } from '@lottiefiles/react-lottie-player';
import { Observer } from 'mobx-react';
import { useStore } from '../../app/data/root.store';
import { AUTH_STEPS } from '../../user/data/user.store';

export const ConciergeRightPane = () => {
  const { userStore } = useStore()

  return (<Observer>{() => (
    <ConciergeRightPaneWrapper>
      <VyzerTextLogo />
      <ConciergeRightPaneTitle>{userStore.authStep === AUTH_STEPS.SIGN_UP_STEP ? 'Welcome to ' : ''}Your Private Investments Concierge.</ConciergeRightPaneTitle>
      <Player
        loop={true}
        src={conciergeAnimData}
        speed={1}
        autoplay
        style={{ height: 'auto' }}
      />
    </ConciergeRightPaneWrapper>
  )}</Observer>)
}

const VyzerTextLogo = () => {
  return (
    <svg width="68" height="26" viewBox="0 0 68 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.383537 6.16489H3.35233L7.56304 15.8589L11.7737 6.16489H14.7426L8.56247 20.403H6.56302L0.382935 6.16489H0.383537Z" fill="#9097A3" />
      <path d="M38.3635 8.74017H30.9855V6.16489H43.4762L35.5716 17.8277H42.9515V20.4024H30.4836L38.3641 8.73957L38.3635 8.74017Z" fill="#9097A3" />
      <path d="M67.6136 6.16489H61.9858H59.5327H59.1079V20.403H61.9858V8.74017H66.4932L67.6136 6.16489Z" fill="#9097A3" />
      <path d="M18.6085 25.9904H21.6609L30.2894 6.16489H27.2598L23.1706 15.5563L18.7168 6.16489H15.6271L21.7163 18.9186L18.6085 25.9904Z" fill="#9097A3" />
      <path d="M54.5019 15.664L54.4634 15.747C53.7377 17.3024 52.4115 18.1243 50.6287 18.1243C48.0871 18.1243 46.504 16.1387 46.1869 14.0959H57.6042L57.6055 13.8637C57.6181 11.5116 56.8172 9.40386 55.3497 7.92848C54.0362 6.60775 52.3129 5.88089 50.4963 5.88089C46.5907 5.88089 43.4137 9.20048 43.4137 13.2806C43.4137 17.3608 46.5829 20.628 50.6287 20.628C53.4808 20.628 55.6974 19.3019 56.8581 16.9492L54.5019 15.6646V15.664ZM50.4969 8.38397C52.3375 8.38397 53.979 9.69989 54.5783 11.6193H46.3638C46.8964 9.96705 48.4283 8.38397 50.4969 8.38397Z" fill="#9097A3" />
    </svg>
  )
}