import { Observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ActionLocked } from '../../../../../../common/components/ActionLocked/ActionLocked';
import { ActionLockedIconWithTooltip } from '../../../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
import { LogoLoader } from '../../../../../../common/components/LogoLoader/LogoLoader';
import { useStore } from '../../../../../app/data/root.store';
import { DocumentRow } from './DocumentRow';
import { ChooseFilesButton, DocumentsHeaderTableRow, DocumentsTableHeaderCell, DocumentsTableWrapper, DragAreaDescription, DragAreaText, DragDropPromptWrapper, DraggAreaWrapper, LoadingLogoWrapper } from './DocumentTab.styles';
import { MobileAssetDocumentsTab } from './MobileAssetDocumentsTab';


export function AssetDocumentsTab(props) {
  const {uiStore} = useStore();
  return (<Observer>{() => (uiStore.isDesktopView ? <AssetDocumentsTabDesktop/> : <MobileAssetDocumentsTab />)}</Observer>);
}   

export function AssetDocumentsTabDesktop(props) {
  const { documentsStore, userStore, billingStore } = useStore();
  const [editedDocumentIndex, setEditedDocumentIndex] = useState(null);

  const documentsTableColumns = [
    'Name', 'Type', 'Report Year', 'Remarks', 'Actions'
  ]

  const addDocuments = useCallback((acceptedFiles) => {
    documentsStore.uploadDocuments(acceptedFiles)
  }, [documentsStore]);

  const onDrop = useCallback(acceptedFiles => {
    if (documentsStore.isLoading) return;
    // Do something with the files
    if (acceptedFiles.length > 0) {
      if (userStore.isReadOnlyMode) {
        return billingStore.setShowPricingTableModal(true);
      }
      addDocuments(acceptedFiles);
    }
  }, [addDocuments, userStore.isReadOnlyMode, billingStore, documentsStore.isLoading])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxSize: 26214400 })

  const onDocumentDelete = (documentId) => {
    documentsStore.deleteDocument(documentId);
  }
  const onDocumentUpdate = (document) => {
    documentsStore.updateDocument(document);
  }

  const handleEditedIndexChange = (index) => {
    if (userStore.isActionBlockedByRole('itemUploadDocument')) {
      userStore.setShowLimitedSharedAccess(true);
    } else {
      setEditedDocumentIndex(index);
    }
  }

  return (
    <Observer>{() => (
      <div className="asset-documents-tab-wrapper">
        <ActionLocked action={'itemUploadDocument'}>
          <DraggAreaWrapper {...getRootProps()} >
            <input {...getInputProps()} />
            {documentsStore.isLoading ? <LoadingLogoWrapper><LogoLoader wbg style={{ marginTop: '-16px' }} /></LoadingLogoWrapper> :
              <DragDropPromptWrapper>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
                  <UploadSvg />
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px' }}>
                    <DragAreaText>Drag and Drop Here {userStore.isReadOnlyMode && <ActionLockedIconWithTooltip />}</DragAreaText>
                    <DragAreaDescription>Upload documents related to this asset</DragAreaDescription>
                  </div>
                </div>
                <ChooseFilesButton>Choose files {userStore.isActionBlockedByRole('itemUploadDocument') && <ActionLockedIconWithTooltip iconColor={'#DDE3EB'} isRoleBlocked={true} />}</ChooseFilesButton>
              </DragDropPromptWrapper>
            }
          </DraggAreaWrapper>
        </ActionLocked>
        <DocumentsTableWrapper>
          {documentsStore.sortedDocuments?.length ? <DocumentsHeaderTableRow>
            {documentsTableColumns.map((columnLabel, ind) => (
              <DocumentsTableHeaderCell key={'hc1' + ind}>
                <span>{columnLabel}</span>
              </DocumentsTableHeaderCell>
            ))}
          </DocumentsHeaderTableRow> : null}

          {documentsStore.sortedDocuments.map((document, index) => (
            <DocumentRow document={document} key={document.id}
              rowUpdated={onDocumentUpdate}
              rowDeleted={onDocumentDelete}
              documentIndex={index}
              editedIndex={editedDocumentIndex}
              onEditedIndexChange={handleEditedIndexChange}
              isRoleBlocked={userStore.isActionBlockedByRole('itemUploadDocument')}
            />
          ))}
        </DocumentsTableWrapper>
      </div>
    )}</Observer>)
}

const UploadSvg = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.56443 23.2647L7.45257 23.2595C4.97444 23.1433 3 21.0959 3 18.5882C3 16.0055 5.09373 13.9118 7.67647 13.9118C7.75669 13.9118 7.83633 13.9138 7.91536 13.9177L9.59922 14.002L9.48701 12.3198C9.47608 12.156 9.47052 11.9905 9.47052 11.8235C9.47052 7.77886 12.7494 4.5 16.7941 4.5C20.5314 4.5 23.6168 7.30078 24.0622 10.9172L24.2004 12.0391L25.317 12.2154C27.9706 12.6345 30 14.9346 30 17.7059C30 20.7759 27.5112 23.2647 24.4411 23.2647H22.0141L22.0141 23.2647H20.3236V24.7647H22.7641L24.4411 24.7647C24.987 24.7647 25.5183 24.7028 26.0286 24.5855C29.1626 23.8653 31.5 21.0585 31.5 17.7059C31.5 14.185 28.9222 11.2662 25.551 10.7338C25.0141 6.37485 21.2982 3 16.7941 3C12.2263 3 8.46917 6.47091 8.0163 10.9192C7.98603 11.2165 7.97052 11.5182 7.97052 11.8235C7.97052 12.0239 7.9772 12.2226 7.99034 12.4196C7.88637 12.4144 7.78173 12.4118 7.67647 12.4118C7.28751 12.4118 6.90694 12.4477 6.53792 12.5165C3.67062 13.0508 1.5 15.566 1.5 18.5882C1.5 21.9008 4.10773 24.6043 7.38231 24.7578V24.7647H8.88231H13.2647V23.2647H7.56443Z" fill="#1A1B1D" />
      <path d="M17 17V28M17 17L13.5 20M17 17L20.5 20" stroke="#1A1B1D" strokeWidth="1.5" />
    </svg>
  )
}