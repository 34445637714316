import * as moment from "moment";
import { CURRENCY_SYMBOL } from "../../../../../../../common/constants/currency-symbol.const";
import { displayMoneyValue, floatFixed } from "../../../../../../../common/utils/number-display.utils";
import { isNullOrUndefinedOrEmptyString, isNullOrUndefinedOrZeroOrEmptyString } from "../../../../../../../common/utils/object.utils";
import { isRequired } from "../../../../../../add-item/data/input-validations";
import { createLabelFnWithPredictedValue } from "../../Events/PredictedAmountDisplay/PredictedAmountDisplay";
import { eventTypeGenerator } from "./createEventUtils";

const valueUpdateOnly = eventTypeGenerator(false, false, true);

const amortizationItemCreatedValueUpdate = ({ updateValue }) => ([
  {
    title: '',
    rows: [
      {
        fields: [
          {
            key: 'transactionValue', type: 'numberWithHoldings', label: 'Initial amount lent',
            validations: [isRequired,
              {
                fn: (value,formValues) => {
                    return value >= formValues.totalPrincipalPaid;
                },
                message:` `,
                bottomErrorMessage: (value, formValues) => {
                  return `The lent amount can’t be lower than the actual principal repayment amount (${displayMoneyValue(formValues.totalPrincipalPaid,formValues.currency)})`
                }
              }
            ],
            generatePropsFn: (values) => {
              return {
                holdings: values.holdings,
                prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                decimalScale: 2,
              }
            },
            onBlur: ({value, formValues}) => {
              if (!isNullOrUndefinedOrEmptyString(value)) {
                updateValue('updatedValue', value);
              }
            },
          }
        ]
      },
      {
        fields: [
          {
            key: 'updatedValue', type: 'numberWithHoldings', label: 'Updated loan balance',
            generatePropsFn: (values) => {
              return {
                prefix: CURRENCY_SYMBOL[values.currency] || '',
                placeholder: CURRENCY_SYMBOL[values.currency] || '',
                holdings: values.holdings,
                decimalScale: 2,
                disabled: true,
              }
            },
            onBlur: ({value, formValues}) => {
              if (!isNullOrUndefinedOrEmptyString(value)) {
                updateValue('transactionValue', value);
              }
            },
          }, {
            key: 'isTransactionLinked' , isHidden:true,
            onChange: ({value, formValues}) => {
              updateValue('updatedValue', formValues.transactionValue);
            },
            // validateFieldsOnChange: ['interestPayment', 'principalPayment', 'paymentSumValidation'],
          }
        ]
      }
    ]
  }
])

export const loanNonAmortizedEventTypeGenerator = (customRows = []) => {
  return ({ updateValue, updateField }) => ([
    {
      title: '',
      rows: [
        ...customRows.map(cr => typeof cr === 'function' ? cr({ updateValue, updateField }) : cr),
        { fields: [], type: 'separator' },
        { fields: [{ key: 'eventRemarks', type: 'textarea', label: 'Notes', rows: 2, placeholder: 'Add notes to remember' }] }
      ]
    }
  ])
}

const interestPaymentRowFn = ({ updateValue, updateField }) => {
  return {
    fields: [
      {
        key: 'originalPrincipalPayment', isHidden: true , 
        defaultValueFn: (values) => values.eventId ? (values.principalPayment || 0 ) : 0 ,
      },
      {
        key: 'principalPayment', type: 'numberWithHoldings',
        fieldCustomStyle: {marginBottom:'-28px'},
        size: 0.5,
        decimalScale: 2,
        validations:[
          {
            fn: (value, formValues) => {
              const { principalPayment, interestPayment } = formValues;
              return principalPayment || interestPayment;
            },
            message: ' ',
          },
            {
              fn: (value, formValues) => {
              const { transactionValue, principalPayment, interestPayment } = formValues;
              const shouldSkipValidation = ( transactionValue && !principalPayment && !interestPayment );
              const paymentsSum = ((principalPayment || 0) + (interestPayment || 0))
              const isValid = shouldSkipValidation ? true : ( paymentsSum >= transactionValue-1 && paymentsSum <= transactionValue+1);
              return isValid;
            },
            message: ' ',
          },
          {
            fn: (value, formValues) => {
              const { transactionValue, principalPayment, interestPayment , originalPrincipalPayment , currentItemValue } = formValues;
              const shouldSkipEqualValidation = !transactionValue ||  ( transactionValue && !principalPayment && !interestPayment);
              const isSumEqualValid = shouldSkipEqualValidation ? true : ((principalPayment || 0) + (interestPayment || 0)) === transactionValue ;
              const isPrincipalAmountValid = (principalPayment || 0 ) - originalPrincipalPayment <= +currentItemValue;
              return (!isSumEqualValid || !transactionValue) ? true : isPrincipalAmountValid;
            },
            message: ' ',
          }
        ],
        showValidationErrorOnBlur: true,
        validateFieldsOnBlur: ['paymentSumValidation', 'interestPayment'],
        skipBlurValidationFn: ({value}) => {
          return isNullOrUndefinedOrEmptyString(value)
        },
        generatePropsFn: (values) => {
          const labelFn = createLabelFnWithPredictedValue(<div>Principal <span style={{fontWeight:450}}>repayment</span></div>,values.predictedAttributes?.principalPayment,`The expected principal repayment`)
          return {
            label: labelFn,
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
            disabled: values.isConnected && moment(values.eventDate).isAfter(moment(), 'M'),
            holdings: values.holdings,
          }
        },
        onBlur: ({ value, formValues }) => {
          const { interestPayment, isTransactionLinked, valueAtDate } = formValues;
          // const isPrincipalOrInterestEmpty = isNullOrUndefinedOrEmptyString(value) || isNullOrUndefinedOrEmptyString(interestPayment);
          // updateField('transactionValue', {disabled: !isPrincipalOrInterestEmpty});
          const trnValue = (value || 0) + (interestPayment || 0);
          const newVal = (+valueAtDate || +formValues.value) - (value || 0);
          if (!isTransactionLinked) {
            // if other exists -> update total
            updateValue('transactionValue', trnValue);
            // if (!isNullOrUndefinedOrEmptyString(interestPayment)) {
            //   updateValue('transactionValue', trnValue);
            //   // updateField('transactionValue', {disabled: !isNullOrUndefinedOrEmptyString(value)});
            // } else if(!isNullOrUndefinedOrEmptyString(value)) {
            //   if (value >= transactionValue) {
            //     updateValue('transactionValue', value);
            //   } else {
            //     updateValue('interestPayment', transactionValue - value);
            //     // updateField('transactionValue', {disabled: true});
            //   }
            // } else { // both empty
              
            // }
          }
          updateValue('updatedValue', newVal > 0 ? newVal : 0);
        },
      },
      {
        key: 'interestPayment', type: 'numberWithHoldings',
        fieldCustomStyle: {marginBottom:'-28px'},
        size: 0.5,
        decimalScale: 2,
        validations:[
          {
            fn: (value, formValues) => {
              const { principalPayment, interestPayment } = formValues;
              return principalPayment || interestPayment;
            },
            message: ' ',
          },
            {
              fn: (value, formValues) => {
              const { transactionValue, principalPayment, interestPayment } = formValues;
              const shouldSkipValidation = ( transactionValue && !principalPayment && !interestPayment );
              const paymentsSum = ((principalPayment || 0) + (interestPayment || 0))
              const isValid = shouldSkipValidation ? true : ( paymentsSum >= transactionValue-1 && paymentsSum <= transactionValue+1);
              return isValid;
            },
            message: ' ',
          }
        ],
        showValidationErrorOnBlur: true,
        validateFieldsOnBlur: ['paymentSumValidation', 'principalPayment'],
        skipBlurValidationFn: ({value}) => {
          return isNullOrUndefinedOrEmptyString(value)
        },
        generatePropsFn: (values) => {
          const labelFn = createLabelFnWithPredictedValue(<div>Interest <span style={{fontWeight:450}}>payment</span></div>,values.predictedAttributes?.interestPayment,`The expected interest payment`)
          return {
            label: labelFn,
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
            holdings: values.holdings,
          }
        },
        onBlur: ({ value, formValues }) => {
          const { principalPayment, isTransactionLinked } = formValues;
          //const isPrincipalOrInterestEmpty = isNullOrUndefinedOrEmptyString(value) || isNullOrUndefinedOrEmptyString(principalPayment);
          // updateField('transactionValue', {disabled: !isPrincipalOrInterestEmpty});
          const trnValue = (value || 0) + (principalPayment || 0);
          // let newLoanBalance = (+valueAtDate || +formValues.value) - (principalPayment || 0);
          if (!isTransactionLinked) {
            // if other exists -> update total
            updateValue('transactionValue', trnValue);
            // if (!isNullOrUndefinedOrEmptyString(principalPayment)) {
            //   updateValue('transactionValue', trnValue);
            //   // updateField('transactionValue', {disabled: !isNullOrUndefinedOrEmptyString(value)});
            // } else if(!isNullOrUndefinedOrEmptyString(value)) {
            //   if (value >= transactionValue) {
            //     updateValue('transactionValue', value);
            //   } else {
            //     updateValue('principalPayment', transactionValue - value);
            //     // updateField('transactionValue', {disabled: true});
            //   }
            // } else { // both empty
              
            // }
          }
          // updateValue('updatedValue', newLoanBalance > 0 ? newLoanBalance : 0);
        },
      },
      {
        key: 'isTransactionLinked' , isHidden:true,
        onChange: ({value, formValues}) => {
          updateField('transactionValue', {isHidden: value});
        },
        validateFieldsOnChange: ['interestPayment', 'principalPayment', 'paymentSumValidation'],
      }
    ]
  }
};

const validationRowFn = ({ updateValue, updateField }) => {
  return {
    fields: [
      {
        key: 'paymentSumValidation',
        type: 'validation',
        validations:[
          {
            fn: (value, formValues) => {
              const { transactionValue, principalPayment, interestPayment , originalPrincipalPayment , currentItemValue } = formValues;
              // is not empty
              if (!principalPayment && !interestPayment){
                return false
              }
              // is equal to transaction value ( in case of linked )
              const paymentsSum = ((principalPayment || 0) + (interestPayment || 0))
              if ( !(paymentsSum >= transactionValue-1 && paymentsSum <= transactionValue+1) ){
                return false
              }
              // principal valid
              if ((principalPayment || 0) - originalPrincipalPayment > +currentItemValue){
                return false
              }
              return true;
            },
            message: ' ',
            bottomErrorMessage: (value, formValues) => {
              const { transactionValue, principalPayment, interestPayment , originalPrincipalPayment , currentItemValue } = formValues;
              // is not empty
              if (!principalPayment && !interestPayment){
                return `Either the principal or the interest is required`
              }
              // is equal to transaction value ( in case of linked )
              const paymentsSum = ((principalPayment || 0) + (interestPayment || 0))
              if ( !(paymentsSum >= transactionValue-1 && paymentsSum <= transactionValue+1) ){
                return `The principal and interest sum must equal the transaction value`
              }
              // principal valid
              if ((principalPayment || 0) - originalPrincipalPayment > +currentItemValue){
                return `The principal portion of the payment cannot exceed the current loan balance`
              }
            }
          },
        ],
        
      }
    ]
  }
}

const interestPaymentAttr = { fieldKey: 'interestPayment', dataKey: 'interestPayment' ,
  setFnForSave: (isDistribution, value, formValues) => {
    if (isNullOrUndefinedOrZeroOrEmptyString(value)) {
      return 0;
    }
    return value;
  }
};

const principalPaymentAttr = { fieldKey: 'principalPayment', dataKey: 'principalPayment',
  setFnForSave: (isDistribution, value, formValues) => {
    
    // if (isNullOrUndefinedOrZeroOrEmptyString(value) && isNullOrUndefinedOrZeroOrEmptyString(interestPayment) && !isNullOrUndefinedOrZeroOrEmptyString(transactionValue)) {
    //   return transactionValue;
    // }
    // return value;
    if (isNullOrUndefinedOrZeroOrEmptyString(value)) {
      return 0;
    }
    return value;
  }
};

const transactionValueFieldFn = (isContribution) => ({ updateValue, updateField }) => {
  return {
    fields: [
      {
        key: 'transactionValue',
        type: 'numberWithHoldings',
        description: 'The total amount paid in this transaction, including both principle and interest.',
        validations: [],
        defaultValue: null,
        disabled: true,
        generatePropsFn: (values) => {
          // const { principalPayment, interestPayment } = values;
          // const isPrincipalOrInterestEmpty = isNullOrUndefinedOrEmptyString(principalPayment) || isNullOrUndefinedOrEmptyString(interestPayment);
          const labelFn = createLabelFnWithPredictedValue('Total distribution amount',values.eventPredictedAmount,`The expected distribution amount`)
          return {
            holdings: values.holdings,
            prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
            decimalScale: 2,
            label: labelFn,
            // disabled: !isPrincipalOrInterestEmpty || (values.isConnected && moment(values.eventDate).isSameOrAfter(moment(), 'M')),
            isHidden: values.isTransactionLinked
          }
        },
        onBlur: ({ value, formValues }) => {
          const { principalPayment, interestPayment, valueAtDate } = formValues;
          // let shouldDisableTransactionField = false;
          if ( !isNullOrUndefinedOrEmptyString(value) ) {
            if ( !isNullOrUndefinedOrEmptyString(principalPayment) ){
              const totalMinusPrincipal = value - principalPayment;
              if (totalMinusPrincipal > 0){
                // shouldDisableTransactionField = true;
                updateValue('interestPayment', totalMinusPrincipal);
              } else if (isNullOrUndefinedOrEmptyString(interestPayment)) {
                // update loan balance
                const newVal = value - totalMinusPrincipal > 0 ? value - totalMinusPrincipal : 0;
                const newLoanBalance = valueAtDate - newVal;
                updateValue('updatedValue', floatFixed(newLoanBalance, 2));
                updateValue('principalPayment', value);
              }
            } else if ( !isNullOrUndefinedOrEmptyString(interestPayment) ){
              const totalMinusInterest = value - interestPayment;
              if (totalMinusInterest > 0){
                // shouldDisableTransactionField = true;
                // update loan balance
                const newVal = value - totalMinusInterest > 0 ? value - totalMinusInterest : 0;
                const newLoanBalance = valueAtDate - newVal;
                updateValue('updatedValue', floatFixed(newLoanBalance, 2));
                updateValue('principalPayment', totalMinusInterest);
              } else if (isNullOrUndefinedOrEmptyString(principalPayment)) {
                updateValue('interestPayment', value);
              }
            }
          } else {
            updateValue('principalPayment', '');
            updateValue('interestPayment', '');
          }
          // updateField('transactionValue', {disabled: shouldDisableTransactionField});
        }
      }
    ]
  }
}


const valueUpdateFieldWithDefaultFn = ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'updatedValue',
        type: 'numberWithHoldings',
        label: 'Updated loan balance',
        showPrevComp: false,
        defaultValueFn: (values) => values.value,
        onBlur: ({ value, formValues }) => {
          if ( !isNullOrUndefinedOrEmptyString(value) && formValues.valueAtDate && formValues.interestPayment ) {
            const newPrincipalValue = formValues.valueAtDate - value;
            updateValue('principalPayment', newPrincipalValue);
            updateValue('transactionValue', newPrincipalValue + formValues.interestPayment);
          }
          if(value === 0){
            updateValue('eventTitle','End date');
            if (isNullOrUndefinedOrEmptyString(formValues.eventRemarks)) {
              updateValue('eventRemarks','Last payment 🎉');
            }
          }
        },
        validations: [
          // {
          //   fn: (value, formValues) => {
          //     return  +formValues.valueAtDate >= +value
          //   },
          //    message:' ',
          //   bottomErrorMessage: (value, formValues) => {
          //     // if (formValues.transactionValue ){
          //       return `Value can’t be higher than your remaining balance: ${displayMoneyValue(formValues.valueAtDate, formValues.currency)}`
          //     // }
          //   }
          // }
        ],
        generatePropsFn: (values) => {
          return {
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
            holdings: values.holdings,
            decimalScale: 2,
            disabled: true,
          }
        },
      }
   ]
  }
}

const contributionFn = ({ updateValue }) => ([
  {
    title: '',
    rows: [
      {
        fields: [
          {
            key:'transactionValue', type: 'numberWithHoldings', 
            validations:[isRequired],
            description: `Costs incurred by the lender for processing, regulatory compliance, and other operational activities related to the loan.`,
            generatePropsFn : (values)=>{
                const labelFn = createLabelFnWithPredictedValue(
                  'Contribution value',
                  values.eventPredictedAmount,
                  `The expected contribution amount`)
                return {
                    holdings: values.holdings,
                    label:labelFn,
                    prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                    placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                    decimalScale: 2, 
                }
            },
          }
        ]
      },
    ]
  }
])

const lentMoreMoneyContributionRowFn = ({ updateValue }) => {
  return {
    fields: [
      {
        key:'transactionValue', type: 'numberWithHoldings',
        validations:[isRequired],
        label: 'Lent amount (contribution)',
        generatePropsFn: (values) => {
            return {
                holdings: values.holdings,
                prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                decimalScale: 2, 
            }
        },
        onBlur: ({value, formValues}) => {
          const { valueAtDate } = formValues;
          const newLoanBalance = (+valueAtDate || +formValues.value) + (value || 0);
          updateValue('updatedValue', newLoanBalance);
        },
      }
    ]
  }
}

const lentMoreMoneyValueUpdateRowFn = ({ updateValue }) => {
  return {
    fields: [
      {
        key: 'updatedValue', type: 'numberWithHoldings', label: 'Updated loan balance',
        generatePropsFn: (values) => {
          return {
            prefix: CURRENCY_SYMBOL[values.currency] || '',
            placeholder: CURRENCY_SYMBOL[values.currency] || '',
            holdings: values.holdings,
            decimalScale: 2,
            disabled: true,
          }
        },
        onBlur: ({value, formValues}) => {
          if (!isNullOrUndefinedOrEmptyString(value)) {
            updateValue('transactionValue', value);
          }
        },
      }
    ]
  }
}

const distributionFn = ({ updateValue }) => ([
  {
    title: '',
    rows: [
      {
        fields: [
          {
            key:'transactionValue', type: 'numberWithHoldings', 
            validations:[isRequired],
            description: `Extra fees charged to the borrower, such as late payment fees, origination fees, and other non-interest charges, not related to the loan’s principal.`,
            generatePropsFn : (values)=>{
                const labelFn = createLabelFnWithPredictedValue(
                  'Distribution value',
                  values.eventPredictedAmount,
                  `The expected distribution amount`)
                return {
                    holdings: values.holdings,
                    label:labelFn,
                    prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                    placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                    decimalScale: 2, 
                }
            },
          }
        ]
      },
    ]
  }
])

const paymentEventDeleteEnableFn = (isItemConnected,isLastPredictedApprovedEvent,isCanceled,hasHappened) => {
  return (isItemConnected) ? false : (isLastPredictedApprovedEvent || isCanceled || !hasHappened);
}

const paymentEventSaveEnableFn = (hasHappened,isItemConnected) => {
  return !(isItemConnected && !hasHappened);
}

const shouldShowTargetModalAfterPaymentSaved = (formValues) => {
  if (formValues.predictedAttributes !== null){
    return (formValues.predictedAttributes?.interestPayment !== formValues.interestPayment || formValues.predictedAttributes?.principalPayment !== formValues.principalPayment)
  }
  return false;
}

export const privateLoanNonAmortizedEvents = [
  {
    title: 'Principal / Interest payment', eventType: 'Interest and principal repayment', sections: loanNonAmortizedEventTypeGenerator([
      transactionValueFieldFn(true),
      interestPaymentRowFn,
      validationRowFn,
      valueUpdateFieldWithDefaultFn,
    ]), attributesToSend: [interestPaymentAttr, principalPaymentAttr], valuesForEditFromItem: [{dataKey:'isConnected', itemDataKey:'isConnected'}],
    isDistribution: true,
    sendValueUpdateAnyway: true,
    saveEnable: ({hasHappened,isItemConnected}) => paymentEventSaveEnableFn(hasHappened,isItemConnected),
    assetInfoLabelId: 'private-loan-non-amortized-payments',
    showTargetModalAfterCreate: true,
    targetFieldLink: 'private-loan-non-amortized-payments',
    showTargetModalAfterSaveFn: ({formValues}) => shouldShowTargetModalAfterPaymentSaved(formValues)
  },
  { title: 'Lent more money', eventType:'Additional cash lent' , sections: loanNonAmortizedEventTypeGenerator([
    lentMoreMoneyContributionRowFn,
    lentMoreMoneyValueUpdateRowFn
  ]), showTargetModalAfterCreate: true, targetFieldLink: 'private-loan-non-amortized-payments' , showTargetModalAfterSaveFn: ({formValues}) => true},
  { title: 'Additional Expenses/Fees', eventType:'General expenses' , sections: contributionFn},
  { title: 'Additional Charges/Income', eventType:'Distribution' , sections: distributionFn, isDistribution: true},
  {
    title: 'End date',
    eventType: 'Interest and principal repayment',
    sections: loanNonAmortizedEventTypeGenerator([
      transactionValueFieldFn(true),
      interestPaymentRowFn,
      validationRowFn,
      valueUpdateFieldWithDefaultFn,
    ]),
    isDistribution: true,
    attributesToSend: [interestPaymentAttr, principalPaymentAttr],
    valuesForEditFromItem: [{dataKey:'isConnected', itemDataKey:'isConnected'}],
    isHidden: true,
    deleteEnable: ({isItemConnected,isLastPredictedApprovedEvent}) => paymentEventDeleteEnableFn(isItemConnected,isLastPredictedApprovedEvent),
    saveEnable: ({hasHappened,isItemConnected}) => paymentEventSaveEnableFn(hasHappened,isItemConnected)
  },
  { title: 'Value update', isHidden: true, sections: valueUpdateOnly },
  { title: 'Item created', isHidden: true, sections: amortizationItemCreatedValueUpdate, deleteEnable: false, valuesForEditFromItem: [{dataKey:'totalPrincipalPaid', itemDataKey:'totalPrincipalPaid'}] },
]

