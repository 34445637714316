import { LoadingOutlined } from '@ant-design/icons';
import { Observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useTrackEvent, useTrackGTMEvent } from '../../../../hooks/useTrackEvent/useTrackEvent';
import { VipSyncIntroText } from '../../../add-item/components/AddItem.styles';
import { InstitutionSearchInputAndResults } from '../../../add-item/components/InstitutionSearchInputAndResults';
import { AddItemPlaidAccountsStep } from '../../../add-item/components/steps/AddItemPlaidAccountStep';
import { AddItemSaltedgeAccountsStep } from '../../../add-item/components/steps/AddItemSaltedgeAccountStep';
import { ADD_ITEM_STEP } from '../../../add-item/data/create-item.store';
import { useStore } from '../../../app/data/root.store';
import { ArrowDownIcon, AutoTrackIcon, LearnSyncCashAccountIcon, OnlineSyncIcon, SecurityIcon } from '../../assets/userActivationIcons';
import { AnimatedText } from '../ChatRow/AnimatedText';
import { ChatRow } from '../ChatRow/ChatRow';
import { ActionButton, ActionButtonsWrapper, ActivationFlowStepWrapper, AnimatedActivationFlowWrapper, LearnPromptButton, LearnPromptHoverEffect, LearnPromptWrapper, MenuItemWrapper, MenuTitle, MenuWrapper, PlaidProviderImage, ProvidersWrapper, SaltedgeProviderImage, StepContent, StepFooter, WhatYouGetMenuItemIcon, WhatYouGetMenuItemSubTitle, WhatYouGetMenuItemTextContent, WhatYouGetMenuItemTitle, WhatYouGetMenuItemWrapper } from '../UserActivationFlowPage.styles';
import { useAnimationsStepDelayedHook } from '../useAnimationsStepDelayedHook';
import { VipSyncConsent } from '../../../add-item/components/steps/VipSyncFlow/steps-vip-sync/VipSyncConsent';
import { VipSyncEmailSent } from '../../../add-item/components/steps/VipSyncFlow/steps-vip-sync/VipSyncEmailSent';
import { VipSyncSelectedInstitutionRow } from '../../../add-item/components/steps/VipSyncFlow/steps-vip-sync/VipSyncIntro';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { AddItemAkoyaAccountsStep } from '../../../add-item/components/steps/AddItemAkoyaAccountStep';
import { ROUTES } from '../../../../common/constants/routes.const';

const vipSyncActionButtonTextByIndex = {
  1: 'Submit',
  2: 'Done',
}

const vipMixpanelByIndex = {
  0: {
    cta_text: 'Next',
    destination: 'Service Agreement',
  },
  1: {
    cta_text: 'Submit',
    destination: 'Success Screen',
  },
  2: {
    cta_text: 'Done',
    destination: 'Synced Account Page',
  },
}

export const SyncCashAccountStep = (props) => {
  const { uiStore, createItemStore, userStore } = useStore();
  // const animationStep = useAnimationsStepDelayedHook({ animationsStepDelayed: [2000, 1200, 1200, 1200] });
  const animationStep = useAnimationsStepDelayedHook({ animationsStepDelayed: [700] });
  const [isLoadingAddingAccount, setIsLoadingAddingAccount] = useState(false);
  const [vipSyncStepIndex, setVipSyncStepIndex] = useState(-1);
  const [isConsentApproved, setIsConsentApproved] = useState(false);
  const { trackEvent } = useAnalytics()
  const mixpanelStepsData = { step_name: 'Sync your accounts', step_number: 3 };

  useEffect(() => {
    createItemStore.setInstitutionStep('bank');
    return () => { }
  }, [createItemStore])

  useEffect(() => {
    if (vipSyncStepIndex === 0) {
      trackEvent('VIP Syncing Service Viewed', {
        step_name: 'Service Overview',
        step_number: 1,
        institution: createItemStore.selectedVipSyncInstitution.name,
        url: window.location.href
      });
    }

    // return () => {}
  }, [trackEvent, createItemStore.selectedVipSyncInstitution, vipSyncStepIndex])


  const handleContinueOnClick = async (skip) => {
    if (skip) {
      trackEvent('Onboarding Step Completed', mixpanelStepsData);
      await uiStore.setWelcomeDone();
      window.location.href = ROUTES.wealthOverview.url;
      return;
    }
    if (vipSyncStepIndex !== -1) {
      trackEvent('CTA Clicked', {
        cta_text: vipMixpanelByIndex[vipSyncStepIndex].cta_text,
        destination: vipMixpanelByIndex[vipSyncStepIndex].destination,
        location: 'VIP Sync Service',
        type: 'Button',
        institution: createItemStore.selectedVipSyncInstitution.name,
      });

      if (vipSyncStepIndex === 1 && !isConsentApproved) return;
      else if (vipSyncStepIndex === 1) {
        createItemStore.createVipAsset();
      }
      if (vipSyncStepIndex === 2) {
        uiStore.setWelcomeDone();
        window.location.href = ROUTES.wealthOverview.url;
        return;
      }
      setVipSyncStepIndex(vipSyncStepIndex + 1);
    }
    if (!createItemStore.hasAnyProviderAccounts) return;
    await uiStore.setWelcomeDone();
    if (createItemStore.step === ADD_ITEM_STEP.PLAID_ACCOUNTS) {
      setIsLoadingAddingAccount(!createItemStore.hasAnyInvalidAccountSelection);
      createItemStore.plaidAccountsContinue((data) => {
        trackEvent('Onboarding Step Completed', mixpanelStepsData);
        window.location.href = ROUTES.wealthOverview.url;
      })
    } else if (createItemStore.step === ADD_ITEM_STEP.SALTEDGE_ACCOUNTS) {
      setIsLoadingAddingAccount(!createItemStore.hasAnyInvalidAccountSelection);
      createItemStore.saltedgeAccountsContinue((data) => {
        trackEvent('Onboarding Step Completed', mixpanelStepsData);
        window.location.href = ROUTES.wealthOverview.url;
      })
    } else if (createItemStore.step === ADD_ITEM_STEP.AKOYA_ACCOUNTS) {
      setIsLoadingAddingAccount(!createItemStore.hasAnyInvalidAccountSelection);
      createItemStore.akoyaAccountsContinue((data) => {
        trackEvent('Onboarding Step Completed', mixpanelStepsData);
        window.location.href = ROUTES.wealthOverview.url;
      })
    }
  }

  const handleBackButtonOnClick = () => {
    if (vipSyncStepIndex > -1) {
      return setVipSyncStepIndex(vipSyncStepIndex - 1);
    }
    if (createItemStore.isAnyProviderInProcess) return;
    uiStore.setActivationStepIndex(uiStore.activationStepIndex - 1);

  }

  useTrackEvent('Onboarding Step Viewed', mixpanelStepsData);
  useTrackGTMEvent('Onboarding Step Viewed', mixpanelStepsData);

  const isWithoutInstitutionSearchInputAndResults = [ADD_ITEM_STEP.PLAID_ACCOUNTS, ADD_ITEM_STEP.SALTEDGE_ACCOUNTS, ADD_ITEM_STEP.AKOYA_ACCOUNTS]

  return (<Observer>{() => (
    <ActivationFlowStepWrapper>

      {/* {animationStep > 0 && */}
      <StepContent withFixedActions>
        {vipSyncStepIndex < 1 ? <ChatRow duration={0}>
          {/* <AnimatedText delay={1000} animationSpeed={1000} text={`Sync your bank account to track the inflow and outflow of your investments.`} /> */}
          <span>Sync your bank account to track the inflow and outflow of your investments.</span>
        </ChatRow> : null}
        {/* <HeightAnimationWrapper show duration={0} height={createItemStore.isAnyProviderInProcess ? 100 : 600}> */}

        {!isWithoutInstitutionSearchInputAndResults.includes(createItemStore.step) && vipSyncStepIndex === -1 && <AnimatedActivationFlowWrapper padding={uiStore.isDesktopView ? '16px 0 16px 62px' : '16px 0px'}><InstitutionSearchInputAndResults isActivationFlow setVipSyncStepIndex={setVipSyncStepIndex} /></AnimatedActivationFlowWrapper>}
        {createItemStore.step === ADD_ITEM_STEP.PLAID_ACCOUNTS && <AnimatedActivationFlowWrapper padding={uiStore.isDesktopView ? '16px 0px 16px 62px' : '16px 0px'}><AddItemPlaidAccountsStep isActivationFlow onFinish={(metadataStore) => { userStore.updateData(); metadataStore.fetchData(); }} /></AnimatedActivationFlowWrapper>}
        {createItemStore.step === ADD_ITEM_STEP.SALTEDGE_ACCOUNTS && <AnimatedActivationFlowWrapper padding={uiStore.isDesktopView ? '16px 0 16px 62px' : '16px 0'}><AddItemSaltedgeAccountsStep isActivationFlow onFinish={(metadataStore) => { userStore.updateData(); metadataStore.fetchData(); }} /></AnimatedActivationFlowWrapper>}
        {createItemStore.step === ADD_ITEM_STEP.AKOYA_ACCOUNTS && <AnimatedActivationFlowWrapper padding={uiStore.isDesktopView ? '16px 0 16px 62px' : '16px 0'}><AddItemAkoyaAccountsStep isActivationFlow onFinish={(metadataStore) => { userStore.updateData(); metadataStore.fetchData(); }} /></AnimatedActivationFlowWrapper>}
        {vipSyncStepIndex === 0 && <><AnimatedActivationFlowWrapper padding={uiStore.isDesktopView ? '16px 0 16px 62px' : '16px 0'}>
          <VipSyncSelectedInstitutionRow item={createItemStore.selectedVipSyncInstitution} isFullWidth />
        </AnimatedActivationFlowWrapper>
          <ChatRow>
            <VipSyncIntroText style={{ padding: '0', textAlign: 'start', maxWidth: '100%' }}>
              Our VIP Syncing Service requires <span style={{ color: '#1A1B1D', fontWeight: '700' }}>read-only</span> access to your account.
              <br />
              <br />
              Please note that this setup process may take some time, depending on how quickly {createItemStore.selectedVipSyncInstitution.name} responds. Once complete, your account will auto-sync with Vyzer's VIP service.
              <br />
              <br />
              Hit 'Continue' to sync this account.
            </VipSyncIntroText>
          </ChatRow>
        </>}
        {vipSyncStepIndex === 1 && <>
          <ChatRow>
            Please approve permission for read-only mode access to your {createItemStore.selectedVipSyncInstitution.name} account
          </ChatRow>
          <ChatRow textOnly customTextWrapperStyle={{ backgroundColor: '#fff' }}>
            <VipSyncSelectedInstitutionRow item={createItemStore.selectedVipSyncInstitution} isFullWidth withoutPadding resetWhiteSpace />
            <VipSyncConsent isActivationFlow isConsentApproved={isConsentApproved} setIsConsentApproved={setIsConsentApproved} userStore={userStore} item={createItemStore.selectedVipSyncInstitution} />
          </ChatRow>
        </>}
        {vipSyncStepIndex === 2 && <><ChatRow>
          <AnimatedText animationSpeed={200} text={`Perfect! 👏`} />
        </ChatRow>
          <ChatRow textOnly customTextWrapperStyle={{ backgroundColor: '#fff', minHeight: '224px', display: 'grid', placeItems: 'center' }}>
            <VipSyncEmailSent userStore={userStore} isActivationFlow item={createItemStore.selectedVipSyncInstitution} />
          </ChatRow>
        </>}

        {/* </HeightAnimationWrapper> */}

        {createItemStore.isAnyProviderInProcess ? <>
          <ChatRow>
            {/* <AnimatedText animationSpeed={10} text={`Your account is being synced to Vyzer. `} />
            <AnimatedText animationSpeed={10} text={`Do not close your browser.`} style={{ color: '#FF0000' }} /> */}
            <span>Your account is being synced to Vyzer.</span>
            <span style={{ color: '#FF0000' }}> Do not close your browser.</span>
          </ChatRow>
          <ChatRow>
            {/* <AnimatedText animationSpeed={10} text={`This process may take some time.`} /> */}
            <span>This process may take some time.</span>
          </ChatRow>
        </> : null}

        {createItemStore.isInstitutionConnectFail && !createItemStore.isAnyProviderInProcess ? <ChatRow>
          <AnimatedText delay={1000} text={`Sorry ${userStore.firstNameCapitalize}, it looks like something went wrong and we couldn’t sync to your account.
Please try again. Remember, do not close your browser until your account has successfully synced.`} />
        </ChatRow> : null}

        {createItemStore.hasAnyProviderAccounts ? <ChatRow>
          <AnimatedText delay={1000} text={`Perfect! 👏 You can sync more accounts later.`} />
        </ChatRow> : null}


      </StepContent>
      {/* } */}


      <StepFooter isFixedToBottom>
        <ActionButtonsWrapper>
          {uiStore.isDesktopView && vipSyncStepIndex !== 2 ? <ActionButton disabled={createItemStore.isAnyProviderInProcess} isBack onClick={handleBackButtonOnClick}>Back</ActionButton> : null}
          {vipSyncStepIndex === -1 ? <div onClick={() => { handleContinueOnClick(true) }} style={{ display: 'grid', placeItems: 'center', cursor: 'pointer', padding: uiStore.isDesktopView ? '0' : '0 16px' }}>Skip</div> : null}
          <ActionButton disabled={(!createItemStore.hasAnyProviderAccounts && vipSyncStepIndex === -1) || (vipSyncStepIndex === 1 && !isConsentApproved)} onClick={() => { handleContinueOnClick(false) }} withIcon black style={ uiStore.isDesktopView ? { padding: '8px 12px 8px 24px' } : {}}>{vipSyncActionButtonTextByIndex[vipSyncStepIndex] || 'Continue'} {isLoadingAddingAccount ? <LoadingOutlined /> : uiStore.isDesktopView ? <ArrowDownIcon /> : null} </ActionButton>
        </ActionButtonsWrapper>
      </StepFooter>

      {(uiStore.isDesktopView && animationStep > 0) && <LearnPromptWrapper>
        <LearnPromptButton>
          <LearnSyncCashAccountIcon />
          Learn
        </LearnPromptButton>
        <LearnPromptHoverEffect><LearnMenu /></LearnPromptHoverEffect>
      </LearnPromptWrapper>}

    </ActivationFlowStepWrapper>
  )}</Observer>)
}

const LearnMenu = (props) => {
  return (
    <MenuWrapper>
      <MenuTitle>Why sync your bank accounts?</MenuTitle>

      <MenuItemWrapper>
        <WhatYouGetMenuItemWrapper>
          <WhatYouGetMenuItemIcon>
            <OnlineSyncIcon />
          </WhatYouGetMenuItemIcon>
          <WhatYouGetMenuItemTextContent>
            <WhatYouGetMenuItemTitle>View your most current balances</WhatYouGetMenuItemTitle>
            <WhatYouGetMenuItemSubTitle>Your Vyzer account will always display the most up-to-date numbers for your balances.</WhatYouGetMenuItemSubTitle>
          </WhatYouGetMenuItemTextContent>
        </WhatYouGetMenuItemWrapper>
      </MenuItemWrapper>

      <MenuItemWrapper>
        <WhatYouGetMenuItemWrapper>
          <WhatYouGetMenuItemIcon>
            <AutoTrackIcon />
          </WhatYouGetMenuItemIcon>
          <WhatYouGetMenuItemTextContent>
            <WhatYouGetMenuItemTitle>Track your performance</WhatYouGetMenuItemTitle>
            <WhatYouGetMenuItemSubTitle>Transactions will be automatically linked to relevant assets, so you will always have an idea of how your investments are performing.</WhatYouGetMenuItemSubTitle>
          </WhatYouGetMenuItemTextContent>
        </WhatYouGetMenuItemWrapper>
      </MenuItemWrapper>

      <MenuItemWrapper>
        <WhatYouGetMenuItemWrapper>
          <WhatYouGetMenuItemIcon>
            <SecurityIcon />
          </WhatYouGetMenuItemIcon>
          <WhatYouGetMenuItemTextContent>
            <WhatYouGetMenuItemTitle>Secure data syncing</WhatYouGetMenuItemTitle>
            <WhatYouGetMenuItemSubTitle>We use secure integrators to ensure that your data is kept safe.</WhatYouGetMenuItemSubTitle>
            <ProvidersWrapper>
              <PlaidProviderImage />
              <SaltedgeProviderImage />
            </ProvidersWrapper>
          </WhatYouGetMenuItemTextContent>
        </WhatYouGetMenuItemWrapper>
      </MenuItemWrapper>

    </MenuWrapper>
  )
}