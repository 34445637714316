import styled from '@emotion/styled/macro';

export const PieWrapper = styled.div`
    position:relative;
    width:100%;
`

export const PieTextContent = styled.div`
    position:absolute;
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding-top: 12px;
    top:${props=>props.contentTop ? props.contentTop : '0px'};
    pointer-events:none;
`

export const PieTextLabel = styled.div`
    color:#9097A3;
    font-size:16px;
    line-height:12px;
`

export const PieTextBigValue = styled.div`
    color:#FFFFFF;
    font-size:${props=>props.isIncognito ? '22px' : props.fontSize ? props.fontSize : '32px'};
`

export const ClassIconWrapper = styled.div`
    width: 25px;
    height: 25px;   


    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        width:25px;
        height:25px;
    }
    path{
        /* stroke:#4F699D; */
        fill:${props=>props.color || '#9097A3'};
    }
`

export const ClassTitleText = styled.div`
    color: ${props=>props.color || '#fff'};
    width:50%;
    font-size:16px;
    line-height: 20px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical; 
`;

export const ClassSumText = styled.div`
    color: #9097A3;
    font-size:14px;
`;