import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";
import { animateSpin } from "../../../../../common/styles/animations.styles";
import { flexCenter } from "../../../../../common/styles/combos.styles";
import { colors, shadows, views, } from "../../../../../common/styles/theme.styles";

export const HeroWrapper = styled.div`
    
    /* background: #000000; */
    /* Card Shadow */
    /* box-shadow: ${shadows.cardShadow}; */
    /* border: 1px solid #DDE3EB; */
    /* border-radius: 8px; */
    // padding:62px;
    display:flex;
    flex-direction:column;
    /* margin-bottom:16px; */
    margin-top:${props => props.topOffset ? props.topOffset : 0}px;
    ${views.desktop} {
        height:325px;
    }
`;

export const AssetCardHeader = styled.div`
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${colors.darkGray1};
`;

export const AssetCardContent = styled.div`
    display: flex;
    flex: 1;
    ${views.tablet} {
        flex-direction: column;
    }
    ${views.desktop}{
        ${props=>props.noStats ? `justify-content: center;` : ``}
    }
`;

export const AssetCardMetricsPane = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    /* margin-right: 8px; */
    /* border-right: 1px solid ${props => props.isClassView ? colors.darkGray2 : colors.gray3}; */
    ${views.desktop} {
        flex: 5;
    }
`;

export const LogoLoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
`;

export const VerticalSeparator = styled.div`
    width: 1px;
    background-color: ${props => props.isClassView ? colors.darkGray2 : colors.gray3};
`;

export const AssetCardCircle = styled.div`
    width: 244px;
    height: 244px;
    border: 4px solid ${props => props.color ? props.color : '#DDE3EB'};
    border-radius: 50%;
    ${flexCenter}
    flex-direction: column;
    font-size:32px;
`;

export const AssetCardGraphPane = styled.div`
    flex: 3.8;
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    position: relative;
    ${views.tablet} {
        padding:24px 0px;
        align-items: center;
    }
    ${views.desktop}{
        align-items: ${props=>props.isItemClosed ? 'flex-start;' : 'center'};
        max-width: calc(100% * (3.8 / 8.8));
    }
`;

export const ItemDoughnutChartWrapper = styled.div`
    position: relative;
`;

export const ChartDataboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    /* z-index: 1; */
    top: 50%;
    left: 50%;
    min-width: 150px;
    transform: translate(-50%, -50%);
    /* ${props => props.isMultipartPie ? `
        top: 45%;
        transform: translateY(-50%);
        left: 50%;
        transform: translateX(-50%);
    ` : `
        top: 0;
        left: 0;
        bottom:0;
        right:0;
    `} */
`;

export const ChartDataboxWrapperToolTip = styled.div`
    display:none;
    position:absolute;
    top:-50px;
    min-height:87px;
    left:-40px;
    width:calc(100% + 100px);
    background: #FFFFFF;
    border: 1px solid #DDE3EB;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    /* padding: 12px 16px 8px 16px; */
    font-size: 24px;
    text-align: center;
    overflow: hidden;
    ${ChartDataboxWrapper}:hover & {
        ${props=>props.isMultipartPie ? `` : `
        display:flex;
        flex-direction: column;
        gap: 16px;
        `}
    }
    /* :hover{
        display:flex;
        flex-direction: column;
        gap: 16px;
    } */
`;

export const ItempageConnectedLiabilityTooltipHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 12px 0 12px;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.darkGray1};
    `;

export const ItempageConnectedLiabilityRow = styled(Link)`
    display: flex;
    justify-content: space-between;
    padding: 8px 16px 8px 16px;
    border-left: 2px solid #fff;
    :hover{
        border-left: 2px solid ${colors.darkGray1};
        background-color: ${colors.lightGray1};
    }
    `;

export const ItempageConnectedLiabilityRowInfo = styled.div`
    display: flex;
    align-items: center;
    `;

export const ItempageConnectedLiabilityRowInfoIcon = styled.div`
    background-color: red;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    `;

export const ItempageConnectedLiabilityRowAction = styled.div`
    ${flexCenter};
    svg{
        transform: rotate(-90deg);
    }
    `;

export const ItempageConnectedLiabilityRowInfoTitlesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    `;

export const ItempageConnectedLiabilityRowInfoMainTitle = styled.div`
    display: flex;
    color: #1A1B1D;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    `;

export const ItempageConnectedLiabilityRowInfoAltTitle = styled.div`
    display: flex;
    color: #9097A3;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    `;

export const RemainingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    `;

export const RemainingText = styled.span`
    color: ${colors.darkGray3};
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-right: 4px;
    `;

export const AssetCardMetricsRowSeparator = styled.div`
    background-color: ${props => props.isClassView ? colors.darkGray2 : colors.gray3};
    /* width: 368px; */
    width: calc(100% - 96px);
    height: 1px;
    margin: 0 auto 16px;
    `;

export const StatInfoBoxWrapper = styled.div`
    padding: 12px;
    box-sizing: content-box;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
    border-radius: 7px;
    ${views.desktop} {
        width:100%;
    }
    ${views.tablet} {
        box-shadow:none;
    }
    `;

export const ClassViewStatInfoBoxWrapper = styled.div`
    padding: ${props => props.isClassView ? '0' : (props.isWealthView ? `12px 12px 16px 12px` : `12px`)};
    box-sizing: content-box;
    width:100%;
   
    ${views.desktop} {
        background: ${colors.darkGray1};
        border: 1px solid #2E2F32;
        border-radius: 8px;     
    }
`;


export const StatsRow = styled.div`
    display:flex;
    flex-direction:row;
    margin:0;
    padding: 0 24px;
    gap: 16px;
    ${views.desktop}{
        padding: 0 40px 0 0;
        gap:0;    
    }
    `;

export const HeroDescriptionBig = styled.div`
    font-size:14px;
    line-height:20px;
    color:${colors.darkGray1};
    ${flexCenter};
    svg {
        margin-left:8px;
        path {
            stroke:#9097A3;
        }
    }
    ${props => props.clickable ? `cursor:pointer;` : ``}
    
    `;

export const StatsColumn = styled.div`
    display: flex;
    ${props=>props.isHidden ? `visibility: hidden` : ``};
    flex: 1;
    padding: 6px 6px;
    `;

export const HeroWorthValue = styled.div`
    display: flex;
    color: #AEAF76;
    font-size: ${props => props.isSelected ? 24 : 14}px;
    gap: 6px;
    // font-weight:700;
    line-height: 38px;
    `;

export const HeroWorthHoldingsValue = styled(HeroWorthValue)`
    color:${colors.darkGray1};
    font-size:32px;
    gap: 0;
    font-weight: 450;
    `;

export const HeroWorthHoldingsBadge = styled.div`
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
`;

export const HeroWorthHoldingsBadgeText = styled.div`
    background-color: ${colors.holdingsBadgeColor};
    padding: 1px 2px 1px 4px;
    border-radius: 4px 0px 0px 4px;
    `;

export const HeroWorthHoldingsBadgeTriangle = styled.div`
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid ${colors.holdingsBadgeColor};
    transform: translateX(-1px);
`;

export const HeroSyncDescription = styled.div`
    display: flex;
    align-items: center;
    font-size:12px;
    color:${colors.darkGray1};
    padding-bottom: 4px;
`;

export const SyncIconWrapper = styled.div`
    svg{
        path{
            fill:${colors.darkGray1};
        }
    }
    animation-name: ${animateSpin};
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    ${flexCenter};
    margin-right:4px;
`;

export const MetricBarsLegendWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 30px 0;
    /* margin-right: 30px; */
    gap: 16px;
    /* padding: 16px 0; */
    `;

export const TargetLegendWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    `;

export const ActualLegendWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    `;

export const TargetLegend = styled.div`
    background-color: #FFCA7B;
    width: 8px;
    height: 8px;
    border-radius: 2px;
    `;

export const ActualLegend = styled(TargetLegend)`
    background-color: #607DE6;
`;


export const ConnectedLiabilitiesTooltipWrapper = styled.div`
    display:none;
    // display: flex;
    align-items: center;
    position:absolute;
    flex-direction: column;
    max-width: 350px;
    
    
    left: 50%;
    transform: translate(-50%);
    background: #FFFFFF;
    border: 1px solid #DDE3EB;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;      
    
    ${views.desktop}{
        bottom:0;
        
    }
    ${views.tablet}{
        top: 40px;
        z-index: 3;
    }
`;

export const ChartAssetDataboxWrapperToolTip = styled.div`
        display:none;
        align-items: center;
        position:absolute;
        max-width: 350px;
        
        min-height:58px;
        left: 50%;
        transform: translate(-50%);
        background: #FFFFFF;
        border: 1px solid #DDE3EB;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;      
        padding-right:16px ;
        ${views.desktop}{
            bottom:0;
            
        }
        ${views.tablet}{
            top: 40px;
            z-index: 3;
        }
    `;

export const LiabilityItemWrapper = styled.div`
    display: flex;
    padding: 8px;
    :not(:last-of-type){
        border-bottom: 1px solid ${colors.lightGray2};
    }
    align-self: stretch;
    align-items: center;
`;

export const ItemLogoWrapper = styled.div`
    height:24px;
    width:24px;
    min-width:24px;
    border-radius: 40px;
    margin-left: 16px;
    margin-right: 8px;
    /* margin-right:12px; */
    img{
        height:24px;
        width:24px;
    }
    margin-top:4px;
`;

export const LoanPageConnectedAssetRowAction = styled.div`
    transform: rotate(-90deg);
    /* height: 30px;
    width: 16px; */
    /* margin-right: 16px; */
    
    `;

export const LoanPageConnectedAssetsTxtWrraper = styled.div`
    font-size: 14px;
    line-height: 17px;
    white-space: nowrap;
    margin-right: 40px;
    flex:1;
    max-width: calc(100% - 90px);
`;

export const LoanPageConnectedAssetTitle = styled.div`
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const LoanPageConnectedAssetCategory = styled.div`
    color: ${colors.darkGray3};
    font-weight: 400;
    `;
export const HeroConnectedAssetWrapper = styled.div`
    ${props=>props.isRelative ? `
        position: relative;
    ` : `
        position:absolute;
        top: ${props.withHoldings ? '96' : '80'}px;
    `}
    :hover{
        ${ChartAssetDataboxWrapperToolTip} {
            display: flex;
        }
        ${ConnectedLiabilitiesTooltipWrapper}{
            display: flex;
        }
    }
`;

export const HeroConnectedAssetTitleBtnWrapper = styled.div`
    cursor: pointer;

    ${props => props.isClosedAsset ? `
        display: flex;
        gap: 4px;
        align-items: center;
        padding-top: 8px;
    ` : ``}
    

`;

export const HeroConnectedAssetTitle = styled.div`
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 1px;
`;

export const HeroConnectedAssetBtn = styled.div`
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: ${props => props.isClosedAsset ? 'none' : colors.lightGray2};
`;

export const HeroTotalCryptoCoinCount = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.darkGray3};
    margin-top: 8px;
`;

export const HeroTotalStockValueWrapper = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: ${colors.darkGray1};
    margin-top: 8px;
    display: flex;
    align-items: center;
    ${props=>props.isBigNumber ? `flex-direction: column` : ``}
`;


export const HoldingsQuantityBadgeWrapper = styled.div`
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    // position:absolute;
    // left:8px;
    // top: 50%;
    // transform: translateY(-50%);
    
`;

export const HoldingsQuantityBadgeText = styled.div`
    background-color: ${colors.holdingsBadgeColor};
    width:8px;
    height:12px;
    border-radius: 2px 0px 0px 2px;
`;

export const HoldingsQuantityBadgeTriangle = styled.div`
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid ${colors.holdingsBadgeColor};
    transform: translateX(-1px);
`;
export const HoldingValueTextColor = styled.span`
    color: #AEAF76;
`;

export const HeroTotalLastStockUpdateDateWrapper = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.darkGray3};
    margin-bottom: 4px;
`;

export const MobileStatsWrapper = styled.div`
    
    width:100%;
    padding:0 0 24px 0;
    color:white;
`;

export const MobileCarouselBoxWrapper = styled.div`
    padding: 0 8px;
    // height: 112px;
`

export const MobileStatBoxWrapper = styled.div`
    border: 1px solid ${props=>props.isItemView ? '#DDE3EB' : '#25272C'};
    border-radius: 8px;
    // margin:0px 8px;
    // padding:16px;
    // width: 100%;
    padding: ${props=>props.padding || 0}px;

`;

export const MobileStatBoxTitle = styled.div`

`;

// export const Mobile